<div class="surface-50">
    <div class="grid">

        <div class="col-12 flex flex-column">
            <app-navbar></app-navbar>
        </div>
        <div class="col-12 flex flex-column">
            <p-table [value]="batidas" responsiveLayout="scroll" [globalFilterFields]="['data']">         
                <ng-template pTemplate="header">
                    <tr>
                        <th>Empresa</th>
                        <th>ID User</th>
                        <th>
                            Data
                            <p-columnFilter type="date" field="data" display="menu"></p-columnFilter>
                        </th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-batida>
                    <tr>
                        <td>{{batida.empresa}}</td>
                        <td>{{batida.cod_identity}}</td>
                        <td>{{batida.data}}</td>   
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>      