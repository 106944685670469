import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { UtilsService } from '../../services/utils.service';
import {
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { Faturamento } from 'src/app/models/faturamento';
import { Column } from 'src/app/models/column';
import { ExportColumn } from 'src/app/models/exportcolumn';
import { ValidatorsErrosForm } from './validator';
import { MarginMota } from 'src/app/models/marginMota';
import { ContratoAtivoWayConsig } from 'src/app/models/contratoAtivoWayconsig';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { listProductsConvenios } from 'src/app/models/listProductsConvenios';
import { NavbarService } from 'src/app/services/navbar-service';


@Component({
  selector: 'app-agreements-requests',
  templateUrl: './agreements-requests.component.html',
  styleUrls: ['./agreements-requests.component.scss'],
})
export class AgreementsRequestsComponent implements OnInit {
  public userLogado: any;
  public clonedPedidos: { [s: string]: any } = {};
  public showModal = false
  public showModalMota = false
  public permission: any = {};
  public createFaturamentoForm: FormGroup;
  public createFormEmprestimo: FormGroup;
  public seaechFormMota: FormGroup;
  public listCategoriesProducts: any[] = []
  public SelectedlistCategories: any;
  date: Date | undefined;
  dateRhapp: Date;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  disabled: boolean = true;
  showModalEmprestimo: boolean = false;
  cols: Column[] = [];
  selectedCols: Column[] = [];
  exportColumn: ExportColumn[] = [];
  filterExecel: Faturamento[] = [];
  validatorErrors: ValidatorsErrosForm | any;
  requisicoes: any[] = [];
  statusRhApp: string;
  sitFolha: boolean = false;
  dialogAprove: boolean = false;
  totalRecordOrder: number;
  requestId:number;
  observacao: string = "";
  nomeFuncionario: string;
  dataMarginMota: MarginMota[] = [];
  contratosAtivosWayConsig: ContratoAtivoWayConsig[] = [];
  cpf:string;
  matricula: string;
  codConvenio: string;
  margemId: string;
  valorParcela: string;
  reserva_margem: number;
  liqId: number;
  metaKeySelection: boolean = true;
  balanceFrozen: boolean = true;
  public listConvenios: listProductsConvenios[] = [];
  showModalConvenioAtivo: boolean = false;
  typeProventos: boolean = false;
  typeDesconto: boolean = false;
  navbarVisible: boolean;
 
  
  constructor(
    public api: ApiService,
    public storageService: StorageService,
    private messageService: MessageService,
    private utilsService: UtilsService,
    public formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    public vocationService: VacationService,
    public navbarService: NavbarService
  ) { 
    this.isRowSelectable = this.isRowSelectable.bind(this);
  }

  async ngOnInit() {

    this.cols = [
      { field: 'dataPedido', header: 'Data Pedido' },
      { field: 'requestId', header: 'Pedido' },
      { field: 'nome', header: 'Nome' },
      { field: 'telefone', header: 'Telefone' },
      { field: 'celular', header: 'Celular' },
      { field: 'email', header: 'Email' },
      { field: 'cpf', header: 'Cpf' },
      { field: 'admissao', header: 'admissão' },
      { field: 'nomeEmpresa', header: 'Empresa' },
      { field: 'descricaoProduto', header: 'Produto' },
      { field: 'precoFinal', header: 'Valor total' },
      { field: 'valorParcela', header: 'Parcela' },
      { field: 'empresaParceira', header: 'Loja' },
      { field: 'statusParceiro', header: 'Status Parceiro' },
      { field: 'statusPedido', header: 'Status Pedido' },
      { field: 'ultimaAtualizacao', header: 'Ultima Atualizacao' },
      { field: 'observacao', header: 'Observacao' }
    ]

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();

    let currentDate = today.getDate();
    let targetMonth = (currentDate >= 16) ? month + 1 : month;

    if (currentDate >= 16) {
      this.minDate = new Date(year, targetMonth, 15);
      this.maxDate = new Date(year, targetMonth, 15);
    } else {
      this.minDate = new Date(year, month, 15);
      this.maxDate = new Date(year, month, 15);

    }

    this.createFaturamentoForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      userId: ['', Validators.required],
      nomefuncionairo: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      descricaoProduto: ['', Validators.required],
      empresa: ['', Validators.required],
      dataPedido: ['', Validators.required],
      precoFinal: ['', [Validators.required, Validators.min(0.01)]],
      qtdParcelas: ['', Validators.required],
      valorParcela: ['', [Validators.required, Validators.min(0.01)]],
      date: ['', [Validators.required]],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      numeroContrato: ['']
    });

    this.createFormEmprestimo = this.formBuilder.group({
      pesquisar: [''],
      margem: [''],
      resultadoSaldo: [''],
      productCategory: [''],
      numeroContrato: [''],
      usuarioFk: [''],
      descricaoProduto: [],
      nomefuncionairo: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      empresa: ['', Validators.required],
      precoFinalEmprestimo: ['', Validators.required],
      qtdParcelasEmprestimo: ['', Validators.required],
      valorParcelaEmprestimo: ['', [Validators.required, Validators.min(0.01)]],
      dateEmprestimo: ['', [Validators.required]],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      matriculaFun:[''],
      codConvenio:[''],
      marginMota:[''],
    });

    try {
      this.userLogado = this.storageService.getUserLogado();
      this.permission = this.storageService.getStoragePeermission();
      this.carregarCategoriasDeLoja();
      this.loading = true;
    } catch (err: any) {
      console.log(err);
    }

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  requisicoes2 = [];
  rowIndex;
  representatives: any[];
  statuses: any[];

  loading: boolean = true;
  typeFileExport = '';
  activityValues: number[] = [0, 100];
  requestDialog = false;

  printingModes = [
    {
      label: 'Paisagem',
      value: 'l',
    },
    {
      label: 'Retrato',
      value: 'p',
    },
  ];
  selectedPrintingMode: 'l' | 'p' | 'portrait' | 'landscape' = 'p';
  selectedCampos = [];
  carregarCategoriasDeLoja() {
    this.loading = true;
    this.requisicoes = [];
    this.api.obterPedidos(this.userLogado.token).subscribe((response: any) => {
  
      response.map(async(valor: any) => {
        this.requisicoes.push({
          dataPedido: valor.created_at,
          requestId: valor.id,
          liqId: valor?.liq_wayconsig[0]?.id,
          nome: valor.usuarios.dados_funcionarios.nome,
          cpf: valor.usuarios.dados_funcionarios.cpf,
          matricula: valor.usuarios.dados_funcionarios.matricula,
          codConvenio: valor.usuarios.dados_funcionarios.empresa_fk,
          empresa_fk: valor.usuarios.dados_funcionarios.empresa_fk,

          tipo_contrato: await this.utilsService.tipoContrato(valor.usuarios.dados_funcionarios.cod_cat.toString())/* tipo de contrato */,
          mes: await this.utilsService.countMonthEmploye(valor.usuarios.dados_funcionarios.dt_admissao), /* conta os meses */
          statusRh: await this.utilsService.verifyStatus(valor.usuarios.dados_funcionarios.sitfolha),  /* fazer uma função para obter o status*/

          nomeEmpresa: valor.usuarios.dados_funcionarios.empresas.nome_fantasia,
          descricaoProduto: valor.products.description,
          precoFinal: valor.final_price,
          statusParceiro: valor.status_parceiro,
          statusPedido: valor.status,
          ultimaAtualizacao: valor.update_at,
          observacao: valor.observacao,
          margem: valor.usuarios.concession_margin.length === 0 ? 0 : (valor?.usuarios?.concession_margin[0]?.margin_liquida - valor?.usuarios?.concession_margin[0]?.reserva_margem) ,
          reserva_margem: valor.usuarios.concession_margin[0]?.reserva_margem,
          margemId: valor.usuarios.concession_margin[0]?.id,
          userId: valor.usuarios.id,
          idProduto: valor.products.id,
          empresaParceira: valor.products.empresas.nome_fantasia,
          valorParcela: valor.products.valor_parcela,
          qtdParcelas: valor.products.number_plots,
          
          celular: valor.usuarios.dados_funcionarios.ddd_celular+valor.usuarios.dados_funcionarios.celular,
         
          saldoReal:  (valor.usuarios.concession_margin[0]?.value - valor.usuarios.concession_margin[0]?.reserva_margem),
          taxaAdesao: valor.products.taxa_adesao[0].taxa_adesao
        });
        this.totalRecordOrder = this.requisicoes.length
        this.requisicoes2 = this.requisicoes;
      });
      this.loading = false;
    });
   
    this.getListCategoriesEmprestimos()
  }

  /**
   * Aprova o pedido de compra, encaminhando para o parceiro
   */

  aprovarPedido(pedido: any) {
    this.confirmationService.confirm({
      message: 'Você tem certeza que deseja confirmar a aprovação do pedido?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.api.approveRequestProduct(pedido).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'info',
              summary: 'Sucesso',
              detail: 'O pedido foi encaminhado para o parceiro.',
            });
          },
          complete: () => {
            this.loading = false;
            this.ngOnInit();
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Ocorreu um erro ao aprovar o pedido',
            });
          }
        });

      },
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
    });
  }

  aprovarPedidoComercial(pedido: any) {
    this.confirmationService.confirm({
      message: 'Você tem certeza que deseja aceitar o pedido?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        try {
          this.api.aprovarPedidoComercail(pedido).subscribe((res) => {
            this.ngOnInit();
            this.messageService.add({
              severity: 'info',
              summary: 'Sucesso',
              detail: 'O pedido aceito com sucesso.',
            });
          });
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Ocorreu um erro ao aprovar o pedido',
          });
        }
      },
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
    })
  }

  rejeitarPedido() {
    if (this.observacao !== "") {
      this.loading = true;
      this.api.rejectRequestProduct(this.requestId, this.observacao).subscribe({
        next: () => {
          this.messageService.add({
            severity: 'info',
            summary: 'Sucesso',
            detail: 'O pedido foi rejeitado com parceiro.',
          });
          this.dialogAprove = false;
        },
        complete: () => {
          this.dialogAprove = false;
          this.loading= false;
          this.carregarCategoriasDeLoja();
        },
        error: () => {
          this.loading= false;
          this.dialogAprove = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Não foi possivel reprovar o pedido',
          });
        }
      });
    } else {
      this.messageService.add({
        severity: 'info',
        summary: 'Observação',
        detail: 'Por favor informar o motivo',
      });
    }
}


  rejeitarPedidoComercial() {
      
        if(this.observacao !== ""){
          this.loading = true;
          this.api.rejectRequestProductComercial(this.requestId, this.observacao).subscribe({
            next: () =>{
              this.messageService.add({
                severity: 'info',
                summary: 'Sucesso',
                detail: 'O pedido foi reprovado com parceiro.',
              });
            },
            complete: () => {
              this.carregarCategoriasDeLoja();
              this.dialogAprove = false;
              this.loading = false;
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erro',
                detail: 'Não foi possivel reprovar pedido',
              });
            }
          });

        }else{
          this.messageService.add({
            severity: 'info',
            summary: 'Observação',
            detail: 'Por favor informar o motivo',
          });
        }
      }
     
  
  deletarCategoria(idCategoria: any) {
    console.log(idCategoria);
    this.loading = true;
    try {
      this.api
        .deletarCategoriaDoProduto(this.userLogado.token, idCategoria)
        .subscribe((response: any) => {
          if (response.deleted) {
            this.loading = false;
            this.carregarCategoriasDeLoja();
            return this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: response.message,
            });
          } else {
            this.carregarCategoriasDeLoja();
            this.loading = false;
            return this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: response.message,
            });
          }
        });
    } catch (error) {
      this.loading = false;
      this.carregarCategoriasDeLoja();
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'Erro interno do servidor por favor tentar novamente mais tarde',
      });
    }
  }

  gerar() {
    return this.utilsService.gerarNumeroAleatorioEmR();
  }
  


  async onRowEditInitdialogAprove(pedido:any) {
    const {requestId, nome, cpf, matricula, codConvenio, margemId, valorParcela, reserva_margem, liqId} = pedido;
    this.requestId = requestId;
    this.nomeFuncionario = nome;
    this.cpf = cpf;
    this.matricula = matricula;
    this.codConvenio = codConvenio
    this.margemId = margemId,
    this.valorParcela = valorParcela
    this.reserva_margem = reserva_margem,
    this.liqId = liqId
    /* obter contratos wayconssig  */
    await this.searchContractWayconsig(cpf, matricula, requestId)
  }



  async onRowEditCancel() {
    this.dialogAprove = false;
    this.carregarCategoriasDeLoja();
  }

  async onRowEditSave(pedido: any) {
    console.log('onRowEditSave');
    console.dir(pedido);
    delete this.clonedPedidos[pedido.id];

    try {
      this.api.putProdRequestObservation(pedido).subscribe((res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'A alteração foi efetuada com sucesso.',
        });
        
      });
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Ocorreu um erro na alteração',
      });
      console.log(error);
    }
  }


  showModalFAturar(pedido: any) {
    this.createFaturamentoForm.controls['id'].setValue(pedido.requestId);
    this.createFaturamentoForm.controls['userId'].setValue(pedido.userId)
    this.createFaturamentoForm.controls['nomefuncionairo'].setValue(pedido.nome);
    this.createFaturamentoForm.controls['cpf'].setValue(pedido.cpf);
    this.createFaturamentoForm.controls['empresa'].setValue(pedido.nomeEmpresa)
    this.createFaturamentoForm.controls['descricaoProduto'].setValue(pedido.descricaoProduto);
    this.createFaturamentoForm.controls['dataPedido'].setValue(pedido.dataPedido);
    this.createFaturamentoForm.controls['precoFinal'].setValue(pedido.precoFinal);
    this.createFaturamentoForm.controls['qtdParcelas'].setValue(pedido.qtdParcelas);
    this.createFaturamentoForm.controls['valorParcela'].setValue(pedido.valorParcela);
    this.createFaturamentoForm.controls['date'].setValue('');
    this.createFaturamentoForm.controls['telefone'].setValue(`${pedido.telefone + ' / ' + pedido.celular}`);
    this.createFaturamentoForm.controls['email'].setValue(pedido.email);
    this.createFaturamentoForm.controls['numeroContrato'].setValue('');

    if (this.disabled) {
      this.createFaturamentoForm.get('id').disable();
      this.createFaturamentoForm.get('nomefuncionairo').disable();
      this.createFaturamentoForm.get('cpf').disable();
      this.createFaturamentoForm.get('empresa').disable();
      this.createFaturamentoForm.get('descricaoProduto').disable();
      this.createFaturamentoForm.get('telefone').disable();
      this.createFaturamentoForm.get('email').disable();
      if (this.permission.roles.description === 'banco') {
        this.createFaturamentoForm.get('precoFinal').enable();
        this.createFaturamentoForm.get('qtdParcelas').enable();
        this.createFaturamentoForm.get('valorParcela').enable();
      } else {
        this.createFaturamentoForm.get('precoFinal').disable();
        this.createFaturamentoForm.get('qtdParcelas').disable();
        this.createFaturamentoForm.get('valorParcela').disable();
      }
    }

    this.showModal = true
  }

  openModalMota(data:any){
  this.dataMarginMota = [];
  this.loading = true;
  const {cpf, matricula, codConvenio} = data;

  this.api.seachMarginMota(cpf, matricula, '1002', codConvenio).subscribe({
      next:(res:any) => {
        if(Object.keys(res).length > 0){
          this.dataMarginMota = res.margin.dadosMargem;
          this.showModalMota = true
        }else{
          this.messageService.add({
            severity: 'info',
            summary: 'Cpf não encontrado',
          });
        }
      },
      complete:() => {
        this.loading = false;
      },
      error:(err) => {
         this.loading = false
      },
  });
  }

  createPedidoFaturamento() {
    this.getregisterFormControl();
    if (
      this.validatorErrors.date === 'INVALID' ||
      this.validatorErrors.precoFinal === 'INVALID' ||
      this.validatorErrors.qtdParcelas === 'INVALID' ||
      this.validatorErrors.valorParcela === 'INVALID'
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor informar todos os campos',
      })
    } else {

      const faturamento: Faturamento = {
        id: this.createFaturamentoForm.controls['id'].value,
        userId: this.createFaturamentoForm.controls['userId'].value,
        nomefuncionairo: this.createFaturamentoForm.controls['nomefuncionairo'].value,
        cpf: this.createFaturamentoForm.controls['cpf'].value,
        empresa: this.createFaturamentoForm.controls['empresa'].value,
        descricaoProduto: this.createFaturamentoForm.controls['descricaoProduto'].value,
        datapedido: this.createFaturamentoForm.controls['dataPedido'].value,
        precoFinal: this.createFaturamentoForm.controls['precoFinal'].value,
        qtdParcelas: this.createFaturamentoForm.controls['qtdParcelas'].value,
        valorParcela: this.createFaturamentoForm.controls['valorParcela'].value,
        date: this.createFaturamentoForm.controls['date'].value,
        telefone: this.createFaturamentoForm.controls['telefone'].value,
        email: this.createFaturamentoForm.controls['email'].value,
        numeroContrato: this.createFaturamentoForm.controls['numeroContrato'].value
      }

      this.api.createPedidoFaturamento(faturamento).subscribe((res) => {
        this.createFaturamentoForm.controls['id'].setValue('');
        this.createFaturamentoForm.controls['nomefuncionairo'].setValue('')
        this.createFaturamentoForm.controls['cpf'].setValue('')
        this.createFaturamentoForm.controls['empresa'].setValue('')
        this.createFaturamentoForm.controls['descricaoProduto'].setValue('')
        this.createFaturamentoForm.controls['dataPedido'].setValue('')
        this.createFaturamentoForm.controls['precoFinal'].setValue('')
        this.createFaturamentoForm.controls['qtdParcelas'].setValue('')
        this.createFaturamentoForm.controls['valorParcela'].setValue('')
        this.createFaturamentoForm.controls['date'].setValue('')
        this.createFaturamentoForm.controls['telefone'].setValue('');
        this.createFaturamentoForm.controls['email'].setValue('');
        this.messageService.add({
          severity: 'success',
          summary: 'Pedido faturado com sucesso!',
        });
      }).add(() => {
        this.showModal = false
        this.carregarCategoriasDeLoja()
      })
    }

  }

  
  getregisterFormControl() {
    const date = this.createFaturamentoForm.get('date')?.status
    const precoFinal = this.createFaturamentoForm.get('precoFinal')?.status;
    const qtdParcelas = this.createFaturamentoForm.get('qtdParcelas')?.status;
    const valorParcela = this.createFaturamentoForm.get('valorParcela')?.status;
    this.validatorErrors = {
      date: date,
      precoFinal: precoFinal,
      qtdParcelas: qtdParcelas,
      valorParcela: valorParcela
    }
    return this.validatorErrors
  }

  getregisterFormControlEmprestimo() {
    const dateEmprestimo = this.createFormEmprestimo.get('dateEmprestimo')?.status
    const precoFinalEmprestimo = this.createFaturamentoForm.get('precoFinalEmprestimo')?.status;
    const qtdParcelasEmprestimo = this.createFaturamentoForm.get('qtdParcelasEmprestimo')?.status;
    const valorParcelaEmprestimo = this.createFaturamentoForm.get('valorParcelaEmprestimo')?.status;
    const productCategory = this.createFormEmprestimo.get('productCategory')?.status
    this.validatorErrors = {
      dateEmprestimo: dateEmprestimo,
      precoFinalEmprestimo: precoFinalEmprestimo,
      qtdParcelasEmprestimo: qtdParcelasEmprestimo,
      valorParcelaEmprestimo: valorParcelaEmprestimo,
      productCategory: productCategory
    }
    return this.validatorErrors
  }

  getListCategoriesEmprestimos() {
    this.api.listCategoriesEmprestimos().subscribe((res: any) => {
      res.map((item: any) => {
        this.listCategoriesProducts.push({
          name: item.description,
          code: item.id
        })
      })
    })
  }

  findPriceProducts(){
    this.loading = true;
    const {code } =  this.createFormEmprestimo.value.productCategory
    this.api.findPriceProduct(code).subscribe({
      next: (res:any) => {
        console.log(res);
        this.createFormEmprestimo.controls['precoFinalEmprestimo'].setValue(res.price);
        this.createFormEmprestimo.controls['qtdParcelasEmprestimo'].setValue(res.number_plots)
        this.createFormEmprestimo.controls['valorParcelaEmprestimo'].setValue(res.valor_parcela)
      },
      complete: () => {this.loading = false},
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error ao concultar preços de produtos',
        });
      }
    })
  }

  showModalEmprestar() {
    if (this.disabled) {
      this.createFormEmprestimo.get('nomefuncionairo').disable();
      this.createFormEmprestimo.get('cpf').disable();
      this.createFormEmprestimo.get('empresa').disable();
      this.createFormEmprestimo.get('telefone').disable();
      this.createFormEmprestimo.get('email').disable();
      this.createFormEmprestimo.get('resultadoSaldo').disable();

      this.createFormEmprestimo.get('precoFinalEmprestimo').disable();
      this.createFormEmprestimo.get('qtdParcelasEmprestimo').disable();
      this.createFormEmprestimo.get('valorParcelaEmprestimo').disable();
    }
    this.showModal = false
    this.showModalEmprestimo = true
  }

  pesquisarFuncionario() {
    this.loading = true;
    const cpf = this.utilsService.limparCPF(this.createFormEmprestimo.controls['pesquisar'].value);
    if(cpf){
      this.api.pesquisarFuncionario(cpf).subscribe((res: any) => {
        if (res.status === 204) {
          this.messageService.add({
            severity: 'warn', summary: 'Warn', detail: 'Funcionário não esta ativo'
          })
          this.sitFolha = false;
          this.statusRhApp = '';
          this.loading = false;
        } else {
          res.map((item: any) => { 
          this.createFormEmprestimo.controls['margem'].setValue(item.margem);
          this.createFormEmprestimo.controls['marginMota'].setValue('');
          this.createFormEmprestimo.controls['resultadoSaldo'].setValue(item.resultadoSaldo)
          this.createFormEmprestimo.controls['nomefuncionairo'].setValue(item.nome);
          this.createFormEmprestimo.controls['cpf'].setValue(item.cpf);
          this.createFormEmprestimo.controls['empresa'].setValue(item.empresa);
          this.createFormEmprestimo.controls['email'].setValue(item.email);
          this.createFormEmprestimo.controls['usuarioFk'].setValue(item.usuario_fk);
          this.createFormEmprestimo.controls['telefone'].setValue(item.telefone || null);
          this.createFormEmprestimo.controls['precoFinalEmprestimo'].setValue('');
          this.createFormEmprestimo.controls['qtdParcelasEmprestimo'].setValue('');
          this.createFormEmprestimo.controls['valorParcelaEmprestimo'].setValue('');
          
          /* valores form faturamento manual */
          this.createFormEmprestimo.controls['matriculaFun'].setValue(item.matricula);
          this.createFormEmprestimo.controls['codConvenio'].setValue(item.empresa_fk);
  
          this.statusRhApp = item.status_rh;
            if (item.sitfolha === " ") {
              this.sitFolha = true
            }
            if (item.sitFolha === 'F') {
              this.sitFolha = false
            }
            if (item.sitFolha === 'A') {
              this.sitFolha === false
            }
            if (item.sitFolha === 'D') {
              this.sitFolha = false
            }
          })
          this.loading = false;
          this.messageService.add({
            severity: 'info', summary: 'Margin', detail: 'Margem rhapp consultada com sucesso!'
          })
        }
       this.searchMarginMota()

      })
    }else{
      this.loading = false;
      this.messageService.add({
        severity: 'warn', summary: 'Margin', detail: 'Informar cpf para consulta'
      })
    }

    }
    

   

 async searchMarginMota(){ 
  this.loading = true;
  const {pesquisar, matriculaFun, codConvenio} = this.createFormEmprestimo.value;
  const cpf = this.utilsService.limparCPF(pesquisar);
  this.api.seachMarginMota(cpf, matriculaFun, '1002', codConvenio).subscribe({
        next:(res:any) => {
          if(Object.keys(res).length > 0){
            this.createFormEmprestimo.controls['marginMota'].setValue(res?.margin?.dadosMargem[0].valorMargemDisponivel);
            this.messageService.add({
              severity: 'info', summary: 'Margin', detail: 'Margin mota consultada com sucesso!'
            })
          }else{
            this.messageService.add({
              severity: 'warn',
              summary: 'Cpf não encontrado base de dados mota',
            });
          }
        },
        complete:() => {
          this.loading = false;
        },
        error:(err) => {
           this.loading = false
        },
    });
  }

  createEmprestimo() {
    this.getregisterFormControlEmprestimo()
    if (
      this.validatorErrors.dateEmprestimo === 'INVALID' ||
      this.validatorErrors.precoFinalEmprestimo === 'INVALID' ||
      this.validatorErrors.qtdParcelasEmprestimo === 'INVALID' ||
      this.validatorErrors.valorParcelaEmprestimo === 'INVALID' ||
      this.validatorErrors.productCategory === 'INVALID'
    ) {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor informar todos os campos',
      })
    } else {
      const emprestimo: Faturamento = {
        id: this.createFormEmprestimo.controls['productCategory'].value.code,
        saldo: this.createFormEmprestimo.controls['resultadoSaldo'].value,
        usuarioFk: this.createFormEmprestimo.controls['usuarioFk'].value,
        nomefuncionairo: this.createFormEmprestimo.controls['nomefuncionairo'].value,
        descricaoProduto: this.createFormEmprestimo.controls['productCategory'].value.name,
        cpf: this.createFormEmprestimo.controls['cpf'].value,
        empresa: this.createFormEmprestimo.controls['empresa'].value,
        precoFinal: this.createFormEmprestimo.controls['precoFinalEmprestimo'].value,
        qtdParcelas: this.createFormEmprestimo.controls['qtdParcelasEmprestimo'].value,
        valorParcela: this.createFormEmprestimo.controls['valorParcelaEmprestimo'].value,
        date: this.createFormEmprestimo.controls['dateEmprestimo'].value,
        telefone: this.createFormEmprestimo.controls['telefone'].value,
        email: this.createFormEmprestimo.controls['email'].value,
        numeroContrato: this.createFormEmprestimo.controls['numeroContrato'].value
      }
      if (emprestimo.saldo < emprestimo.valorParcela) {
        return this.messageService.add({
          severity: 'error',
          summary: 'Funcionario não tem saldo suficiente para adesão!',
        });
      }

      this.api.createEmprestimoBanco(emprestimo).subscribe((res: any) => {
        if (res.status === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'Pedido faturado com sucesso!',
          });
          this.createFormEmprestimo.reset();
          this.showModalEmprestimo = false
          this.statusRhApp = '';
          this.sitFolha = false
        }
      })
    }
  }


  async searchContractWayconsig(cpf:string, matricula:string, requestId:string | any){
    this.contratosAtivosWayConsig = [];
    this.loading = true;
    this.api.searchContractWayconsig(cpf, matricula, requestId).subscribe({
      next: (res:any) => {
        this.contratosAtivosWayConsig = res
      },
      complete: () => {
        this.loading = false
        this.dialogAprove = true;
      },
      error: (error) => {
        
      }
    })
  }


  liquidarContratoWayConsig(numeroContrato:string){
    this.loading = true;
    const liquidarContrato = {
      codBanco: 1002,
      codConvenio: this.codConvenio,
      cpf: this.cpf,
      matricula: this.matricula,
      numeroContrato: numeroContrato,
      codMotivo: "1",
    }
    const liqRhapp = {
      reserva_margin: this.reserva_margem, 
      valorParcela:this.valorParcela,
      requestId: this.requestId,
      liqId: this.liqId
    }

    this.api.liquidarContratoWayconsig(liquidarContrato, this.margemId, liqRhapp).subscribe({
      next: (res:any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Contrato liquidado com sucesso',
        });
      },
      complete: () => {
        this.searchContractWayconsig(liquidarContrato.cpf, liquidarContrato.matricula, this.requestId)
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    })
  }

  resetForm(){
    this.createFormEmprestimo.reset();
    this.sitFolha = false;
  }

isRowSelectable(event) {
  return !this.isOutOfStock(event.data);
}

isOutOfStock(data) {
  return data.inventoryStatus === 'OUTOFSTOCK';
}

 getListProductConvenios(pedido:any){
  this.listConvenios = [];
  this.loading = true;
  this.typeProventos = false;
  this.vocationService.listProductsConvenio(pedido.userId, pedido.empresa_fk ).subscribe({
    next: (res:any) => {
      res.map(async (item:any) => {
        this.listConvenios.push({
          descontoConvenio: item.descontoConvenio,
          nome_funcionario: item.nome_funcionario,
          resultCalMargemBruta: item.resultCalMargemBruta,
          margemLiquida: item.listMargin[0].margin_liquida,
          list: item.list,
          verbasProventos: item.verbasProventos,
          verbasDescontos: item.verbasDescontos,
          cpf: item.cpf,
          calcDesconto: item.calcDesconto,
          periodo: await this.utilsService.parseStringdateFormatMonthYearDay(item.periodo)
        })

      })
    },
  complete: () => {
    this.showModalConvenioAtivo = true
    this.loading = false;
  },
   error: () => {

   }
});
}

showModalVerbasProventos(){
  this.typeProventos = true;
}

showModalVerbasDescontos(){
  this.typeDesconto = true;
}

}
