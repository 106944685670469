import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'divideBy'
})
export class divideByPipy implements PipeTransform {

    transform(value: number, divisor: number): number {
        if (!divisor) {
          return value;
        }
        return value / divisor;
      }
}
