import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'localdatetime'
})
export class LocalDateTime implements PipeTransform {

    transform(value: string): string {
        if (value) {
          const formattedDate =  moment.utc(value).format('DD/MM/YYYY HH:mm');
          return formattedDate;
        } else {
          return '-';
        }
      }

}
