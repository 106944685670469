<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-2"  *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>

  <div class="col-10" [class.col-12]="!navbarVisible">
  <form autocomplete="off" [formGroup]="formEmpresa">
    <p-tabView styleClass="tabview-custom">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-building"></i>
          <span>Informações da empresa</span>
        </ng-template>
        <div class="grid">
          <div class="col-5">
            <label>Nome Fantasia:</label>
            <input pInputText type="text" placeholder="Nome da empresa" formControlName="empresa" class="w-full" />
          </div>
          <div class="col-5">
            <label>Razão social:</label>
            <input pInputText type="text" placeholder="Razão social" formControlName="razaoSocial" class="w-full" />
          </div>
          <div class="col-2">
            <label>CNPJ:</label>
            <p-inputMask mask="99.999.999/9999-99" placeholder="CNPJ" class="w-11" formControlName="cnpj"
              styleClass="w-full"></p-inputMask>
          </div>
        </div>
        <div class="grid">
          <div class="col-5">
            <label for="grupo">Grupo:</label>
            <input pInputText type="text" placeholder="Grupo da empresa" formControlName="grupo" class="w-full" />
          </div>
          <div class="col-3">
            <label for="grupo">Filial:</label>
            <input pInputText type="text" placeholder="Grupo da empresa" formControlName="filial" class="w-full" />
          </div>
          <div class="col-2">
            <label for="grupo">Tipo empresa:</label>
          <p-dropdown [options]="selectedTypeCompanies"
            placeholder="Select Tipo empresa" formControlName="tipo"
            optionLabel="tipo">
          </p-dropdown>
          </div>
          <div class="col-2">
            <label for="grupo">Porcentagem margem:</label>
            <input pInputText type="number" placeholder="Porcentagem" formControlName="porcentagemMagem" class="w-full" />
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Localização">
        <ng-template pTemplate="header">
          <i class="pi pi-building"></i>
          <span>Localização</span>
        </ng-template>
        <div class="grid">
          <div class="col-3">
            <label for="email" class="mr-2">CEP:</label>
            <p-inputMask mask="99999-999" placeholder="CEP" class="w-11" formControlName="cep" styleClass="w-full"
              (onBlur)="blurCep()"></p-inputMask>
            <!-- <input pInputText type="email" (blur)="blurCep()" formControlName="cep" placeholder="CEP" class="w-full" /> -->
          </div>
          <div class="col-2 mt-auto">
            <label for="telefone" class="mr-2">Cidade:</label>
            <input pInputText type="email" placeholder="Cidade" class="w-full" formControlName="cidade" />
          </div>
          <div class="col-3 mt-auto">
            <label for="celular" class="mr-2">Bairro:</label>
            <input pInputText type="email" placeholder="Bairro" class="w-full" formControlName="bairro" />
          </div>
          <div class="col-3 mt-auto">
            <label for="celular" class="mr-2">Logradouro:</label>
            <input pInputText type="email" placeholder="Logradouro" class="w-full" formControlName="logradouro" />
          </div>
          <div class="col-1 mt-auto">
            <label for="celular" class="mr-2">Número:</label>
            <input pInputText type="email" placeholder="Número" class="w-full" formControlName="numero" />
          </div>
        </div>
      </p-tabPanel><p-tabPanel header="Contato e finalização">
        <ng-template pTemplate="header">
          <i class="pi pi-phone"></i>
          <span>Contatos</span>
        </ng-template>
        <div class="grid">
          <div class="col-3">
            <label for="email" class="mr-2">Email:</label>
            <input pInputText type="email" placeholder="email" formControlName="email" class="w-full" />
          </div>
          <div class="col-3 mt-auto">
            <label for="celular" class="mr-2">Celular:</label>
            <p-inputMask mask="(99) 99999-9999" placeholder="Celular" class="w-full" formControlName="celular"
              autocomplete="off"></p-inputMask>
          </div>
          <div class="col-3 mt-auto">
            <label for="telefone" class="mr-2">Telefone:</label>
            <p-inputMask mask="(99) 9999-9999" placeholder="Telefone" class="w-full" formControlName="telefone"
              autocomplete="off"></p-inputMask>
          </div>
        </div>
        <div class="grid ml-auto mt-2">
          <p-button label="Criar" icon="pi pi-plus" (onClick)="criarEmpresa()"></p-button>
        </div>
      </p-tabPanel>
    </p-tabView>
  </form>
</div>
  </div>
</div>