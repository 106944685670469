import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { StorageService } from '../storage.service';
import { environment } from 'src/environments/environment';
import { Comunication } from 'src/app/models/comunication';
import { LazyLoadEvent } from 'primeng/api';
import { PayloadNotification } from 'src/app/models/payloadNotification';

@Injectable({
  providedIn: 'root',
})
export class VacationService {
  public urlApi = environment.apiUrl;

  constructor(
    public http: HttpClient, 
    public storageService: StorageService
  ) {}

  public  getVacationsSync(event: LazyLoadEvent, totalRecords:number) {
    /* filters  */
    let builtFilters: any = {};
    if(event.filters !== undefined){
      Object.keys(event?.filters).forEach(key => {
        const filterValues:any = event?.filters[key];
          if(filterValues[0]?.value !== null){
            const builtFilterValues = filterValues?.map((filter: any) => ({
              value: filter?.value,
              matchMode: filter?.matchMode || 'startsWith', 
              operator: filter?.operator || 'and' 
            }));
            builtFilters[key] = builtFilterValues;
          }
      });

    }
    
  
    let params = new HttpParams();
    params = params
                  .append('offset', event?.first)
                  .append('rows', event?.rows)
                  .append('sortField', event?.sortField)
                  .append('sortOrder', event?.sortOrder)
                  .append('totalRecords', totalRecords)

    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params,
    };
    const url = `${this.urlApi}/gpe/vacation/sync`;
    return this.http.post(url, {builtFilters:builtFilters}, httpOptions);
  }

  public getVacationsReceipt(event:LazyLoadEvent, totalRecords:number) {
    /* filters */    
    let builtFilters: any = {};
    if(event.filters !== undefined){
      Object.keys(event?.filters).forEach(key => {
        const filterValues:any = event?.filters[key];
          if(filterValues[0]?.value !== null){
            const builtFilterValues = filterValues?.map((filter: any) => ({
              value: filter?.value,
              matchMode: filter?.matchMode || 'startsWith', 
              operator: filter?.operator || 'and' 
            }));
            builtFilters[key] = builtFilterValues;
          }
      });
    }

    let params = new HttpParams();
    params = params
                  .append('offset', event?.first)
                  .append('rows', event.rows)
                  .append('sortField', event?.sortField)
                  .append('sortOrder', event?.sortOrder)
                  .append('totalRecords', totalRecords);

    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params,
    };
    const url = `${this.urlApi}/gpe/vacation/receipt/portal`;
    return this.http.post(url, {builtFilters:builtFilters}, httpOptions);
  }


  public sendVacationWarning(user: any, notification:any, type: string) {
    const { token } = this.storageService.getUserLogado();
    let params = new HttpParams();
    params = params.append('type', type);
    const headers = new HttpHeaders({
      'x-access-token': token,
    });

    let options = {
      headers,
      params,
    };
    const url = `${this.urlApi}/gpe/vacation/send`;
    return this.http.post(url, {payload:user, notification:notification}, options);
  }

  public sendVacationWarningReceipt(users: any, notification:PayloadNotification, type: string) {
    const { token } = this.storageService.getUserLogado();
    let params = new HttpParams();
    params = params.append('type', type);
    const headers = new HttpHeaders({
      'x-access-token': token,
    });

    const body = {
      users,
      notification
    }

    let options = {
      headers,
      params,
    };
    const url = `${this.urlApi}/gpe/vacation/send/receipt`;
    return this.http.post(url, body, options);
  }

  public getVacationsSended(event: LazyLoadEvent, totalRecords:number) {
  /* filters */    
  let builtFilters: any = {};
  if(event?.filters !== undefined){
    Object.keys(event?.filters).forEach(key => {
      const filterValues:any = event?.filters[key];
        if(filterValues[0]?.value !== null){
          const builtFilterValues = filterValues?.map((filter: any) => ({
            value: filter?.value,
            matchMode: filter?.matchMode || 'startsWith', 
            operator: filter?.operator || 'and' 
          }));
          builtFilters[key] = builtFilterValues;
        }
    });
  }

    const { token } = this.storageService.getUserLogado();
    let params = new HttpParams();
    params = params
                  .append('offset', event?.first)
                  .append('rows', event?.rows)
                  .append('sortField', event?.sortField)
                  .append('totalRecords', totalRecords)
                  .append('sortOrder', event?.sortOrder);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params:params
    };
    const url = `${this.urlApi}/gpe/vacation/sended`;
    return this.http.post(url, {builtFilters:builtFilters}, httpOptions);
  }

  public printPdfContraCheques(contracheque:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/gpe/printpdf/contracheque`;
    return this.http.post(url,{contracheque: contracheque}, httpOptions);
  }


  saveComunicationContracheques(comunication:Comunication){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/gpe/comunication/contracheques`;
    return this.http.post(url, {comunication: comunication}, httpOptions);
  }

  listComunication(){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/gpe/list/comunication`;
    return this.http.get(url, httpOptions);
  }

  editComunication(comunication:Comunication){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/gpe/edit/comunication`;
    return this.http.put(url, {comunication:comunication}, httpOptions);
  }

  deleteComunication(id:number){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/gpe/delete/comunication/${id}`;
    return this.http.delete(url, httpOptions);
  }

  getLayoutPdfAvisoFerias(aviso:any){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/gpe/layout/aviso/ferias/pdf`;
  return this.http.post(url,{aviso: aviso}, httpOptions);
  }

  cancelWarningBerforeShipping(id:number){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/gpe/cancel/warning/beforeshipping/${id}`;
    return this.http.put(url,{}, httpOptions);
  }

  getLayoutPdfReciboFerias(recibo:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/gpe/layout/recibo/ferias/pdf`;
    return this.http.post(url,{recibo: recibo}, httpOptions);
    }

    getListDocwesignPending(event: LazyLoadEvent, totalRecords:number){
      /* filters  */
    let builtFilters: any = {};
    if(event.filters !== undefined){
      Object.keys(event?.filters).forEach(key => {
        const filterValues:any = event?.filters[key];
          if(filterValues[0]?.value !== null){
            const builtFilterValues = filterValues?.map((filter: any) => ({
              value: filter?.value,
              matchMode: filter?.matchMode || 'startsWith', 
              operator: filter?.operator || 'and' 
            }));
            builtFilters[key] = builtFilterValues;
          }
      });

    }
    
  
    let params = new HttpParams();
    params = params
                  .append('offset', event?.first)
                  .append('rows', event?.rows)
                  .append('sortField', event?.sortField)
                  .append('sortOrder', event?.sortOrder)
                  .append('totalRecords', totalRecords)
      const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params
    };
    const url = `${this.urlApi}/gpe/list/wesign/pending`;
    return this.http.post(url, {builtFilters:builtFilters}, httpOptions);
    }

    getListDocwesignManual(event: LazyLoadEvent, totalRecords:number){
      /* filters  */
    let builtFilters: any = {};
    if(event.filters !== undefined){
      Object.keys(event?.filters).forEach(key => {
        const filterValues:any = event?.filters[key];
          if(filterValues[0]?.value !== null){
            const builtFilterValues = filterValues?.map((filter: any) => ({
              value: filter?.value,
              matchMode: filter?.matchMode || 'startsWith', 
              operator: filter?.operator || 'and' 
            }));
            builtFilters[key] = builtFilterValues;
          }
      });

    }
    
  
    let params = new HttpParams();
    params = params
                  .append('offset', event?.first)
                  .append('rows', event?.rows)
                  .append('sortField', event?.sortField)
                  .append('sortOrder', event?.sortOrder)
                  .append('totalRecords', totalRecords)
      const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params
    };
    const url = `${this.urlApi}/gpe/list/wesign/manual`;
    return this.http.post(url, {builtFilters:builtFilters}, httpOptions);
    }


     /* signature manual */
     signatureDocManual(doc:any, motivo_cancelamento:any){
      const { token } = this.storageService.getUserLogado();
      
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/signature/manual`;
      return this.http.put(url,{doc:doc, motivo_cancelamento:motivo_cancelamento}, httpOptions);
    }


    getListDocwesignConcluded(event: LazyLoadEvent, totalRecords:number){
    
      /* filters  */
      let builtFilters: any = {};
      if(event.filters !== undefined){
        Object.keys(event?.filters).forEach(key => {
          const filterValues:any = event?.filters[key];
            if(filterValues[0]?.value !== null){
              const builtFilterValues = filterValues?.map((filter: any) => ({
                value: filter?.value,
                matchMode: filter?.matchMode || 'startsWith', 
                operator: filter?.operator || 'and' 
              }));
              builtFilters[key] = builtFilterValues;
            }
        });
  
      }
      
    
      let params = new HttpParams();
      params = params
                    .append('offset', event?.first)
                    .append('rows', event?.rows)
                    .append('sortField', event?.sortField)
                    .append('sortOrder', event?.sortOrder)
                    .append('totalRecords', totalRecords)
    
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params
    };
    const url = `${this.urlApi}/gpe/list/wesign/concluded`;
    return this.http.post(url, {builtFilters:builtFilters}, httpOptions);
    }



    downloadDocWesignConcluded(id:string){
      const { token } = this.storageService.getUserLogado();
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/download/doc/signature/${id}`;
      return this.http.get(url, httpOptions);
    }

    cancelDocVacantion(doc:any, motivo_cancelamento:string, motivo:string, sendToken:any){
      const { token } = this.storageService.getUserLogado();
     
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/cancel/docweign/vacation`;
      return this.http.post(url,{doc:doc, motivo_cancelamento:motivo_cancelamento, motivo:motivo, sendToken:sendToken}, httpOptions);
    }

    
    definitiveCancellationDocVacantion(doc:any, motivo_cancelamento:string, motivo:string, sendToken:any){
      const { token } = this.storageService.getUserLogado();
     
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/definitive/cancel/docweign/vacation`;
      return this.http.post(url,{doc:doc, motivo_cancelamento:motivo_cancelamento, motivo:motivo, sendToken:sendToken}, httpOptions);
    }

    /* cancelDocWessign BY Data invalid */
    cancelDocdataInavalid(doc:any, motivo_cancelamento:string, motivo:string, sendToken:any){
      const { token } = this.storageService.getUserLogado();
      
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/calcel/docweign/vacation/invalid`;
      return this.http.post(url,{doc:doc, motivo_cancelamento:motivo_cancelamento, motivo:motivo, sendToken:sendToken}, httpOptions);
    }

    listProductsConvenio(usuario_fk:number, empresa_fk:number){
      const { token } = this.storageService.getUserLogado();
      const params = new HttpParams()
      .set('usuario_fk', usuario_fk)
      .set('empresa_fk', empresa_fk) // empresa_funcionario

      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
        params: params
      };
      const url = `${this.urlApi}/gpe/list/convenio/ativo`;
      return this.http.get(url, httpOptions);
    }

    listDadosVacationEmploeeUserid(dataVacation:any){
      const { token } = this.storageService.getUserLogado();
      const params = new HttpParams()
      .set('periodo', dataVacation.periodo)
      
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
        params:params
      };
      const url = `${this.urlApi}/gpe/listall/vacation/period/${dataVacation.id}`;
      return this.http.get(url,httpOptions);
    }

    sendNotificationVacation(notificationToken:any){
      const { token } = this.storageService.getUserLogado();
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/received/notification`;
      return this.http.post(url,{notificationToken:notificationToken}, httpOptions);
    }

     getPdfConcludedWesing(idDoc:string){
      const { token } = this.storageService.getUserLogado();
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/pdf/conclued/wesing/${idDoc}`;
      return this.http.get(url, httpOptions);
    }


    getPdfCanceledWesing(idDoc:string){
      const { token } = this.storageService.getUserLogado();
      const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        }),
      };
      const url = `${this.urlApi}/gpe/pdf/canceled/wesing/${idDoc}`;
      return this.http.get(url, httpOptions);
    }
}
