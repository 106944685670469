import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Company } from 'src/app/models/company';
import { Comunication } from 'src/app/models/comunication';
import { ApiService } from 'src/app/services/api.service';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-aviso-contrachque',
  templateUrl: './aviso-contrachque.component.html',
  styleUrls: ['./aviso-contrachque.component.scss']
})
export class AvisoContrachqueComponent implements OnInit {

  
  countText: number  = 0;
  countTextDisebled: boolean = false;
  clients: Company[] = [{name: 'Empresas', id:0}];

  litComuniCation: Comunication[] = [];
  selectedLitComuniCation:any;
  loading: boolean = false;
  navbarVisible: boolean;

  constructor(
    private messageservice: MessageService,
    private formBuild: FormBuilder,
    private apiService: ApiService,
    private vocationservice: VacationService,
    private navbarService: NavbarService
    ) { }

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.formBuild.group({
      text:['', [Validators.required, Validators.maxLength(200)]],
      client: ['', [Validators.required]],
      titulo: ['', Validators.required]
    })

    this.listComunicationAviso()
    this.getAllCompanies()

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  onKeyDown(event:any){
    const {keyCode, key} = event
  
    if(keyCode !== 8){
    this.countText +=  1 
   }else if(key === 'Backspace' && this.countText >= 1){
    this.countText -=  1 
   }

    if(this.countText >= 158){
      this.countTextDisebled = true;
      this.countText = 0
        this.messageservice.add({ 
          severity: 'warn', 
          summary: 'Comunicação', 
          detail: `Quantidade maxima ${this.countText} caracteres` })
    }
    
  }

  getAllCompanies(){
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{
    const filterCompanies = res.filter(item =>  item.tipo === 'Cliente')
    filterCompanies.map((item) => {
      this.clients.push({
        name: item.nome_fantasia,
        id: item.id
      })
    })

    })
  }

  saveComunication(){
    if(this.form.status === 'INVALID'){
      this.messageservice.add({ 
        severity: 'warn', 
        summary: 'Comunicação', 
        detail: 'Por favor preencher todos os campos' })
    }else{
      const {text, client, titulo} = this.form.value;
      const replaceText = text.replace(/<\/?p>/g, '');
      const aviso: Comunication = {
        text: replaceText ,
        client: {
          name: client.name,
          id: client.id
        },
        titulo: titulo
      }
      this.vocationservice.saveComunicationContracheques(aviso).subscribe({
        next: (res:any) => {
          if(res.status === 200){
            this.messageservice.add({ 
              severity: 'warn', 
              summary: 'Comunicação', 
              detail: `${res.message}` })
            this.form.reset()
          }else{
            this.messageservice.add({ 
              severity: 'success', 
              summary: 'Comunicação', 
              detail: `${res.message}` })
            this.form.reset()
          }
        },
        complete: () => {
          this.listComunicationAviso()
        },
        error: (e) => {}
      })

    }
  }

  resetCountText(){
    this.countText = 0
  }


  listComunicationAviso(){
    this.loading = true;
    this.vocationservice.listComunication().subscribe((res:any) => {
      this.litComuniCation = res
    }).add(() => {
      this.loading = false
    })
  }

  itemselectedValue(){
    if(this.selectedLitComuniCation.length > 1){
     return this.messageservice.add({ 
        severity: 'warn', 
        summary: 'Comunicação', 
        detail: 'Editar item por vez' })
    }else{
      const  {titulo, message} = this.selectedLitComuniCation[0]
      this.clients  = [];
      this.clients.push({
        name: this.selectedLitComuniCation[0].empresas.nome_fantasia,
        id: this.selectedLitComuniCation[0].empresas.id
      })
      this.form.controls['text'].setValue(message) 
      this.form.controls['titulo'].setValue(titulo) 
    }
    
  }

  editItemComunication(){
    this.loading = true;
    const {text, client, titulo} = this.form.value;
    const  {id} = this.selectedLitComuniCation[0]
    const replaceText = text.replace(/<\/?p>/g, '');
    const editAviso: Comunication = {
      id:id,
      text: replaceText ,
      client: {
        name: client.name,
        id: client.id
      },
      titulo: titulo
    }

    this.vocationservice.editComunication(editAviso).subscribe({
      next: (res:any) => {
         this.messageservice.add({ 
          severity: 'success', 
          summary: 'Comunicação', 
          detail: `${res.message}` 
        })
        this.loading = false;
      },
      error: (e) => {},
      complete: () => {
        this.selectedLitComuniCation = []
        this.getAllCompanies()
        this.form.reset()
        this.listComunicationAviso()
      }
    })
  }

  deleteComunication(id:number){
    this.loading = true;
    this.vocationservice.deleteComunication(id).subscribe({
      next: (res:any) => {
        this.messageservice.add({ 
          severity: 'warn', 
          summary: 'Comunicação', 
          detail: `${res.message}` 
        })
        this.loading = false;
      },
      error : () => {},
      complete: () => {
        this.listComunicationAviso()
      }
    })
  }

}
