import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public data: any;

  constructor() {}

  /*
    retorna uma string no padrão do protgheyu
  */
  public toProtheusDate(data: Date): string {
    let result = new Date(data).toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    let dtFormatada = `${result.substring(6, 10)}${result.substring(
      3,
      5
    )}${result.substring(0, 2)}`;
    return dtFormatada;
  }

  /**
   *
   * @param {string} data data no formato do protheus (YYYYMMDD)
   * @returns {string} data no formato (DD/MM/YYYY)
   */
  public fromProtheusDate(data: string) {
    const dataFormatted = `${data.substring(6, 8)}/${data.substring(
      4,
      6
    )}/${data.substring(0, 4)}`;
    console.log('opa ', dataFormatted);
    return dataFormatted;
  }

  public trataPeriodo(data: Date): string {
    // let format = data.toString();
    // format = format.substring(0, 4) + format.substring(5, 7);
    const inputDateStr = data;
    const inputDate = new Date(inputDateStr);

    const outputDateStr = `${inputDate.getFullYear()}${(
      inputDate.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}`;
    // console.log(outputDateStr);
    return outputDateStr;
  }

  public fromProtheusToDateJs(data: string) {
    const dataPart = {
      ano: parseInt(data.substring(0, 4), 10),
      mes: parseInt(data.substring(4, 6), 10) - 1,
      dia: parseInt(data.substring(6, 8), 10),
    };
    return new Date(dataPart.ano, dataPart.mes, dataPart.dia);
  }

  fromJsDateToString(value: string) {
    if (value != null) {
      let result = new Date(value).toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
      return result;
    } else {
      return '-';
    }
  }

  protheusToFullDate(date: string) {
    const dateJs: Date = this.fromProtheusToDateJs(date);
    const month = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ][dateJs.getMonth()];
    const fullDate: string = `${dateJs.getDate()} de ${month} de ${dateJs.getFullYear()}`;
    console.log(dateJs);

    return fullDate;
  }
}
