import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataProtheus'
})
export class DataProtheusPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let transformed: string;
    if (value) {
      transformed = `${value.substring(6, 8)}/${value.substring(
        4,
        6
      )}/${value.substring(0, 4)}`
    } else
      transformed = '-'

    return transformed;
  }
}
