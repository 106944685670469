import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value?.length === 10)
      return `(${value.substring(0, 2)})${value.substring(2, 6)}-${value.substring(6, 10)}`;
    return ''
  }

}
