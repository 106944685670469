<div class="surface-50">
    <div class="grid">

        <div class="col-12 flex flex-column">
            <app-navbar></app-navbar>
        </div>
        <div class="col-12 flex flex-column">
            <p-fieldset>
                <ng-template pTemplate="header">
                    Apontamento
                </ng-template>
                <a routerLink="" class="no-underline">
                    Obtenha uma visão dos apontamentos do período selecionado.
                </a>
                <br>
            </p-fieldset>
        </div>
        <div class="col-12 flex flex-column">
            <p-fieldset>        
                <ng-template pTemplate="header">
                    Espelho
                </ng-template>
                <a [routerLink]="['/espelho']" class="no-underline">
                    Obtenha uma visão do espelho de ponto do período selecionado.
                </a>
            </p-fieldset>
        </div>
    </div>
</div>