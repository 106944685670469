import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { ProductService } from 'src/app/services/shop/product.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  loading: boolean =false;
  campanigs: any[];
  formCampaign: FormGroup;
  formGroupChecked: FormGroup;
  countText: number  = 0;
  countTextDisebled: boolean = false;
  visible: boolean = false;
  acceipts: any[] = [];
  empresas: any[] = [];
  company_campaigns: any[] = [];
  visibleCampagin: boolean = false;
  Addcompany_campaigns: any[] =[];
  selectedcompanys: any[] = [];
  AddcompanyCampaignsId: number;
  navbarVisible: boolean;
  
  constructor( 
    private formBuild: FormBuilder, 
    private messageService: MessageService,
    private productservice: ProductService,
    private api:ApiService,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.listCampaigns();
    this.listCompany();
    this.formCampaign = this.formBuild.group({
      text:['', [Validators.required, Validators.maxLength(200)]],
      title:['', [Validators.required]],
      id:[0],
      companys: ['',[]],
      company_campaigns: []
    });

    this.formGroupChecked = new FormGroup({
      checked: new FormControl<boolean>(false)
  });

  this.navbarService.navbarVisible$.subscribe((show) => {
    this.navbarVisible = show
  })
  }


  onKeyDown(event:any){
    const {keyCode, key} = event
    if(keyCode !== 8){
    this.countText +=  1 
   }else if(key === 'Backspace' && this.countText >= 1){
    this.countText -=  1 
   }

    if(this.countText >= 158){
      this.countTextDisebled = true;
      this.countText = 0
        this.messageService.add({ 
          severity: 'warn', 
          summary: 'Comunicação', 
          detail: `Quantidade maxima ${this.countText} caracteres` })
    }
  }


salveCompaning(){
  this.loading = true;
  if(this.formCampaign.value.id === 0){
    if(this.formCampaign.value.companys === ""){
      this.loading = false;
     return this.messageService.add({ 
        severity: 'warn', 
        summary: 'Campanha', 
        detail:  'Adicionar uma empresa'
      })

    }else{
      this.productservice.createCampaigns(this.formCampaign.value).subscribe({
        next: (res:any) => {
          this.messageService.add({ 
            severity: 'info', 
            summary: 'Campanha', 
            detail:  'Canpanha registrada com sucesso'
          })
        },
        complete: () => {
          this.loading = false;
          this.formCampaign.reset();
          this.listCampaigns();
        },
        error: (error) => {}
      })
    }

  }else{
    this.productservice.updateCampaigns(this.formCampaign.value).subscribe({
      next: (res:any) => {
        this.messageService.add({ 
          severity: 'info', 
          summary: 'Campanha', 
          detail:  'Campanha atualizada com sucesso'
        })
      },
      complete: () => {
        this.formCampaign.reset();
        this.listCampaigns();
        this.listCompany();
        this.loading = false;
      },
      error: (error) => {}
    }).add()
  }
}


listCampaigns(){
  this.campanigs = [];
  this.loading = true;
  this.productservice.listCampaigns().subscribe({
    next: (res:any) => {
      this.campanigs = res
    },
    complete: () => {this.loading = false},
    error: () => {}
  })
}

desableCampanig(id:number, status:boolean){  
  this.loading = true;
  this.productservice.desableCampaing(status, id).subscribe({
    next:() => {
      this.messageService.add({ 
        severity: 'info', 
        summary: 'Campanha', 
        detail:  'Campanha ativada com sucesso'
      });
    },
    complete:() =>{
      this.loading = false;
      this.formGroupChecked.controls['checked'].setValue(false)
      this.listCampaigns();
    },
    error:() =>{
      
    },
  })
}

updateCampaign(campanig:any){
this.formCampaign.controls['text'].setValue(campanig.description);
this.formCampaign.controls['title'].setValue(campanig.title);
this.formCampaign.controls['id'].setValue(campanig.id);
this.formCampaign.controls['company_campaigns'].setValue(campanig.company_campaigns);
}

viewAceeipt(accept_campaign:any){
  this.acceipts = accept_campaign
  this.visible = true;
}

viewCompanyCampagin(campanig:any){
  this.AddcompanyCampaignsId = campanig.id
  this.Addcompany_campaigns = campanig.company_campaigns
  this.visibleCampagin = true;
}

listCompany(){
  this.api.postViewCompanys().subscribe({
    next: (res:any) => {
      console.log(res);
      
      this.empresas = res;
    },
    complete: () => {},
    error: () => {}
  })
}

resetForm(){
  this.formCampaign.reset();
}

resetCompanyCampaign(){
  this.selectedcompanys = [];
  this.listCompany();
}


deleteCampaging(id:number){
  this.productservice.deleteCompanyCampaging(id).subscribe({
    next: (res:any) => {
      this.messageService.add({ 
        severity: 'info', 
        summary: 'Campanha', 
        detail:  'Empresa deletada com sucssso!'
      });
    },
    complete: () => {
      this.visibleCampagin = false
      this.listCampaigns()
    },
    error: () => {}
  })
}


salveCompanyCampaign(id:number){
this.productservice.createCompanyCampaging(id, this.selectedcompanys).subscribe({
  next: () => {
    this.messageService.add({ 
      severity: 'info', 
      summary: 'Campanha', 
      detail:  'Empresa adicionada com sucssso!'
    });
  },
  complete: () => {
    this.visibleCampagin = false
      this.listCampaigns()
  },
  error: () => {}
})
}

}
