import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Column } from 'src/app/models/column';
import { Company } from 'src/app/models/company';
import { ExportColumn } from 'src/app/models/exportcolumn';
import { Faturamento } from 'src/app/models/faturamento';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-relatorio-adesao',
  templateUrl: './relatorio-adesao.component.html',
  styleUrls: ['./relatorio-adesao.component.scss']
})
export class RelatorioAdesaoComponent implements OnInit {
   adesoes: Faturamento[] = []
   selectedAdesoes: Faturamento[] = []
   loading: boolean= false
   cols: Column[] = [];
   selectedCols: Column[] = [];
   exportColumn: ExportColumn[] = [];
   filterExecel: Faturamento[] = [];
   companies: Company[] = [{name:'Todas as Parceiras', id:0}];
   clients: Company[] = [{name:'Todas os Clientes', idClient:0}];
   selectedCompanies: Company | undefined;
   selectedClient: Company | undefined
   date: Date;
   dataFechementoSeleted: any
   newDateFechamento: any;
   permission:any
   navbarVisible:boolean;

  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private localStorage: StorageService,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.permission = this.localStorage.getStoragePeermission();
    let dataFechamento = new Date();
    dataFechamento.setDate(15);
    dataFechamento.setMonth(8);
    dataFechamento.setFullYear(2023);
    let dia = dataFechamento.getDate().toString().padStart(2, '0');
    let mes = (dataFechamento.getMonth() + 1).toString().padStart(2, '0');
    let ano = dataFechamento.getFullYear();
    this.newDateFechamento = `${ano}-${mes}-${dia}`;

  
    this.getRelatorioAdesoa()
    this.getAllCompanies()

    this.cols = [
      {field: 'loja', header: 'Loja' },
      {field: 'empresa', header: 'Empresa' },
      {field: 'nomefuncionairo', header: 'Funcionário' },
      {field: 'cpf', header: 'Cpf' },
      {field: 'descricaoProduto', header: 'Produto'},
      {field: 'valorParcela', header: 'V.parcela' },
      {field: 'parcelasPaga', header: 'Q.P.Paga'},
      {field: 'qtdParcelas', header: 'Qtd.Parcelas' },
      {field: 'dataFaturamento', header: 'D.faturamento'},
      {field: 'datapedido', header:  'D.pedido'},
      {field: 'date', header: '1º Mês(desc)'},
      {field: 'precoFinal', header: 'Total'},
      {field: 'totalPago', header: 'T.Pago'},
      {field: 'statusFuncionario', header:'S.funcionário'},
      {field: 'saldo', header: 'Saldo'},
    ];

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  onChangeDateSeleted(){
    let diaSelected = this.date?.getDate().toString().padStart(2, '0');
    let mesSeleted = (this.date?.getMonth() + 1).toString().padStart(2, '0');
    let anoSelected = this.date?.getFullYear();
    this.newDateFechamento = `${anoSelected}-${mesSeleted}-${diaSelected}`;
  }

  async getRelatorioAdesoa() {
    this.loading = true
    let pesquisar = {
      id: this.selectedCompanies?.id || 0,
      idCliente: this.selectedClient?.idClient || 0,
      dateFechamento: this.newDateFechamento
    }
    
    this.apiService.getParcelasAdesao(pesquisar).subscribe(
      (res: any) => {
        this.adesoes = [];
        res.requests.map((item: any) => {
          this.adesoes.push({
            id: item.id,
            cpf: item.cpf,
            datapedido: item.data_pedido,
            date: item.data_primeiro_desconto,
            nomefuncionairo: item.funcionario,
            descricaoProduto: item.produto,
            empresa: item.empresa,
            loja: item.razao_social,
            dataFaturamento: item.data_faturamento,
            precoFinal: item.total,
            qtdParcelas: item.qtd_parcelas,
            valorParcela: item.valor_parcela,
            parcelasPaga: item.parcelas_paga,
            statusFuncionario: item.status_funcionario,
            rescisao: item.rescisao,
            saldo: item.saldo,
            totalPago: item.total_pago,
            code: item.code
          })
        })
      }).add(() => {
        this.loading = false
      })
  }

  getAllCompanies(){
    const {empresa_parceira} = this.localStorage.getUserLogado()
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{
    const filterCompanies = res.filter(item =>  item.tipo === 'Parceira');
    
    if(this.permission.roles.description === "rhapp"){
      const filterClients = res.filter(item => item.tipo === 'Cliente')
      filterClients.map(item => {
        this.clients.push({
          name: item.nome_fantasia,
          idClient: item.id
        })
      })
    }else{
      const filterClients = res.filter(item => item.tipo === 'Cliente'  && item.id === parseInt(empresa_parceira))
      filterClients.map(item => {
        this.clients.push({
          name: item.nome_fantasia,
          idClient: item.id
        })
      })

    }


    filterCompanies.map((item) => {
      this.companies.push({
        name: item.nome_fantasia,
        id: item.id
      })
    })

    })
  }


  exportAdesaoPdf(){
    if(this.adesoes.length > 0){
      const tituloRelatorio = 'RELATÓRIO DE ADESÕES DE CONVÊNIOS';
      this.apiService.relatorioAdesaoPdf(this.adesoes, tituloRelatorio).subscribe((res:any) => {
        const byteCharacters = atob(res.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document; 
        a.click();
      }).add(() => {
        this.adesoes = []
      })

    }else{
      this.messageService.add({ severity: 'warn', summary: 'Relatório', detail: 'Por favor realizar a pesquisa' })
    }
  }



}
