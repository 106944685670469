<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
      <p-table #dt1 
        [value]="posts" 
        dataKey="id"
        [rows]="50" 
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 50, 100]" 
        [loading]="loading" 
        [paginator]="true"
        [globalFilterFields]="['id', 'descricao', 'dataPedido', 'cpf','email','nomeEmpresa','empresaParceira','statusParceiro','statusPedido','nome']"
        scrollDirection="horizontal" 
        [scrollable]="true" 
        scrollHeight="600px" 
        editMode="row">
       
        <ng-template pTemplate="header">
          <tr>
            <th>
              Banner
            </th>
            <th>
             Empresas
            </th>
            <th>
              Ações
            </th>
            
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-post let-ri="rowIndex">
          <tr>
            <td><img [src]="post.imgPost" width="100" height="80"></td>
            <td>{{ post.empresa }}</td>
            <td>
                <p-button 
                icon="pi pi-times" 
                styleClass="p-button-rounded p-button-danger p-button-text"
                (click)="deletePost(post.empresasId, post.postId)"
                >
                </p-button>
            </td>
          </tr>
  
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">Não foi encontrada nenhuma categoria.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="card flex justify-content-center gap-2">
      <p-toast></p-toast>
      <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    </div>
  </div>

  
    