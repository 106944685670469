
<div class="surface-0">
  <app-menubar></app-menubar>
  <div class="grid">
  
    <div class="col-2" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
  
  <div class="col-10" [class.col-12]="!navbarVisible">
    <p-table 
    #dt2 
    [value]="atestados" 
    dataKey="id" 
    [rows]="10" 
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 50, 100]" 
    [loading]="loading" [paginator]="true" 
    [lazy]="true"
    currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
    [globalFilterFields]="['atestado.usuarios.dados_funcionarios.nome']"
    [tableStyle]="{ 'min-width': '100%' }" 
    (onLazyLoad)="getAtestados($event)" 
    [totalRecords]="totalRecords"
    [loading]="loading" 
    scrollDirection="horizontal" 
    [scrollable]="true"
    scrollHeight="600px"
    styleClass="p-datatable-striped" 
    [(selection)]="atestadosToApprove">
      <ng-template pTemplate="caption">
        <div class="print-docs">
          <div>
            <button type="button" pButton pRipple icon="pi pi-file-excel" class="p-button-success mr-2" pTooltip="XLS"
              tooltipPosition="bottom">
            </button>
            <button type="button" pButton pRipple icon="pi pi-file-pdf" [disabled]="true" class="p-button-warning mr-2"
              pTooltip="PDF" tooltipPosition="bottom">
            </button>

            <p-multiSelect [options]="cols" [(ngModel)]="selectedCols" optionLabel="header"
              [style]="{'min-width': '200px', 'margin-right':'10px'}" placeholder="Escolha as colunas">
            </p-multiSelect>
            Pesquisar Orgão
            <p-columnFilter 
                [showAddButton]="false" 
                matchMode="contains" 
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
                type="text" 
                field="centro_custo"
                display="menu">
              </p-columnFilter>
          </div>
          <div>
              <p-button type="button" 
              pRipple icon="pi pi-check" 
              (click)="approveAtestado()" 
              class="p-button-success mr-2"
              pTooltip="Aprovar atestado(s)" 
              tooltipPosition="bottom" [badge]="
              atestadosToApprove.length > 0
              ? atestadosToApprove.length.toString()
              : ''
              ">
              </p-button>
          </div>
        </div>

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th  style="min-width: 20rem">
            Empresa
            <p-columnFilter [showAddButton]="false" matchMode="contains" [showOperator]="false" type="text" field="nome_fantasia"
              display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="nome" style="min-width: 20rem">
            Funcionário
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="text" field="nome"
              display="menu"></p-columnFilter>
          </th>

          <th>
            CPF
           
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="text" field="cpf"
              display="menu"></p-columnFilter>
          </th>


          <th pSortableColumn="dt_inicio">
            Início
            <p-sortIcon field="dt_inicio"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="date" field="dt_inicio"
              display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="dt_fim">
            Fim
            <p-sortIcon field="dt_fim"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="date" field="dt_fim"
              display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="qtd_dias" style="min-width:7rem">
            Dias
            <p-sortIcon field="qtd_dias"></p-sortIcon>
          </th>
                  
          <th class="text-center">Ações</th>
          <th style="width: 4rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-atestado let-ri="rowIndex">
        <tr>
          <td style="min-width: 20rem">
            {{ atestado.usuarios.empresas.nome_fantasia }}
          </td>
          <td style="min-width: 20rem">
            {{ atestado.usuarios.dados_funcionarios.nome }}
          </td>
          <td>{{ atestado.usuarios.dados_funcionarios.cpf }}</td>
          
          <td>
            {{ atestado.dt_inicio | dataProtheus }}
          </td>
          <td>
            <span>{{ atestado.dt_fim | dataProtheus }}</span>
          </td>
          <td>
            <span>{{ atestado.qtd_dias }}</span>
          </td>
          <td>
            <div class="flex align-items-center justify-content-center gap-2">
              <button 
              pButton 
              pRipple 
              type="button" icon="pi pi-file-pdf"
              class="p-button-rounded p-button-primary p-button-text p-button-raised"
              (click)="downloadAtestado(atestado)"></button>

              <button 
              pButton 
              pRipple 
              type="button" 
              icon="pi pi-search"
              class="p-button-rounded p-button-primary p-button-text p-button-raised"
                (click)="showModalDetailheAtestado(atestado)">
              </button>

              <button 
              pButton 
              pRipple 
              type="button" 
              icon="pi pi-times"
              class="p-button-rounded p-button-danger p-button-text p-button-raised"
                (click)="showMotivoReprovacao(atestado)">
              </button>
            </div>
          </td>
          <td>
            <p-tableCheckbox [value]="atestado"></p-tableCheckbox>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Nenhum atestado encontrado.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!--modal-comfirme dialogo-->
  <div class="card flex justify-content-center gap-2">
    <p-toast></p-toast>
    <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
  </div>

  <!--modal-reprovação-->
  <div class="card flex justify-content-center">
    <p-dialog header="Informe o motivo da reprovação" [(visible)]="visible" [style]="{ width: '50vw' }">
      <form [formGroup]="atestadoForm">
        <textarea rows="5" cols="80" pInputTextarea formControlName="motivo"></textarea>
      </form>
      <p-button label="Enviar" [loading]="loading" (onClick)="reproveAtestado()"></p-button>
    </p-dialog>
  </div>

  <!--modal--detalhes-atestado-->
  <p-dialog header="Detalhamento" [(visible)]="modalAtestado" [style]="{width: '50vw'}">
    <div class="grid">
    <div class="col">
      <ul>
        <li><span class="font-bold">Empresa:</span> {{detalheAtestado?.usuarios?.empresas?.nome_fantasia}}</li>
        <li><span class="font-bold">Funcionario:</span> {{detalheAtestado?.usuarios?.dados_funcionarios?.nome}}</li>
        <li><span class="font-bold">Cpf:</span> {{detalheAtestado?.usuarios?.dados_funcionarios?.cpf}}</li>
        <li><span class="font-bold">Inicio:</span> {{detalheAtestado?.dt_inicio}}</li>
        <li><span class="font-bold">Fim:</span> {{detalheAtestado?.dt_fim}}</li>
        <li><span class="font-bold">Dias:</span> {{detalheAtestado?.qtd_dias}}</li>
      </ul>
    </div>
    <div class="col">
      <ul>
        <li><span class="font-bold">Medico:</span> {{detalheAtestado?.medico}}</li>
        <li><span class="font-bold">Crm:</span> {{detalheAtestado?.crm}}</li>
        <li><span class="font-bold">Cid:</span>{{detalheAtestado?.cid}}</li>
        <li><span class="font-bold">D.Recebimento:</span> {{detalheAtestado?.created_at | localdate}}</li>
        <li><span class="font-bold">D.Alteração:</span> {{detalheAtestado?.updated_at}}</li>
        <li><span class="font-bold">Status:</span> {{detalheAtestado?.status}}</li>
      </ul>
    </div>
    </div>
    
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="modalAtestado=false" label="Fechar" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
</div>
</div>