<div class="surface-50">
  <p-toast></p-toast>
  <app-menubar></app-menubar>
 
  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
  
  <div class="col-10" [class.col-12]="!navbarVisible">
    <p-table 
    #dt1 
    selectionMode="multiple"
    [metaKeySelection]="true"
    [value]="requisicoes" 
    dataKey="requestId"
    [rows]="10" 
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 50, 100]" 
    [totalRecords]="totalRecordOrder"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [loading]="loading" 
    [paginator]="true"
     [globalFilterFields]="['requestId', 'descricao', 'dataPedido', 'cpf','email','nomeEmpresa','empresaParceira','statusParceiro','statusPedido','nome']"
      scrollDirection="horizontal" 
      [scrollable]="true" 
      scrollHeight="600px" 
      editMode="row">
      <ng-template pTemplate="caption">
        <div class="rhsearch">
          <div>
            <button type="button" pButton pRipple icon="pi pi-plus" (click)="showModalEmprestar()"
              class="p-button-success ml-2" pTooltip="LANÇAMENTO MANUAL" tooltipPosition="bottom">
            </button>
          </div>
          <div>
            <span class="p-input-icon-left mr-2">
              <i class="pi pi-search"></i>
              <input pInputText (input)="dt1.filterGlobal(
                  $any($event.target).value, 'contains')" type="text" />
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr [pEditableRow]="requisicoes">
          <th pSortableColumn="requestId" pFrozenColumn>
            N˚ do Pedido
            <p-sortIcon field="requestId"></p-sortIcon>
          </th>
          <th style="min-width: 12rem;" pFrozenColumn>
            Data do Pedido
          </th>
          <th style="min-width: 23rem;" pFrozenColumn>
            Funcionário
          </th>
          <th style="min-width: 12rem;">
            CPF
          </th>
          <th style="min-width: 20rem;">
            Empresa
          </th>
          <th> Status </th>
          <th>Tipo(contrato)</th>
          <th>Mês(contrato)</th>
          <th> Celular </th>
        
         
          <th>Taxa adesão</th>
          <th style="min-width: 15rem;">
            Produto
          </th>
          <th>
            Valor total
          </th>
          <th>
            Parcela
          </th>
          <th *ngIf="permission.roles.description === 'rhapp' || permission.roles.description === 'banco'" style="min-width: 8rem;">
            Margem
          </th>
          <th  *ngIf="permission.roles.description === 'rhapp' || permission.roles.description === 'banco'">
            Wayconsig
          </th>
          <th style="min-width: 15rem;">
            Loja
          </th>
          <th>
            Status Parceiro
          </th>
          <th>
            Situação
          </th>
          <th style="min-width: 12rem;">
            Última Alteração
          </th>
          <th alignFrozen="right" pFrozenColumn [frozen]="balanceFrozen" style="min-width: 10rem;">
            Ações
          </th>
          <th *ngIf="permission.roles.description === 'comercial'" >
            Faturar
          </th>
          <th *ngIf="permission.roles.description === 'banco'">
            Aprovar
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-pedido let-editing="editing" let-ri="rowIndex">
        <tr [pSelectableRow]="pedido" [pSelectableRowIndex]="rowIndex">
          <td pFrozenColumn>{{ pedido.requestId }}</td>
          <td pFrozenColumn>{{ pedido.dataPedido | localdatetime}}</td>
          <td pFrozenColumn>{{ pedido.nome }}</td>
          <td>{{ pedido.cpf | cpf }}</td>
          <td>{{ pedido.nomeEmpresa}}</td>
          <td>{{ pedido.statusRh }}</td>
          <th>{{ pedido.tipo_contrato }}</th>
          <td>{{ pedido.mes }}</td>
          <td>{{ pedido.celular }} </td>
         
          
          <td> {{pedido.taxaAdesao | currency: 'BRL'}} </td>
          <td>{{ pedido.descricaoProduto }}</td>
          <td>{{ pedido.precoFinal | currency: 'BRL' }}</td>
          <td> {{ pedido.valorParcela | currency: 'BRL'}} </td>
          <!--Margem-->
          <td *ngIf="permission.roles.description === 'rhapp'  || permission.roles.description === 'banco'">
            {{ pedido.margem | currency: 'BRL' || '0,00'}}
          </td>

          <td  *ngIf="permission.roles.description === 'rhapp' || permission.roles.description === 'banco'">
            <button pButton type="button" (click)="openModalMota(pedido)" pRipple icon="pi pi-money-bill"
              class="p-button-rounded p-button-info p-button-text p-button-raised"
              [disabled]="pedido.statusParceiro === 'REPROVADO'">
            </button>
          </td>
        
          <td>{{ pedido.empresaParceira }} </td>
          <td>{{pedido.statusParceiro}}</td>
          <td>{{pedido.statusPedido }}</td>
          <td>{{pedido.ultimaAtualizacao | localdatetime}}</td>
          <!--Actions-->
          <td alignFrozen="right" pFrozenColumn [frozen]="balanceFrozen">
            <div class="flex align-items-center justify-content-center gap-2">
              <!--rhapp-->
              <div *ngIf="permission.roles.description === 'rhapp'">
                <button  pButton pRipple type="button" icon="pi pi-check"
                  class="p-button-rounded p-button-text" (click)="aprovarPedido(pedido)" [disabled]="pedido.statusParceiro !=='-' ">
                </button>
              </div>
              <div *ngIf="permission.roles.description === 'rhapp'">
                <button  pButton pRipple type="button" icon="pi pi-times"
                  class="p-button-rounded p-button-text" (click)="onRowEditInitdialogAprove(pedido)">
                </button>
              </div>
              <!--comercial-->
              <div *ngIf="permission.roles.description === 'comercial'">
                <button pButton pRipple type="button" icon="pi pi-check"
                  class="p-button-rounded p-button-text" (click)="aprovarPedidoComercial(pedido)"  [disabled]="pedido.statusParceiro !=='PENDENTE'">
                </button>
              </div>
              <div *ngIf="permission.roles.description === 'comercial'">
                <button  pButton pRipple type="button" icon="pi pi-times"
                  class="p-button-rounded p-button-text" (click)="onRowEditInitdialogAprove(pedido)">
                </button>
              </div>
              <!--banco-->
              <div *ngIf="permission.roles.description === 'banco'">
                <button  pButton pRipple type="button" icon="pi pi-check"
                  class="p-button-rounded p-button-text" (click)="aprovarPedidoComercial(pedido)">
                </button>
              </div>
              <div *ngIf="permission.roles.description === 'banco'">
                <button  pButton pRipple type="button" icon="pi pi-times"
                  class="p-button-rounded p-button-text" (click)="rejeitarPedidoComercial()">
                </button>
              </div>
            </div>
          </td>
          <td alignFrozen="right" pFrozenColumn [frozen]="balanceFrozen" style="min-width: 10rem;">

            <button pButton type="button" (click)="showModalFAturar(pedido)" pRipple icon="pi pi-money-bill"
              class="p-button-rounded p-button-info p-button-text p-button-raised mr-2">
            </button>

            <button *ngIf="permission.roles.description === 'rhapp'" pButton type="button" (click)="getListProductConvenios(pedido)" pRipple icon="pi pi-eye"
              class="p-button-rounded p-button-info p-button-text p-button-raised"
              >
            </button>
          </td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">Não foi encontrada nenhuma categoria.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-toast></p-toast>
    <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
  </div>
  
  <!--modal--aprovar--desaprovar-->
  <p-dialog header="REPROVAR REQUISIÇÂO: {{nomeFuncionario}}" [(visible)]="dialogAprove" [style]="{width: '50vw'}">
    <div class="grid">
      <div class="col-12">
        <p-table [value]="contratosAtivosWayConsig" [tableStyle]="{ 'min-width': '50rem' }">
          <ng-template pTemplate="header">
              <tr>
                  <th>N°Contrato</th>
                  <th>Valor Contrato</th>
                  <th>Matricula</th>
                  <th>valorParcela</th>
                  <th>Action</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-contrato>
              <tr>
                  <td>{{ contrato.numeroContrato }}</td>
                  <td>{{ contrato.valorContrato | currency: 'BRL'}}</td>
                  <td>{{ contrato.matricula }}</td>
                  <td>{{ contrato.valorParcela | currency: 'BRL'}}</td>
                  <td>
                    <button  pButton pRipple type="button" icon="pi pi-times"
                    class="p-button-rounded p-button-text" (click)="liquidarContratoWayConsig( contrato.numeroContrato )">
                  </button>
                  </td>
              </tr>
          </ng-template>
      </p-table>
      
        <div class="card flex justify-content-left">
          <textarea rows="2" cols="100" pInputTextarea [(ngModel)]="observacao" placeholder="motivo"></textarea>
      </div>
      </div>
      <!--rhapp-->
      <div class="col-12 buttonEdit" *ngIf="permission.roles.description === 'rhapp'">
          <button type="button" 
          pButton pRipple icon="pi pi-save"
          class="p-button-primary mr-2" 
          pTooltip="Salvar" 
          (click)="rejeitarPedido()"
          [disabled]="contratosAtivosWayConsig.length !== 0 "
          tooltipPosition="bottom">
        </button>
        <button type="button" 
          pButton pRipple icon="pi pi-times"
          class="p-button-warning mr-2" 
          (click)="onRowEditCancel()"
          pTooltip="Cancelar" 
          tooltipPosition="bottom">
        </button>
      </div>
      <!--comercial-->
      <div class="col-12 buttonEdit" *ngIf="permission.roles.description === 'comercial'">
        <button type="button" 
        pButton pRipple icon="pi pi-save"
        class="p-button-primary mr-2" 
        pTooltip="Salvar" 
        (click)="rejeitarPedidoComercial()"
        [disabled]="contratosAtivosWayConsig.length !== 0 "
        tooltipPosition="bottom">
      </button>
      <button type="button" 
        pButton pRipple icon="pi pi-times"
        class="p-button-warning mr-2" 
        (click)="onRowEditCancel()"
        pTooltip="Cancelar" 
        tooltipPosition="bottom">
      </button>
    </div>
    </div>
</p-dialog>

  <!-- Faturamento -->
  <p-dialog header="Faturamento" [(visible)]="showModal" [style]="{width: '50vw'}" *ngIf="showModal">
    <form [formGroup]="createFaturamentoForm">

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Nº Pedido
            </span>
          </div>
          <div class="col-2">
            <input type="text" id="disabled-input" class="flex w-full" pInputText placeholder="id" formControlName="id"
              [disabled]="disabled" />
          </div>
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Nº Contrato
            </span>
          </div>
          <div class="col-4">
            <input type="text" id="disabled-input" class="flex w-full" pInputText placeholder="Numero contrato"
              formControlName="numeroContrato" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Funcionário
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="nomefuncionairo"
              formControlName="nomefuncionairo" />
          </div>
        </div>
      </div>


      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Cpf
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="cpf" formControlName="cpf" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Empresa
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="empresa" formControlName="empresa" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Produto
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="descricaoProduto"
              formControlName="descricaoProduto" />
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Email
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="email" formControlName="email" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Contato
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full " pInputText placeholder="telefone" formControlName="telefone" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <label htmlFor="username">Mês/ano 1º desconto</label>
            <div class="p-inputgroup field flex" *ngIf="permission.roles.description === 'comercial'">
              <div class="flex w-full">
                <p-calendar [(ngModel)]="date" [showIcon]="true" formControlName="date" dateFormat="yy.mm.dd"
                  class="ng-invalid ng-dirty" [minDate]="minDate" [maxDate]="maxDate">
                </p-calendar>
              </div>
            </div>

            <div class="p-inputgroup field flex" *ngIf="permission.roles.description === 'rhapp'">
              <div class="flex w-full">
                <p-calendar [(ngModel)]="dateRhapp" [showIcon]="true" formControlName="date" dateFormat="yy.mm.dd"
                  class="ng-invalid ng-dirty">
                </p-calendar>
              </div>
            </div>
          </div>

          <div class="col-3">
            <label htmlFor="total">Total:R$</label>
            <input type="text" class="flex w-full" pInputText placeholder="total" formControlName="precoFinal" />

          </div>
          <div class="col-3">
            <label htmlFor="qtd.parcelas">Qtd.parcelas</label>
            <input type="text" class="flex w-full" pInputText placeholder="qtdParcelas" formControlName="qtdParcelas" />
          </div>
          <div class="col-3">
            <label htmlFor="v.parcelas">V.parcela:R$</label>
            <input type="text" class="flex w-full" pInputText placeholder="valorParcela" formControlName="valorParcela"
              required minlength="2" />

          </div>
        </div>
      </div>

    </form>
    <ng-template pTemplate="footer" class="flex ">
      <p-button icon="pi pi-save" label="Faturar" class="mt-4" (click)="createPedidoFaturamento()"></p-button>
    </ng-template>
  </p-dialog>

  <!--LANÇAMENTO--MANUAL-->
  <p-dialog header="Lançamento manual / Situação: {{statusRhApp || sitFolha}}" [(visible)]="showModalEmprestimo"
    [style]="{width: '60vw'}" *ngIf="showModalEmprestimo">
    <form [formGroup]="createFormEmprestimo">

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Margin Rhapp
            </span>
          </div>
          <div class="col-2">
            <p-button icon="pi pi-search" label="Pesquisar" class="mt-4" (click)="pesquisarFuncionario()"></p-button>
          </div>
          <div class="col-3">
            <input type="text" id="disabled-input" class="flex w-full" pInputText placeholder="Pesquisar cpf"
              formControlName="pesquisar" />
          </div>
          <div class="col-4">
            <p-inputNumber class="flex w-full" formControlName="resultadoSaldo" inputId="currency-us" mode="currency"
              currency="BRL" locale="en-US">
            </p-inputNumber>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Magin Mota
            </span>
          </div>
          <div class="col-3">
            <input type="text" id="disabled-input" class="flex w-full" pInputText placeholder="Matricula"
              formControlName="matriculaFun" />
          </div>
          <div class="col-3">
            <input type="text" id="disabled-input" class="flex w-full" pInputText placeholder="Codigo convenio"
              formControlName="codConvenio" />
          </div>
          <div class="col-3">
            <p-inputNumber class="flex w-full" formControlName="marginMota" inputId="currency-us" mode="currency"
              currency="BRL" locale="en-US">
            </p-inputNumber>
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Funcionário
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="nomefuncionairo"
              formControlName="nomefuncionairo" />
          </div>
        </div>
      </div>


      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Cpf
            </span>
          </div>
          <div class="col-4">
            <input type="text" class="flex w-full" pInputText placeholder="cpf" formControlName="cpf" />
          </div>
          <div class="col-5">
            <input type="text" class="flex w-full" pInputText placeholder="Nº contrato"
              formControlName="numeroContrato" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Empresa
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="empresa" formControlName="empresa" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Produto
            </span>
          </div>
          <div class="p-inputgroup field flex">
            <div class="col-9">
              <p-dropdown 
              class="flex w-full" 
              [options]="listCategoriesProducts" 
              [(ngModel)]="SelectedlistCategories"
              formControlName="productCategory"
              (onChange)="findPriceProducts()"
              optionLabel="name">
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Email
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="email" formControlName="email" />
          </div>
        </div>
      </div>

      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <span class="p-inputgroup-addon">
              Contato
            </span>
          </div>
          <div class="col-9">
            <input type="text" class="flex w-full" pInputText placeholder="telefone" formControlName="telefone" />
          </div>

        </div>
      </div>
      <!--calendar-->
      <div class="grid">
        <div class="p-inputgroup field flex">
          <div class="col-3">
            <label htmlFor="username">Mês/ano 1º desconto</label>
            <!--Comercial-->
            <div class="p-inputgroup field flex" *ngIf="permission.roles.description === 'comercial'">
              <div class="flex w-full">
                <p-calendar [(ngModel)]="date" [showIcon]="true" formControlName="dateEmprestimo" dateFormat="yy.mm.dd"
                  [minDate]="minDate" [readonlyInput]="true" [maxDate]="maxDate">
                </p-calendar>
              </div>
            </div>
            <!--Rhapp-->
            <div class="p-inputgroup field flex" *ngIf="permission.roles.description === 'rhapp'">
              <div class="flex w-full">
                <p-calendar [(ngModel)]="dateRhapp" [showIcon]="true" formControlName="dateEmprestimo" dateFormat="yy.mm.dd">
                </p-calendar>
              </div>
            </div>
          </div>

          <div class="col-3">
            <label htmlFor="total">Total:R$</label>
            <p-inputNumber class="flex w-full" formControlName="precoFinalEmprestimo" inputId="currency-us"
              mode="currency" currency="BRL" locale="en-US">
            </p-inputNumber>

          </div>
          <div class="col-3">
            <label htmlFor="qtd.parcelas">Qtd.parcelas</label>
            <input type="text" class="flex w-full" pInputText placeholder="qtdParcelas"
              formControlName="qtdParcelasEmprestimo" />
          </div>
          <div class="col-3">
            <label htmlFor="v.parcelas">V.parcela:R$</label>
            <p-inputNumber class="flex w-full" formControlName="valorParcelaEmprestimo" inputId="currency-us"
              mode="currency" currency="BRL" locale="en-US">
            </p-inputNumber>
          </div>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer" class="flex ">
      <p-button icon="pi pi-save" label="Cancelar" class="mt-4" (click)="resetForm()">
      </p-button>

      <p-button icon="pi pi-save" label="Faturar" class="mt-4" (click)="createEmprestimo()"
        [disabled]="!sitFolha">
      </p-button>

    </ng-template>
  </p-dialog>


  <!--Margin--mota-->
  <p-dialog header="Margin Mota" [(visible)]="showModalMota" [style]="{width: '60vw'}" *ngIf="showModalMota">
    <p-table [value]="dataMarginMota" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>
              <th>Funcionário</th>
              <th>Cpf</th>
              <th>Matricula</th>
              <th>Margin</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-mota>
          <tr>
              <td>{{ mota.nome }}</td>
              <td>{{ mota.cpf }}</td>
              <td>{{ mota.matricula }}</td>
              <td>{{ mota.valorMargemDisponivel | currency: 'BRL'}}</td>
          </tr>
      </ng-template>
  </p-table>
  </p-dialog>

   <!--list--products--convenios-->
   <p-dialog header="Convenios" [(visible)]="showModalConvenioAtivo" [modal]="true" [style]="{ width: '80vw' }" [draggable]="true" [resizable]="true">
    <p-table [value]="listConvenios" [autoLayout]="false" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
        <tr>
          <th>Label</th>
          <th>Valor</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-convenio>
        <tr>
          <td style="width: 180px; margin-right: 30px;">NOME:</td>
          <td><span>{{convenio.nome_funcionario}} - CPF: {{convenio.cpf | cpf}}  </span></td>
        </tr>
        <tr>
          <td style="width: 180px;  margin-right: 30px;">MARGEM BRUTA:</td>
          <td>
            <span> {{convenio.resultCalMargemBruta | currency:'BRL'}}</span>
           <a href="javascript:void(0);" (click)="showModalVerbasProventos()">
             (30% PROVENTOS COSIGNAVÉIS)
           </a>

           <span> - PERIODO: {{ convenio.periodo | date:'MM/YYYY'}} </span>
          </td>
        </tr>
        <tr>
          <td style="width: 180px;  margin-right: 30px;">DESCONTOS:</td>
          <td>
            <span>{{convenio.calcDesconto | currency:'BRL'}}</span>
            <a href="javascript:void(0);" (click)="showModalVerbasDescontos()">(DESCONTOS COSIGNAVÉIS - CONTRACHEQUE)</a>
          </td>
        </tr>
        <tr>
          <td style="width: 180px;  margin-right: 30px;">MARGEM LIQUIDA:</td>
          <td>
            <span> {{convenio.margemLiquida | currency:'BRL'}}</span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    
    <div class="grid">
      <div class="col-12">
        <h3>Acompanhamento RHapp</h3>
      </div>
    </div>
    
    <p-table [value]="listConvenios" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>
              <th>Verba</th>
              <th>Produto</th>
              <th>Valor</th>
              <th>Data Pedido</th>
              <th>1ºMes(desc)</th>
              <th>Parcela atual</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-convenio>
          <tr *ngFor="let item of convenio.list">
              <td>{{ item.codigoProduto }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.valorParcela |currency:'BRL'}}</td>
              <td>{{ item.dataPedido | datetime}}</td>
              <td>{{ item.dataPrimeiroDesconto | datetime}}</td>
              <td> {{item.parcelaAtual}} </td>
          </tr>
      </ng-template>
    </p-table>
</p-dialog>


<!--modal--verbas--proventos-->
<p-dialog header="Proventos" [(visible)]="typeProventos" [style]="{width: '50vw'}">
  <p-table [value]="listConvenios" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
        <tr>
            <th>Verba</th>
            <th>Descrição</th>
            <th>Valor</th>
            
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-convenio>
        <tr *ngFor="let item of convenio.verbasProventos">
            <td>{{ item.cod_verba }}</td>
            <td>{{ item.desc_verba }}</td>
            <td>{{ item.valor |currency:'BRL'}}</td>
           
        </tr>
    </ng-template>
  </p-table>
</p-dialog>

  <!--modal--verbas--descontos-->
  <p-dialog header="Descontos" [(visible)]="typeDesconto" [style]="{width: '50vw'}">
    <p-table [value]="listConvenios" [tableStyle]="{ 'min-width': '50rem' }">
      <ng-template pTemplate="header">
          <tr>
              <th>Verba</th>
              <th>Descrição</th>
              <th>Valor</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-convenio>
          <tr *ngFor="let item of convenio.verbasDescontos">
              <td>{{ item.cod_verba }}</td>
              <td>{{ item.desc_verba }}</td>
              <td>{{ item.valor |currency:'BRL'}}</td>
          </tr>
      </ng-template>
    </p-table>
  </p-dialog>

</div>
</div>


