import { Component, NgZone, OnInit } from '@angular/core';
import { MessageService, ConfirmationService, LazyLoadEvent, ConfirmEventType } from 'primeng/api';
import { PayloadNotification } from 'src/app/models/payloadNotification';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { UtilsService } from 'src/app/services/utils.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-list-vacation',
  templateUrl: './list-vacation.component.html',
  styleUrls: ['./list-vacation.component.scss'],
})
export class ListVacationComponent implements OnInit {
  public vacations: Array<any>;
  public loading: boolean;
  public visible: boolean;
  public navbarVisible: boolean;
  public selectedVacations: any[] = [];
  public totalRecords: number = 0;
  public lazy: boolean = true;
  public avisoFilter: any[] = [];
  value: number = 0;
  interval: any;
  editForm: FormGroup;
  visibleEdit: boolean = false;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private vacationService: VacationService,
    private navbarService: NavbarService,
    private utilsService: UtilsService,
    private ngZone: NgZone,
    private fb:FormBuilder,
    private api: ApiService
  ) { }

  ngOnInit(): void {

    this.editForm = this.fb.group({
      ddd_celular:['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
      celular: ['',[Validators.required, Validators.maxLength(9), Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.email]],
      nome: ['', [Validators.required]],
      fer_ini:['',Validators.required],
      fer_fim: ['', Validators.required]
    });

    this.visible = false;
    this.loading = false;
    this.selectedVacations = [];

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });

    this.avisoFilter = [
      { nome_fantasia: 'nome_fantasia' },
      { nome: 'nome' },
      { cpf: 'cpf' },
      { matricula: 'matricula' },
      { fer_ini: 'fer_ini' },
      { fer_fim: 'fer_fim' },
      { mes_movimento: 'mes_movimento' }
    ];
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  showDialog() {
    this.visible = true;
  }

  async modalEditdataEmploee(doc:any){
    const ferIini = await this.utilsService.formatDate(doc.fer_ini);
    const ferFim = await this.utilsService.formatDate(doc.fer_fim);
    const email = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.email);
    const nome = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.nome);
    this.editForm.reset()
    this.selectedVacations = [doc]
    this.editForm.controls['nome'].setValue(nome);
    this.editForm.controls['ddd_celular'].setValue(doc.usuarios.dados_funcionarios.ddd_celular);
    this.editForm.controls['celular'].setValue(doc.usuarios.dados_funcionarios.celular);
    this.editForm.controls['email'].setValue(email);
    this.editForm.controls['fer_ini'].setValue(ferIini);
    this.editForm.controls['fer_fim'].setValue(ferFim);
    this.visibleEdit = true
    }

  async searchVacations(event: LazyLoadEvent) {

    this.vacations = [];
    this.totalRecords = 0;
    this.loading = true;
    this.vacationService.getVacationsSync(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const listVacation = res.fer.map(async (item: any) => {
          this.vacations.push({
            ...item
          })
        })

        await Promise.all(listVacation);
        this.totalRecords = res.totalRecords;
      },
      complete: () => {
        this.loading = false;
      },
      error: (error) => {
        console.log(error);
      },
    });
    return this.vacations;
  }

  async printVacationWarning(vacation) {
    this.selectedVacations = [vacation]

    this.loading = true;
    this.vacationService.getLayoutPdfAvisoFerias(this.selectedVacations).subscribe({
      next: (res: any) => {
        const blob = new Blob([new Uint8Array(res.pdfBUffer.data)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document;
        a.click();
      },
      complete: () => {
        this.loading = false;
        this.selectedVacations = [];
      },
      error: () => { }
    });

  }

  async cancelWarinigBeforeShipping(vacation: any) {
    const { id, usuarios } = vacation;
    this.confirmationService.confirm({
      message: `Você deseja proseguir com o cancelamento de aviso do Sr(a) ${usuarios.dados_funcionarios.nome}`,
      header: 'Deletar aviso',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading = true;
        this.vacationService.cancelWarningBerforeShipping(id).subscribe({
          next: (res: any) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Recibo',
              detail: res.message,
            });
          },
          complete: () => {
            this.loading = false;
            this.searchVacations({ first: 0, rows: 10 });
          },
          error: (erro) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Recibo',
              detail: 'Não foi possivel  cancelar o aviso',
            });
          }
        })
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Procedimento foi abortado' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Procedimento foi abortado' });
            break;
        }
      }
    });


  }


  verifyVacationsSelected() {
    if (this.selectedVacations.length > 0) {
      this.printAlert();
      return false;
    } else {
      return true;
    }
  }

  printAlert() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Ops',
      detail:
        'Primeiro selecione pelo menos um item de férias para prosseguir.',
    });
  }

  uploadVacations(type: string) {

    if (this.selectedVacations.length !== 0) { 
      this.loading = true;
      this.confirmationService.confirm({
        message: 'Você tem certeza que deseja enviar?',
        header: 'Atenção',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          try {

            this.selectedVacations.map((item, i) => {
              this.ngZone.runOutsideAngular(() => {
               this.interval = setInterval(() => {
                  this.ngZone.run(() => {
                    this.value = this.value + Math.floor(Math.random() * 10) + i + 10;
                    if (this.value >= 100) {
                      this.value = 100;
                      clearInterval(this.interval);
                    }
                  });
                }, 50);
              });

              const sendToken: PayloadNotification = {
                tokens: [item.usuarios?.notifications_tokens[0]?.token],
                notification: {
                  title: 'Olá',
                  body: 'Seu aviso de férias está disponível no aplicativo.'
                },
                data: {
                  action: '/ferias',
                  intent: 'FERIAS_INTENT'
                }
              }
              this.vacationService.sendVacationWarning(item, sendToken, type).subscribe({
                next: (res: any) => {},
                error: () => {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail:
                      'Ocorreu um erro ao tentar enviar as férias para o(s) funcionário(s) selecionados.',
                  });
                },
                complete: async () => {
                  this.loading = false;
                  this.selectedVacations = [];
                },
              });
            });
           
              this.messageService.add({
                severity: 'info',
                summary: 'Sucesso',
                detail:
                  'A(s) férias foram enviadas para o(s) funcionário(s) selecionados.',
              });
              this.searchVacations({ first: 0, rows: 10 });

          } catch (error) {
            this.loading = false;
            console.error(error);
          }
        },
        reject: () => {
          this.selectedVacations = []
          this.loading = false
        },
        acceptLabel: 'SIM',
        rejectLabel: 'NÃO',
      });

    } else {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Recibo de férias',
        detail: 'Seleciona pelo menos um item',
      });
    }

  }

  acceptedNotifications(vacation: any) {
    return vacation.usuarios.notifications_tokens.length > 0 ? 'Sim' : 'Não';
  }

  onCheckboxChange(event: any, rowData: any) {
    const itemIndex = this.selectedVacations.findIndex(item => item === rowData);
    if (event.checked && itemIndex === -1) {
      this.selectedVacations.push(rowData); // Adiciona o item selecionado se não estiver na lista
    } else if (!event.checked && itemIndex !== -1) {
      this.selectedVacations.splice(itemIndex, 1); // Remove o item deselecionado da lista se estiver presente
    }
  }

  async exportExcel() {
    this.vacations = [];
    this.loading = true;
    const event = { first: 0, rows: this.totalRecords }
    this.vacationService.getVacationsSync(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const listVacation = res.fer.map(async (item: any) => {
          this.vacations.push({
            ...item
          })
        })

        await Promise.all(listVacation);
        this.totalRecords = res.totalRecords;
      },
      complete: () => {
      },
      error: (error) => {
        console.log(error);
      },
    }).add(() => {
      this.utilsService.exportExcell(this.avisoFilter, this.vacations);
      this.vacations = [];
      this.loading = false;
      this.searchVacations({ first: 0, rows: 10 });
    });

  }

  async editarCadastro(){
  if(this.editForm.status === 'INVALID'){    
    this.messageService.add({
      severity: 'warn',
      summary: 'Aviso',
      detail:'Todos os campos sao obrigatórios',
    });
  }else{
    this.loading = true
    const data =  {
        ddd_celular: this.editForm.value.ddd_celular,
        celular: this.editForm.value.celular,
        email: await this.utilsService.clearEmailNomeSpaceInWhite(this.editForm.value.email),
        nome: await this.utilsService.clearEmailNomeSpaceInWhite(this.editForm.value.nome),
        fer_ini: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_ini),
        fer_fim: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_fim),
        funcId: this.selectedVacations[0].usuarios.dados_funcionarios.id,
        ferId: this.selectedVacations[0].id
      }
      
      this.api.updateEmployee(data).subscribe({
        next: (res:any) => {
          this.loading = false;
          this.messageService.add({
            severity: 'info',
            summary: 'Editar cadastro',
            detail: res.message,
          });
        },
        complete: () => {
          this.editForm.reset()
          this.loading = false
          this.selectedVacations = []
          this.visibleEdit = false
          this.searchVacations({ first: 0, rows: 10 });
        },
        error: (error) => {}
      })

  }
  }

}
