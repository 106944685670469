import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { FunctionsService } from '../../services/functions.service';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-listar-empresas',
  templateUrl: './listar-empresas.component.html',
  styleUrls: ['./listar-empresas.component.scss'],
})
export class ListarEmpresasComponent implements OnInit {
  public companys:any;
  public userLogado:any;
  navbarVisible:boolean;
  public showModal = false;
  editingCompanyForm = this.formBuilder.group({
    cnpj: ['', [Validators.minLength(14)]],
    nome_fantasia: ['', [Validators.maxLength(100)]],
    grupo: ['', [Validators.maxLength(2)]],
    filial: ['', [Validators.maxLength(6)]],
    razao_social: ['', [Validators.maxLength(100)]],
    email: ['', [Validators.maxLength(50)]],
    telefone: ['', [Validators.minLength(13), Validators.maxLength(13)]],
    celular: ['', [Validators.minLength(14), Validators.maxLength(14)]],
  });
  public copyCompany: any = {};

  constructor(
    public api: ApiService,
    public storageService: StorageService,
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public functionsService: FunctionsService,
    public confirmationService: ConfirmationService,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
    try {
      this.userLogado = await this.storageService.getUserLogado();
      this.api.getEmpresas().subscribe((res) => {
        this.companys = res;
        console.log(this.companys);
      });
    } catch (error) {}

    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })

  }

  editCompany(company: any) {
    this.showModal = true;
    this.extractSelectCompany(company);
  }

  editCompanyDb() {
    let { id: companyId } = this.copyCompany;
    let editingCompanyFormValues: any = this.extractFormValues(
      this.editingCompanyForm
    );
    let company = this.fieldsChanged(editingCompanyFormValues);

    console.log(company);
    if (Object.keys(company).length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops',
        detail: 'O cadastro não foi alterado, não há necessidade de salvar.',
      });
    } else {
      this.api.editCompany(companyId, company).subscribe({
        next: (res: any) => {
          if (res.edited)
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'O cadastro foi alterado com sucesso.',
            });
          else
            this.messageService.add({
              severity: 'error',
              summary: 'Ocorreu um erro ao alterar o cadastro da empresa',
              detail: res.message,
            });
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: error.error.message,
          });
        },
      });
    }
  }

  extractFormValues(form) {
    let formValues = {};

    Object.keys(form.controls).forEach((key) => {
      formValues[key] = form.get(key).value;
    });

    return formValues;
  }

  fieldsChanged(companyChanged: any) {
    let fieldsChangeds = {};
    Object.keys(companyChanged).forEach((key) => {
      if (this.copyCompany[key] !== companyChanged[key]) {
        if (key === 'telefone' || key === 'celular')
          fieldsChangeds[key] = this.functionsService.formatCelTel(
            companyChanged[key]
          );
        else if (key === 'cnpj')
          fieldsChangeds[key] = this.functionsService.formatCnpj(
            companyChanged[key]
          );
        else fieldsChangeds[key] = companyChanged[key];
      }
    });

    console.log(fieldsChangeds);
    return fieldsChangeds;
  }

  extractSelectCompany(company) {
    this.copyCompany = company;
    Object.keys(company).forEach((key) => {
      try {
        // Evita que gere erro ao atribuir valores que existem no company e não no form
        this.editingCompanyForm.controls[key].setValue(company[key]);
      } catch (error) {}
    });
  }

  /**
   * Captura os erros do formulário e o retorna.
   */
  get errorControl() {
    console.log(this.editingCompanyForm.controls.celular.errors);
    return this.editingCompanyForm.controls;
  }

  deleteCompany({ id: companyId }: any) {
    this.confirmationService.confirm({
      message: 'Você tem certeza que deseja deletar a empresa?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        try {
          this.api.deleteCompany(companyId).subscribe((res) => {
            this.ngOnInit();
            this.messageService.add({
              severity: 'info',
              summary: 'Sucesso',
              detail: 'A empresa foi deletada com sucesso.',
            });
          });
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Ocorreu um erro ao deletar a empresa.',
          });
        }
      },
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
    });
  }
}
