import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../services/api.service';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { FunctionsService } from '../../services/functions.service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    cpf: ['', [Validators.required, Validators.minLength(14)]],
    senha: ['', Validators.required],
  });
  gettingRequest = false;

  constructor(
    public api: ApiService,
    public formBuilder: FormBuilder,
    public route: Router,
    public primengConfig: PrimeNGConfig,
    public functionsService: FunctionsService,
    public messageService: MessageService,
    public storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  loading() {
    this.gettingRequest = true;
  }

  login() {
    if (!this.loginForm.valid) {
      //se o formulário NÃO for válido
      console.log(this.loginForm.controls.cpf.errors);
      console.log(this.loginForm.controls.senha.errors);
      //this.functionsService.presentToast("Formulário não válido! Verifique se o CPF está no formato XXX.XXX.XXX-XX!", 4000)
      return false;
    } else {
      this.gettingRequest = true;
      let cpfFormatted = this.functionsService.formatCpf(
        this.loginForm.controls.cpf.value
      );

      this.api
        .login(cpfFormatted, this.loginForm.controls.senha.value)
        .subscribe({
          next: (response) => {
            let responseFormat: any = response;
            // if (responseFormat.accessControlRoles.includes('rhapp')) {
            this.storageService.login(responseFormat);
            this.gettingRequest = false;
            this.route.navigateByUrl('/home', {
              replaceUrl: true, //destroi a pagina atual
            });
            console.log(response);
            // } else {
            //   this.gettingRequest = false;
            //   this.messageService.add({
            //     severity: 'error',
            //     summary: 'Você não tem permissão para logar no portal!'
            //   });
            // }
          },
          error: (error) => {
            this.gettingRequest = false;
            console.log(error);
            this.messageService.add({
              severity: 'error',
              summary: 'Ocorreu um erro ao logar!',
              detail: error.error.message,
            });
          },
        });
      return true;
    }
  }

  get errorControl() {
    //Captura os erros do formulário e o retorna
    return this.loginForm.controls;
  }
}
