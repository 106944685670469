import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusRh'
})
export class StatusRhPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let ret = {
      " ": "ATIVO",
      "D": "DEMITIDO",
      "F": "FÉRIAS",
      "A": "AFASTADO"
    }
    return ret[value];
  }

}
