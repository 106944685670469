import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-add-to-group',
  templateUrl: './add-to-group.component.html',
  styleUrls: ['./add-to-group.component.scss'],
})
export class AddToGroupComponent implements OnInit {
  selectedPerson: any;
  results1: any;
  hideBtn: boolean = false;
  loading: boolean = true;
  navbarVisible:boolean;

  // funcoes: [{}] = [{ label: 'teste', value: '' }];
  public isLoading: boolean = false;
  public searchForm = this.formBuilder.group({
    nome: ['', [Validators.minLength(4), Validators.required]],
    cpf: ['', [Validators.minLength(3), Validators.required]],
    filterSelected: ['cpf', Validators.required],
  });
  public userLogado: any = {};
  public results: any;
  public results2: any;
  public clonedUsers: { [s: string]: any } = {};
  filters = [
    { name: 'CPF', value: 'cpf' },
    { name: 'NOME', value: 'nome' },
  ];
  selectedFilter: any;
  postForm = this.formBuilder.group({
    id: [''],
    description: [''],
    grupos: [''],
  });
  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public storageService: StorageService,
    public messageService: MessageService,
    private navbarService: NavbarService
  ) {}
  //Aqui
  testeValor(pessoa: any) {
    console.log(pessoa);
    console.log(this.postForm.value.grupos);
  }
  async ngOnInit() {
    try {
      this.userLogado = await this.storageService.getUserLogado();
      console.log(this.userLogado);
      this.obterDados();
    } catch (error) {}
    this.loading = false;

    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })
  }

  //List-profiles
  searchEmployee() {
    let isFormValid: boolean;
    let body: any = {
      token: this.userLogado.token,
    };
    const filterSelected: any = this.searchForm.controls.filterSelected.value;
    filterSelected === 'cpf'
      ? (body.cpf = this.searchForm.controls.cpf.value)
      : (body.nome = this.searchForm.controls.nome.value?.toUpperCase());
    if (filterSelected === 'cpf') {
      if (this.searchForm.controls.cpf.valid) isFormValid = true;
      else isFormValid = false;
    } else {
      if (this.searchForm.controls.nome.valid) isFormValid = true;
      else isFormValid = false;
    }

    if (isFormValid) {
      this.isLoading = true;
      try {
        this.api
          .postDadosFuncionario(this.userLogado, body)
          .subscribe((res) => {
            this.results = res;
            this.verifyPendente(this.results);
            this.results2 = res;
            this.isLoading = false;
            console.log(res);
          });
        // const parse = JSON.parse(res.subscribe)
      } catch (error: any) {
        this.isLoading = false;
        // const errorParsed = JSON.parse(error)

        this.messageService.add({
          severity: 'error',
          summary:
            'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
        });
        console.error(error);
      }
    }
  }
  searchGlobal(dt: any, $event: any) {
    // console.dir(dt);
    // console.dir($event);
    dt.filterGlobal($event.target.value, 'contains');
  }

  verifyPendente(users: any) {
    for (let i = 0; i < users.length; i++) {
      let usuario = users[i];
      if (usuario.telefone === '' || usuario.email === '') {
        usuario.verify = '../../assets/img/status/pendente/pendente.png';
      } else {
        usuario.verify = '../../assets/img/status/pendente/certo.png';
      }
    }
  }

  async obterDados() {
    try {
      await this.api.getRoles(this.userLogado).subscribe((res: any) => {
        this.results1 = res.roles;
      });

      // const parse = JSON.parse(res.subscribe)
    } catch (error: any) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary:
          'Ocorreu um erro ao carregar a tabela, por favor tente novamente mais tarde',
      });
      console.error(error);
    }
  }

  situacao(user: any) {
    if (user.sitfolha === ' ') {
      return 'NORMAL';
    } else if (user.sitfolha === 'D') {
      return 'DEMITIDO';
    } else if (user.sitfolha === 'A') {
      return 'Afastado';
    } else if (user.sitfolha === 'F') {
      return 'Ferias';
    } else {
      return 'NÃO ENCONTRADO';
    }
  }
}
