<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>
    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>

        <div class="col-10" [class.col-12]="!navbarVisible">
        <div class="col-12 verbas" *ngIf="permission.roles?.description ==='rhapp'">
            <input type="text" pInputText [(ngModel)]="codeverba" class="m-2" placeholder="Codigo"/>
            <input type="text" pInputText [(ngModel)]="description" class="m-2" placeholder="descrição"/>
            <p-dropdown [options]="companies" [(ngModel)]="companiesSelected" optionLabel="name" class="m-2"></p-dropdown>
            <p-dropdown [options]="typeVerba" [(ngModel)]="typeSelectedVerba" optionLabel="type" class="m-2"></p-dropdown>
            <p-button icon="pi pi-plus" label="" class="m-2" (click)="saveVerbas()"></p-button>
        </div>
    
        <div class="col-12">
            <div class="grid"> 
            <div class="col-6">
                    <p-table
                    #dt1 
                    [value]="verbasProventos" 
                    [(selection)]="selectdVerbasProventos" 
                    dataKey="id" 
                    [rows]="10"
                    [showCurrentPageReport]="true" 
                    [rowsPerPageOptions]="[10, 50, 100]" 
                    (onLazyLoad)="seachVerbas($event)"
                    [lazy]="true"
                    [totalRecords]="totalRecordProventos"
                    [loading]="loading" 
                    [paginator]="true"
                    [globalFilterFields]="[]" 
                    scrollDirection="horizontal" 
                    [scrollable]="true"
                    [filterDelay]="0"
                    scrollHeight="600px" 
                    editMode="row">
                        <ng-template pTemplate="caption">

                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="codigo">
                                    Codigo
                                    <p-sortIcon field="code"></p-sortIcon>
                                    <p-columnFilter
                                    [showAddButton]="false"
                                    [showOperator]="false"
                                    type="text"
                                    field="code"
                                    display="menu"
                                    ></p-columnFilter>
                                </th>
                                <th>Proventos</th>
                                <th>
                                    Empresa
                                    <p-columnFilter 
                                    *ngIf="permission.roles?.description ==='rhapp'"
                                    field="company_fk" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                        <ng-template pTemplate="header">
                                            <div class="px-3 pt-3 pb-0">
                                                <span class="font-bold">Agent Picker</span>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                            <p-multiSelect [ngModel]="value" [options]="companies" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                                <ng-template let-option pTemplate="item">
                                                    <div class="inline-block vertical-align-middle">
                                                    <span class="ml-1 mt-1">{{ option.name }}</span>
                                                    </div>
                                                </ng-template>
                                            </p-multiSelect>
                                        </ng-template>
                                    </p-columnFilter>
                                
                                </th>
                                <th *ngIf="permission.roles?.description ==='rhapp'">A/I</th>
                                <th *ngIf="permission.roles?.description ==='rhapp'">Del</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-provento>
                            <tr [ngStyle]="{'background-color': provento.status_code  === 'INATIVO' ? 'var(--red-100)' : ''}">
                                <td>{{ provento.code }}</td>
                                <td>{{ provento.description}}</td>
                                <td>{{ provento.nome_fantasia }} </td>
                                <td *ngIf="permission.roles?.description ==='rhapp'">
                                    <p-button 
                                    icon="pi pi-check" 
                                    styleClass="p-button-rounded p-button-text" 
                                    (click)="ativarEdesativarVerba(provento)"
                                    ></p-button>
                                </td>
                                <td *ngIf="permission.roles?.description ==='rhapp'">
                                    <p-button 
                                    icon="pi pi-times" 
                                    styleClass="p-button-rounded p-button-danger p-button-text"
                                    (click)="deleteVerba(provento.id)"
                                    >
                                </p-button>
                                </td>
            
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                            </tr>
                        </ng-template>
                    </p-table>
        </div>


        <div class="col-6">
            <p-table 
            #dt1 
            [value]="verbasDescontos" 
            [(selection)]="typeSelectedVerba" 
            dataKey="id" 
            [rows]="10"
            (onLazyLoad)="seachVerbas($event)"
            [lazy]="true"
            [showCurrentPageReport]="true" 
            [rowsPerPageOptions]="[10, 50, 100]"
            [totalRecords]="totalRecordDescontos"
            [paginator]="true"
            [loading]="loading" 
            [paginator]="true"
            [globalFilterFields]="[]" 
            scrollDirection="horizontal" 
            [scrollable]="true"
            [filterDelay]="0"
            scrollHeight="600px" 
            editMode="row">
                <ng-template pTemplate="caption">
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="codigo">
                            Codigo
                            <p-sortIcon field="code"></p-sortIcon>
                            <p-columnFilter
                              [showAddButton]="false"
                              [showOperator]="false"
                              type="text"
                              field="code"
                              display="menu"
                            ></p-columnFilter>
                        </th>
                        <th>Descontos</th>
                        <th>
                            Empresa
                            <p-columnFilter 
                            *ngIf="permission.roles?.description ==='rhapp'"
                             field="company_fk" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                <ng-template pTemplate="header">
                                    <div class="px-3 pt-3 pb-0">
                                        <span class="font-bold">Agent Picker</span>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-multiSelect [ngModel]="value" [options]="companies" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                        <ng-template let-option pTemplate="item">
                                            <div class="inline-block vertical-align-middle">
                                            <span class="ml-1 mt-1">{{ option.name }}</span>
                                            </div>
                                        </ng-template>
                                    </p-multiSelect>
                                </ng-template>
                            </p-columnFilter>
                        </th>
                        <th *ngIf="permission.roles?.description ==='rhapp'">A/I</th>
                        <th *ngIf="permission.roles?.description ==='rhapp'">Del</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-provento>
                    <tr  [ngStyle]="{'background-color': provento.status_code  === 'INATIVO' ? 'var(--red-100)' : ''}">
                        <td>{{ provento.code }}</td>
                        <td>{{ provento.description}}</td>
                        <td>{{ provento.nome_fantasia }} </td>
                        <td *ngIf="permission.roles?.description ==='rhapp'">
                            <p-button 
                            icon="pi pi-check" 
                            styleClass="p-button-rounded p-button-text" 
                            (click)="ativarEdesativarVerba(provento)">
                            </p-button>
                        </td>
                        <td *ngIf="permission.roles?.description ==='rhapp'">
                            <p-button 
                            icon="pi pi-times" 
                            styleClass="p-button-rounded p-button-danger p-button-text"
                            (click)="deleteVerba(provento.id)"
                            >
                            </p-button>
                        </td>
                        
                    </tr>
    
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                    </tr>
                </ng-template>
            </p-table>

        </div>
        </div>
    </div>
</div>
</div>