//atestado.component
import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { ImageModule } from 'primeng/image';
import { StorageService } from '../../services/storage.service';
import { Dropdown } from '../../models/dropdown';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  Form,
} from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { PayloadNotification } from 'src/app/models/payloadNotification';
import { library } from '@fortawesome/fontawesome-svg-core';
import { NavbarService } from 'src/app/services/navbar-service';
@Component({
  selector: 'app-atestados',
  templateUrl: './atestados.component.html',
  styleUrls: ['./atestados.component.scss'],
})
export class AtestadosComponent implements OnInit {
  public loading: boolean= false;
  public atestados;
  detalheAtestado: any;
  public statusAtestado: boolean = false;
  public totalRecords: number;
  public optionsStatusMed: Dropdown[];
  public optionsStatusRh: Dropdown[];
  public statusMedValue: any;
  public statusRhValue: any;
  public showModal: boolean;
  public imgSrc: string;
  modalAtestado: boolean= false;
  public navbarVisible: boolean;
  public visible: boolean;
  public atestadoForm: FormGroup;
  public atestadoSelected: any;
  public atestadosToApprove: [];
  public selectedCols: [];
  public cols = [
    { field: 'nome', header: 'Nome do Funcionario' },
  ]
atestado: any;
  constructor(
    public api: ApiService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    public storageService: StorageService,
    public formBuilder: FormBuilder,
    public utilsService: UtilsService,
    private navbarService: NavbarService
  ) { }

  ngOnInit(): void {
    this.atestadosToApprove = []
   // this.loading = true;
    this.imgSrc = '../../assets/img/photo-camera.png';
    this.showModal = false;
    this.optionsStatusMed = [
      {
        name: 'Aprovado',
        code: 'A',
      },
      {
        name: 'Pendente',
        code: 'P',
      },
      {
        name: 'Reprovado',
        code: 'R',
      },
    ];
    this.optionsStatusRh = [
      {
        name: 'ATIVO',
        code: ' ',
      },
      {
        name: 'DEMITIDO',
        code: 'D',
      },
      {
        name: 'FÉRIAS',
        code: 'F',
      },
      {
        name: 'AFASTADO',
        code: 'A',
      },
    ];
    this.atestadoForm = this.formBuilder.group({
      motivo: ['', [Validators.required]],
    });

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  showModalDetailheAtestado(atestado:any){
    this.detalheAtestado= atestado
    this.modalAtestado = true
  }

  getAtestados(event: LazyLoadEvent) {
    this.loading = true;
    if (!event.sortField) {
      event.sortField = 'created_at'
      event.sortOrder = -1
    }
    this.api.getAtestados(event).subscribe({
      next: (res: any) => {
        console.log(res);
        this.atestados = res.medical_certificates;
        this.totalRecords = res.totalRecords;
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary:
            'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
        });
        console.error(error);
      },
    });
  }

  approveAtestado() {
    if (this.verifyMedicalReportSelected()) {

      let tokens = [];
      const filterTokens =  this.atestadosToApprove.filter((item:any) => item.usuarios.notifications_tokens.length > 0 );
        filterTokens.map((item:any) => {
        tokens.push(item.usuarios.notifications_tokens[0]?.token)
      });
    
      this.confirmationService.confirm({
        message: 'Você tem certeza que deseja confirmar a aprovação do atestado?',
        header: 'Atenção',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {

          const sendToken:PayloadNotification =  {
            tokens: tokens,
            notification: {
              title: 'Olá',
              body: 'Atestado aprovado, verifique agora!'
            },
            data: {
              action: '/atestado',
              intent: 'ATESTADO_INTENT'
            }
        }
          tokens = []
          this.api.approveAtestado(this.atestadosToApprove, sendToken).subscribe({
            next: (res) => {
              const resConv: any = res;
              const severity = {
                '0': 'error',
                'all': 'info',
                'onlyAFew': 'warn'
              }
              const summary = {
                '0': 'Erro',
                'all': 'Sucesso',
                'onlyAFew': 'Alerta'
              }
              
              this.messageService.add({
                severity: severity[resConv.approved],
                summary: summary[resConv.approved],
                detail: resConv.message,
              });
              setTimeout(() => {
                window.location.href = '/atestados';
              }, 800)
            }, error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erro',
                detail: 'Ocorreu um erro ao aprovar o atestado',
              });
            }
          });
        },
        acceptLabel: 'SIM',
        rejectLabel: 'NÃO',
      });
    }
  }

  reproveAtestado() {
    /* payload notification */
    const sendToken:PayloadNotification =  {
        tokens: [this?.atestadoSelected.usuarios?.notifications_tokens[0]?.token],
        notification: {
          title: 'Atestado',
          body: 'Atestado reprovado, verifique agora!'
        },
        data: {
          action: '/atestado',
          intent: 'ATESTADO_INTENT'
        }
    }

    const motivoReprovacao = this.atestadoForm.controls['motivo'].value;
    this.confirmationService.confirm({
      message:
        'Você tem certeza que deseja confirmar a reprovação do atestado?',
      header: 'Atenção',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        try {
          this.api.reproveAtestado(this.atestadoSelected, motivoReprovacao, sendToken).subscribe((res) => {
            this.messageService.add({
              severity: 'info',
              summary: 'Sucesso',
              detail: 'O atestado foi reprovado com sucesso.',
            });
            setTimeout(() => {
              window.location.href = '/atestados';
            }, 800)
          });
        } catch (error) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Ocorreu um erro ao aprovar o atestado',
          });
        }
      },
      acceptLabel: 'SIM',
      rejectLabel: 'NÃO',
    });
  }

  showMotivoReprovacao(atestado: any) {
    this.atestadoSelected = atestado;
    this.visible = true;
  }

  async downloadAtestado(atestado:any) {

    try {
      switch (atestado.mime_type) {
        case 'image/jpeg':
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
            const base64Img: any = await this.utilsService.convertImageToBase64(url);
            await this.utilsService.formatImgForPdf(base64Img, atestado);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;
        case 'image/png':
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
            const base64Img: any = await this.utilsService.convertImageToBase64(url);
            await this.utilsService.formatImgForPdf(base64Img, atestado);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;

          case 'image/heic':
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
            await this.utilsService.downloadPdfImgToFromHeic(url, atestado);
            this.loading = false;
          } catch (error) {
            this.loading = false;
            console.log(error);
          }
          break;

        default:
          this.loading = true;
          try {
            let { token } = this.storageService.getUserLogado();
            let url = `${this.api.urlApi}/aws/s3img/5/8764?img=${atestado.foto}&token=${token}`;
            await this.utilsService.getAtestadoAws(atestado, url);
            this.loading = false;
          } catch (error) {
            console.log(error);
          }
          break;
      }
    } catch (error) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops',
        detail:
          'Error ao abir pdf',
      });
    }
  }

  verifyMedicalReportSelected() {
    if (this.atestadosToApprove.length === 0) {
      this.printAlert();
      return false;
    } else {
      return true;
    }
  }

  printAlert() {
    this.messageService.add({
      severity: 'warn',
      summary: 'Ops',
      detail:
        'Primeiro selecione pelo menos um item de atestado para prosseguir.',
    });
  }

  getSeverity(status: string) {
    const fromTo = {
      A: 'success',
      R: 'danger',
      P: 'warning',
    };

    return fromTo[status];
  }
}
