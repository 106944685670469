import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Company } from 'src/app/models/company';
import { Verbas } from 'src/app/models/verbas';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-cadastro-verbas',
  templateUrl: './cadastro-verbas.component.html',
  styleUrls: ['./cadastro-verbas.component.scss']
})
export class CadastroVerbasComponent implements OnInit {

  verbasProventos: Verbas[] = [];
  selectdVerbasProventos:  Verbas[] = [];

  verbasDescontos: Verbas[] = [];
  selectdVerbasDescontos:  Verbas[] = [];
  loading: boolean = false;

  codeverba: string;
  description: string;
 

  companies: Company[] = [{name:'EMPRESA',id: 0}];
  companiesSelected: Company

  typeVerba: Verbas[] = [{type:"TIPO"},{type:"proventos"},{ type:"descontos"}]
  typeSelectedVerba: Verbas;

  ativar: string;

  permission:any;
  navbarVisible:boolean;

  public totalRecordProventos: number= 0;
  public totalRecordDescontos: number= 0;

 
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private locaStorage: StorageService,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.getAllCompanies()
    this.listCodigoVerbas()
    const  { permission } = this.locaStorage.getUserLogado()
    this.permission = permission;

    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })
  }


  saveVerbas(){
  if(this.codeverba === undefined || this.description === undefined || this.companiesSelected.id === 0 || this.typeSelectedVerba.type === "Escolher tipo"){
    this.messageService.add({
       severity: 'warn', 
       summary: 'Parametros', 
       detail: 'Por favor preencher todos os campos' 
    })

  }else{
    const verba: Verbas = {
      code: this.codeverba,
      description: this.description,
      type: this.typeSelectedVerba.type,
      company_fk: this.companiesSelected.id
    }
    this.apiService.cadastrarVerbas(verba).subscribe((res:any) => {
      this.codeverba = undefined
      this.description = undefined   
      this.companiesSelected = {name:'Escolha loja',id: 0}
      this.typeSelectedVerba = {type:"Escolher tipo"}
    }).add(() => {
      this.messageService.add({
        severity: 'success', 
        summary: 'Parametros', 
        detail: 'Codigo cadastrado com sucesso!' 
     })

     this.verbasProventos = []
     this.verbasDescontos = []
     this.listCodigoVerbas()
    })
  }
  }

  getAllCompanies(){
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{
    const filterCompanies = res.filter(item =>  item.tipo === 'Cliente')
    
    filterCompanies.map((item) => {
      this.companies.push({
        name: item.nome_fantasia,
        id: item.id
      })
    })

    })
  }

  listCodigoVerbas(){
    const event= 0;
    this.loading = true
    this.apiService.listVerbas(event).subscribe(async (res:any) => {
     res.list.map((item:any) => {

      if(item.type_code === 'proventos'){
        this.verbasProventos.push({
          id: item.id,
          status_code: item.status_code,
          code:   item.code,
          description: item.description,
          nome_fantasia: item.empresas.nome_fantasia
        })
        this.totalRecordProventos = this.verbasProventos.length
      }else{
        this.verbasDescontos.push({
          id: item.id,
          status_code: item.status_code,
          code:   item.code,
          description: item.description,
          nome_fantasia: item.empresas.nome_fantasia
        })
        this.totalRecordDescontos = this.verbasDescontos.length
      }
      })
      
    }).add(()  => {
      this.loading = false
    })
  }

  seachVerbas(event:LazyLoadEvent){
    this.verbasProventos = [];
    this.verbasDescontos = [];
    this.loading = true;
    this.apiService.listVerbas(event.filters).subscribe(async (res:any) => {
     res.list.map((item:any) => {

      if(item.type_code === 'proventos'){
        this.verbasProventos.push({
          id: item.id,
          status_code: item.status_code,
          code:   item.code,
          description: item.description,
          nome_fantasia: item.empresas.nome_fantasia
        })
        this.totalRecordProventos = this.verbasProventos.length
      }else{
        this.verbasDescontos.push({
          id: item.id,
          status_code: item.status_code,
          code:   item.code,
          description: item.description,
          nome_fantasia: item.empresas.nome_fantasia
        })
        this.totalRecordDescontos = this.verbasDescontos.length
      }
      })
      
    }).add(()  => {
      this.loading = false
    })
  }

  ativarEdesativarVerba(provento: Verbas){
   this.apiService.ativarInativarVerba(provento).subscribe((res:any) => {
    this.messageService.add({
      severity: 'success', 
        summary: 'Parametros', 
        detail: res.message 
    })
   }).add(() => {
    this.verbasProventos = []
    this.verbasDescontos = []
    this.listCodigoVerbas()
   })
  }

  deleteVerba(id:number){
    this.apiService.deleteVerba(id).subscribe((res:any) => {
      this.messageService.add({
        severity: 'success', 
          summary: 'Parametros', 
          detail: 'Verba deletada com sucesso' 
      })
    }).add(() => {
      this.verbasProventos = []
      this.verbasDescontos = []
      this.listCodigoVerbas()
    })
  }

}
