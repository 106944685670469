<div class="surface-0">
  <p-toast></p-toast>
  <app-menubar></app-menubar>
  <div class="grid">

    <div class="col-2 bg-secondary" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>

    <div  class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table
          #dt1
          [value]="vacations"
          dataKey="id"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50, 100]"
          [totalRecords]="totalRecords"
          [(selection)]="selectedVocationConcluded"
          [paginator]="true"
          [loading]="loading"
          scrollDirection="horizontal"
          currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
          [scrollable]="true"
          scrollHeight="600px"
          [lazy]="true"
          (onLazyLoad)="listVacationsSended($event)">

          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
              <div>
                Pesquisar Orgão
                <p-columnFilter 
                    [showAddButton]="false" 
                    matchMode="contains" 
                    [showOperator]="false"
                    [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
                    type="text" 
                    field="centro_custo"
                    display="menu">
                  </p-columnFilter>
              </div>
              
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th>Empresa</th>
              <th>Funcionário</th>
              <th>
                CPF
                  <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    type="text"
                    field="cpf"
                    display="menu"
                  ></p-columnFilter>
              </th>
              <th pSortableColumn="fer_ini">
                Início
                <p-sortIcon field="fer_ini"></p-sortIcon>
              </th>
              <th pSortableColumn="fer_fim">
                Fim
                <p-sortIcon field="fer_fim"></p-sortIcon>
              </th>
              <th pSortableColumn="warning_sended_at">
                Aviso
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                type="date" 
                field="warning_sended_at" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th>
                Recibo
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                type="date" 
                field="receipt_sended_at" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th pSortableColumn="contador">
                Cont
                <p-sortIcon field="contador">
                </p-sortIcon> 
              </th>
              <th>
                Aceite Aviso
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                type="date" 
                field="warning_acceppted_at" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th>
                Aceite Recibo
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                type="date" 
                field="receipt_acceppted_at" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th>Ação</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-vacation let-ri="rowIndex">
            <tr>
              <td>
                <p-tableCheckbox [value]="vacation"></p-tableCheckbox>
              </td>
              <td>{{ vacation.empresas.nome_fantasia }}</td>
              <td>{{ vacation.usuarios.dados_funcionarios.nome }}</td>
              <td>{{ vacation.usuarios.dados_funcionarios.cpf | cpf }}</td>
              <td>{{ vacation.fer_ini }}</td>
              <td>{{ vacation.fer_fim  }}</td>
              <td>{{ vacation.warning_sended_at | localdate }}</td>
              <td>{{ vacation.receipt_sended_at | localdate  }}</td>
              <td>{{vacation.count_day_vocation}}</td>
              <td>{{ vacation.warning_acceppted_at | localdate }}</td>
              <td>{{ vacation.receipt_acceppted_at | localdate }}</td>
              <td>
                <button
                pButton
                pRipple
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-rounded p-button-info p-button-text p-button-raised"
                (click)="printVacationConclued(vacation)"
              ></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Não foi encontrada nenhuma categoria.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="card flex justify-content-center gap-2">
        <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
      </div>
    </div>
  </div>
</div>
