import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Company } from 'src/app/models/company';
import { Faturamento } from 'src/app/models/faturamento';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-relatorio-adesao-mensal',
  templateUrl: './relatorio-adesao-mensal.component.html',
  styleUrls: ['./relatorio-adesao-mensal.component.scss']
})
export class RelatorioAdesaoMensalComponent implements OnInit {
  financeiro: Faturamento[] = [];
  financeiroSelected: Faturamento[] = [];
  loading: boolean = false;
   filterExecel: Faturamento[] = [];
   companies: Company[] = [{name:'Todas as Parceiras', id:0}];
   clients: Company[] = [{name:'Todas os Clientes', idClient:0}];
   selectedCompanies: Company | undefined;
   selectedClient: Company | undefined
   date: Date;
   dataFechementoSeleted: any
   newDateFechamento: any;
   permission:any;
   navbarVisible:boolean;
/* aqui será o de conferencia  */
  constructor(
    private apiService:ApiService,
    private messageService: MessageService,
    private localStorage: StorageService,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.permission = this.localStorage.getStoragePeermission();
    let dataFechamento = new Date();
    dataFechamento.setDate(15);
    dataFechamento.setMonth(8);
    dataFechamento.setFullYear(2023);
    let dia = dataFechamento.getDate().toString().padStart(2, '0');
    let mes = (dataFechamento.getMonth() + 1).toString().padStart(2, '0');
    let ano = dataFechamento.getFullYear();
    this.newDateFechamento = `${ano}-${mes}-${dia}`;

    this.getAllCompanies()
    this.getRelatorioFinanceiro()

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }


  getRelatorioFinanceiro(){
    this.financeiro = [];
    this.loading = true
    let pesquisar = {
      id: this.selectedCompanies?.id || 0,
      idCliente: this.selectedClient?.idClient || 0,
      dateFechamento: this.newDateFechamento
    }

    this.apiService.relatorioFinananceiroConvenios(pesquisar, this.selectedCompanies).subscribe((res: any) => {
      res.map((item:any) => {
        this.financeiro.push({
        id: item.id,
        cpf: item.cpf,
        datapedido: item.data_pedido,
        date: item.data_primeiro_desconto,
        nomefuncionairo: item.funcionario,
        descricaoProduto: item.produto,
        empresa: item.empresa,
        loja: item.razao_social,
        dataFaturamento: item.data_faturamento,
        precoFinal: item.total,
        qtdParcelas: item.qtd_parcelas,
        valorParcela: item.valor_parcela,
        parcelasPaga: item.parcelas_paga,
        statusFuncionario: item.status_funcionario,
        rescisao: item.rescisao,
        saldo: item.saldo,
        totalPago: item.total_pago,
        code: item.code
        })
      })
    }).add(() => {
      this.loading = false;
    })
  }

  onChangeDateSeleted(){
    let diaSelected = this.date?.getDate().toString().padStart(2, '0');
    let mesSeleted = (this.date?.getMonth() + 1).toString().padStart(2, '0');
    let anoSelected = this.date?.getFullYear();
    this.newDateFechamento = `${anoSelected}-${mesSeleted}-${diaSelected}`;
  }

  ConveniosFinanciroPdf(){
    if(this.financeiro.length > 0){
      const tituloRelatorio = 'RELATÓRIO CONFERÊNCIA'
      this.apiService.relatorioAdesaoPdf(this.financeiro, tituloRelatorio).subscribe((res:any) => {
        const byteCharacters = atob(res.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document; 
        a.click();
      }).add(() => {
        this.financeiro = []
      })

    }else{
      this.messageService.add({ severity: 'warn', summary: 'Relatório', detail: 'Por favor realizar a pesquisa' })
    }
  }


  getAllCompanies(){
    const {empresa_parceira} = this.localStorage.getUserLogado()
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{
    const filterCompanies = res.filter(item =>  item.tipo === 'Parceira')

    if(this.permission.roles.description === "rhapp"){
      const filterClients = res.filter(item => item.tipo === 'Cliente')
      filterClients.map(item => {
        this.clients.push({
          name: item.nome_fantasia,
          idClient: item.id
        })
      })
    }else{
      const filterClients = res.filter(item => item.tipo === 'Cliente' && item.id === parseInt(empresa_parceira))
      filterClients.map(item => {
        this.clients.push({
          name: item.nome_fantasia,
          idClient: item.id
        })
      })
    }

    filterCompanies.map((item) => {
      this.companies.push({
        name: item.nome_fantasia,
        id: item.id
      })
    })

    })
  }

}
