import { Component, OnInit, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { StorageService } from '../../services/storage.service';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public ambiente = isDevMode() ? 'Desenvolvimento' : 'Produção';
  public userLogado: any = {};
  public usersPermission: any[] = [];
  public alert: Message[] = [
    {
      severity: 'info',
      summary: `Ambiente atual: ${this.ambiente}`,
    },
  ];
  items = [];

  activeItem = this.items[0];
  navbarVisible: boolean;
  constructor(
    public router: Router,
    public storageService: StorageService,
    private apiService: ApiService,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
    this.userLogado =  this.storageService.getUserLogado();
    this.getPermissionUSers();

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }


  logoffUser() {
    this.storageService.logoffUser();
    this.userLogado = null;
    this.router.navigateByUrl('/login', {
      replaceUrl: true, //destroi a pagina atual
    });
    this.items = [];
    this.usersPermission = [];
    this.userLogado = {};
  }

  goToHomePage() {
    this.router.navigateByUrl('/home', {
      replaceUrl: true, //destroi a pagina atual
    });
  }

  async getPermissionUSers() {
    this.apiService
      .getPermissionUSers(this.userLogado.id)
      .subscribe((permission: any) => {
        this.usersPermission = permission.roles;
        const lastArray = permission.roles[permission.roles.length - 1];
        localStorage.setItem('permission', JSON.stringify(lastArray));
      })
      .add(() => {
        this.usersPermission.forEach((item) => {
          if (item.roles.description === "comercial") { // comercial
            this.items = [
              {
                label: 'Convenios',
                icon: 'pi pi-fw pi-shopping-cart',
                items: [
                  {
                    label: 'Requisão de convenios',
                    icon: 'pi pi-fw pi-shopping-cart',
                    routerLink: ['/Agreements-list'],
                  },
                  {
                    label: 'Pedidos Cancelados',
                    icon: 'pi pi-times-circle',
                    routerLink: ['/pedidos-cancelados'],
                  },
                  {
                    label: 'Pedidos Faturados',
                    icon: 'pi pi-money-bill',
                    routerLink: ['/faturamento'],
                  },
                  {
                    label: 'Pedidos Concluidos',
                    icon: 'pi pi-check',
                    routerLink: ['/pedidos-concluidos'],
                  },
                ],
              },
              {
                label: 'Relatórios',
                icon: 'pi pi-list',
                items:[
                  {
                    label: 'Novas adesões detalhado/resumido',
                    icon: 'pi pi-file-pdf',
                    routerLink: ['/relatorio-adesao-detalhado'],
                  }
                ]
              }
            ];
          }

          if (item.roles.description === "banco") { // banco
            this.items = [
              {
                label: 'Home',
                icon: 'pi pi-fw pi-home',
                routerLink: ['/home'],
              },
              {
                label: 'Convenios',
                icon: 'pi pi-fw pi-shopping-cart',
                items: [
                  {
                    label: 'Requisão de convenios',
                    icon: 'pi pi-fw pi-shopping-cart',
                    routerLink: ['/Agreements-list'],
                  },
                  {
                    label: 'Pedidos Cancelados',
                    icon: 'pi pi-times-circle',
                    routerLink: ['/pedidos-cancelados'],
                  },
                  {
                    label: 'Pedidos Faturados',
                    icon: 'pi pi-money-bill',
                    routerLink: ['/faturamento'],
                  },
                  {
                    label: 'Pedidos Concluidos',
                    icon: 'pi pi-check',
                    routerLink: ['/pedidos-concluidos'],
                  },
                ],
              },
              {
                label: 'Relatórios',
                icon: 'pi pi-list',
                items:[
                  {
                    label: 'Relatório adesao detalhado/resumido',
                    icon: 'pi pi-file-pdf',
                    routerLink: ['/relatorio-adesao-detalhado'],
                  }
                ]
              }
            ];
          }

          if (item.roles.description === 'dep pessoal') { // dep pessoal
            this.items = [
              {
                label: 'Departamento Pessoal',
                icon: 'pi pi-users',
                items: [
                  {
                    label: 'Cadastro - Funcionários',
                    icon: 'pi pi-fw pi-user-edit',
                    routerLink: ['/list-profiles'],
                  },                  
                  {
                    label: 'Férias',
                    icon: 'pi pi-fw pi-server',
                    items: [
                      {
                        label: 'Envio de Aviso',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias'],
                      },
                      {
                        label:'Gerenciamento de Férias',
                        icon: 'pi pi-fw pi-server',
                        routerLink:['/list-ferias-recibo']
                      },
                      {
                        label: 'Finalizadas',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias-enviadas'],
                      },
                    ],
                  }
                ],
              },
              
              {
                label: 'Sesmt',
                icon: 'pi pi-heart',
                    items: [
                      {
                        label: 'Atestados',
                        icon: 'pi pi-heart-fill',
                        items: [
                          {
                            label: 'Atestados - Abertos',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/atestados'],
                            disabled: false,
                          },
                          {
                            label: 'Atestados - Finalizados',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/listar-atestados-apurados'],
                            disabled: false,
                          },
                        ]
                      }
                    ],
                  },
                  {
                    
                      label: 'Assinatura',
                      icon: 'pi pi-file',
                      items: [
                        {
                          label: 'Pendentes',
                          icon: 'pi pi-info',
                          routerLink: ['/list-doc-pending'],
                        },
                        {
                          label: 'Concluidas',
                          icon: 'pi pi-check',
                          routerLink: ['/list-doc-concluded'],
                        },
                        {
                          label: 'Manual',
                          icon: 'pi pi-check',
                          routerLink: ['/doc-manual'],
                        },
                        
                      ],
                  },
            ];
          }


          if (item.roles.description === 'operacional') { // operacional
            this.items = [
              {
                label: 'Departamento Pessoal',
                icon: 'pi pi-users',
                items: [
                  {
                    label: 'Cadastro - Funcionários',
                    icon: 'pi pi-fw pi-user-edit',
                    routerLink: ['/list-profiles'],
                  },                  
                  {
                    label: 'Férias',
                    icon: 'pi pi-fw pi-server',
                    items: [
                      {
                        label: 'Envio de Aviso',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias'],
                      },
                      {
                        label:'Gerenciamento de Férias',
                        icon: 'pi pi-fw pi-server',
                        routerLink:['/list-ferias-recibo']
                      },
                      {
                        label: 'Finalizadas',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias-enviadas'],
                      },
                    ],
                  }
                ],
              },
              
              {
                label: 'Sesmt',
                icon: 'pi pi-heart',
                    items: [
                      {
                        label: 'Atestados',
                        icon: 'pi pi-heart-fill',
                        items: [
                          {
                            label: 'Atestados - Abertos',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/atestados'],
                            disabled: false,
                          },
                          {
                            label: 'Atestados - Finalizados',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/listar-atestados-apurados'],
                            disabled: false,
                          },
                        ]
                      }
                    ],
                  },
                  {
                    
                      label: 'Assinatura',
                      icon: 'pi pi-file',
                      items: [
                        {
                          label: 'Pendentes',
                          icon: 'pi pi-info',
                          routerLink: ['/list-doc-pending'],
                        },
                        {
                          label: 'Concluidas',
                          icon: 'pi pi-check',
                          routerLink: ['/list-doc-concluded'],
                        },
                        {
                          label: 'Manual',
                          icon: 'pi pi-check',
                          routerLink: ['/doc-manual'],
                        },
                      ],
                  },
            ];
          }


          if (item.roles.description === 'arquivo') { // arquivo
            this.items = [
              {
                label: 'Departamento Pessoal',
                icon: 'pi pi-users',
                items: [
                  {
                    label: 'Cadastro - Funcionários',
                    icon: 'pi pi-fw pi-user-edit',
                    routerLink: ['/list-profiles'],
                  },                  
                  {
                    label: 'Férias',
                    icon: 'pi pi-fw pi-server',
                    items: [
                      {
                        label: 'Envio de Aviso',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias'],
                      },
                      {
                        label:'Gerenciamento de Férias',
                        icon: 'pi pi-fw pi-server',
                        routerLink:['/list-ferias-recibo']
                      },
                      {
                        label: 'Finalizadas',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias-enviadas'],
                      },
                    ],
                  }
                ],
              },
              
              {
                label: 'Sesmt',
                icon: 'pi pi-heart',
                    items: [
                      {
                        label: 'Atestados',
                        icon: 'pi pi-heart-fill',
                        items: [
                          {
                            label: 'Atestados - Abertos',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/atestados'],
                            disabled: false,
                          },
                          {
                            label: 'Atestados - Finalizados',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/listar-atestados-apurados'],
                            disabled: false,
                          },
                        ]
                      }
                    ],
                  },
                  {
                    
                      label: 'Assinatura',
                      icon: 'pi pi-file',
                      items: [
                        {
                          label: 'Pendentes',
                          icon: 'pi pi-info',
                          routerLink: ['/list-doc-pending'],
                        },
                        {
                          label: 'Concluidas',
                          icon: 'pi pi-check',
                          routerLink: ['/list-doc-concluded'],
                        },
                        {
                          label: 'Manual',
                          icon: 'pi pi-check',
                          routerLink: ['/doc-manual'],
                        },
                      ],
                  },
            ];
          }


          if(item.roles.description === "sesmt") { // sesmet
            this.items = [
              {
                label: 'Departamento Pessoal',
                icon: 'pi pi-users',
                items: [
                  {
                    label: 'Cadastro - Funcionários',
                    icon: 'pi pi-fw pi-user-edit',
                    routerLink: ['/list-profiles'],
                  }
                ],
              },
              
              {
                label: 'Sesmt',
                icon: 'pi pi-heart',
                    items: [
                      {
                        label: 'Atestados',
                        icon: 'pi pi-heart-fill',
                        items: [
                          {
                            label: 'Atestados - Abertos',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/atestados'],
                            disabled: false,
                          },
                          {
                            label: 'Atestados - Finalizados',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/listar-atestados-apurados'],
                            disabled: false,
                          },
                        ]
                      }
                    ],
                  },              
            ]
          }

          if (item.roles.description === "rhapp") { // rhapp
            this.items = [
              {
                label: 'Departamento Pessoal',
                icon: 'pi pi-users',
                items: [
                  {
                    label: 'Cadastro - Funcionários',
                    icon: 'pi pi-fw pi-user-edit',
                    routerLink: ['/list-profiles'],
                  },                  
                  {
                    label: 'Férias',
                    icon: 'pi pi-fw pi-server',
                    items: [
                      {
                        label: 'Envio de Aviso',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias'],
                      },
                      {
                        label:'Gerenciamento de Férias',
                        icon: 'pi pi-fw pi-server',
                        routerLink:['/list-ferias-recibo']
                      },
                      {
                        label: 'Finalizadas',
                        icon: 'pi pi-fw pi-server',
                        routerLink: ['/listar-ferias-enviadas'],
                      },
                    ],
                  }
                ],
              },
              
              {
                label: 'Sesmt',
                icon: 'pi pi-heart',
                    items: [
                      {
                        label: 'Atestados',
                        icon: 'pi pi-heart-fill',
                        items: [
                          {
                            label: 'Atestados - Abertos',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/atestados'],
                            disabled: false,
                          },
                          {
                            label: 'Atestados - Finalizados',
                            icon: 'pi pi-heart-fill',
                            routerLink: ['/listar-atestados-apurados'],
                            disabled: false,
                          },
                        ]
                      }
                    ],
                  },
                  {
                    
                      label: 'Assinatura',
                      icon: 'pi pi-file',
                      items: [
                        {
                          label: 'Pendentes',
                          icon: 'pi pi-info',
                          routerLink: ['/list-doc-pending'],
                        },
                        {
                          label: 'Concluidas',
                          icon: 'pi pi-check',
                          routerLink: ['/list-doc-concluded'],
                        },
                        {
                          label: 'Manual',
                          icon: 'pi pi-check',
                          routerLink: ['/doc-manual'],
                        },
                      ],
                  },
              {
                label: 'Convenios',
                icon: 'pi pi-fw pi-shopping-cart',
                items: [
                  {
                    label: 'Requisão de convenios',
                    icon: 'pi pi-fw pi-shopping-cart',
                    routerLink: ['/Agreements-list'],
                  },
                  {
                    label: 'Pedidos Cancelados',
                    icon: 'pi pi-times-circle',
                    routerLink: ['/pedidos-cancelados'],
                  },
                  {
                    label: 'Pedidos Faturados',
                    icon: 'pi pi-money-bill',
                    routerLink: ['/faturamento'],
                  },
                  {
                    label: 'Pedidos Concluidos',
                    icon: 'pi pi-check',
                    routerLink: ['/pedidos-concluidos'],
                  },
                ],
              },

              {
                label: 'Produtos',
                icon: 'pi pi-align-center',
                items:[
                  {
                    label: 'Criação de produtos',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/criar-produto'],
                  },
                  {
                    label: 'Criar categoria de Loja',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/criar-categoria-loja'],
                  },
                  
                  {
                    label: 'Gerenciar categorias de loja',
                    icon: 'pi pi-fw pi-bars',
                    routerLink: ['/gerenciador-loja'],
                  },
                  {
                    label: 'Gerenciar produtos',
                    icon: 'pi pi-fw pi-bars',
                    routerLink: ['/gerenciador-produto'],
                  }
                ]
              },


              {
                label: 'Relatórios',
                icon: 'pi pi-list',
                items:[
                  {
                    label: 'DP-Novas adesões',
                    icon: 'pi pi-file-pdf',
                    routerLink: ['/relatorio-adesao'],
                  },
                  {
                    label: 'DP-Conferência',
                    icon: 'pi pi-file-pdf',
                    routerLink: ['/financeiro-convenios'],
                  },
                  {
                    label: 'CM-Novas adesões detalhado/resumido',
                    icon: 'pi pi-file-pdf',
                    routerLink: ['/relatorio-adesao-detalhado'],
                  },

                  {
                    label: 'CM-Convênios financeiro detalhado',
                    icon: 'pi pi-file-pdf',
                    routerLink: ['/financeiro-detalhado-covenios'],
                  }

                ]
              },
              {
                disabled: false,
                label: 'Comunicação',
                icon: 'pi pi-comments',
                items: [
                  {
                    label: 'Contracheque',
                    icon: 'pi pi-comments',
                    routerLink: ['/aviso-contracheque'],
                    disabled: false,
                  },
                  {
                    label: 'Criar publicação',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/criar-post'],
                    disabled: false,
                  },
                  {
                    label: 'Listar publicações',
                    icon: 'pi pi-fw pi-eye',
                    routerLink: ['/listar-post'],
                    disabled: false,
                  },
                  {
                    label: 'Cria notificação',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/criar-notificacao'],
                    disabled: false,
                  },
                  {
                    label: 'Notificação Padrão',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/notification-default'],
                    disabled: false,
                  },
                  {
                    label: 'Cria Campanha',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/criar-campanha'],
                    disabled: false,
                  },
                  {
                    label: 'app-version',
                    icon: 'pi pi-fw pi-plus',
                    routerLink: ['/app-version'],
                    disabled: false,
                  }
                ],
              },
              {
                label: 'Configurador',
                icon: 'pi pi-fw pi-cog',
                items: [
                  {
                    label: 'Usuário',
                    icon: 'pi pi-fw pi-user',
                    items: [
                      {
                        label: 'Novo usuário',
                        icon: 'pi pi-fw pi-user-plus',
                        routerLink: ['/cadastro-funcionario'],
                      },
                      {
                        label: 'Gerenciar usuários',
                        icon: 'pi pi-fw pi-user-plus',
                        routerLink: ['/listar-usuarios'],
                      },
                    ],
                  },
                  {
                    label: 'Empresas',
                    icon: 'pi pi-building',
                    items: [
                      {
                        label: 'Nova empresa',
                        icon: 'pi pi-building',
                        routerLink: ['/criar-empresa'],
                      },
                      {
                        label: 'Gerenciar empresas',
                        icon: 'pi pi-building',
                        routerLink: ['/listar-empresa'],
                      },
                    ],
                  },
                  {
                    label: 'Perfis',
                    icon: 'pi pi-fw pi-sitemap',
                    items: [
                      {
                        label: 'Criar perfis',
                        icon: 'pi pi-fw pi-sitemap',
                        routerLink: ['/group-create'],
                      },
                      {
                        label: 'Listar perfis',
                        icon: 'pi pi-fw pi-sitemap',
                        routerLink: ['/group-list'],
                      },
                      {
                        label: 'Adicionar a perfis',
                        icon: 'pi pi-fw pi-user-plus',
                        routerLink: ['/add'],
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Verbas - Margem',
                icon:'pi pi-fw pi-cog',
                routerLink: ['/cadastro-verbas']
              },
            ];
          }
        });
      });
  }
}
