<div class="surface-50">
    <div class="grid">
        <div class="col-12 flex flex-column">
            <app-navbar></app-navbar>
        </div>
        <div class="col-12 flex flex-column">
            <p-card>
                <ng-template pTemplate="header">
                    Sobre mim
                </ng-template> 
            </p-card>
        </div>
    </div>    
</div>  