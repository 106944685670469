<div class="surface-50">
    <div class="grid">

        <div class="col-12 flex flex-auto">
            <p-toast></p-toast>
        </div>
        <div class="col-12 flex flex-column">
            <form [formGroup]="resetForm" novalidate>
                <div class="p-inputgroup field flex">
                    <input type="text" pInputText placeholder="Insira seu CPF..." formControlName="cpf">
                </div>
                <button pButton type="button" label="Confirmar"></button>
            </form>
        </div>
    </div>
</div>