import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { StorageService } from '../storage.service';
import { environment } from 'src/environments/environment';
import { Faturamento } from 'src/app/models/faturamento';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public urlApi = environment.apiUrl;
  constructor(public http: HttpClient, public storageService: StorageService) { }

  public putProduct(product: any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const body = product;
    const url = `${this.urlApi}/shop/products/${product.id}`;
    return this.http.put(url, {body:body}, httpOptions);
  }

  public relatorioDemitidos(filterSeparationStatus: Faturamento[], titulo: string){
    const { token, name } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/shop/relatorio/demitidos`;
    return this.http.post(url, {demitidos:filterSeparationStatus, name:name, titulo:titulo}, httpOptions);
  }

  public relatorioEncerrados(filterSeparationStatus: Faturamento[], titulo: string){
    const { token, name } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/shop/relatorio/encerrados`;
    return this.http.post(url, {demitidos:filterSeparationStatus, name:name, titulo:titulo}, httpOptions);
  }


  listCategoryProdutos(){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/shop/list/category/products`;
    return this.http.get(url, httpOptions);
  }

  listAllProdutos(){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/shop/products`;
    return this.http.get(url, httpOptions);
  }
  
  listPostsProducts(){
    const { token, empresa } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/shop/list/posts/products/${empresa}`;
    return this.http.get(url, httpOptions);
  }

  deleteBannerProduct(empresaId:any, postId:number){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    
    const url = `${this.urlApi}/shop/post/banner`;
    return this.http.post(url,{empresaId:empresaId, postId:postId}, httpOptions);
  }

  listCampaigns(){
    const { token, empresa } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    
    const url = `${this.urlApi}/shop/acceipt/campaign/all/${empresa}`;
    return this.http.get(url, httpOptions);
  }


  createCampaigns(campaigns:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/create/campaign`;
    return this.http.post(url,{text:campaigns.text, title:campaigns.title, companys:campaigns.companys}, httpOptions);
  }



  updateCampaigns(campaigns:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/update/campaign`;
    return this.http.put(url,{
      text:campaigns.text, 
      title:campaigns.title,
      id:campaigns.id,
      companys: campaigns.companys,
      company_campaigns: campaigns.company_campaigns
    }, httpOptions);
  }


  desableCampaing(checked:boolean, id:number){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/update/status/campaign`;
    return this.http.put(url,{status: checked, id:id}, httpOptions);
  }


  deleteCompanyCampaging(id:number){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/delete/company/campaign/${id}`;
    return this.http.delete(url, httpOptions);
  }

  
  createCompanyCampaging(id:number, company:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/create/company/campaign/${id}`;
    return this.http.post(url, {company:company}, httpOptions);
  }
}
