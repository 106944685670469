<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>

  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
    <div class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table
          #dt1
          [value]="users"
          dataKey="id"
          [rows]="50"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50, 100]"
          [paginator]="true"
          scrollDirection="horizontal"
          [scrollable]="true"
          scrollHeight="600px"
          editMode="row"
          style="z-index: 0"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>CPF</th>
              <th>Nome</th>
              <th>Sexo</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Status</th>
              <th style="width: 5rem"></th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-user
            let-editing="editing"
            let-ri="rowIndex"
          >
            <tr>
              <td>{{ user.cpf }}</td>
              <td>{{user.name}}</td>
              <td>{{ user.sexo | sexo }}</td>
              <td>{{ user.celular | celular }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.status | statusUser }}</td>
             
             
              <td class="flex m-0 p-0">
                <div class="col-4">
                  <p-button
                    type="button"
                    pRipple
                    icon="pi pi-pencil"
                    (click)="editUser(user)"
                  ></p-button>
                </div>
                <div class="col-4">
                  <p-button
                    type="button"
                    styleClass="p-button-success"
                    pRipple
                    icon="pi pi-eye"
                    (click)="updatePassword(user)"
                  ></p-button>
                </div>

                <div class="col-4">
                  <p-button
                    type="button"
                    styleClass="p-button-secondary"
                    pRipple
                    icon="pi pi-unlock"
                    (click)="modalAccessCompany(user)"
                  ></p-button>
                </div>

              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <!-- Modal de Edição do usuário -->
    <div class="col-12">
      <p-dialog
        header="Atualizar cadastro da empresa"
        [(visible)]="showModal"
        [style]="{ width: '70vw' }"
        *ngIf="showModal"
      >
        <form [formGroup]="editingUserForm" novalidate>
          <div class="grid">
            <!--sexo-->
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Sexo </span>
              </div>
              <div class="col-6">
              
                <p-dropdown
                  [options]="optionsSexo"
                  placeholder="Selecione o sexo do usuário"
                  formControlName="sexo"
                  optionLabel="name"
                  optionValue="code"
                  class="flex w-full"
                ></p-dropdown>
              </div>
            </div>
            <!--dt_nascimento-->
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Data de Nascimento </span>
              </div>
              <div class="col-6">
                
                <p-calendar
                  formControlName="dt_nasc"
                  dateFormat="dd/mm/yy"
                ></p-calendar>
                
              </div>
            </div>

            <!--email-->
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Email </span>
              </div>
              <div class="col-6">
               
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Email"
                  formControlName="email"
                />
              </div>
            </div>
       
            <!--telefone-->
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Telefone </span>
              </div>
              <div class="col-6">
                <p-inputMask
                  class="flex w-full"
                  mask="(99)9999-9999"
                  formControlName="telefone"
                  placeholder="Telefone"
                ></p-inputMask>
              </div>
            </div>

            <!--celular-->
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Celular </span>
              </div>
              <div class="col-6">
                <p-inputMask
                  class="flex w-full"
                  mask="(99)99999-9999"
                  formControlName="celular"
                  placeholder="Celular"
                ></p-inputMask>
              </div>
            </div>

            <!--status-->
            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Status </span>
              </div>
              <div class="col-6">
              
                <p-dropdown
                  [options]="optionsStatus"
                  placeholder="Selecione o status do usuário"
                  optionLabel=" descricao"
                  formControlName="status"
                  optionLabel="name"
                  optionValue="code"
                  class="flex w-full"
                ></p-dropdown>
               
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <span class="p-inputgroup-addon"> Perfis </span>
              </div>
              <div class="col-6">
                <!--roles-->
                <p-dropdown
                  [options]="optionsRoles"
                  formControlName="role_fk"
                  optionLabel="name"
                  optionValue="code"
                  display="chip"
                  class="flex w-full"
                ></p-dropdown>
               
              </div>
            </div>
          </div>
        </form>
        <ng-template pTemplate="footer" class="flex">
          <p-button
            icon="pi pi-save"
            label="Atualizar"
            class="mt-4"
            (click)="editUserDb()"
          ></p-button>
         
        </ng-template>
      </p-dialog>
    </div>

    <!--modal--reset--senha -->
    <p-dialog header="{{this?.userSelected?.name}}" [(visible)]="displayModalreset" [style]="{width: '30vw'}">
     <div class="col-12 flex  justify-content-between">
      <div class="col-10 flex align-items-center justify-content-center">
            <input
              type="text"
              class="flex w-full"
              pInputText
              placeholder="senha"
              [(ngModel)]="senha"
          />
      </div>
      <div class="col-2 flex align-items-center justify-content-center">
          <p-button
            type="button"
            pRipple
            (click)="changePassword()"
            icon="pi pi-save">
          </p-button>
      </div>
     </div>
  </p-dialog>


     <!--modal--acecess--company-->
     <p-dialog header="{{this?.userSelected?.name}}" [(visible)]="displayModalCompany" [style]="{width: '60vw', height:'60vh'}">
      <p-table [value]="accessCompanyes" [tableStyle]="{'min-width': '50rem'}">
        <ng-template pTemplate="caption">
          <div class="flex align-items-center justify-content-start">
            <p-multiSelect
              [options]="companies" 
              [(ngModel)]="selectedcompanies" 
              defaultLabel="Selecione empresas para para  acesso" 
              
              optionLabel="nome_fantasia" 
              selectedItemsLabel="{0} items selected" >
            </p-multiSelect>
           <p-button
              class="ml-1"
              type="button"
              pRipple
              (click)="addAccessCompany()"
              icon="pi pi-plus">
           </p-button>
          </div>
      </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Empresa</th>
                <th>Ação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{item.empresas.nome_fantasia}}</td>
                <td>
                  <p-button
                    type="button"
                    styleClass="p-button-warning"
                    pRipple
                    (click)="deleteAccessCompany(item)"
                    icon="pi pi-trash"
                  ></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
   </p-dialog>
  
  </div>
</div>
