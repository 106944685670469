import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { PrimeNGConfig } from 'primeng/api';
import { FilterMatchMode } from 'primeng/api';

@Component({
  selector: 'app-espelho',
  templateUrl: './espelho.component.html',
  styleUrls: ['./espelho.component.scss'],
})
export class EspelhoComponent implements OnInit {
  batidas: any;
  constructor(
    public api: ApiService,
    public storageService: StorageService,
    public primeNGConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.primeNGConfig.filterMatchModeOptions = {
      text: [
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.CONTAINS,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      ],
      date: [
        FilterMatchMode.DATE_IS,
        FilterMatchMode.DATE_IS_NOT,
        FilterMatchMode.DATE_BEFORE,
        FilterMatchMode.DATE_AFTER,
      ],
    };
    let user = this.storageService.getUserLogado();
    this.api.getBatidas(user.token).subscribe((responseBatidas) => {
      let response: any = responseBatidas;
      this.batidas = response.recordsets[0];
      
    });
  }
}
