<div class="col-12">
    <p-menubar [model]="menuTop" styleClass="bg-primary">
        <ng-template pTemplate="start">
            <div *ngIf="navbarVisible; else elseBlock">
                <img routerLink="/"  src="assets/img/logo_transparente_bb.png" height="40" class="mr-2">
                <button
                pButton 
                pRipple 
                type="button" 
                icon="pi pi-times"
                (click)="hideNavBar()"
                class="p-button-primary"
               >
            </button>
        </div>
        <ng-template #elseBlock>
            <img routerLink="/" src="assets/img/logo_transparente_bb.png" height="40" class="mr-2">
                <button
                    pButton 
                    pRipple 
                    type="button" 
                    icon="pi pi-align-justify"
                    (click)="showNavBar()"  
                    class="p-button-primary">
                </button>
            </ng-template>
        </ng-template>
        
        <ng-template pTemplate="end">
            <button
                    pButton pRipple 
                    type="button" 
                    icon="pi pi-fw pi-sign-out"
                    (click)="logout()"  
                    class="p-button-primary">
                </button>
        </ng-template>
    
    </p-menubar>

</div>