<div class="surface-0">
    <p-toast></p-toast>
    <app-menubar></app-menubar>
    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>
    
    <div class="col-10" [class.col-12]="!navbarVisible">
        <p-table 
        #dt1 
        [value]="pedidosConcluidos" 
        dataKey="id" 
        [rows]="10"
        [showCurrentPageReport]="true" 
        [rowsPerPageOptions]="[10, 50, 100]" 
        [totalRecords]="totalRecordOrder"
        [loading]="loading" 
        [paginator]="true"
        (onLazyLoad)="searchPedidosConcluidos($event)"
        [lazy]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['id', 'cpf', 'date', 'nomefuncionairo', 'parcelasPaga', 'empresa', 'loja', 'dataFaturamento', 'datapedido', 'descricaoProduto', 'qtdParcelas']" 
        scrollDirection="horizontal" 
        [scrollable]="true"
        [filterDelay]="0"
        scrollHeight="600px" 
        editMode="row">

            <ng-template pTemplate="caption">
                <div class="captionPrintDocs">
                    <div>
                        <p-button icon="pi pi-search" label="" class="m-2" (click)="searchPedidosConcluidos()"></p-button>
                            <p-splitButton 
                            label="" 
                            [model]="items" 
                            icon="pi pi-file-pdf"  
                            styleClass="p-button-raised p-button-danger">
                            </p-splitButton>
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>

                    <th class="loja">
                        Loja
                        <p-columnFilter 
                         field="loja" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Agent Picker</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="companies" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                    <ng-template let-option pTemplate="item">
                                        <div class="inline-block vertical-align-middle">
                                        <span class="ml-1 mt-1">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>

                    <th class="empresa">
                        Empresa
                        <p-columnFilter 
                        field="company_fk" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Agent Picker</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="clients" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                    <ng-template let-option pTemplate="item">
                                        <div class="inline-block vertical-align-middle">
                                        <span class="ml-1 mt-1">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th class="funcionario">Funcionário</th>
                    <th>
                        Cpf
                        <p-sortIcon field="faturamento.cpf"></p-sortIcon>
                        <p-columnFilter
                           matchMode="equals"
                          [showAddButton]="false"
                          [showOperator]="false"
                          type="text"
                          field="cpf"
                          display="menu"
                        ></p-columnFilter>
                    </th>
                    <th class="prod">Prod</th>
                    <th>Taxa Adesao</th>
                    <th>V.Par</th>
                    <th>P.Paga</th>
                    <th>Qtd.Par</th>
                    <th class="datas">D.Fatura</th>
                    <th class="datas">D.Pedido</th>
                    <th class="datas">1º Mês(des)</th>
                    <th>Total</th>
                    <th>T.Pago</th>
                    <th>S.funcionário</th>
                    <th class="threscisao">
                        Rescisao
                    </th>
                    <th class="thslado">Saldo</th>
                    
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-faturamento>
                <tr>
                    <td>{{ faturamento.id }}</td>
                    <td>{{ faturamento.loja}}</td>
                    <td>{{ faturamento.empresa }} </td>
                    <td>{{ faturamento.nomefuncionairo }}</td>
                    <td>{{ faturamento.cpf }}</td>
                    <td>{{ faturamento.descricaoProduto }}</td>
                    <td> {{faturamento.taxaAdesao | currency: 'BRL'}} </td>
                    <td>{{ faturamento.valorParcela | currency:'BRL'}}</td>
                    <td>{{ faturamento.parcelasPaga || '0' }}</td>
                    <td>{{ faturamento.qtdParcelas }}</td>
                    <td>{{ faturamento.dataFaturamento | localdatetime}}</td>
                    <td>{{ faturamento.datapedido }}</td>
                    <td>{{ faturamento.date | localdatetime}}</td>
                    <td>{{ faturamento.precoFinal | currency:'BRL' }}</td>
                    <td>{{ faturamento.totalPago | currency:'BRL' }}</td>
                    <td>{{ faturamento.statusFuncionario }}</td>
                    <td>{{ faturamento.rescisao | currency:'BRL' }}</td>
                    <td>{{ faturamento.saldo | currency:'BRL'}}</td>
                </tr>

            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</div>