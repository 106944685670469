import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  Form,
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { ProductService } from 'src/app/services/shop/product.service';
import { ProdutoCategory } from 'src/app/models/productCategory';
import { Products } from 'src/app/models/products';
import { NavbarService } from 'src/app/services/navbar-service';


@Component({
  selector: 'app-criar-post',
  templateUrl: './criar-post.component.html',
  styleUrls: ['./criar-post.component.scss'],
})
export class CriarPostComponent implements OnInit {
  text: string = '';
  postForm = this.formBuilder.group({
    companys: ['', Validators.required],
    category: ['', Validators.required],
    product: ['', Validators.required],
    action:''
  });
  gettingRequest: boolean = false;
  user: any;
  empresas: any;
  productsCategories: ProdutoCategory[] = [];
  allProducts: Products[] = [];
  navbarVisible:boolean;

  constructor(
    public formBuilder: FormBuilder,
    public api: ApiService,
    public storageService: StorageService,
    public messageService: MessageService,
    public productService:ProductService,
    private navbarService: NavbarService
  ) {}

  ngOnInit(): void {
    this.user = this.storageService.getUserLogado();
    this.api.postViewCompanys().subscribe((res) => {
      this.empresas = res;
    });

    this.listProductsCategories();
    this.listProducts();

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  listProductsCategories(){
    this.productService.listCategoryProdutos().subscribe({
      next: (res:any) => {
        res.map((item:any) => {
          this.productsCategories.push({
            description: item.description,
            id: item.id
          })
        })
      },
      error: () => {},
      complete: () => {}
    })
  }

  post(event: any) {
    if (!this.postForm.valid) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Preencha os campos corretamente!',
      });
     
    } else {
      const companys = this.postForm.controls.companys.value;
      const category = this.postForm.controls.category.value[0];
      const product:any = this.postForm.controls.product.value;
      const actionUrl = this.postForm.controls.action.value || 'undefined';
      const formData = new FormData();

      for (let i = 0; i <= event.files.length; i++) {
        formData.append('arquivo', event.files[i]);
      }

      this.api.postPost(formData, { companys, category }, this.user, product, actionUrl).subscribe({
        next: (res:any) => {
          this.messageService.add({
            severity: 'success',
            detail:'Publicação',
            summary: res.message,
          });
        },
        complete: () => {
          this.postForm.reset();
          this.listProducts();
        },
        error: (e) => {
          this.messageService.add({
            severity: 'warn',
            detail:'Publicação',
            summary: 'Não foi possivél fazer publicação',
          });
        }
      });
    }
  }

  listProducts(){
    this.allProducts = [];
    
    this.productService.listAllProdutos().subscribe({
      next: (res:any) => {
        res.resultProducts.map((item:any) => {
          this.allProducts.push({
            description:item.description,
            id: item.id
          })
        })
      },
      complete: () => {},
      error: () => {}
    })
  }

 
}
