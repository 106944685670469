import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  Form,
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { UtilsService } from '../../services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from '../../models/dropdown';
import { Roles } from 'src/app/models/roles';
import { NavbarService } from 'src/app/services/navbar-service';
@Component({
  selector: 'app-employee-registration',
  templateUrl: './employee-registration.component.html',
  styleUrls: ['./employee-registration.component.scss'],
})
export class EmployeeRegistrationComponent implements OnInit {
  public formUser: FormGroup;
  public userLogado: any = {};
  public optionsRoles: Dropdown[] = [];
  selectedoptionEmpresa: any[] = [];
  public optionSexo = [];
  public optionEmpresa = [];
  navbarVisible:boolean;

  constructor(
    public utilService: UtilsService,
    public api: ApiService,
    public dataService: DataService,
    public storageService: StorageService,
    public messageService: MessageService,
    public formBuilder: FormBuilder,
    public translateService: TranslateService,
    private navbarService: NavbarService
  ) {}
  async showMessage(type: string, description: string) {
    this.messageService.add({
      severity: type,
      detail: description,
    });
  }

  async ngOnInit() {
    this.allRolesProfiles()
    this.optionSexo = [
      {
        label: 'Masculino',
        code: 'M',
      },
      {
        label: 'Feminino',
        code: 'F',
      },
    ];

    this.formUser = new FormGroup({
      cpf: new FormControl(<string>null, [Validators.required]),
      nomeUser: new FormControl(<string>null, [Validators.required]),
      sexo: new FormControl(<any | null>[Validators.required]),
      dataNascimento: new FormControl(<string>null, [Validators.required]),
      funcao: new FormControl(<string>null, [Validators.required]),
      email: new FormControl(<string>null, [Validators.required]),
      celular: new FormControl(<string>null, [Validators.required]),
      telefone: new FormControl(<string>null, [Validators.required]),
      senha: new FormControl(<string>null, [Validators.required]),
      empresa: new FormControl(<any[]>null, [Validators.required]),
      role: new FormControl(<[]>null, [Validators.required]),
    });
    //TODO  - CRIAR A ROTA DE CRIAR EMPRESA
    this.formUser.value.sexo = this.optionSexo[0];
    //Montar usuario
    this.userLogado =  this.storageService.getUserLogado();

     this.obterEmpresas();

     this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })
  }
  obterEmpresas() {
    // debugger;
    this.api.getEmpresas().subscribe({
      next: (res: any) => {
        // debugger;
        this.optionEmpresa = res;
      },
      error: (error) => {
        // debugger;
        this.messageService.add({
          severity: 'error',
          summary:
            'Ocorreu um erro ao obter a lista de empresas, favor tentar novamente mais tarde',
        });
        console.error(error);
      },
    });
  }

  verifyForm({
    cpf,
    celular,
    dataNascimento,
    email,
    empresa,
    role,
    nomeUser,
    senha,
    sexo,
    telefone
  }) {

    if (cpf == undefined) {
      this.showMessage('error', 'Por favor verificar o campo de CPF');
      return false;
    } else if (cpf.length < 11) {
      this.showMessage('error', 'Por numero de CPF invalido');
      return false;
    } else if (celular == undefined) {
      this.showMessage('error', 'Por favor verificar o campo de Celular');
      return false;
    } else if (celular.length < 10) {
      this.showMessage('error', 'Numero de celular invalido');
      return false;
    } else if (
      !dataNascimento ||
      dataNascimento == null ||
      dataNascimento == undefined
    ) {
      this.showMessage('error', 'Por favor verificar a data de nascimento');
      return false;
    } else if (email.indexOf('@') < 0) {
      this.showMessage('error', 'Email invalido');
      return false;
    } else if (
      empresa.length === 0    ) {
      this.showMessage('error', 'Por favor selecionar uma empresa');
      return false;
    } else if (
      role === '' ||
      role === undefined ||
      role === null
    ) {
      this.showMessage('error', 'Por favor selecionar o tipo de perfil');
      return false;
    } else if (nomeUser === '' || nomeUser === null) {
      this.showMessage('error', 'Por favor verificar o nome de usuario');
      return false;
    } else if (senha === '' || senha === null) {
      debugger;
      this.showMessage('error', 'Por favor verificar a senha');
      return false;
    } else if (sexo === '' || sexo === null || sexo === undefined) {
      this.showMessage('error', 'Por favor verificar o Sexo');
      return false;
    } else if (telefone === '' || telefone === null || telefone === undefined) {
      this.showMessage('error', 'Por favor verificar o campo de CPF');
      return false;
    } else if (telefone.length < 10) {
      this.showMessage('error', 'Telefone invalido');
      return false;
    } else {
      return true;
    }
  }
  async enviarDados() {
   
    if (this.verifyForm(this.formUser.value)) {
      this.formUser.value.cpf = this.utilService.limparCPF(
        this.formUser.value.cpf
      );
      this.formUser.value.dataNascimento = this.dataService.toProtheusDate(
        this.formUser.value.dataNascimento
      );
      this.formUser.value.telefone = this.utilService.limparTelefone(
        this.formUser.value.telefone
      );
      this.formUser.value.celular = this.utilService.limparTelefone(
        this.formUser.value.celular
      );
       this.api
        .criarUsuario(this.userLogado.token, this.formUser.value)
        .subscribe((res: any) => {
          console.log(res);
          if (res.created) {
            this.showMessage('success', res.message);
          } else {
            this.showMessage('error', res.message);
          }
        }).add(() => {
          this.ngOnInit()
        });
    }
  }

  async allRolesProfiles(){
    this.api.getAllRolesProfiles().subscribe(
      (result:Roles) => {
        result.roles.map(item => {
          this.optionsRoles.push({
            name: item.description.toLocaleUpperCase(),
            code: item.id
          })
        })
        
      }
    )
  }
}
