import { Component, OnInit } from '@angular/core';
import { Column } from 'src/app/models/column';
import { ExportColumn } from 'src/app/models/exportcolumn';
import { ApiService } from 'src/app/services/api.service';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/api';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-pedidos-cancelados',
  templateUrl: './pedidos-cancelados.component.html',
  styleUrls: ['./pedidos-cancelados.component.scss']
})
export class PedidosCanceladosComponent implements OnInit {
  productRequests: any[] = [];
  cols: Column[] = [];
  selectedCols: Column[] = [];
  exportColumn: ExportColumn[] = [];
  filterExecel: any[] = [];
  loading: boolean = true;
  totalRecordOrder: number;
  navbarVisible:boolean;

 
  constructor(
    private apiService:ApiService,
    private messageService: MessageService,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.getPedidosCancelados()
    this.cols = [
      { field: 'dataPedido', header: 'Data Pedido' },
      { field: 'requestId', header: 'Pedido' },
      { field: 'nome', header: 'Nome'},
      { field: 'telefone', header: 'Telefone'},
      { field: 'celular', header: 'Celular'},
      {field: 'email', header: 'Email'},
      { field: 'cpf', header: 'Cpf' },
      { field: 'admissao', header: 'admissão' },
      { field: 'nomeEmpresa', header: 'Empresa' },
      { field: 'descricaoProduto',header: 'Produto'},
      { field: 'precoFinal', header: 'Valor total'},
      {field: 'valorParcela', header: 'Parcela'},
      {field: 'empresaParceira', header: 'Loja'},
      { field: 'statusParceiro', header: 'Status Parceiro'},
      { field: 'statusPedido',header: 'Status Pedido'},
      { field: 'ultimaAtualizacao',  header: 'Ultima Atualizacao' },
      { field: 'observacao', header: 'Observacao'}
    ]

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

async  getPedidosCancelados(){
    this.apiService.pedidosCancelados().subscribe((res:any) => {
      res.requests.map((item) => {
        this.productRequests.push({
          dataPedido: item.data_pedido,
          requestId: item.request_id,
          nome: item.name_user,
          cpf: item.cpf_user,
          admissao: item.data_admissao,
          statusRh: item.status_rh,
          nomeEmpresa: item.nome_empresa,
          descricaoProduto: item.product_description,
          precoFinal: item.final_price,
          statusParceiro: item.status_parceiro,
          statusPedido: item.status_pedido,
          ultimaAtualizacao: item.ultima_atualizacao,
          observacao: item.observacao,
          margem: item.margem,
          userId: item.user_id,
          idProduto: item.product_id,
          empresaParceira: item.empresa_parceira,
          valorParcela: item.valor_parcela,
          qtdParcelas: item.qtd_parcelas,
          telefone: item.telefone,
          celular: item.celular,
          email: item.email,
          taxaAdesao: item.taxaAdesao
        })
        this.totalRecordOrder = this.productRequests.length
      })
      this.loading = false
    })
  }

  exportFaturamentoExcel(selectedCols: Column[]){
    if(selectedCols.length >  0){
      this.productRequests.forEach((item: any) => {
        const dataItem: any = {};
        selectedCols.forEach(selectedCol => {
          const nomeDoCampoDesejado = selectedCol.field;
          const colunaDesejada = this.cols.find(coluna => coluna.field === nomeDoCampoDesejado);
          if (colunaDesejada) {
            const nomeDaColuna = colunaDesejada.header;
            dataItem[nomeDaColuna] = item[nomeDoCampoDesejado];
          } else {
            console.log(`Coluna "${nomeDoCampoDesejado}" não encontrada`);
          }
        });
        if (Object.keys(dataItem).length > 0) {
          this.filterExecel.push(dataItem);
        }
      });
     
      import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.filterExecel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'pedidos');
      this.filterExecel = []
      this.selectedCols = []
      });
      
    }else{
      this.messageService.add({
        severity:'error',
        summary:'Error',
        detail: 'Por favor adicionar campos'
      })
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
