import { Component, OnInit } from '@angular/core';

import {
  FormBuilder,
  Validators
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { UtilsService } from '../../services/utils.service';
import { ViaCepServiceService } from '../../services/via-cep-service.service';
import { FunctionsService } from '../../services/functions.service';
import { NavbarService } from 'src/app/services/navbar-service';
@Component({
  selector: 'app-criar-empresa',
  templateUrl: './criar-empresa.component.html',
  styleUrls: ['./criar-empresa.component.scss'],
})
export class CriarEmpresaComponent implements OnInit {
  navbarVisible: boolean;
  formEmpresa = this.formBuilder.group({
    cnpj: ['', [Validators.required]],
    empresa: ['', [Validators.required]],
    razaoSocial: ['', [Validators.required]],
    grupo: ['', [Validators.required]],
    filial: ['', [Validators.required]],
    cep: ['', [Validators.required]],
    bairro: ['', [Validators.required]],
    logradouro: ['', [Validators.required]],
    numero: ['', [Validators.required]],
    cidade: ['', [Validators.required]],
    celular: ['', [Validators.required]],
    telefone: ['', [Validators.required]],
    email: ['', [Validators.required]],
    tipo:['',[Validators.required]],
    porcentagemMagem: ['', [Validators.required]]
  });
  selectedTypeCompanies: any;
  public userLogado: any = {};
  constructor(
    public api: ApiService,
    public utilservice: UtilsService,
    public storageService: StorageService,
    public messageService: MessageService,
    public formBuilder: FormBuilder,
    public viaCep: ViaCepServiceService,
    public functionsService: FunctionsService,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
  
    this.userLogado =  this.storageService.getUserLogado();
    this.selectedTypeCompanies = [
      {tipo: 'Cliente'},
      {tipo: 'Parceira'}
    ]


    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })
  }
  validateForm() {
    if (this.formEmpresa.value.cnpj.length < 14) {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor verificar o campo de CNPJ',
      });
      return false;
    } else if (this.formEmpresa.value.empresa === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor verificar Nome da empresa',
      });
      return false;
    } else if (this.formEmpresa.value.grupo === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor verificar o campo Grupo',
      });
      return false;
    } else if (this.formEmpresa.value.filial === '') {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor verificar o campo de Filial',
      });
      return false;
    }
    return true;
  }

  async blurCep() {
    let cep = this.functionsService.formatCep(
      this.formEmpresa.controls.cep.value
    );
    this.viaCep.getCep(cep).subscribe((res: any) => {
      console.log(res);
      this.formEmpresa.controls.bairro.setValue(res.bairro);
      this.formEmpresa.controls.cidade.setValue(res.localidade);
      this.formEmpresa.controls.logradouro.setValue(res.logradouro);
    });
  }
  async criarEmpresa() {
    if (this.validateForm()) {
      try {
        this.formEmpresa.value.cnpj = this.utilservice.limparCNPJ(
          this.formEmpresa.value.cnpj
        );
        this.formEmpresa.value.telefone = this.utilservice.limparTelefone(
          this.formEmpresa.value.telefone
        );
        this.formEmpresa.value.celular = this.utilservice.limparTelefone(
          this.formEmpresa.value.celular
        );

        this.api
          .criarEmpresa(
            this.userLogado.token,
            this.formEmpresa.value.cnpj,
            this.formEmpresa.value.empresa,
            this.formEmpresa.value.razaoSocial,
            this.formEmpresa.value.grupo,
            this.formEmpresa.value.filial,
            this.formEmpresa.value.celular,
            this.formEmpresa.value.telefone,
            this.formEmpresa.value.tipo,
            this.formEmpresa.value.email,
            this.formEmpresa.value.cep,
            this.formEmpresa.value.cidade,
            this.formEmpresa.value.bairro,
            this.formEmpresa.value.logradouro,
            this.formEmpresa.value.numero,
            this.formEmpresa.value.porcentagemMagem
          )
          .subscribe((res: any) => {
            console.dir(res);
            if (res.created) {
              this.messageService.add({
                severity: 'success',
                summary: 'Empresa criada com sucesso!',
              });
            } else {
              console.log(res);
              this.messageService.add({
                severity: 'error',
                summary: 'Empresa não foi criada por conta de um error!',
              });
            }
          }).add(() => {
            this.formEmpresa.reset()
          });
      } catch (err: any) {
        this.messageService.add({
          severity: 'error',
          summary:
            'Erro interno do servidor por favor tentar novamente mais tarde',
        });
      }
    } else {
      return;
    }
  }

  selecTypeCompanies(){

  }
}
