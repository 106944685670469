import { Injectable } from '@angular/core';
import { parse } from '@fortawesome/fontawesome-svg-core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() { }

  login(user: any) {
    localStorage.setItem('empresa', user.empresa);
    localStorage.setItem('id', user.id);
    localStorage.setItem('cpf', user.cpf);
    localStorage.setItem('first_login', user.first_login);
    localStorage.setItem('token', user.token);
    localStorage.setItem('empresa_parceira', user.idempresaParceira);
    localStorage.setItem('accessControl', JSON.stringify(user.accessControl));
    const name = user.name === '' ? "RHapp" : user.name;
    localStorage.setItem('name', name);
  }

  getUserLogado() {
    let storage = {
      empresa: localStorage.getItem('empresa'),
      token: localStorage.getItem('token'),
      id: localStorage.getItem('id'),
      empresa_parceira: localStorage.getItem('empresa_parceira'),
      accessControl: localStorage.getItem('accessControl'),
      name: localStorage.getItem('name'),
      permission: JSON.parse(localStorage.getItem('permission'))
    };
    // console.log(storage);
    storage.accessControl = JSON.parse(storage.accessControl);

    return storage;
  }

  logoffUser(): void {
    localStorage.removeItem('cpf');
    localStorage.removeItem('first_login');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('empresa');
    localStorage.removeItem('accessControl');
    localStorage.removeItem('permission')
    localStorage.removeItem('empresa_parceira');
    localStorage.removeItem('name')
  }

  getStoragePeermission() {
    const permission = JSON.parse(localStorage.getItem('permission'))
    return permission
  }
}
