import { Component } from '@angular/core';
import { StorageService } from './services/storage.service';
import { Router } from '@angular/router';
import { ApiService } from './services/api.service';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portal-rhapp';

  constructor(
    public storageService: StorageService,
    public router: Router,
    public api: ApiService,
    public messageService: MessageService,
    public translateService: TranslateService,
    public config: PrimeNGConfig
  ) {
    this.config.setTranslation({
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      weak: 'Fraca',
      medium: 'Média',
      strong: 'Forte',
      passwordPrompt: 'Insira a senha',
      startsWith: 'Começa com',
      contains: 'Contém',
      notContains: 'Não contém',
      endsWith: 'Termina com',
      equals: 'Igual a',
      notEquals: 'Não é igual a',
      noFilter: 'Sem filtro',
      dateIs: 'A data é',
      dateAfter: 'A data é depois de',
      dateBefore: 'A data é antes de',
      dateIsNot: 'A data não é',
      matchAll: 'Corresponde tudo',
      matchAny: 'Corresponde pelo menos uma das',
      addRule: 'Adicionar regra',
      clear: 'Apagar',
      apply: 'Aplicar',
      lt: 'Menor que',
      lte: 'Menor que ou igual a',
      gt: 'Maior que',
      gte: 'Maior que ou igual a',
    });
    const currentUrl: string = window.location.href;
    const userLogado = this.storageService.getUserLogado();
    console.log(userLogado);
    if (userLogado.id === null) {
      this.router.navigateByUrl('/login', {
        replaceUrl: true, //destroi a pagina atual
      });
    } else {
      this.api.getTokenValid(userLogado).subscribe({
        next: (response) => {
          //token válido
          if (currentUrl.includes('/login')) {
            // Se o usuário tentar acessar o login mesmo logado, redirecione para o home
            this.router.navigateByUrl('', {
              replaceUrl: true, //destroi a pagina atual
            });
          }
        },
        error: (error) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Para prosseguir, por favor logue novamente.',
          });
          this.router.navigateByUrl('/login', {
            replaceUrl: true, //destroi a pagina atual
          });
        },
      });
    }
  }
}
