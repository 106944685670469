import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  FormBuilder,
  Validators,
} from '@angular/forms';
import { FunctionsService } from '../../services/functions.service';
import { DataService } from '../../services/data.service';
import { MessageService } from 'primeng/api';
import { NavbarService } from 'src/app/services/navbar-service';
import { Roles } from 'src/app/models/roles';
import { Dropdown } from 'src/app/models/dropdown';
@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit {
  public users: any;
  public showModal: boolean = false;
  displayModalreset: boolean = false;
  displayModalCompany: boolean = false;
  public copyUser: any = {};
  public userSelected: any;
  navbarVisible: boolean;
  senha: string = '';
  companies: any[];
  selectedcompanies: any[] = []
  accessCompanyes: any[] = []

  editingUserForm = this.formBuilder.group({
    dt_nasc:  [new Date(2023, 2, 1),Validators.required],
    status:   [' ', [Validators.maxLength(6)]],
    sexo:     ['', [Validators.maxLength(100)]],
    email:    ['', [Validators.maxLength(50)]],
    telefone: ['', [Validators.minLength(13), Validators.maxLength(13)]],
    celular:  ['', [Validators.minLength(14), Validators.maxLength(14)]],
    role_fk:  ['', [Validators.required]],
  });

  public optionsStatus = [
    {
      name: 'Ativo',
      code: '',
    },
    {
      name: 'Primeiro Acesso',
      code: 'V',
    },
    {
      name: 'Bloqueado',
      code: 'B',
    },
  ];
  
  public optionsRoles:Dropdown[] = [];
  
  public optionsSexo = [
    {
      name: 'Feminino',
      code: 'F',
    },
    {
      name: 'Masculino',
      code: 'M',
    },
  ];

  constructor(
    public api: ApiService,
    public formBuilder: FormBuilder,
    public functionsService: FunctionsService,
    public dataService: DataService,
    public messageService: MessageService,
    private navbarService: NavbarService
  ) {}

  ngOnInit(): void {
    this.api.getUsersDb().subscribe({
      next: (res) => {
        console.log(res);
        this.users = res;
      },
      error: (res) => {},
    });

    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })
    this.allRolesProfiles();
    this.getCompanies();
  }

  async allRolesProfiles(){
    this.optionsRoles = [];
    this.api.getAllRolesProfiles().subscribe(
      (result:Roles) => {
        result.roles.map(item => {
          this.optionsRoles.push({
            name: item.description.toLocaleUpperCase(),
            code: item.id
          })
        })
      }
    )
  }

  async getCompanies(){
    this.api.getEmpresas().subscribe({
      next: (res:any) => {
        this.companies = res;
      },
      complete: () => {},
      error: () => {}
    })
  }

  async deleteAccessCompany(item:any){
    this.api.deleteAccessCompany(item).subscribe({
      next: (res:any) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Acesso',
          detail: res.message,
        });
      },
      complete: () => {this.getAccessCompany()},
      error: () => {}
    })
  }

  /* add access company */
  async addAccessCompany(){
    if(this.selectedcompanies?.length !== 0){
      const access = [];
      this.selectedcompanies.map((item) => {
        access.push({
             usuario_fk: this.userSelected.id ,
             company_access: item.id
         })
      });
     this.api.addAccessCompany(access).subscribe({
      next: (res:any) => {
        this.messageService.add({
          severity: 'info',
          summary: 'Acesso',
          detail: res.message,
        });
      },
      complete: () => {
        this.getAccessCompany()
        this.selectedcompanies = []
      }
        ,
      error: () => {}
     });
    }else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Acesso',
        detail: 'Por favor adiconar uma empresa',
      });
    }
  }

  editUser(user: any) {
    this.showModal = true;
    this.extractSelectUser(user);
  }

  updatePassword(user:any) {
    this.userSelected = user;
    this.displayModalreset = true;
  }

  modalAccessCompany(user:any){
    this.userSelected = user;
    this.api.getAccessCompanies(user.id).subscribe({
      next: (res:any) => {
        this.accessCompanyes = res;
      },
      complete: () => {
        this.displayModalCompany = true;
      },
      error: () => {}
    })
  }
  
  getAccessCompany(){
    this.api.getAccessCompanies(this.userSelected.id).subscribe({
      next: (res:any) => {
        this.accessCompanyes = res;
      },
      complete: () => {
        this.displayModalCompany = true;
      },
      error: () => {}
    })
  }

  changePassword(){
    if(this.senha !== ''){
      const {id} = this.userSelected;
      this.api.changePassword(id, this.senha).subscribe({
        next: (res:any) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Senha',
            detail: res.message,
          });
        },
        complete: () => {
          this.displayModalreset = false
          this.senha = '';
        },
        error: () => {}
      })

    }else{
      this.messageService.add({
        severity: 'warn',
        summary: 'Senha',
        detail: 'Por favor informar nova senha',
      });
    }
  }

  editUserDb() {
    let { id: userId } = this.copyUser;
    let editingUserFormValues: any = this.extractFormValues(
      this.editingUserForm
    );
    let user = this.fieldsChanged(editingUserFormValues);

    if (Object.keys(user).length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops',
        detail: 'O cadastro não foi alterado, não há necessidade de salvar.',
      });
    } else {
      this.api.editUser(userId, user).subscribe({
        next: (res: any) => {
          if (res.edited)
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso',
              detail: 'O cadastro foi alterado com sucesso.',
            });
          else
            this.messageService.add({
              severity: 'error',
              summary: 'Ocorreu um erro ao alterar o cadastro de usuário',
              detail: res.message,
            });
        },
        complete: () => {
          this.editingUserForm.reset();
          this.showModal = false;
          
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: error.error.message,
          });
        },
      }).add(() =>{
        this.ngOnInit()
      });
    }
  }

  extractSelectUser(user) {
    this.copyUser = user;
    Object.keys(user).forEach((key) => {
      try {
        // Evita que gere erro ao atribuir valores que existem no company e não no form
        if (key === 'dt_nasc' && user[key])
          this.editingUserForm.controls[key].setValue(
            this.dataService.fromProtheusToDateJs(user[key])
          );
        else if (key === 'created_at') {
          this.editingUserForm.controls[key].setValue(
            this.dataService.fromJsDateToString(user[key])
          );
          this.editingUserForm.controls[key].disable();
        } else if (key === 'status') {
          this.editingUserForm.controls[key].setValue(user[key]);
          if (user[key] === 'V') {
            this.optionsStatus = [
              {
                name: 'Primeiro Acesso',
                code: 'V',
              },
              {
                name: 'Bloqueado',
                code: 'B',
              },
            ];
          } else {
            this.optionsStatus = [
              {
                name: 'Ativo',
                code: '',
              },
              {
                name: 'Bloqueado',
                code: 'B',
              },
            ];
          }
        } else this.editingUserForm.controls[key].setValue(user[key]);
      } catch (error) {}
    });
  }

  extractFormValues(form) {
    let formValues = {};

    Object.keys(form.controls).forEach((key) => {
      formValues[key] = form.get(key).value;
    });

    return formValues;
  }

  fieldsChanged(userChanged: any) {
    let fieldsChangeds = {};
    Object.keys(userChanged).forEach((key) => {
      if (this.copyUser[key] !== userChanged[key] && key !== 'created_at') {
        if (key === 'dt_nasc') {
          let desconverteData = this.dataService.toProtheusDate(
            userChanged[key]
          );
          if (desconverteData !== this.copyUser[key])
            fieldsChangeds[key] = userChanged[key];
        } else if (key === 'telefone' || key === 'celular')
          fieldsChangeds[key] = this.functionsService.formatCelTel(
            userChanged[key]
          );
        else if (key === 'cnpj')
          fieldsChangeds[key] = this.functionsService.formatCnpj(
            userChanged[key]
          );
        else fieldsChangeds[key] = userChanged[key];
      }
    });

    console.log(fieldsChangeds);
    return fieldsChangeds;
  }
}
