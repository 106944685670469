import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxMaskModule } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResetSenhaComponent } from './components/reset-senha/reset-senha.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { TabMenuModule } from 'primeng/tabmenu';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { ImageModule } from 'primeng/image';
import { PerfilComponent } from './components/perfil/perfil.component';
import { MenubarModule } from 'primeng/menubar';
import { CriarPostComponent } from './components/criar-post/criar-post.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { ListarPostComponent } from './components/listar-post/listar-post.component';
import { PontoComponent } from './components/ponto/ponto.component';
import { FieldsetModule } from 'primeng/fieldset';
import { EspelhoComponent } from './components/espelho/espelho.component';
import { TableModule } from 'primeng/table';
import { ListProfilesComponent } from './components/list-profiles/list-profiles.component';
import { FormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { GroupCreateComponent } from './components/group-create/group-create.component';
import { GroupListComponent } from './components/group-list/group-list.component';
import { AgreementsRequestsComponent } from './components/agreements-requests/agreements-requests.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { EmployeeRegistrationComponent } from './components/employee-registration/employee-registration.component';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { AddToGroupComponent } from './components/add-to-group/add-to-group.component';
import { AtestadosComponent } from './components/atestados/atestados.component';
import { CriarNotificacaoComponent } from './components/criar-notificacao/criar-notificacao.component';



import { KnobModule } from 'primeng/knob';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CriarProdutoComponent } from './components/criar-produto/criar-produto.component';
import { EditorModule } from 'primeng/editor';
import { CirarCategoriaLojaComponent } from './components/cirar-categoria-loja/cirar-categoria-loja.component';
import { GerenciarCategoriasLojaComponent } from './components/gerenciar-categorias-loja/gerenciar-categorias-loja.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { GerenciadorProdutosComponent } from './components/gerenciador-produtos/gerenciador-produtos.component';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { CriarEmpresaComponent } from './components/criar-empresa/criar-empresa.component';
import { ConveniosPipe } from './pipes/convenios.pipe';
import { StatusConvenioParceiroPipe } from './pipes/status-convenio-parceiro.pipe';
import { StatusRhPipe } from './pipes/status-rh.pipe';
import { SituacaoPedidoConvenioPipe } from './pipes/situacao-pedido-convenio.pipe';
import { DatetimePipe } from './pipes/datetime.pipe';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CpfPipe } from './pipes/cpf.pipe';
import { DataProtheusPipe } from './pipes/data-protheus.pipe';
import { ListarEmpresasComponent } from './components/listar-empresas/listar-empresas.component';
import { CelularPipe } from './pipes/celular.pipe';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import {
  TranslateService,
  TranslateStore,
  TranslateModule,
} from '@ngx-translate/core';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { StatusUserPipe } from './pipes/status-user.pipe';
import { TelefonePipe } from './pipes/telefone.pipe';
import { SexoPipe } from './pipes/sexo.pipe';
import { StatusAtestadoPipe } from './pipes/status-atestado.pipe';
import { ListVacationComponent } from './components/list-vacation/list-vacation.component';
import { TagModule } from 'primeng/tag';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PedidoFaturadoComponent } from './components/pedido-faturado/pedido-faturado.component';
import { ListVacationSendedComponent } from './components/list-vacation-sended/list-vacation-sended.component';
import { StatusFeriasEnvioPipe } from './pipes/status-ferias-envio.pipe';
import { InputNumberModule } from 'primeng/inputnumber';
import { PedidosConcluidosComponent } from './components/pedidos-concluidos/pedidos-concluidos.component';
import { PedidosCanceladosComponent } from './components/pedidos-cancelados/pedidos-cancelados.component';
import { ListMedicalCertificateSendedComponent } from './components/list-medical-certificate-sended/list-medical-certificate-sended.component';
import { RelatorioAdesaoComponent } from './components/relatorio-adesao/relatorio-adesao.component';
import { RelatorioAdesaoDetalahdoComponent } from './components/relatorio-adesao-detalahdo/relatorio-adesao-detalahdo.component';

import { RelatorioAdesaoMensalComponent } from './components/relatorio-adesao-mensal/relatorio-adesao-mensal.component';
import { RelatorioFinanceiroAdesaoDetalhadoComponent } from './components/relatorio-financeiro-adesao-detalhado/relatorio-financeiro-adesao-detalhado.component';

import { StatusProdutoPipe } from './pipes/status-produto.pipe';
import { DividerModule } from 'primeng/divider';
import { CadastroVerbasComponent } from './components/cadastro-verbas/cadastro-verbas.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AvisoContrachqueComponent } from './components/aviso-contracheque/aviso-contrachque.component';
import { ListFeriasReciboComponent } from './components/list-ferias-recibo/list-ferias-recibo.component';
import { WesignPendingComponent } from './components/wesign-pending/wesign-pending.component';
import { LocalDate } from './pipes/localdate.pipe';
import { WesignDocConcludedComponent } from './components/wesign-doc-concluded/wesign-doc-concluded.component';
import { CpfRemoveCaracteresPipe } from './pipes/cpf-remove-caracteres.pipe';
import { LocalDateTime } from './pipes/localdate-time.pipe';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { DefaultNotificationComponent } from './components/default-notification/default-notification.component';
import { AppVersionComponent } from './components/app-version/app-version.component';
import {PanelMenuModule} from 'primeng/panelmenu';
import { MenubarComponent } from './menubar/menubar.component';
import { SidebarModule } from 'primeng/sidebar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { divideByPipy } from './pipes/divideby.pipe';
import { WesignManualComponent } from './components/doc-manual/wesign-manual.component';
import {ProgressBarModule} from 'primeng/progressbar';


@NgModule({
  declarations: [
    AppComponent,
    ResetSenhaComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    PerfilComponent,
    CriarPostComponent,
    ListarPostComponent,
    PontoComponent,
    EspelhoComponent,
    ListProfilesComponent,
    GroupCreateComponent,
    GroupListComponent,
    AgreementsRequestsComponent,
    EmployeeRegistrationComponent,
    AddToGroupComponent,
    AtestadosComponent,
    CriarNotificacaoComponent,
    CriarProdutoComponent,
    CirarCategoriaLojaComponent,
    GerenciarCategoriasLojaComponent,
    GerenciadorProdutosComponent,
    CriarEmpresaComponent,
    ConveniosPipe,
    StatusConvenioParceiroPipe,
    StatusRhPipe,
    SituacaoPedidoConvenioPipe,
    DatetimePipe,
    CpfPipe,
    divideByPipy,
    DataProtheusPipe,
    ListarEmpresasComponent,
    CelularPipe,
    ListUsersComponent,
    StatusUserPipe,
    TelefonePipe,
    SexoPipe,
    StatusAtestadoPipe,
    ListVacationComponent,
    PedidoFaturadoComponent,
    ListVacationSendedComponent,
    StatusFeriasEnvioPipe,
    PedidosConcluidosComponent,
    PedidosCanceladosComponent,
    ListMedicalCertificateSendedComponent,
    RelatorioAdesaoComponent,
    RelatorioAdesaoDetalahdoComponent,

    RelatorioAdesaoMensalComponent,
    RelatorioFinanceiroAdesaoDetalhadoComponent,

    StatusProdutoPipe,
     CadastroVerbasComponent,
     AvisoContrachqueComponent,
     ListFeriasReciboComponent,
     WesignPendingComponent,
     WesignDocConcludedComponent,
    /* pipe */
     LocalDate,
     CpfRemoveCaracteresPipe,
     LocalDateTime,
     CampaignsComponent,
     DefaultNotificationComponent,
     AppVersionComponent,
     MenubarComponent,
     WesignManualComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputMaskModule,
    DialogModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    PasswordModule,
    TabMenuModule,
    KeyFilterModule,
    ToastModule,
    CalendarModule,
    BrowserAnimationsModule,
    PanelModule,
    ImageModule,
    EditorModule,
    MenubarModule,
    TabViewModule,
    InputTextareaModule,
    FileUploadModule,
    FieldsetModule,
    TableModule,
    FormsModule,
    ProgressSpinnerModule,
    DropdownModule,
    MessagesModule,
    MultiSelectModule,
    KnobModule,
    SelectButtonModule,
    ConfirmDialogModule,
    TranslateModule.forChild(),
    TagModule,
    FontAwesomeModule,
    InputNumberModule,
    SplitButtonModule,
    ToggleButtonModule,
    DividerModule,
    RadioButtonModule,
    NgxMaskModule.forRoot(),
    PanelMenuModule,
    SidebarModule,
    NgxChartsModule,
    ProgressBarModule
    
  ],
  providers: [
    ConfirmationService,
    MessageService,
    TranslateService,
    TranslateStore,
    ListProfilesComponent,
    NavbarComponent,
    
    {
      provide: LOCALE_ID, useValue: 'en_US',
    },
    
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
