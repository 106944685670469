<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>

    <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
    </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
    <form [formGroup]="appFrom">
        <div class="grid">
            <div class="col-8">
                <input type="text" class="flex w-full" pInputText placeholder="Url android" formControlName="url_android" />
            </div>

            <div class="col-1">
                <input type="text" class="flex w-full" pInputText placeholder="Version android" formControlName="version_android" />
            </div>

            <div class="col-3">
                <p-button 
                icon="pi pi-check" label="" 
                class="m-2" 
                (click)="saveAppversion()"
                [disabled]="!appFrom.valid"
                ></p-button>
            </div>
    
            <div class="col-8">
                <input type="text" class="flex w-full" pInputText placeholder="url ios" formControlName="url_ios" />
            </div>

            <div class="col-1">
                <input type="text" class="flex w-full" pInputText placeholder="version ios" formControlName="version_ios" />
        </div>
        </div>
    </form>
</div>
    </div>
</div>