import { Component, OnInit } from '@angular/core';
import { MessageService, MenuItem, LazyLoadEvent } from 'primeng/api';
import { Column } from 'src/app/models/column';
import { ExportColumn } from 'src/app/models/exportcolumn';
import { Faturamento } from 'src/app/models/faturamento';
import { ApiService } from 'src/app/services/api.service';
import * as FileSaver from 'file-saver';
import { Company } from 'src/app/models/company';
import { StorageService } from 'src/app/services/storage.service';
import { ProductService } from 'src/app/services/shop/product.service';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-pedidos-concluidos',
  templateUrl: './pedidos-concluidos.component.html',
  styleUrls: ['./pedidos-concluidos.component.scss']
})
export class PedidosConcluidosComponent implements OnInit {
  pedidosConcluidos: Faturamento[] = [];
  loading: boolean = false;
  cols: Column[] = [];
  selectedCols: Column[] = [];
  exportColumn: ExportColumn[] = [];
  filterExecel: Faturamento[] = [];
  totalRecordOrder: number;
  navbarVisible:boolean;

  date1: Date;
  date2: Date;

  dataInicio: any;
  dataFim: any;

  companies: Company[] = [{name:'Todas as Parceiras', id:0}];
  selectedCompanies: Company | undefined;
  
  clients: Company[] = [{name:'Todas os Clientes', idClient:0}];
  selectedClient: Company | undefined

  items: MenuItem[];

  constructor(
    private messageService: MessageService,
    private apiService: ApiService,
    private storageService: StorageService,
    private  productService: ProductService,
    private navbarService: NavbarService

  ) { }

  ngOnInit(): void {
    this.items = [
      {
          label: 'Relatório Encerrados',
          icon: 'pi pi-file-pdf',
          command: () => {
              this.relatorioEncerrados();
          }
      },
      {
        label: 'Relatório Demitidos',
        icon: 'pi pi-file-pdf',
        command: () => {
            this.relatorioDemitidos();
        }
    }
  ];

    let dataFechamento = new Date();
    dataFechamento.setDate(15);
    dataFechamento.setMonth(8);
    dataFechamento.setFullYear(2023);
    let dia = dataFechamento.getDate().toString().padStart(2, '0');
    let mes = (dataFechamento.getMonth() + 1).toString().padStart(2, '0');
    let ano = dataFechamento.getFullYear();
    this.dataInicio = `${ano}-${mes}-${dia}`;
    this.dataFim = `${ano}-${mes}-${dia}`;
    this.getAllCompanies()

    this.cols = [
      {field: 'loja', header: 'Loja' },
      {field: 'empresa', header: 'Empresa' },
      {field: 'nomefuncionairo', header: 'Funcionário' },
      {field: 'cpf', header: 'Cpf' },
      {field: 'descricaoProduto', header: 'Produto'},
      {field: 'valorParcela', header: 'V.parcela' },
      {field: 'parcelasPaga', header: 'Q.P.Paga'},
      {field: 'qtdParcelas', header: 'Qtd.Parcelas' },
      {field: 'dataFaturamento', header: 'D.faturamento'},
      {field: 'datapedido', header:  'D.pedido'},
      {field: 'date', header: '1º Mês(desc)'},
      {field: 'precoFinal', header: 'Total'},
      {field: 'totalPago', header: 'T.Pago'},
      {field: 'statusFuncionario', header:'S.funcionário'},
      {field: 'saldo', header: 'Saldo'},
    ];

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  searchPedidosConcluidos(event:LazyLoadEvent = null) {
    this.pedidosConcluidos = [];
    this.loading = true;
   
    this.apiService.pedidosConcluidos(event?.filters).subscribe((res: any) => {
      res.requests.map((item: any) => {
        this.pedidosConcluidos.push({
          id: item.id,
          cpf: item.cpf,
          datapedido: item.data_pedido,
          date: item.data_primeiro_desconto,
          nomefuncionairo: item.funcionario,
          descricaoProduto: item.produto,
          empresa: item.empresa,
          loja: item.product_requests.products.empresas.nome_fantasia,
          dataFaturamento: item.data_primeiro_desconto,
          precoFinal: item.total,
          qtdParcelas: item.qtd_parcelas,
          valorParcela: item.valor_parcela,
          parcelasPaga: item.parcelas_paga,
          statusFuncionario: item.status_funcionario,
          rescisao: item.rescisao,
          saldo: item.saldo,
          totalPago: item.total_pago,
          code: item.code,
          comissao: item.comissao,
          dataDemissao: item.dataDemissao,
          taxaAdesao: item.product_requests.products.taxa_adesao[0].taxa_adesao
        })
        this.totalRecordOrder = this.pedidosConcluidos.length
      })
    }).add(() => {
      this.loading = false;
    })
  }

  exportFaturamentoExcel(selectedCols: Column[]){
    if(selectedCols.length >  0){
      this.pedidosConcluidos.forEach((item: any) => {
        const dataItem: any = {};
        selectedCols.forEach(selectedCol => {
          const nomeDoCampoDesejado = selectedCol.field;
          const colunaDesejada = this.cols.find(coluna => coluna.field === nomeDoCampoDesejado);
          if (colunaDesejada) {
            const nomeDaColuna = colunaDesejada.header;
            dataItem[nomeDaColuna] = item[nomeDoCampoDesejado];
          } else {
            //console.log(`Coluna "${nomeDoCampoDesejado}" não encontrada`);
          }
        });
        if (Object.keys(dataItem).length > 0) {
          this.filterExecel.push(dataItem);
        }
      });
     
      import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(this.filterExecel);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'faturamentos');
      this.filterExecel = []
      this.selectedCols = []
      });
      
    }else{
      this.messageService.add({
        severity:'error',
        summary:'Error',
        detail: 'Por favor adicionar campos'
      })
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  onChangeDateSeleted(){
    let diaSelected = this.date1?.getDate().toString().padStart(2, '0');
    let mesSeleted = (this.date1?.getMonth() + 1).toString().padStart(2, '0');
    let anoSelected = this.date1?.getFullYear();
    this.dataInicio = `${anoSelected}-${mesSeleted}-${diaSelected}`;
  }

  onChangeDateFinal(){
    let diaSelected = this.date2?.getDate().toString().padStart(2, '0');
    let mesSeleted = (this.date2?.getMonth() + 1).toString().padStart(2, '0');
    let anoSelected = this.date2?.getFullYear();
    this.dataFim = `${anoSelected}-${mesSeleted}-${diaSelected}`;
  }


  relatorioEncerrados(){
    const filterSeparationStatus = this.pedidosConcluidos.filter((item) => item.statusFuncionario === 'ENCERRADO');
    if(filterSeparationStatus.length > 0){
      const titulo = 'RELATÓRIO CONVÊNIOS ENCERRADOS'
      this.productService.relatorioEncerrados(filterSeparationStatus, titulo).subscribe((res:any) => {
        const byteCharacters = atob(res.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document; 
        a.click();
      }).add(() => {
        this.pedidosConcluidos = []
      })
    }else{
      /* fazer nova busca*/
    }
  }

  relatorioDemitidos(){
    const filterSeparationStatus = this.pedidosConcluidos.filter((item) => item.statusFuncionario === 'DEMITIDO');
    if(filterSeparationStatus.length > 0){
      const titulo = 'RELATÓRIO CONVÊNIOS DEMITIDOS'
      this.productService.relatorioDemitidos(filterSeparationStatus, titulo).subscribe((res:any) => {
        const byteCharacters = atob(res.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document; 
        a.click();
      }).add(() => {
        this.pedidosConcluidos = []
      })
    }else{
      /* fazer nova busca*/
    }
  }


  getAllCompanies(){
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{

    const  {empresa_parceira} = this.storageService.getUserLogado()
    if(parseInt(empresa_parceira) > 0){
      const filterCompanies = res.filter(item =>  item.id === parseInt(empresa_parceira))
      filterCompanies.map((item) => {
        this.companies.push({
          name: item.nome_fantasia,
          id: item.id
        })
      })
    }else{
      const filterCompanies = res.filter(item =>  item.tipo === 'Parceira')
      filterCompanies.map((item) => {
        this.companies.push({
          name: item.nome_fantasia,
          id: item.id
        })
      })
    }

    const filterClients = res.filter(item => item.tipo === 'Cliente')
    filterClients.map(item => {
      this.clients.push({
        name: item.nome_fantasia,
        idClient: item.id
      })
    })
    })
  }



}
