<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
  
  <div class="col-10" [class.col-12]="!navbarVisible">
    <p-table #dt1 [value]="categoriasProdutos" dataKey="id" editMode="row" dataKey="id" [rows]="50"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[100, 200, 300]" [loading]="loading" [paginator]="true"
      [globalFilterFields]="['id', 'descricao']">

      <ng-template pTemplate="header">
        <tr>
          <th style="min-width:15rem">
            <div class="flex align-items-center">
              ID
              <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
            </div>
          </th>
          <th style="min-width:15rem">
            <div class="flex align-items-center">
              Descrição
              <p-columnFilter type="text" field="descricao" display="menu"></p-columnFilter>
            </div>
          </th>


          <th style="min-width:10rem">
            <div class="flex align-items-center">
              Actions
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-categoria let-ri="rowIndex" let-editing="editing">
        <tr [pEditableRow]="categoria" [ngStyle]="{'background-color': categoria.status  === false ? 'var(--red-50)' : ''}">
          <td>{{ categoria.id }}</td>
          <td>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="categoria.descricao">
              </ng-template>
              <ng-template pTemplate="output">
                {{ categoria.descricao }}
              </ng-template>
            </p-cellEditor>
          </td>
          <!-- <td><button type="button" pButton pRipple (click)="deletarCategoria(categoria.id)" label="Deletar"
              class="p-button-danger" icon=""></button></td> -->
          <td>
            <button *ngIf=" !editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
              (click)="onRowEditInit(categoria)" class="p-button-rounded p-button-text"></button>
            <button *ngIf=" !editing" pButton pRipple type="button" icon="pi pi-trash"
              (click)="deletarCategoria(categoria.id)" class="p-button-rounded p-button-text"></button>
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
              (click)="onRowEditSave(categoria)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
              (click)="onRowEditCancel(categoria, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
            
            <button 
            (click)="updateStatusCategory(categoria)" 
            pButton pRipple type="button" 
            icon="pi pi-check" class="p-button-rounded p-button-text">
            </button>

          </td>
        </tr>

      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">Não foi encontrada nenhuma catgoria.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
</div>