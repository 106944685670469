import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { PaypmentCodeProduct } from 'src/app/models/paypmentcode.product';
import { Company } from 'src/app/models/company';
import { Verbas } from 'src/app/models/verbas';
import { NavbarService } from 'src/app/services/navbar-service';


interface categoria {
  id: string;
  descricao: string;
}
@Component({
  selector: 'app-criar-produto',
  templateUrl: './criar-produto.component.html',
  styleUrls: ['./criar-produto.component.scss'],
})
export class CriarProdutoComponent implements OnInit {
  public imagePreview: string = '';
  public selectedType: categoria | any;
  public hideImg: boolean = false;
  public formProduct: FormGroup | any;
  public formFeatures: FormGroup | any;
  public userLogado: any = {};
  public categoriasDeLoja: any = [];
  public categoriasDeProduto: any = [];
  public campos: any = [];
  public valores: any = [];
  public tipoDesconto: any = [];

  public paymentCodeProduct: PaypmentCodeProduct[]= []
  public paymentCodeProductSelected: PaypmentCodeProduct;

  public companyes: Company[] = [];
  public companiesSelected: Company;

  public companyesClient: Company[] =  [];
  public companyesClientSelected: Company;

  public verbasCompany: Verbas[] = [];
  public selectdverbasCompany: Verbas;

  public verbas: Verbas[] = [];
  public seletecdVerbas: Verbas;
  public description: string;
  navbarVisible:boolean;

  constructor(
    public api: ApiService,
    public storageService: StorageService,
    public formBuilder: FormBuilder,
    private messageService: MessageService,
    private apiService:ApiService,
    private navbarService: NavbarService
    
  ) {}
  async ngOnInit() {
    this.getAllCompay()
    this.formProduct = new FormGroup({
      titulo: new FormControl(<string | null>null, [Validators.required]),
      preco: new FormControl(<string | null>null, [Validators.required]),
      taxaadm: new FormControl(<string | null>null),
      parcelas: new FormControl(<string | null>null, [Validators.required]),
      metodoDesconto: new FormControl(<string | null>null,[Validators.required]),
      details: new FormControl(<string | null>null,[Validators.required]),
      categoriaLoja: new FormControl(<string | null>null, [Validators.required]),
      categoriaProduto: new FormControl(<string | null>null, [Validators.required]),
      companyId: new FormControl(<string | null>null,[Validators.required]),
      comissao: new FormControl(<string | null>null,[Validators.required]),
      empresaCliente: new FormControl(<string | null>null,[Validators.required]),
      verbasCompany: new FormControl(<string | null>null,[Validators.required ]),
      description: new FormControl(<string | null>null,[Validators.required]),
      taxaAdesao: new FormControl(<string | null> null)
    });
    try {
      this.userLogado = await this.storageService.getUserLogado();
      console.log(this.userLogado);
      this.receberCategorias();
    } catch (error) {}

    this.tipoDesconto =  [{tipo:'Desconto em Folha'},{tipo:'Renovação Automática'}]

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });

  }
  onUpload(event: any) {
    console.log(event);
  }
  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file.name != '') {
      this.hideImg = true;
      if (file) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.imagePreview = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    }
  }

  nomeCategoria: string;
  zerarCampo() {
    this.campos = [];
  }
  async receberCategorias() {
    this.categoriasDeLoja = [];
    try {
      
      this.api.listCategories(this.userLogado.token).subscribe({
        next: (res:any) => {
          res.map((item:any) => {
            this.categoriasDeLoja.push({
            descricao: item.description,
            id: item.id,
        });
      })
        },
        complete: () => {},
        error: () => {}
      });
      this.campos = [];
    } catch (error) {
      console.log(error);
    }
  }

 

  

  montarFields() {
    this.campos = [];
    this.valores = [];
    let listaDeFields = this.formProduct.value.categoriaProduto.pruductFields;
    listaDeFields.forEach((valor) => {
      this.campos.push({
        label: valor.product_category_features_name,
        value: '',
      });
      this.valores.push({
        value: '',
        featureId: valor.product_category_features_id,
      });
    });
  }

  //Enviar a imagem ao BD e subir a imagem
  async createProduct(event: any) {
    if(event.files.length < 3){
     return this.messageService.add({
        severity: 'warn',
        summary: 'Produto',
        detail: 'Quantidade minima 3 imagens!'
      })
    }

    const formData = new FormData();
    for (let i = 0; i <= event.files.length; i++) {
      formData.append('arquivo', event.files[i]);
    }
     this.api
      .criarProduto(
        this.userLogado.token,
        this.formProduct.value.titulo,
        this.formProduct.value.categoriaLoja.id,
        this.formProduct.value.preco,
        this.formProduct.value.parcelas,
        this.formProduct.value.metodoDesconto.tipo,
        this.formProduct.value.comissao,
        this.verbas,
        this.formProduct.value.taxaadm,
        this.formProduct.value.details,
        this.formProduct.value.companyId.id,
        this.formProduct.value.taxaAdesao
      )
      .subscribe((res: any) => {
        this.api
          .enviarImagem(this.userLogado.token, res.productId, formData)
          .subscribe({
            next: (res:any) => {
               this.messageService.add({
                severity: 'success',
                summary: 'Produto',
                detail: res.message
              })
            },
            complete: () => {
               this.formProduct.reset()
               setTimeout(() => {
               window.location.href = '/criar-produto'
               },800)
            },
            error: () => {
              this.messageService.add({
                severity: 'warn',
                summary: 'Produto',
                detail: 'Não foi possivel fazer upload das imagens'
              })
            }
          });
      });
  }


  async getAllCompay(){
    this.apiService.getAllcompany().subscribe(
      (result:any) => {
        const filterTypeCompany = result.filter((result:any) => result.tipo === 'Parceira');
        const filterTypeCompanyCliente =  result.filter((result:any) => result.tipo === 'Cliente');
        filterTypeCompany.map((item:any) => {
          this.companyes.push(
            {
              nome_fantasia: item.nome_fantasia, 
              id: item.id
            }
          )
         })
         filterTypeCompanyCliente.map((item:any) => {
          this.companyesClient.push({
            nome_fantasia: item.nome_fantasia,
            id: item.id
          })
         })

      }
    )
  }

  listVerbaByCompany(){
    this.verbasCompany = [];
    const {id} = this.formProduct.value.empresaCliente
    this.apiService.listPaypmentCodeByCompany(id).subscribe((res:any) => {
     res.map((item:any) => {
      this.verbasCompany.push({
        code: `${item.code}`,
        description: `${item.code}-${item.description}`,
        paypmentCodeFk: item.id
      })
     })
    })
  }

  addlistVerbaEmpresa(){
    const {code, description, paypmentCodeFk} = this.formProduct.value.verbasCompany;
    const {nome_fantasia} = this.formProduct.value.empresaCliente;
    const {id} = this.formProduct.value.empresaCliente; 
    
    const filterCode = this.verbas.filter((item) => item.code === code && item.nome_fantasia === nome_fantasia)
    if(filterCode.length > 0){
      this.messageService.add({
        severity: 'warn',
        summary: 'Averbação',
        detail: 'Verba ja foi adicionada ao produto'
      })
    }else{
      this.verbas.push({
        code: code,
        nome_fantasia: nome_fantasia,
        description: description,
        company_fk: id,
        id: paypmentCodeFk
      })
    }
  }


  checkListVerbasCompanyes(code:string, nome_fantasia:string){
    this.verbas.map((item) => {
      if(item.code === code && item.nome_fantasia === nome_fantasia){
        return this.messageService.add({
          severity: 'warn',
          summary: 'Averbaçôes',
          detail: 'A verba ja adicionada para esse produto'
        })
      }
    })
  }

  deleteVerba(index:any){
    const removeItemVerba  = this.verbas.filter((verba, i) => i !== index)
    this.verbas = removeItemVerba
  }

}
