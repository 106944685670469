<div class="surface-0">
  <p-toast></p-toast>
  <app-menubar></app-menubar>

  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
    </div>
    
    <div class="col-10" [class.col-12]="!navbarVisible">
      <p-table 
      #dt1 [value]="productRequests" 
      dataKey="id" 
      [rows]="10" 
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 50, 100]" 
      [totalRecords]="totalRecordOrder"
      [loading]="loading" 
      [paginator]="true"
      [globalFilterFields]="['id', 'descricao', 'dataPedido', 'cpf','email','nomeEmpresa','empresaParceira','statusParceiro','statusPedido','nome']" 
      scrollDirection="horizontal" 
      [scrollable]="true" 
      scrollHeight="600px"
      editMode="row">
        <ng-template pTemplate="caption">
          <div class="rhsearch">
            <div>
              <span class="p-input-icon-left mr-2">
                <i class="pi pi-search"></i>
                <input pInputText (input)="dt1.filterGlobal(
                    $any($event.target).value, 'contains')"  
                    type="text"/>
            </span>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr [pEditableRow]="productRequests">
            <th style="min-width: 12rem;">
              Data do Pedido
            </th>
            <th>
              N˚ do Pedido
            </th>
            <th style="min-width: 22rem;">
              Funcionário
            </th>
            <th>
              Telefone
            </th>
            <th>
              Celular
            </th>
            <th>
              Email
            </th>
            <th style="min-width: 12rem;">
              CPF
            </th>
            <th>
              Admissão
            </th>
  
            <th style="min-width: 20rem;">
              Empresa
            <th style="min-width: 15rem;">
              Produto
            </th>
            <th>Taxa adesão</th>
            <th>
              Valor total
            </th>
            <th>
              Parcela
            </th>
            <th style="min-width: 12rem;">
              Loja
            </th>
            <th>
              Status Parceiro
            </th>
            <th>
              Situação
            </th>
            <th style="min-width: 12rem;">
              Última Alteração
            </th>
            <th style="min-width: 15rem;">Observação</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pedido let-editing="editing" let-ri="rowIndex">
          <tr [pEditableRow]="pedido">
            <td>{{ pedido.dataPedido | localdatetime}}</td>
            <td>{{ pedido.requestId }}</td>
            <td>{{ pedido.nome }}</td>
            <td> {{ pedido.telefone }} </td>
            <td> {{ pedido.celular }} </td>
            <td> {{pedido.email }} </td>
            <td>{{ pedido.cpf | cpf }}</td>
            <td>{{ pedido.admissao | dataProtheus }}</td>
            <td>{{ pedido.nomeEmpresa}}</td>
            <td>{{ pedido.descricaoProduto }}</td>
            <td> {{pedido.taxaAdesao | currency: 'BRL'}} </td>
            <td>{{ pedido.precoFinal | currency: 'BRL' }}</td>
            <td>{{ pedido.valorParcela | currency: 'BRL'}} </td>
            <td>{{ pedido.empresaParceira }} </td>
            <td>{{pedido.statusParceiro}}</td>
            <td>{{pedido.statusPedido }}</td>
            <td>{{pedido.ultimaAtualizacao | localdatetime}}</td>
            <td> {{ pedido.observacao || '-'}} </td>
          </tr>
  
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">Não foi encontrada nenhuma categoria.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <div class="card flex justify-content-center gap-2">
      <p-toast></p-toast>
      <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    </div>
  </div>
  </div>