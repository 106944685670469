import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { MessageService } from 'primeng/api';
import {
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { ProductService } from 'src/app/services/shop/product.service';
import { ProdutoCategory } from 'src/app/models/productCategory';
import { NavbarService } from 'src/app/services/navbar-service';


@Component({
  selector: 'app-gerenciador-produtos',
  templateUrl: './gerenciador-produtos.component.html',
  styleUrls: ['./gerenciador-produtos.component.scss'],
})
export class GerenciadorProdutosComponent implements OnInit {
  produtos: any[] = [];
  representatives: any[];
  statuses: any[];
  loading: boolean = true;
  activityValues: number[] = [0, 100];

  public userLogado: any;
  public loadingModalProduct: number;
  public showModalEditProduct: boolean;
  public editingProductForm: FormGroup;
  public copyProduct: any;
  public loadingUpdateProduct: boolean;
  navbarVisible: boolean;

  listCategory: ProdutoCategory[] = [];

  listCategorySelected: ProdutoCategory;

  constructor(
    public api: ApiService,
    public storageService: StorageService,
    private messageService: MessageService,
    public formBuilder: FormBuilder,
    public functionsService: FunctionsService,
    private productService: ProductService,
    private navbarService: NavbarService
  ) { }

  async ngOnInit() {
    this.loadingUpdateProduct = false;
    this.showModalEditProduct = false;
    this.loadingModalProduct = -1;
    this.editingProductForm = this.formBuilder.group({
      product_description: [''],
     // storeCategory: [''],
      product_number_plots: [0],
      product_monthly_discount: [0],
      product_price: [0],
      id:[0],
      isActive: [true],
      taxa_adm:[0],
      comissao: [0],
      taxa_adesao: [0],
      taxaId:[0],
      details_product:['']
    });
    try {
      this.userLogado = this.storageService.getUserLogado();
      console.log(this.userLogado);
       this.carregarCategoriasDeLoja();
      this.loading = false;
    } catch (err: any) {
      console.log(err);
    }
    this.listCategoryProducts()
    

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }


  carregarCategoriasDeLoja() {
    this.loading = true;
    this.produtos = [];
    this.api.obterProdutos(this.userLogado.token).subscribe((response: any) => {
      console.log(response);
      this.produtos = response.resultProducts;
      this.loading = false;
    });
  }

  editProduct(product: any, rowIndex: number) {
    this.showModalEditProduct = true;
    this.editingProductForm.controls['product_description'].setValue(product.description);
    this.editingProductForm.controls['product_number_plots'].setValue(product.number_plots);
    this.editingProductForm.controls['product_monthly_discount'].setValue(product.valor_parcela);
    this.editingProductForm.controls['product_price'].setValue(product.price);
    this.editingProductForm.controls['id'].setValue(product.id);
    this.editingProductForm.controls['taxa_adm'].setValue(product.taxa_adm);
    this.editingProductForm.controls['comissao'].setValue(product.comissao);
    this.editingProductForm.controls['taxa_adesao'].setValue(product.taxa_adesao[0].taxa_adesao);
    this.editingProductForm.controls['taxaId'].setValue(product.taxa_adesao[0].id);
    this.editingProductForm.controls['details_product'].setValue(product.details_product);
  }

  extractFormValues(form) {
    let formValues = {};
    Object.keys(form.controls).forEach((key) => {
      formValues[key] = form.get(key).value;
    });
    return formValues;
  }

  async saveProductDb() {
      this.productService.putProduct(this.editingProductForm.value).subscribe({
        next: (res) => {
          const resConv: any = res;
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: resConv.message,
          });
          this.showModalEditProduct = false;
          this.ngOnInit();
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro',
            detail: 'Ocorreu um erro ao atualizar o produto.',
          });
        }
      })
    
  }

  fieldsChanged(productChanged: any) {
    let fieldsChangeds = {};
    Object.keys(productChanged).forEach((key) => {
      if (
        this.copyProduct.product[key] !== productChanged[key] &&
        key !== 'created_at' &&
        key !== 'product_monthly_discount'
      ) {
        fieldsChangeds[key] = productChanged[key];
      }
    });

    console.log(fieldsChangeds);
    return fieldsChangeds;
  }

  listCategoryProducts() {
   this.productService.listCategoryProdutos().subscribe((res:any) => {
    res.map(item => {
      this.listCategory.push({
          description: item.description,
          id: item.id,
      })
    })
   })
  }
}
