import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusAtestado'
})
export class StatusAtestadoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    const fromTo = {
      'A': 'Aprovado',
      'P': 'Pendente',
      'R': 'Reprovado'
    }
    return fromTo[value];
  }

}
