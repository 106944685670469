import { AtestadosComponent } from './components/atestados/atestados.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CriarPostComponent } from './components/criar-post/criar-post.component';
import { EspelhoComponent } from './components/espelho/espelho.component';
import { HomeComponent } from './components/home/home.component';
import { ListarPostComponent } from './components/listar-post/listar-post.component';
import { LoginComponent } from './components/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PontoComponent } from './components/ponto/ponto.component';
import { ResetSenhaComponent } from './components/reset-senha/reset-senha.component';
import { ListProfilesComponent } from './components/list-profiles/list-profiles.component';
import { GroupCreateComponent } from './components/group-create/group-create.component';
import { GroupListComponent } from './components/group-list/group-list.component';
import { AgreementsRequestsComponent } from './components/agreements-requests/agreements-requests.component';
import { EmployeeRegistrationComponent } from './components/employee-registration/employee-registration.component';
import { AddToGroupComponent } from './components/add-to-group/add-to-group.component';
import { CriarNotificacaoComponent } from './components/criar-notificacao/criar-notificacao.component';
import { CriarProdutoComponent } from './components/criar-produto/criar-produto.component';
import { CirarCategoriaLojaComponent } from './components/cirar-categoria-loja/cirar-categoria-loja.component';
import { GerenciarCategoriasLojaComponent } from './components/gerenciar-categorias-loja/gerenciar-categorias-loja.component';
import { GerenciadorProdutosComponent } from './components/gerenciador-produtos/gerenciador-produtos.component';
import { CriarEmpresaComponent } from './components/criar-empresa/criar-empresa.component';
import { ListarEmpresasComponent } from './components/listar-empresas/listar-empresas.component';
import { ListUsersComponent } from './components/list-users/list-users.component';
import { ListVacationComponent } from './components/list-vacation/list-vacation.component';
import { PedidoFaturadoComponent } from './components/pedido-faturado/pedido-faturado.component';
import { ListVacationSendedComponent } from './components/list-vacation-sended/list-vacation-sended.component';
import { PedidosConcluidosComponent } from './components/pedidos-concluidos/pedidos-concluidos.component';
import { PedidosCanceladosComponent } from './components/pedidos-cancelados/pedidos-cancelados.component';
import { ListMedicalCertificateSendedComponent } from './components/list-medical-certificate-sended/list-medical-certificate-sended.component';
import { RelatorioAdesaoComponent } from './components/relatorio-adesao/relatorio-adesao.component';
import { RelatorioAdesaoDetalahdoComponent } from './components/relatorio-adesao-detalahdo/relatorio-adesao-detalahdo.component';
import { RelatorioAdesaoMensalComponent } from './components/relatorio-adesao-mensal/relatorio-adesao-mensal.component';
import { RelatorioFinanceiroAdesaoDetalhadoComponent } from './components/relatorio-financeiro-adesao-detalhado/relatorio-financeiro-adesao-detalhado.component';
import { CadastroVerbasComponent } from './components/cadastro-verbas/cadastro-verbas.component';
import { AvisoContrachqueComponent } from './components/aviso-contracheque/aviso-contrachque.component';
import { ListFeriasReciboComponent } from './components/list-ferias-recibo/list-ferias-recibo.component';
import { WesignPendingComponent } from './components/wesign-pending/wesign-pending.component';
import { WesignDocConcludedComponent } from './components/wesign-doc-concluded/wesign-doc-concluded.component';
import { CampaignsComponent } from './components/campaigns/campaigns.component';
import { DefaultNotificationComponent } from './components/default-notification/default-notification.component';
import { AppVersionComponent } from './components/app-version/app-version.component';
import { WesignManualComponent } from './components/doc-manual/wesign-manual.component';
const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'reset-senha', component: ResetSenhaComponent },
  { path: 'home', component: HomeComponent },
  { path: 'navbar', component: NavbarComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'criar-post', component: CriarPostComponent },
  { path: 'listar-post', component: ListarPostComponent },
  { path: 'ponto', component: PontoComponent },
  { path: 'espelho', component: EspelhoComponent },
  { path: 'list-profiles', component: ListProfilesComponent },
  { path: 'group-create', component: GroupCreateComponent },
  { path: 'group-list', component: GroupListComponent },
  { path: 'Agreements-list', component: AgreementsRequestsComponent },
  { path: 'cadastro-funcionario', component: EmployeeRegistrationComponent },
  { path: 'add', component: AddToGroupComponent },
  { path: 'atestados', component: AtestadosComponent },
  { path: 'criar-notificacao', component: CriarNotificacaoComponent },
  { path: 'criar-produto', component: CriarProdutoComponent },
  { path: 'criar-categoria-loja', component: CirarCategoriaLojaComponent },
  { path: 'gerenciador-loja', component: GerenciarCategoriasLojaComponent, },
  { path: 'gerenciador-produto', component: GerenciadorProdutosComponent, },
  { path: 'criar-empresa', component: CriarEmpresaComponent, },
  { path: 'listar-empresa', component: ListarEmpresasComponent, },
  { path: 'listar-usuarios', component: ListUsersComponent, },
  { path: 'listar-ferias', component: ListVacationComponent, },
  { path: 'faturamento', component: PedidoFaturadoComponent },
  { path: 'pedidos-concluidos', component: PedidosConcluidosComponent },
  { path: 'pedidos-cancelados', component: PedidosCanceladosComponent },
  { path: 'listar-ferias-enviadas', component: ListVacationSendedComponent, },
  { path: 'listar-atestados-apurados', component: ListMedicalCertificateSendedComponent, },
  { path: 'relatorio-adesao', component: RelatorioAdesaoComponent },
  { path: 'relatorio-adesao-detalhado', component: RelatorioAdesaoDetalahdoComponent },
  { path: 'financeiro-convenios', component: RelatorioAdesaoMensalComponent },
  { path: 'financeiro-detalhado-covenios', component: RelatorioFinanceiroAdesaoDetalhadoComponent },
  { path: 'cadastro-verbas', component: CadastroVerbasComponent },
  { path: 'aviso-contracheque', component: AvisoContrachqueComponent },
  { path: 'list-ferias-recibo', component: ListFeriasReciboComponent },
  { path: 'list-doc-pending', component: WesignPendingComponent, },
  { path: 'list-doc-concluded', component: WesignDocConcludedComponent },
  { path: 'criar-campanha', component: CampaignsComponent },
  { path: 'notification-default', component: DefaultNotificationComponent },
  { path: 'app-version', component: AppVersionComponent },
  { path: 'doc-manual', component: WesignManualComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
