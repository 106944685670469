import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MessageService } from 'primeng/api';
import { StorageService } from '../../services/storage.service';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-cirar-categoria-loja',
  templateUrl: './cirar-categoria-loja.component.html',
  styleUrls: ['./cirar-categoria-loja.component.scss'],
})
export class CirarCategoriaLojaComponent implements OnInit {
  navbarVisible:boolean;
  constructor(
    public api: ApiService,
    public storageService: StorageService,
    private messageService: MessageService,
    private navbarService: NavbarService
  ) {}
  public userLogado: any = {};
  public nomeCategoria: any;
  async ngOnInit() {
    try {
      this.userLogado =  this.storageService.getUserLogado();
      console.log(this.userLogado);
    } catch (err: any) {
      console.log(err);
    }

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  async criarCategoria(event: any) {
    if (this.nomeCategoria == undefined || this.nomeCategoria == '') {
      return this.messageService.add({
        severity: 'warm',
        summary: 'Erro',
        detail: 'Por favor informar o nome da categoria!',
      });
    }
    const formData = new FormData();
    formData.append('arquivo', event.files[0]);
    try {
      this.api
        .criarCategoriadeLoja(
          this.userLogado.token,
          this.nomeCategoria,
          formData
        )
        .subscribe((response: any) => {
          console.log(response);
          if (response.created) {
            return this.messageService.add({
              severity: 'success',
              summary: 'Successo',
              detail: 'Categoria criada com sucesso!',
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: 'Categoria não foi criada!',
            });
          }
        }).add(() => {
          setTimeout(() => {
            window.location.href =  '/criar-categoria-loja'
          },800)
        });
    } catch (error) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary: 'Erro',
        detail:
          'Erro interno do servidor por favor tentar novamente mais tarde',
      });
    }

    // try {
    //   this.api
    //     .criarCategoriadeLoja(this.userLogado.token, this.nomeCategoria)
    //     .subscribe({
    //       next: (response) => {
    //         console.log(response);
    //       },
    //       (error) => {
    //         console.log(error);
    //       }
    //     );
    // } catch (error) {}
  }
}
