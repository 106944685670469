<div class="surface-0">
  <app-menubar></app-menubar>
  <div class="grid">
    <div class="col-2 bg-secondary" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
 
  <div class="col-10" [class.col-12]="!navbarVisible">
    <p-table 
      #dt2 [value]="atestados" 
      dataKey="id" 
      [rows]="10" 
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 50, 100]" 
      [loading]="loading" 
      [paginator]="true" 
      [lazy]="true"
      currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
      [tableStyle]="{ 'min-width': '100%' }" 
      (onLazyLoad)="getAtestados($event)" 
      [totalRecords]="totalRecords"
      [loading]="loading" 
      scrollDirection="horizontal" 
      [scrollable]="true" 
      scrollHeight="600px"
      styleClass="p-datatable-striped">

      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-start">
          Pesquisar Orgão
          <p-columnFilter 
              [showAddButton]="false" 
              matchMode="contains" 
              [showOperator]="false"
              [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
              type="text" 
              field="centro_custo"
              display="menu">
            </p-columnFilter>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th>
            Empresa
            <p-columnFilter [showAddButton]="false" matchMode="contains" [showOperator]="false" type="text" field="nome_fantasia"
              display="menu"></p-columnFilter>
          </th>
          <th style="min-width: 20rem">
            Funcionário
           
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="text" field="nome"
              display="menu"></p-columnFilter>
          </th>

          <th>
            CPF
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="text" field="cpf"
              display="menu"></p-columnFilter>
          </th>

         
          <th pSortableColumn="dt_inicio">
            Início
            <p-sortIcon field="dt_inicio"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="date" field="dt_inicio"
              display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="dt_fim">
          Fim
            <p-sortIcon field="dt_fim"></p-sortIcon>
            <p-columnFilter [showAddButton]="false" [showOperator]="false" type="date" field="dt_fim"
              display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="qtd_dias" style="min-width:7rem">
            Dias
            <p-sortIcon field="qtd_dias"></p-sortIcon>
          </th>

          <th pSortableColumn="status">
            Status <p-sortIcon field="status"></p-sortIcon><p-columnFilter [showAddButton]="false"
              [showOperator]="false" type="text" field="status" display="menu" matchMode="equals"
              [showMatchModes]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="statusMedValue" [options]="optionsStatusMed" (onChange)="filter($event.value)"
                  placeholder="Selecione o status" optionLabel="name" optionValue="code">
                  <ng-template let-option pTemplate="item">
                    <p-tag [value]="option.name" [severity]="getSeverity(option.code)"></p-tag>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>Pdf</th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-atestado let-ri="rowIndex" let-expanded="expanded">
        <tr>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="atestado" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        </td>
          <td style="min-width: 20rem">
            {{ atestado.usuarios.empresas.nome_fantasia }}
          </td>
          <td style="min-width: 20rem">
            {{ atestado.usuarios.dados_funcionarios.nome }}
          </td>
          <td>{{ atestado.usuarios.dados_funcionarios.cpf }}</td>
      
          <td style="min-width: 15rem">
            {{ atestado.dt_inicio | dataProtheus }}
          </td>
          <td>
            <span>{{ atestado.dt_fim | dataProtheus }}</span>
          </td>
          <td>
            <span>{{ atestado.qtd_dias }}</span>
          </td>
        
          <td>
            <p-tag [value]="atestado.status | statusAtestado" [severity]="getSeverity(atestado.status)"></p-tag>
          </td>

          <td>
            <button pButton pRipple type="button" icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-text p-button-raised"
            (click)="downloadAtestado(atestado)"></button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-atestado>
        <tr>
            <td colspan="10">
                <div class="p-1">
                    <p-table [value]="atestado.items" dataKey="id">
                        <ng-template pTemplate="header">
                            <tr>
                               
                                <th>Méddico</th>
                                <th>Crm</th>
                                <th>Cid</th>
                                <th>Recebimento</th>
                                <th>Atualização</th>
                                <th>Motivo</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-i>
                            <tr>
                               
                                <td>{{i.medico}}</td>
                                <td>{{i.crm}}</td>
                                <td>{{i.cid}}</td>
                                <td>{{i.created_at | localdate}}</td>
                                <td>{{i.updated_at | localdate}}</td>
                                <td>{{i.motivo_reprovacao}}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">There are no order for this product yet.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5">Nenhum atestado encontrado.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-toast></p-toast>
    <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
  </div>
</div>
</div>