import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusUser'
})
export class StatusUserPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const fromTo = {
      '': 'ATIVO',
      'V': 'PRIMEIRO ACESSO',
      'B': 'BLOQUEADO'
    }
    return fromTo[value];
  }

}
