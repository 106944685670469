<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
    <div class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table 
          #dt1 
          [value]="companys" 
          dataKey="id" 
          [rows]="50" 
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50, 100]" 
          [paginator]="true" 
          scrollDirection="horizontal" 
          [scrollable]="true"
          scrollHeight="600px" 
          editMode="row" 
          style="z-index: 0;">
          <ng-template pTemplate="header">
            <tr>
              <th>CNPJ</th>
              <th>Nome Fantasia</th>
              <th>Grupo</th>
              <th>Filial</th>
              <th>Razão Social</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Celular</th>
              <th style="width: 5rem"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-company let-editing="editing" let-ri="rowIndex">
            <tr>
              <td>{{ company.cnpj }}</td>
              <td>{{ company.nome_fantasia }}</td>
              <td>{{ company.grupo }}</td>
              <td>{{ company.filial }}</td>
              <td>{{ company.razao_social }}</td>
              <td>{{ company.email }}</td>
              <td>{{ company.telefone }}</td>
              <td>{{ company.celular | celular}}</td>
              <td class="flex">
                <div class="col-6">
                  <p-button type="button" pRipple icon="pi pi-pencil" (click)="editCompany(company)"></p-button>
                </div>
                <div class="col-6">
                  <p-button type="button" styleClass="p-button-danger" pRipple icon="pi pi-trash"
                    (click)="deleteCompany(company)"></p-button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="card flex justify-content-center gap-2">
      <p-toast></p-toast>
      <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
    </div>

    <p-dialog header="Atualizar cadastro da empresa" [(visible)]="showModal" [style]="{width: '50vw'}"
      *ngIf="showModal">
      <form [formGroup]="editingCompanyForm" novalidate>
        <div class="grid">
          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                CNPJ
              </span>
            </div>
            <div class="col-6">
              <p-inputMask class="flex w-full" mask="99.999.999/9999-99" formControlName="cnpj"
                placeholder="CNPJ"></p-inputMask>
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Nome Fantasia
              </span>
            </div>
            <div class="col-6">
              <!-- <p-inputMask class="flex w-full" [(ngModel)]="editingCompany.nome_fantasia"
              [(ngModel)]="editingCompany.cnpj" placeholder="CNPJ"></p-inputMask> -->
              <input type="text" class="flex w-full" pInputText placeholder="Senha" formControlName="nome_fantasia">
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Grupo
              </span>
            </div>
            <div class="col-6">
              <!-- <p-inputMask class="flex w-full" [(ngModel)]="editingCompany.nome_fantasia"
                      [(ngModel)]="editingCompany.cnpj" placeholder="CNPJ"></p-inputMask> -->
              <input type="text" class="flex w-full" pInputText placeholder="Grupo" formControlName="grupo">
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Filial
              </span>
            </div>
            <div class="col-6">
              <!-- <p-inputMask class="flex w-full" [(ngModel)]="editingCompany.nome_fantasia"
                      [(ngModel)]="editingCompany.cnpj" placeholder="CNPJ"></p-inputMask> -->
              <input type="text" class="flex w-full" pInputText placeholder="Filial" formControlName="filial">
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Razão Social
              </span>
            </div>
            <div class="col-6">
              <!-- <p-inputMask class="flex w-full" [(ngModel)]="editingCompany.nome_fantasia"
                      [(ngModel)]="editingCompany.cnpj" placeholder="CNPJ"></p-inputMask> -->
              <input type="text" class="flex w-full" pInputText placeholder="Razão Social"
                formControlName="razao_social">
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Email
              </span>
            </div>
            <div class="col-6">
              <!-- <p-inputMask class="flex w-full" [(ngModel)]="editingCompany.nome_fantasia"
                      [(ngModel)]="editingCompany.cnpj" placeholder="CNPJ"></p-inputMask> -->
              <input type="text" class="flex w-full" pInputText placeholder="Email" formControlName="email">
            </div>
          </div>

          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Telefone
              </span>
            </div>
            <div class="col-6">
              <span *ngIf="errorControl.telefone.errors">
                Erro
              </span>
              <p-inputMask class="flex w-full" mask="(99)9999-9999" formControlName="telefone"
                placeholder="Telefone"></p-inputMask>

            </div>
          </div>
          <div class="p-inputgroup field flex">
            <div class="col-6">
              <span class="p-inputgroup-addon">
                Celular
              </span>
            </div>
            <div class="col-6">
              <p-inputMask class="flex w-full" mask="(99)99999-9999" formControlName="celular"
                placeholder="Celular"></p-inputMask>
            </div>
          </div>
        </div>

      </form>
      <ng-template pTemplate="footer" class="flex ">
        <p-button icon="pi pi-save" label="Atualizar" class="mt-4" (click)="editCompanyDb()"></p-button>
        <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveProduct()"></button> -->
      </ng-template>
    </p-dialog>

  </div>
</div>