<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-2"  *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>
  
  <div class="col-10"  [class.col-12]="!navbarVisible">
  <form [formGroup]="formProduct">
    <p-tabView styleClass="tabview-custom">
      <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="pi pi-calendar"></i>
            <span>Produto</span>
        </ng-template>
        <!--produtos-->
        <div class="grid">
          <div class="col-3">
            <label for="">Titulo do Produto</label>
            <div class="p-inputgroup mt-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-shopping-cart"></i>
              </span>
              <input pInputText placeholder="Titulo do Produto" formControlName="titulo" />
            </div>
          </div>
          <div class="col-2">
            <label for="">Preço do produto</label>
            <div class="p-inputgroup mt-2">
              <p-inputNumber 
              formControlName="preco"
              placeholder="Preço do Produto"
              inputId="currency-us" 
              mode="currency" 
              currency="BRL" 
              locale="en-US"
              >
            </p-inputNumber>
            </div>
          </div>
          <div class="col-1">
            <label for=""> Parcelas</label>
            <div class="p-inputgroup mt-2">
              <input pInputText placeholder="" pKeyFilter="int"
                formControlName="parcelas" />
            </div>
          </div>

          <div class="col-3">
            <label for="loja">Loja Parceiras:</label>
            <div class="mt-2">
              <p-dropdown [options]="companyes"
                placeholder="Selecione a categoria de loja" formControlName="companyId"
                optionLabel="nome_fantasia">
              </p-dropdown>
            </div>            
          </div>
          <div class="col-1">
            <label for="comissao">Comisão</label>
            <div class="mt-2">
              <p-inputNumber 
              formControlName="comissao"
              placeholder="Comissão"
              inputId="currency-us" 
              mode="currency" 
              currency="BRL" 
              locale="en-US"
              >
            </p-inputNumber>
            </div>
          </div>
        </div>

        <div class="grid mt-2">
          <div class="col-2">
              <label for="comissao">Tipo desconto</label>
              <p-dropdown 
              [options]="tipoDesconto" 
              optionLabel="tipo"
              formControlName="metodoDesconto"
              placeholder="Taxa Admnistração"
              class="mr-2">
            </p-dropdown>
          </div>

          <div class="col-2">
            <div>
              <label for="comissao">Taxa Adm</label>
              <p-inputNumber 
              formControlName="taxaadm"
              inputId="currency-us" 
              mode="currency" 
              placeholder="Taxa adm"
              currency="BRL" 
              locale="en-US"
              >
            </p-inputNumber>
            </div>
          </div>

          <div class="col-2">
            <label for="comissao">Taxa Adesão</label>
            <p-inputNumber 
            formControlName="taxaAdesao"
            inputId="currency-us" 
            mode="currency" 
            placeholder="Taxa adesão"
            currency="BRL" 
            locale="en-US"
            >
          </p-inputNumber>
          </div>

          <div class="col-2">
            <label for="teste" class="mr-2">Categoria de Loja:</label>
            <p-dropdown [options]="categoriasDeLoja"
              placeholder="Selecione a categoria de loja" formControlName="categoriaLoja"
              optionLabel="descricao"></p-dropdown>
          </div>
          <div class="col-10">
            <textarea rows="2" class="w-full mt-2" pInputTextarea [autoResize]="true" placeholder="Descrição"
              formControlName="details"></textarea>
          </div>
        </div>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
          <i class="pi pi-calendar"></i>
          <span>Averbação</span>
      </ng-template>
     <!--averbação-->
     <div class="grid">
      <div class="col-6">
        
        <p-dropdown 
          [options]="companyesClient" 
          (onChange)="listVerbaByCompany()"
          optionLabel="nome_fantasia"
          formControlName="empresaCliente"
          class="mr-2">
        </p-dropdown>

        <p-dropdown 
        [options]="verbasCompany" 
        optionLabel="description"
        formControlName="verbasCompany"
        class="mr-2">
      </p-dropdown>

        <p-button icon="pi pi-plus" 
        label="" class="" 
        (click)="addlistVerbaEmpresa()">
        </p-button>
        
      </div>

      <div class="col-6">
        <p-table
        #dt1 
        [value]="verbas" 
        [(selection)]="seletecdVerbas" 
        dataKey="id" 
        [rows]="50"
        [showCurrentPageReport]="true" 
        [rowsPerPageOptions]="[10, 20, 40]" 
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="[]" 
        scrollDirection="horizontal" 
        [scrollable]="true"
        [filterDelay]="0"
        scrollHeight="600px" 
        editMode="row">
            <ng-template pTemplate="caption">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Empresa</th>
                    <th>descrição</th>
                    <th >Del</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-code let-ri="rowIndex">
                <tr>
                    <td>{{ code.nome_fantasia}}</td>
                    <td>{{ code.description }} </td>
                    <td>
                        <p-button 
                        icon="pi pi-times" 
                        styleClass="p-button-rounded p-button-danger p-button-text"
                        (click)="deleteVerba(ri)"
                        >
                    </p-button>
                    </td>

            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                </tr>
            </ng-template>
        </p-table>
      </div>
     </div>
  </p-tabPanel>


  <p-tabPanel>
    <ng-template pTemplate="header">
        <i class="pi pi-calendar"></i>
        <span>Imagens</span>
    </ng-template>
   <!--imagens-->
  <div class="mt-5">
    <p-fileUpload chooseLabel="Foto" [showUploadButton]=true uploadLabel="Salvar" cancelLabel="Cancelar"
      [customUpload]="true" accept="image/*, video/*" [multiple]="true" (uploadHandler)="createProduct($event)">
    </p-fileUpload>
  </div>
  <section *ngIf="hideImg">
    <div>
      <p-button label="Criar" class="w-full" [style]="{'width':'100%'}" icon="pi pi-check"></p-button>
    </div>
  </section>
</p-tabPanel>
    </p-tabView>
  </form>
</div>
</div>
</div>
