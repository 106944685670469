<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
    <app-navbar></app-navbar>
  </div>
  
  <div class="col-10" [class.col-12]="!navbarVisible">
  <form [formGroup]="formUser" autocomplete="off">
    <!-- Dados iniciais do usuário -->
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-user mr-2"></i>
          Dados do usuário
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <div class="col-2">
              <label for="nome">Nome do usuário:</label>
            </div>
            <div class="col-10">
              <input
                pInputText
                type="text"
                placeholder="Nome do usuário"
                formControlName="nomeUser"
                class="w-full"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="funcao">Função:</label>
            </div>
            <div class="col-10">
              <input
                pInputText
                type="text"
                placeholder="Função"
                formControlName="funcao"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="cpf">CPF:</label>
            </div>
            <div class="col-10">
              <p-inputMask
                mask="999.999.999-99"
                placeholder="CPF"
                class="w-full"
                formControlName="cpf"
              ></p-inputMask>
            </div>
          </div>
          <!-- </div> -->
          <!-- <div class="grid"> -->
          <div class="col-12">
            <div class="col-2">
              <label for="dataNascimento" class="mr-2"
                >Data de nascimento:</label
              >
            </div>
            <div class="col-10">
              <p-calendar
                formControlName="dataNascimento"
                dateFormat="dd/mm/yy"
                class="w-full"
                formControlName="dataNascimento"
              ></p-calendar>
            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="sexo" class="mr-2">Sexo:</label>
            </div>
            <div class="col-10">
              <p-dropdown
                formControlName="sexo"
                placeholder="Selecione o sexo do usuário"
                [options]="optionSexo"
                optionLabel="label"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-envelope mr-2"></i>
          <span>Contatos</span>
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <div class="col-2">
              <label for="email">Email:</label>
            </div>
            <div class="col-10">
              <input
                pInputText
                type="email"
                placeholder="Email"
                formControlName="email"
                class="w-full"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="celular" class="mr-2">Celular:</label>
            </div>
            <div class="col-10">
              <p-inputMask
                mask="(99) 99999-9999"
                placeholder="Celular"
                class="w-full"
                formControlName="celular"
                autocomplete="off"
              ></p-inputMask>
            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="telefone" class="mr-2">Telefone:</label>
            </div>
            <div class="col-10">
              <p-inputMask
                mask="(99) 9999-9999"
                placeholder="Telefone"
                class="w-full"
                formControlName="telefone"
                autocomplete="off"
              ></p-inputMask>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-lock mr-2"></i>
          <span>Senha e controle de acesso</span>
        </ng-template>
        <div class="grid">
          <div class="col-12">
            <div class="col-2">
              <label for="empresa">Empresa:</label>
            </div>
            <div class="col-10">
              <p-multiSelect 
              [options]="optionEmpresa" 
              formControlName="empresa" 
              optionLabel="nome_fantasia" 
              placeholder="Selecione a empresa" >
              </p-multiSelect>

            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="senha">Senha:</label>
            </div>
            <div class="col-10">
              <p-password
                type="password"
                class="w-full"
                placeholder="Senha"
                styleClass="p-password p-component p-inputwrapper p-input-icon-right"
                [toggleMask]="true"
                formControlName="senha"
              ></p-password>
            </div>
          </div>
          <div class="col-12">
            <div class="col-2">
              <label for="teste">Perfil:</label>
            </div>
            <div class="col-10">
              <p-dropdown
                formControlName="city"
                [options]="optionsRoles"
                optionLabel="name"
                optionValue="code"
                formControlName="role"
              ></p-dropdown>
            </div>
          </div>
        </div>
        <!-- Finalização -->

        <div class="grid">
          <div class="col-12">
            <p-button
              label="Criar"
              icon="pi pi-plus"
              styleClass="p-button"
              (onClick)="enviarDados()"
            ></p-button>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>

    <!-- Parte de modo de contato -->
  </form>
</div>
  </div>
</div>