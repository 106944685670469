
<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>
    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>
    
    <div class="col-10" [class.col-12]="!navbarVisible">
        <form [formGroup]="form">
        <div class="grid">
            <div class="col-8">
             <div class="card">
                     <p-editor formControlName="text" [style]="{ height: '280px' }"  
                     (keydown)="onKeyDown($event)"
                     [readonly]="countTextDisebled"
                     >
                         <ng-template pTemplate="header">
                             <span class="ql-formats">
                                 <button type="button" class="ql-bold" aria-label="Bold"></button>
                                 <button type="button" class="ql-italic" aria-label="Italic"></button>
                                 <button type="button" class="ql-underline" aria-label="Underline"></button>
                             </span>
                         </ng-template>
                     </p-editor>
                 </div>
             </div>
             <div class="col-4">
                <input type="text" class="flex w-full" pInputText placeholder="Titulo"
                formControlName="titulo" />
                <div class="p-inputgroup field flex">
                    <p-dropdown formControlName="client" 
                    [options]="clients" 
                    optionLabel="name" 
                    class="flex w-full mt-5"
                    >
                    </p-dropdown>
                </div>
                <p-button 
                label="Salvar" 
                icon="pi pi-check"  
                (onClick)="saveComunication()">
                </p-button>

                <p-button 
                label="Editar" 
                icon="pi pi-pencil"  
                styleClass="p-button-success ml-2"
                (onClick)="editItemComunication()" 
                type="reset">
                </p-button>

                <p-button 
                label="Cancelar" 
                icon="pi pi-times-circle"  
                styleClass="p-button-secondary ml-2"
                (onClick)="resetCountText()" 
                type="reset">
                </p-button>
             </div>

        </div>
    </form>
        <div class="grid">
            <div class="col-12">
               158 / {{countText}}
            
            
        <p-table 
        #dt1 
        [value]="litComuniCation" 
        [(selection)]="selectedLitComuniCation" 
        dataKey="id" 
        [rows]="50"
        [showCurrentPageReport]="true" 
        [rowsPerPageOptions]="[100, 200, 300]" 
        [loading]="loading" 
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['']" 
        scrollDirection="horizontal" 
        [scrollable]="true"
        [filterDelay]="0"
        scrollHeight="600px" 
        editMode="row">

            <ng-template pTemplate="caption">
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Empresa</th>
                    <th>Titulo</th>
                    <th>Menssagem</th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-list>
                <tr>
                    <td> {{list.empresas.nome_fantasia}} </td>
                    <td> {{list.titulo}} </td>
                    <td> {{list.message }} </td>
                    <td>
                        <p-tableCheckbox [value]="list" (click)="itemselectedValue()"></p-tableCheckbox>
                    </td>
                    <td>
                        <span class="pi pi-times  p-button-danger" (click)="deleteComunication(list.id)"></span>
                    </td>
                </tr>

            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                </tr>
            </ng-template>
        </p-table>
        </div>
        </div>
    </div>
    </div>
</div>
