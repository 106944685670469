<div class="surface-0">
    <p-toast></p-toast>
    <app-menubar></app-menubar>

    <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
    </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
        <p-table 
        #dt1 
        (onLazyLoad)="searchEmploee($event)"
        [lazy]="true"
        [value]="faturamentos" 
        [(selection)]="selectedFaturamento" 
        dataKey="id" 
        [rows]="10"
        [showCurrentPageReport]="true" 
        [rowsPerPageOptions]="[10, 500, 100]" 
        [totalRecords]="totalRecordOrder"
        [loading]="loading" 
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        
        scrollDirection="horizontal" 
        [scrollable]="true"
        [filterDelay]="0"
        scrollHeight="600px" 
        editMode="row">

            <ng-template pTemplate="caption">
                <div class="print-docs">
                    <div *ngIf="permission.roles.description ==='rhapp'">
                        <button type="button" pButton pRipple icon="pi pi-user-minus" (click)="calcularRescisao()"
                        [disabled]="inputDesableRescisao"  class="p-button-danger mr-2" pTooltip="DEMISSÃO" tooltipPosition="bottom">
                        </button>
                        <button type="button" pButton pRipple icon="pi pi-money-bill" (click)="baixarParcelas()"
                        [disabled]="inputDesableButtonFaturar" class="p-button-success mr-2" pTooltip="BAIXAR" tooltipPosition="bottom">
                    </button>
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>
                    <th class="loja">Loja</th>
                    <th class="empresa">Empresa</th>
                    <th class="funcionario">Funcionário</th>
                    <th>
                        Cpf
                        <p-sortIcon field="cpf"></p-sortIcon>
                        <p-columnFilter
                          [showAddButton]="false"
                          [showOperator]="false"
                          type="text"
                          field="cpf"
                          display="menu"
                        ></p-columnFilter>
                    </th>
                    <th class="prod">Prod</th>
                    <th>Taxa Adesão</th>
                    <th>V.Par</th>
                    <th>
                        P.Paga
                        <p-sortIcon field="parcelasPaga"></p-sortIcon>
                        <p-columnFilter
                          [showAddButton]="false"
                          [showOperator]="false"
                          type="text"
                          field="parcelasPaga"
                          display="menu"
                        ></p-columnFilter>
                    </th>
                    <th>Qtd.Par</th>
                    <th class="dataFaturamento">D.Fatura</th>
                    <th>D.Pedido</th>
                    <th class="primedesconto">1º Mês(des)</th>
                    <th>Comissão</th>
                    <th>Total</th>
                    <th>T.Pago</th>
                    <th>S.funcionário</th>

                    <th class="threscisao" *ngIf="permission.roles.description ==='rhapp'">
                        <button pButton pRipple 
                        type="button" 
                        [icon]="'pi pi-pencil'" 
                        (click)="enableInputRescisao()" 
                        class="p-button-sm p-button-text">
                        </button>
                        Rescisao
                    </th>

                    <th class="thslado">Saldo</th>

                    <th class="thcheck-box" *ngIf="permission.roles.description ==='rhapp'">
                        <p-tableHeaderCheckbox [disabled]="false"></p-tableHeaderCheckbox>
                        <label>Selecionar</label>
                    </th>

                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-faturamento>
                <tr>
                    <td>{{ faturamento.id }}</td>
                    <td>{{ faturamento.loja }}</td>
                    <td>{{ faturamento.empresa }} </td>
                    <td>{{ faturamento.nomefuncionairo }}</td>
                    <td>{{ faturamento.cpf }}</td>
                    <td>{{ faturamento.descricaoProduto }}</td>
                    <td>{{ faturamento.taxaAdesao | currency: 'BRL' }}</td>
                    <td>{{ faturamento.valorParcela | currency:'BRL'}}</td>
                    <td>{{ faturamento.parcelasPaga || '0' }}</td>
                    <td>{{ faturamento.qtdParcelas }}</td>
                    <td>{{ faturamento.dataFaturamento | localdatetime }}</td>
                    <td>{{ faturamento.datapedido }}</td>
                    <td>{{ faturamento.date | localdatetime }}</td>
                    <td>{{ faturamento.comissao | currency:'BRL'}} </td>
                    <td>{{ faturamento.precoFinal | currency:'BRL' }}</td>
                    <td>{{ faturamento.totalPago | currency:'BRL' }}</td>
                    <td>{{ faturamento.statusFuncionario }}</td>

                    <td *ngIf="permission.roles.description ==='rhapp'">
                    <p-inputNumber 
                        [(ngModel)]="faturamento.rescisao" 
                        inputId="currency-us" 
                        mode="currency" 
                        currency="BRL" 
                        [disabled]="inputDesableRescisao"
                        locale="en-US"> 
                    </p-inputNumber>
                    </td>
                    <td>{{ faturamento.saldo | currency:'BRL'}}</td>
                    <td *ngIf="permission.roles.description ==='rhapp'">
                        <p-tableCheckbox [value]="faturamento" (click)="enableinputDesableButtonFaturar()"></p-tableCheckbox>
                    </td>
                </tr>

            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="7" class="text-right">{{ taxaAdesao | currency:'BRL' }}</td>
                    <td colspan="6">{{ valorTotalParcela | currency:'BRL' }}</td>
                    <td colspan="1">{{ comissao | currency:'BRL' }}</td>
                    <td colspan="1">{{ totalPrecoFinal | currency:'BRL' }}</td>
                    <td colspan="3">{{ totalPago | currency:'BRL' }}</td>
                    <td colspan="1">{{ saldo | currency:'BRL' }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</div>