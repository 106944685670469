import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, Form } from '@angular/forms';

@Component({
  selector: 'app-reset-senha',
  templateUrl: './reset-senha.component.html',
  styleUrls: ['./reset-senha.component.scss']
})
export class ResetSenhaComponent implements OnInit {
  resetForm = this.formBuilder.group({
    "cpf": ["", Validators.required],
    "text": ["a", Validators.required]
  });
  constructor(
    public formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
  }

}
