<div class="surface-0">
    <p-toast></p-toast>
    <app-menubar></app-menubar>
    <div class="grid">
      <div class="col-2 bg-secondary" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
      </div>

   
    <div class="col-10" [class.col-12]="!navbarVisible">
      <p-progressBar [value]="value"></p-progressBar>
      <div class="card">
        <p-table
          #dt1
          [value]="docPending"
          dataKey="id"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50]"
          currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
          [totalRecords]="totalRecords"
          [lazy]="true"
          (onLazyLoad)="listDocPendingWesign($event)"
          [paginator]="true"
          [loading]="loading"
          scrollDirection="horizontal"
          [scrollable]="true"
          scrollHeight="600px"
          editMode="row"
          [(selection)]="docPendingSelected">
          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
              <div>
                PESQUISAR POR:
                Locação
                <p-columnFilter 
                    [showAddButton]="false" 
                    matchMode="equals" 
                    [showOperator]="false"
                    [matchModeOptions]="[{label: 'igual', value: 'equals'}]"
                    type="text" 
                    field="num_centro_custo"
                    display="menu">
                  </p-columnFilter>
                Orgão
                <p-columnFilter 
                    [showAddButton]="false" 
                    matchMode="contains" 
                    [showOperator]="false"
                    [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
                    type="text" 
                    field="centro_custo"
                    display="menu">
                  </p-columnFilter>

              </div>
              <div>
              <button 
              type="button" 
              pButton pRipple 
              icon="pi pi-file-excel" 
              (click)="exportExcel()" 
              class="p-button-success mr-2"  
              pTooltip="XLS" tooltipPosition="bottom">
            </button>
                <button
                pButton
                pRipple
                pTooltip="Cancelamento assinatura manual" 
                tooltipPosition="left" 
                type="button"
                icon="pi pi-times"
                class="p-button-warning p-button-text p-button-raised"
                (click)="showmodal()"
              ></button>
              </div>
            </div>
        </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 20rem;">
                Empresa
                <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
                    type="text"
                    field="nome_fantasia"
                    display="menu"
                  ></p-columnFilter>
              </th>
              <th style="min-width: 25rem;">
                Nome Funcionário
                <p-columnFilter
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Contém', value: 'contains'}]"
                type="text"
                field="nome"
                display="menu"
              ></p-columnFilter>
              </th>
              <th style="min-width: 10rem;">
                CPF
                  <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    type="text"
                    [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                    field="cpf"
                    display="menu"
                  ></p-columnFilter>
              </th>
              <th>
                Matricula
                <p-columnFilter
                    [showAddButton]="false"
                    [showOperator]="false"
                    type="text"
                    [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                    field="matricula"
                    display="menu"
                  ></p-columnFilter>
              </th>
              <th>Locação</th>
              <th pSortableColumn="fer_ini"  style="min-width: 15rem;">
                Início das Férias
                <p-sortIcon field="fer_ini"></p-sortIcon>
                <p-columnFilter 
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                [showAddButton]="false"
                [showOperator]="false"
                type="text" 
                field="fer_ini" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th pSortableColumn="fer_fim"  style="min-width: 15rem;">
                Fim das Férias
                <p-sortIcon field="fer_fim"></p-sortIcon>
                <p-columnFilter 
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                [showAddButton]="false"
                [showOperator]="false"
                type="text"
                field="fer_fim" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              
              <th pSortableColumn="created_at" >
                Data Envio
                <p-sortIcon field="created_at"></p-sortIcon>
                <p-columnFilter 
                [showAddButton]="false"
                [showOperator]="false"
                [matchModeOptions]="[{label: 'Igual', value: 'equals'}]"
                type="date" 
                field="created_at" 
                display="menu" 
                class="ml-auto"
                ></p-columnFilter>
              </th>
              <th>App</th>
              <th>Not.</th>
              <th>Pdf</th>
              <th>Action</th>
              <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-doc let-ri="rowIndex">
            <tr>
              <td style="min-width: 20rem;">{{ doc.empresas.nome_fantasia }}</td>
              <td style="min-width: 25rem;">{{ doc.usuarios.dados_funcionarios.nome }}</td>
              <td style="min-width: 10rem;">{{ doc.usuarios.dados_funcionarios.cpf | cpf }}</td>
              <td>{{  doc.usuarios.dados_funcionarios.matricula }}</td>
              <td>{{doc.usuarios.dados_funcionarios.num_centro_custo}}</td>
              <td style="min-width: 7rem;">{{ doc.fer_ini }}</td>
              <td style="min-width: 10rem;">{{ doc.fer_fim }}</td>
              <td style="min-width: 8rem;"> {{ doc.created_at | localdate }} </td>
              <td>
                <ng-template [ngIf]="doc?.usuarios?.notifications_tokens?.length > 0" [ngIfElse]="notification">
                  <div class="userYesNotification"></div>
                </ng-template>
                
                <ng-template #notification>
                  <div class="userNotNotification"></div>
                </ng-template>
              </td>
              <td>
                <button
                pButton
                pRipple
                pTooltip="Enviar Notificação" 
                tooltipPosition="left" 
                type="button"
                icon="pi pi-envelope"
                class="p-button-rounded p-button-primary p-button-text p-button-raised"
                (click)="sendNotification(doc?.usuarios?.notifications_tokens)"
              ></button>
              </td>
              <td>
                <button
                  pButton
                  pRipple
                  pTooltip="Aviso de férias" 
                  tooltipPosition="left" 
                  type="button"
                  icon="pi pi-file-pdf"
                  class="p-button-rounded p-button-info p-button-text p-button-raised"
                  (click)="printVacationWarning(doc)"
              ></button>
              </td>
              <td class="flex justify-content-between">
                <button
                  pButton
                  pRipple
                  pTooltip="Editar dados" 
                  tooltipPosition="left" 
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-info p-button-text p-button-raised"
                  (click)="modalEditdataEmploee(doc)">
                </button>
                <button 
                  pButton
                  pRipple
                  pTooltip="Reenviar aviso para assinatura" 
                  tooltipPosition="left" 
                  icon="pi pi-send"
                  class="p-button-rounded p-button-info p-button-text p-button-raised ml-2"
                  (click)="cancelDocWessignBydataInvalid(doc)">
                </button>

                <button
                pButton
                pRipple
                pTooltip="Cancelamento de férias" 
                tooltipPosition="left" 
                type="button"
                icon="pi pi-times"
                class="p-button-rounded p-button-warning p-button-text p-button-raised ml-1"
                (click)="showmodalCancelVacation()">
              </button>

              </td>
              <td style="width: 4rem">
                <p-tableCheckbox [value]="doc"></p-tableCheckbox>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Não foi encontrada nenhuma categoria.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="card flex justify-content-center gap-2">
        <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
      </div>
    </div>

     <!--modal-dialog-cacellation--doc--signature---manual-->
      <p-dialog header="{{this.docPendingSelected[0]?.usuarios?.dados_funcionarios?.nome}}" [(visible)]="visible" [style]="{ width: '25vw' }">
        <div class="grid">
            <div class="col-12 text-center">
              <h2>Você esta cancelando a assinatura digital.</h2>
              <form [formGroup]="docForm">
                <textarea rows="5" cols="35" maxlength="150" pInputTextarea formControlName="motivo_cancelamento"></textarea>
              </form>
              
              <p-fileUpload chooseLabel="Foto" [showUploadButton]=true uploadLabel="Salvar" cancelLabel="Cancelar"
              [customUpload]="true" accept="image/*, application/pdf" [multiple]="false" (uploadHandler)="onUpload($event)">
              <ng-template pTemplate="content">
               <ul *ngIf="uploadedFiles.length">
                   <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
               </ul>
           </ng-template>
            </p-fileUpload>
            </div>
          </div>
      </p-dialog>

      <!--cancelamnto de assinatura e aviso-->
      <p-dialog header="{{this.docPendingSelected[0]?.usuarios?.dados_funcionarios?.nome}}" [(visible)]="visibleCancel" [style]="{ width: '25vw' }">
        <div class="grid">
            <div class="col-12 text-center">
              <h2>Você esta cancelando a assinatura digital e as férias.</h2>
              <form [formGroup]="docForm">
                <textarea rows="5" cols="35" pInputTextarea formControlName="motivo_cancelamento"></textarea>
              </form>
              <p-button 
                label="Confirma Cancelamento" 
                [loading]="loadingCancelDefinitive" 
                (click)="definitiveCancellationDocWessigSignature()" >
              </p-button>
            </div>
          </div>
      </p-dialog>

      <!--modal--edit--dados--invalidos-->
      <p-dialog header="{{docPendingSelected[0]?.usuarios?.dados_funcionarios?.nome}}" [(visible)]="visibleEdit" [style]="{width: '30vw'}">
        <form [formGroup]="editForm">
          <div class="grid">
            <div class="p-inputgroup field flex">
              <div class="col-2">
                <input
                type="text"
                class="flex w-full"
                pInputText
                placeholder="ddd"
                maxlength="2"
                formControlName="ddd_celular"
              />
              </div>
              <div class="col-10">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  maxlength="9"
                  minlength="9"
                  placeholder="Celular"
                  formControlName="celular"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-12">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Email"
                  formControlName="email"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-12">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Nome"
                  formControlName="nome"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <label for="buttondisplay" class="font-bold block mb-2"> Inicio das férias </label>
                <p-calendar 
                  formControlName="fer_ini" 
                  dateFormat="dd/mm/yy"
                  [showIcon]="true" 
                  inputId="buttondisplay" 
                  [showOnFocus]="false" >
              </p-calendar>
              </div>
              <div class="col-6">
                <label for="buttondisplay" class="font-bold block mb-2"> Fim das férias </label>
                <p-calendar 
                  formControlName="fer_fim" 
                  dateFormat="dd/mm/yy"
                  [showIcon]="true" 
                  inputId="buttondisplay" 
                  [showOnFocus]="false" >
              </p-calendar>
                
              </div>
            </div>
          </div>
        </form>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" 
            [disabled]="editForm.status === 'INVALID' ? true : false"
             (click)="editarCadastro()" label="EDITAR" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
  
  </div>
</div>
  