import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
    HttpClient,
    HttpHeaders
  } from '@angular/common/http';
import { StorageService } from "../storage.service";

@Injectable({
    providedIn:'root'
})

export class DashboardService {
    public urlApi = environment.apiUrl;

    constructor(
        private readonly http:HttpClient,
        public storageService: StorageService
    ){}

/* DASHBOARD RHAPP */
    countEmploye(){
        const { token } = this.storageService.getUserLogado();
        const httpOptions = {
          headers: new HttpHeaders({
            'x-access-token': token,
          }),
        };
        
        const url = `${this.urlApi}/dashboard/count/employee`;
        return this.http.get(url, httpOptions);
  }

  countMedicalCertificate(){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    
    const url = `${this.urlApi}/dashboard/count/atestados`;
    return this.http.get(url, httpOptions);
}

countWarning(){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/count/aviso`;
  return this.http.get(url, httpOptions);
}

countAceiptReceipt(){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/count/recibo`;
  return this.http.get(url, httpOptions);
}

countSignature(){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/count/signature`;
  return this.http.get(url, httpOptions);
}

getCompanies(){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/list/companies`;
  return this.http.get(url, httpOptions);
}

searchFiltersDashboard(hasCompanies:any, mesMovimentostart:Date, mesMovimentoEnd:Date){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  const monthMoviment = {
    hasCompanies:hasCompanies,
    mesMovimentostart:mesMovimentostart,
    mesMovimentoEnd: mesMovimentoEnd
  }
  const url = `${this.urlApi}/dashboard/search/month/moviment`;
  return this.http.post(url, monthMoviment, httpOptions);
}

/* DASHBOARD COMMERCIAL */
getRequestPending(){
  const { token, empresa_parceira } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/pending/requests/${empresa_parceira}`;
  return this.http.get(url, httpOptions);
}

getRequestApproved(){
  const { token, empresa_parceira } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/approved/requests/${empresa_parceira}`;
  return this.http.get(url, httpOptions);
}

getRequestCanceled(){
  const { token, empresa_parceira } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/canceled/requests/${empresa_parceira}`;
  return this.http.get(url, httpOptions);
}

getRequestConcluded(){
  const { token, empresa_parceira } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/concluded/requests/${empresa_parceira}`;
  return this.http.get(url, httpOptions);
}

getMarginPositive(){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/margin/positive`;
  return this.http.get(url, httpOptions);
}

getMarginNegative(){
  const { token } = this.storageService.getUserLogado();
  const httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': token,
    }),
  };
  
  const url = `${this.urlApi}/dashboard/margin/negative`;
  return this.http.get(url, httpOptions);
}
}