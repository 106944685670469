import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  Form,
} from '@angular/forms';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss'],
})
export class GroupCreateComponent implements OnInit {
  nomeGrupo: string = '';
  navbarVisible: boolean;
  searchGroup: string = '';
  public userLogado: any = {};
  public results: any;
  groupForm = this.formBuilder.group({
    value: ['4', Validators.required],
  });
  stateOptions: any = [
    {
      label: 'Departamento Pessoal',
      value: '1',
    },
    {
      label: 'RhAPP',
      value: '2',
    },
    {
      label: 'Comercial',
      value: '3',
    },
    {
      label: 'Funcionário',
      value: '4',
    },
  ];
  value2: any = '';
  constructor(
    public api: ApiService,
    public storageService: StorageService,
    public messageService: MessageService,
    public formBuilder: FormBuilder,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
    try {
      this.userLogado = await this.storageService.getUserLogado();
      console.log(this.userLogado);
    } catch (error) {}
    this.obterDados();

    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })

  }
  obterDados() {
    try {
      this.api.getRoles(this.userLogado).subscribe((res: any) => {
        this.results = res;
        if (res.created) {
        }
      });
      // const parse = JSON.parse(res.subscribe)
    } catch (error: any) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary:
          'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
      });
      console.error(error);
    }
  }
  async criarRole() {
    if (this.nomeGrupo != '') {
      try {
        this.api
          .createRole(this.userLogado, this.nomeGrupo)
          .subscribe((res: any) => {
            if (res.created) {
              this.messageService.add({
                severity: 'success',
                summary: 'Perfil criado com sucesso!',
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: res.message,
              });
            }
          });
      } catch (err: any) {
        this.messageService.add({
          severity: 'error',
          summary:
            'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
        });
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Por favor verificar o nome do grupo',
      });
    }
  }
}
