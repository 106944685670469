<div class="surface-0">
  <app-menubar></app-menubar>
  <div class="grid">
    <div class="col-2">
      <app-navbar></app-navbar>
    </div>


    <div class="col-10">
  
<div class="col-12">
  <p-table 
  #dt2 
  [value]="results"
  dataKey="id" 
  [rows]="5" 
  [showCurrentPageReport]="true"
  [rowsPerPageOptions]="[5,10,15]" 
  [loading]="loading" 
  [paginator]="true"
  currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
  [tableStyle]="{'min-width':'100%'}"
   editMode="row" 
  [(selection)]="selectedPerson"
  >
    <ng-template pTemplate="caption">
      <div class="flex align-items-center justify-content-start">
            <form [formGroup]="postForm" novalidate>
              <div class="card flex field ">
                <p-multiSelect formControlName="grupos" [options]="results1" optionLabel="description" optionValue="id"
                  defaultLabel="Selecione o(s) perfil(s)" display="chip"></p-multiSelect>
              </div>
          </form>

          <form [formGroup]="searchForm" novalidate class="col-8 flex">
            <p-dropdown [options]="filters" formControlName="filterSelected" optionLabel="name"
              optionValue="value"></p-dropdown>
          
            <div class="p-inputgroup field flex ml-2" *ngIf="searchForm.controls.filterSelected.value === 'cpf'">
              <input type="text" pInputText placeholder="Informe o CPF do funcionário..." formControlName="cpf">
            </div>
            <div class="p-inputgroup field flex" *ngIf="searchForm.controls.filterSelected.value === 'nome'">
              <input type="text" pInputText placeholder="Informe o NOME do funcionário..." formControlName="nome">
            </div>
            <p-button label="Buscar" icon="pi pi-search" [loading]="isLoading" (click)="searchEmployee()" class="ml-2"></p-button>
          </form>

        </div>
        <p-button label="Adicionar" icon="pi pi-plus" (click)="testeValor(selectedPerson)" class="p-0"></p-button>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th style="width: 100rem;">Nome<p-columnFilter type="text" field="nome" display="menu"></p-columnFilter>
        </th>
        <th>CPF<p-columnFilter type="text" field="cpf" display="menu"></p-columnFilter></th>
        <th>Empresa<p-columnFilter type="text" field="empresa" display="menu"></p-columnFilter></th>
        <th>Função<p-columnFilter type="text" field="empresa" display="menu"></p-columnFilter></th>
        <th>Email<p-columnFilter type="text" field="funcao" display="menu"></p-columnFilter></th>
        <th>Telefone<p-columnFilter type="text" field="telefone" display="menu"></p-columnFilter></th>
        <th>Celular<p-columnFilter type="text" field="celular" display="menu"></p-columnFilter></th>
        <th style="width: 0.1rem;">
          Status
          <p-columnFilter type="text" field="status" display="menu" matchMode="in" [showMatchModes]="false"
            [showOperator]="false" [showAddButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect [ngModel]="value" [options]="results" placeholder="Any" (onChange)="filter($event.value)"
                optionLabel="sitfolha">
                <ng-template let-option pTemplate="item">
                  <div class="p-multiselect-representative-option">
                    <span class="p-ml-1">{{option.sitfolha}}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th style="width: 25px;">Dados</th>

      </tr>

    </ng-template>
    <ng-template pTemplate="body" let-user let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="user">
        <td>
          <p-tableRadioButton [value]="user"></p-tableRadioButton>
        </td>
        <td>
          <span class="image-text">{{user.nome}}</span>
        </td>
        <td>
          <span class="image-text">{{user.cpf}}</span>
        </td>
        <td>
          <span class="image-text">{{user.empresa_fantasia}}</span>
        </td>
        <td>
          <span class="image-text">{{user.funcao}}</span>
        </td>
        <td pEditableColumn>
          <span class="image-text">{{user.email}}</span>
        </td>
        <td>
          <span class="image-text">{{user.telefone}}</span>
        </td>
        <td>
          <span class="image-text">{{user.celular}}</span>
        </td>
        <td>
          {{situacao(user)}}
        </td>
        <td>
          <img src="{{user.verify}}" alt="" class="icon-pendency">
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate=" emptymessage">
      <tr>
        <td colspan="5">No users found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>
  </div>
</div>