import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { DocPendingWesign } from 'src/app/models/docPendingWesign';
import { PayloadNotification } from 'src/app/models/payloadNotification';
import { ApiService } from 'src/app/services/api.service';
import { AwsService } from 'src/app/services/aws/aws.service';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-wesign-pending',
  templateUrl: './wesign-pending.component.html',
  styleUrls: ['./wesign-pending.component.scss']
})
export class WesignPendingComponent implements OnInit {

  docPending: DocPendingWesign[] = [];
  docPendingSelected: any[] = [];
  loading: boolean = false;
  loadingManual: boolean = false;
  loadingCancelDefinitive: boolean = false;
  loadingInvalid: boolean = false;
  visible: boolean = false;
  visibleCancel: boolean = false;
  visibleEdit: boolean = false;
  public navbarVisible: boolean;
  public docForm: FormGroup;
  docCancel = [];
  totalRecords: number = 0;
  loadingButtonAviso: boolean = false;
  editForm: FormGroup;
  uploadedFiles: any[] = [];
  value: number = 0;
  avisoFilter: any[] = [];

  /* Usar o form */

  constructor(
    private vacationService: VacationService,
    private messageService: MessageService,
    public formBuilder: FormBuilder,
    public utilsservices: UtilsService,
    private navbarService: NavbarService,
    private fb: FormBuilder,
    private api: ApiService,
    private confirmationService: ConfirmationService,
    private awsService: AwsService,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.docForm = this.formBuilder.group({
      motivo_cancelamento: ['', [Validators.required]],
    });
    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });

    this.editForm = this.fb.group({
      ddd_celular: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
      celular: ['', [Validators.required, Validators.maxLength(9), Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.email]],
      nome: ['', [Validators.required]],
      fer_ini: ['', Validators.required],
      fer_fim: ['', Validators.required]
    });

    this.avisoFilter = [
      { nome_fantasia: 'nome_fantasia' },
      { nome: 'nome' },
      { cpf: 'cpf' },
      { matricula: 'matricula' },
      { fer_ini: 'fer_ini' },
      { fer_fim: 'fer_fim' },
      { mes_movimento: 'mes_movimento' },
      { warning_sended_at: 'warning_sended_at' },
      { num_centro_custo: 'num_centro_custo' }
    ];
  }

  listDocPendingWesign(event: LazyLoadEvent) {
    this.loading = true;
    this.docPending = [];
    this.totalRecords = 0;
    this.vacationService.getListDocwesignPending(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const promisPending = res.data.map(async (item: any) => {
          this.docPending.push({
            id: item.id,
            id_doc: item.id_doc,
            id_upload: item.id_upload,
            url: item.url,
            embed_url: item.embed_url,
            empresa_fk: item.empresa_fk,
            usuario_fk: item.usuario_fk,
            ferias_cabecalho_fk: item.ferias_cabecalho_fk,
            created_at: item.created_at,
            update_at: await this.utilsservices.parseStringdateFormatMonthYearDay(item.update_at),
            flowactionId: item.flowactionId,
            status: item.status,
            type_doc: item.type_doc,

            //created_at: item.ferias_cabecalhos.created_at,
            aceite_warning: item.ferias_cabecalhos.aceite_warning,
            aceite_receipt: item.ferias_cabecalhos.aceite_receipt,
            sended_receipt: item.ferias_cabecalhos.sended_receipt,
            sended_warning: item.ferias_cabecalhos.sended_warning,
            warning_acceppted_at: item.ferias_cabecalhos.warning_acceppted_at,
            receipt_acceppted_at: item.ferias_cabecalhos.receipt_acceppted_at,
            warning_sended_at: item.ferias_cabecalhos.warning_sended_at,
            receipt_sended_at: item.ferias_cabecalhos.receipt_sended_at,
            fer_ini: item.ferias_cabecalhos.fer_ini,
            fer_fim: item.ferias_cabecalhos.fer_fim,
            base_ini: item.ferias_cabecalhos.base_ini,
            base_fim: item.ferias_cabecalhos.base_fim,
            matricula: item.usuarios.dados_funcionarios.matricula,
            empresas: {
              cnpj: item.empresas.cnpj,
              nome_fantasia: item.empresas.nome_fantasia
            },
            usuarios: {
              notifications_tokens: item.usuarios.notifications_tokens,
              dados_funcionarios: {
                cpf: item.usuarios.dados_funcionarios.cpf,
                id: item.usuarios.dados_funcionarios.id,
                nome: item.usuarios.dados_funcionarios.nome,
                centro_custo: item.usuarios.dados_funcionarios.centro_custo,
                num_ctps: item.usuarios.dados_funcionarios.num_ctps,
                serie_ctps: item.usuarios.dados_funcionarios.serie_ctps,
                matricula: item.usuarios.dados_funcionarios.matricula,
                num_centro_custo: item.usuarios.dados_funcionarios.num_centro_custo,
                celular: item.usuarios.dados_funcionarios.celular,
                ddd_celular: item.usuarios.dados_funcionarios.ddd_celular,
                email: item.usuarios.dados_funcionarios.email
              }
            }
          })
        })

        await Promise.all(promisPending);
        this.totalRecords = res.totalRecords;
      },
      complete: () => { this.loading = false },
      error: (e) => { }
    })
  }

  showmodal() {
    if (this.docPendingSelected.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cancelamento',
        detail: 'Por favor selecionar um item',
      });
    } else {
      this.visible = true;
      this.docPendingSelected.map((item: any) => {
        this.docCancel.push({
          empresa_fk: item.empresa_fk,
          usuario_fk: item.usuario_fk,
          fer_id: item.ferias_cabecalho_fk,
          wesign: {
            id_doc: item.id_doc
          }
        })
      });
    }
  }

  showmodalCancelVacation() {
    if (this.docPendingSelected.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cancelamento',
        detail: 'Por favor selecionar um item',
      });
    } else {
      this.visibleCancel = true;
      this.docPendingSelected.map((item: any) => {
        this.docCancel.push({
          empresa_fk: item.empresa_fk,
          usuario_fk: item.usuario_fk,
          fer_id: item.ferias_cabecalho_fk,
          wesign: {
            id_doc: item.id_doc
          }
        })
      });

    }
  }



  onUpload(event: any) {
    this.value = 60
    this.cancelDocWessignBymanualSignature(event);
  }


  cancelDocWessignBymanualSignature(event: any) {
    if (this.docPendingSelected === undefined) {
      this.visible = false,
        this.messageService.add({
          severity: 'warn',
          summary: 'Cancelamento',
          detail: 'Por favor selecionar um item',
        });

    } else {

      this.loadingManual = true
      const motivo_cancelamento = this.docForm.value.motivo_cancelamento;
      const motivo = 'manual'
      let tokens = [];
      const filterTokens = this.docPendingSelected.filter((item) => item.usuarios.notifications_tokens.length > 0);
      filterTokens.map((item) => {
        tokens.push(item.usuarios.notifications_tokens[0]?.token)
      });
      /* DESATIVAR ENVIO DE TOKEN NOTIFICATION FERIAS */
      const sendToken: PayloadNotification = {
        tokens: [],//tokens,
        notification: {
          title: 'Olá',
          body: 'Férias canceladas, verificar com departamento pessoal'
        },
        data: {
          action: '/ferias',
          intent: 'FERIAS_INTENT'
        }
      }

      this.vacationService.cancelDocVacantion(this.docCancel, motivo_cancelamento, motivo, sendToken).subscribe({
        next: (res: any) => {
          if (res.status === 201 && res.cancelDoc === true && res.notificationSent === true) {
            this.value = 80;
            this.messageService.add({
              severity: 'info',
              summary: 'Férias cancelada',
              detail: res.message,
            });
          }
          if (res.status === 201 && res.cancelDoc === true && res.notificationSent === false) {
            this.value = 80;
            this.messageService.add({
              severity: 'warn',
              summary: 'Férias cancelada',
              detail: res.message,
            });
          }
        },

        error: (err) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Férias cancelada',
            detail: 'Não foi possivél fazer cancelamento de ferias',
          });
        }
      }).add(() => {
        this.awsService.uploadDocManualSignature(this.docCancel, event).subscribe({
          next: (res: any) => {
            this.value = 90
            this.messageService.add({
              severity: 'info',
              summary: 'Férias cancelada',
              detail: 'Envio comṕletado',
            });
          },
          complete: () => {
            this.value = 0;
            this.docCancel = [];
            this.docForm.reset();
            this.visible = false;
            this.loadingManual = false;
            this.listDocPendingWesign({ first: 0, rows: 10 });
          },
          error: () => { }
        })
      })
    }
  }


  definitiveCancellationDocWessigSignature() {
    this.loadingCancelDefinitive = true
    const motivo_cancelamento = this.docForm.value.motivo_cancelamento;
    const motivo = '-'
    let tokens = [];
    const filterTokens = this.docPendingSelected.filter((item) => item.usuarios.notifications_tokens.length > 0);
    filterTokens.map((item) => {
      tokens.push(item.usuarios.notifications_tokens[0]?.token)
    });

    /* DESATIVAR ENVIO DE NOTIFICAÇÔES */
    const sendToken: PayloadNotification = {
      tokens: [],//tokens,
      notification: {
        title: 'Olá',
        body: 'Férias canceladas, verificar com departamento pessoal'
      },
      data: {
        action: '/ferias',
        intent: 'FERIAS_INTENT'
      }
    }

    this.vacationService.definitiveCancellationDocVacantion(this.docCancel, motivo_cancelamento, motivo, sendToken).subscribe({
      next: (res: any) => {
        if (res.status === 201 && res.cancelDoc === true && res.notificationSent === true) {
          this.messageService.add({
            severity: 'info',
            summary: 'Férias cancelada',
            detail: res.message,
          });
        }
        if (res.status === 201 && res.cancelDoc === true && res.notificationSent === false) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Férias cancelada',
            detail: res.message,
          });
        }
      },
      complete: () => {
        this.docCancel = [];
        this.docForm.reset();
        this.docPendingSelected = [];
        this.visible = false,
          this.loadingCancelDefinitive = false
        this.listDocPendingWesign({ first: 0, rows: 10 });
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Férias cancelada',
          detail: 'Não foi possivél fazer cancelamento de ferias',
        });
      }
    })
  }


  cancelDocWessignBydataInvalid(doc: any) {
    this.docPendingSelected = [];

    this.confirmationService.confirm({
      message: 'VOCÊ JA FEZ ATUALIZAÇÂO DDD,  CELULAR E EMAIL',
      header: 'Reenviar Assinatura',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        this.loading = true;
        this.docPendingSelected.push({
          ...doc,
          fer_id: doc.ferias_cabecalho_fk,
          wesign: {
            id_doc: doc.id_doc
          }
        }
        )

        this.loadingInvalid = true;
        const motivo = '-';
        const motivo_cancelamento = 'Cancelamento por dados inválido, o documento será reenviando novamente!';
        let tokens = [];
        const filterTokens = this.docPendingSelected?.filter((item) => item.usuarios?.notifications_tokens.length > 0);
        filterTokens?.map((item) => {
          tokens.push(item.usuarios.notifications_tokens[0]?.token)
        });

        const sendToken: PayloadNotification = {
          tokens: tokens,
          notification: {
            title: 'Olá',
            body: 'Aviso reenviado com sucesso'
          },
          data: {
            action: '/ferias',
            intent: 'FERIAS_INTENT'
          }
        }
        this.vacationService.cancelDocdataInavalid(this.docPendingSelected, motivo_cancelamento, motivo, sendToken).subscribe({
          next: (res: any) => {
            if (res.status === 201 && res.cancelDoc === true && res.notificationSent === true) {
              this.messageService.add({
                severity: 'info',
                summary: 'Férias cancelada',
                detail: res.message,
              });
            }
            if (res.status === 201 && res.cancelDoc === true && res.notificationSent === false) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Férias cancelada',
                detail: res.message,
              });
            }
          },
          complete: () => {
            this.docCancel = [];
            this.docForm.reset();
            this.visible = false,
              this.loadingInvalid = false;
            this.loading = false;
            this.docPendingSelected = [];
            this.listDocPendingWesign({ first: 0, rows: 10 });
          },
          error: (err) => {
            console.log(err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Férias cancelada',
              detail: 'Não foi possivél fazer cancelamento de ferias',
            });
          }
        })
      },
      reject: (type: any) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Procedimento cancelado' });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({ severity: 'warn', summary: 'Cancelled', detail: 'Procedimento cancelado' });
            break;
        }
      },
      acceptLabel: 'Reenviar',
      rejectLabel: 'Fechar'
    });
  }




  sendNotification(sendnotification: any) {
    this.loading = true;
    const notification: PayloadNotification = {
      tokens: [sendnotification[0].token],
      notification: {
        "title": "Olá",
        "body": "Seu aviso de férias está disponível no aplicativo."
      },
      data: {
        action: "/ferias",
        intent: "FERIAS_INTENT"
      }
    }
    this.vacationService.sendNotificationVacation(notification).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.messageService.add({
          severity: 'info',
          summary: 'Notificação',
          detail: res.message,
        });
      },
      complete() {
        this.loading = false;
      },
      error(err) {
        this.loading = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Notificações',
          detail: 'Não foi possivél enviar notificação',
        });
      },
    })
  }


  async printVacationWarning(doc: any) {
    this.loading = true;
    const docPrint = { ...doc, id: doc.ferias_cabecalho_fk }
    this.vacationService.getLayoutPdfAvisoFerias([docPrint]).subscribe({
      next: (res: any) => {
        const blob = new Blob([new Uint8Array(res.pdfBUffer.data)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document;
        a.click();
      },
      complete: () => {
        this.loading = false;

      },
      error: () => { }
    });

  }

  async modalEditdataEmploee(doc: any) {
    this.editForm.reset()
    const ferIini = await this.utilsService.formatDate(doc.fer_ini);
    const ferFim = await this.utilsService.formatDate(doc.fer_fim);
    const email = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.email);
    const nome = await this.utilsService.clearEmailNomeSpaceInWhite(doc.usuarios.dados_funcionarios.nome);
    this.docPendingSelected = [doc]
    this.editForm.controls['nome'].setValue(nome);
    this.editForm.controls['ddd_celular'].setValue(doc.usuarios.dados_funcionarios.ddd_celular);
    this.editForm.controls['celular'].setValue(doc.usuarios.dados_funcionarios.celular);
    this.editForm.controls['email'].setValue(email);
    this.editForm.controls['fer_ini'].setValue(ferIini);
    this.editForm.controls['fer_fim'].setValue(ferFim);
    this.visibleEdit = true
    console.log(this.docPendingSelected);
    
  }

  /* FAZER ALTERAÇÔES AQUI */
  async editarCadastro() {
    if(this.editForm.status === 'INVALID'){
      this.messageService.add({ severity: 'warn', summary: 'Aviso', detail: 'Todos os campos são obrigatório' });
    }else{
      this.loading = true
      const data = {
        ddd_celular: this.editForm.value.ddd_celular,
        celular: this.editForm.value.celular,
        email: this.editForm.value.email,
        nome: this.editForm.value.nome,
        fer_ini: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_ini),
        fer_fim: await this.utilsService.formatDateSubtractDay(this.editForm.value.fer_fim),
        funcId: this.docPendingSelected[0].usuarios.dados_funcionarios.id,
        ferId: this.docPendingSelected[0].ferias_cabecalho_fk
      }
      this.api.updateEmployee(data).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.messageService.add({
            severity: 'info',
            summary: 'Editar cadastro',
            detail: res.message,
          });
        },
        complete: () => {
          this.loading = false
          this.docPendingSelected = []
          this.editForm.reset()
          this.visibleEdit = false
         this.listDocPendingWesign({ first: 0, rows: 10 });
        },
        error: (error) => {
          console.log(error);
          
         }
      })

    }

  }


  async exportExcel() {
    this.loading = true;
    this.docPending = [];
    const event = { first: 0, rows: this.totalRecords }
    this.vacationService.getListDocwesignPending(event, this.totalRecords).subscribe({
      next: async (res: any) => {
        const listVacation = res.data.map(async (item: any) => {
          this.docPending.push({
            ...item,
          })
        })

        await Promise.all(listVacation);
        this.totalRecords = res.totalRecords;

      },
      complete: () => {
      },
      error: (error) => {
        console.log(error);
      },
    }).add(() => {
      this.utilsService.exportExcell(this.avisoFilter, this.docPending);
      this.docPending = [];
      this.loading = false;
      this.listDocPendingWesign({ first: 0, rows: 10 });
    });

  }

}
