
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private navbarVisibleSubject = new BehaviorSubject<boolean>(true);
  navbarVisible$ = this.navbarVisibleSubject.asObservable();

  setNavbarVisible(visible: boolean) {
    this.navbarVisibleSubject.next(visible);
  }
}
