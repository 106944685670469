import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { ProductService } from 'src/app/services/shop/product.service';
import { Posts } from 'src/app/models/posts';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-listar-post',
  templateUrl: './listar-post.component.html',
  styleUrls: ['./listar-post.component.scss'],
})
export class ListarPostComponent implements OnInit {
  public posts: Posts[] = [];
  imgSrc: string[] = [];
  loading: boolean = false;
  user:any;
  navbarVisible:boolean;


  constructor(
    public api: ApiService,
    public apiShopService: ProductService,
    public storageService: StorageService,
    public messageService: MessageService,
    private navbarService: NavbarService
  ) {}

  ngOnInit(): void {
    this.user = this.storageService.getUserLogado();
    this.listPots()


    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  contact({ action }: any) {
    window.open(action, '_system', 'location=yes');
    return false;
  }

  
  listPots(){
    this.loading = true
    this.posts = [];
    this.apiShopService.listPostsProducts().subscribe({
      next: (res:any) => {
        res.map((item:any) => {
          this.posts.push({
            empresa: item.posts_empresas.map((em:any) => {
              return em.empresas.nome_fantasia
            }),
            empresasId:  item.posts_empresas.map((em:any) => {
              return em.id
            }),
            imgPost: `${this.api.urlApi}/aws/s3img/${item.posts_empresas[0].empresa_fk}/${this.user.id}?img=${item.img}&token=${this.user.token}`,
            postId: item.id
          })
        })
      },
      complete: () => {this.loading = false},
      error: (error) => {}
    });
  }

  deletePost(empresa:any, postId:number){
    this.loading = true
    this.apiShopService.deleteBannerProduct(empresa, postId).subscribe({
      next: (res:any) => {
        console.log(res);
        this.messageService.add({
          severity: 'success',
          summary: res.message,
        });
      },
      complete: () => {
        this.loading = false;
        this.listPots();
      },
      error: (err) => {
        this.loading = false;
        this.messageService.add({
          severity: 'warn',
          summary: 'Não foi possível deletar post',
        });
      }
    })
  }
  

}
