import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AppServices } from 'src/app/services/app/app.services';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class AppVersionComponent implements OnInit {
  appFrom: FormGroup;
  navbarVisible: boolean;
  
  list: any[] = [];
  constructor(
    private formBuild: FormBuilder,
    private messageService: MessageService,
    private appService: AppServices,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    this.appFrom = this.formBuild.group({
      url_android:['', [Validators.required, Validators.maxLength(200)]],
      url_ios: ['', [Validators.required, Validators.maxLength(200)]],
      version_android: ['', Validators.required],
      version_ios: ['', Validators.required],
      id:['', Validators.required]
    })

    this.listVersion();

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  saveAppversion(){
    this.appService.saveAppVersion(this.appFrom.value).subscribe({
      next:(value:any) => {
        this.messageService.add({
          severity: 'success',
          summary: value.message,
        });
      },
      complete() {
        this.listVersion();
      },
      error(err) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Não foi possivel fazer o registro de versão',
        });
      },
    })
  }

async  listVersion(){
    this.list = [];
    this.appService.listVersion().subscribe({
      next: (res:any) => {
        this.list = res.data;
        this.appFrom.controls['id'].setValue(res.id);
        this.appFrom.controls['url_android'].setValue(res.url_android);
        this.appFrom.controls['url_ios'].setValue(res.url_ios);
        this.appFrom.controls['version_android'].setValue(res.version_android);
        this.appFrom.controls['version_ios'].setValue(res.version_ios);
      },
      complete: () => {},
      error: () => {}
    })
  }

}
