<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>

    <div class="grid">
        <div class="col-2"  *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
        <p-table #dt1 [value]="produtos" dataKey="id" [rows]="50" [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 50, 100]" [loading]="loading" [paginator]="true"
            [globalFilterFields]="['id', 'descricao']" style="z-index: 0">

            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:15rem">
                        <div class="flex align-items-center">
                            ID do produto
                            <p-columnFilter type="text" field="id" display="menu"></p-columnFilter>
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex align-items-center">
                            Titulo do produto
                            <p-columnFilter type="text" field="descricao" display="menu"></p-columnFilter>
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex align-items-center">
                            Numero de parcelas
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex align-items-center">
                            Valor mensal
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex align-items-center">
                            Preço total do produto
                        </div>
                    </th>
                    <th style="min-width:15rem">
                        <div class="flex align-items-center">
                            Status
                        </div>
                    </th>

                    <th style="min-width:10rem">
                        <div class="flex align-items-center">
                            Ações
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-produto let-rowIndex="rowIndex">
                <tr>
                    <td>{{ produto.id }}</td>
                    <td>{{ produto.description }}</td>
                    <td>{{ produto.number_plots}}</td>
                    <td>{{ produto.valor_parcela | currency: 'BRL'}}</td>
                    <td>{{ produto.price | currency: 'BRL'}}</td>
                    <td>{{ produto.isActive | statusProduto}}</td>
                    <td>
                        <button 
                        pButton type="button" 
                        pRipple icon="pi pi-pencil" 
                        class="p-button-rounded p-button-text"
                        (click)="editProduct(produto, rowIndex)"
                        [loading]="loadingModalProduct === rowIndex"></button>
                    </td>
                </tr>

            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não foi encontrada nenhuma catgoria.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>


    <!-- Modal de Edição do usuário -->
    <div class="col-12">
        <p-dialog header="Atualizar cadastro do produto" [(visible)]="showModalEditProduct" [style]="{ width: '70vw' }"
            *ngIf="showModalEditProduct">
            <form [formGroup]="editingProductForm" novalidate>
                <div class="grid">
                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon"> Nome Produto </span>
                        </div>
                        <div class="col-6">
                            <input type="text" class="flex w-full" pInputText placeholder="Descrição"
                                formControlName="product_description" />
                        </div>
                    </div>

                  
                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon"> Número de Parcelas </span>
                        </div>
                        <div class="col-6">
                            <input type="number" class="flex w-full" pInputText placeholder="Número de Parcelas"
                                formControlName="product_number_plots" />
                        </div>
                    </div>
                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon"> Valor Mensal </span>
                        </div>
                        <div class="col-6">
                            <input type="number" class="flex w-full" pInputText placeholder="Valor Mensal"
                                formControlName="product_monthly_discount" />
                        </div>
                    </div>
                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon"> Preço total do Produto </span>
                        </div>
                        <div class="col-6">
                            <input type="number" class="flex w-full" pInputText placeholder="Valor Mensal"
                                formControlName="product_price" />
                        </div>
                    </div>

                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon">Taxas</span>
                        </div>
                        <div class="col-2">
                            <span class="p-float-label">
                                <p-inputNumber 
                                inputId="integeronly" 
                                formControlName="taxa_adm"  
                                currency="BRL" 
                                locale="en-US"> 
                                </p-inputNumber>
                                <label htmlFor="number-input">Taxa administrativa</label>
                            </span>
                        </div>
                        <div class="col-2">
                            <span class="p-float-label">
                                <p-inputNumber inputId="integeronly" formControlName="comissao"> </p-inputNumber>
                                <label htmlFor="number-input">Comissão</label>
                            </span>
                        </div>
                        <div class="col-2">
                            <span class="p-float-label">
                                <p-inputNumber inputId="integeronly" formControlName="taxa_adesao"> </p-inputNumber>
                                <label htmlFor="number-input">Taxa adesão</label>
                            </span>
                        </div>
                    </div> 

                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon"> Descrição produto</span>
                        </div>
                        <div class="col-6">
                            <div class="card flex justify-content-center">
                                <textarea  rows="2" cols="30" pInputTextarea  formControlName="details_product"></textarea>
                            </div>
                        </div>
                    </div>

                    
                    <div class="p-inputgroup field flex">
                        <div class="col-6">
                            <span class="p-inputgroup-addon"> Status</span>
                        </div>
                        <div class="col-1">
                            <p-toggleButton formControlName="isActive" onLabel="Ativo" offLabel="Inativo"
                                offIcon="pi pi-times" onIcon="pi pi-check">
                            </p-toggleButton>
                        </div>
                    </div>   

                </div>
            </form>
            <ng-template pTemplate="footer" class="flex">
                <p-button icon="pi pi-save" label="Atualizar" class="mt-4" [loading]="loadingUpdateProduct"
                    (click)="saveProductDb()">
                </p-button>
            </ng-template>
        </p-dialog>
    </div>
</div>
</div>