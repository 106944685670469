<!-- body da página -->
<div class="h-screen flex align-items-center justify-content-center">
  <div class="grid">
    <div class="col-12 flex flex-auto">
      <p-toast></p-toast>
    </div>
    <div class="col-5 flex align-items-center justify-content-center ">
      <img src="../../../assets/img/logo_transparente.png" class="img-logo">
    </div>
    <div class="col-2 flex align-items-center justify-content-center">
      <div class="v1"></div>
    </div>
    <div class="col-5 flex ">
      <p-card [style]="{width: '150%', height: '100%'}">
        <ng-template pTemplate="title">
          <p class="flex align-items-center justify-content-center text-blue-400">
            LOGIN
          </p>
        </ng-template>
        <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
          <!-- <div class="p-inputgroup field flex">

            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>

            <input type="text" pInputText placeholder="CPF" formControlName="cpf">
          </div> -->
          <div class="p-inputgroup field flex">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <p-inputMask class="flex w-full" mask="999.999.999-99" formControlName="cpf"
              placeholder="Login"></p-inputMask>
          </div>

          <div class="p-inputgroup field flex">
            <span class="p-inputgroup-addon"><i class="pi pi-lock"></i></span>
            <input type="password" pInputText placeholder="Senha" formControlName="senha" autocomplete="on">
          </div>

          <!--<div class="col-12 flex align-items-center justify-content-center align-items-stretch">
            <a [routerLink]="['/reset-senha']" class="no-underline">Esqueceu sua senha? Resete aqui!</a>
          </div> -->

          <div class="flex card-container">
            <div
              class="flex-grow-1 flex-shrink-1 flex align-items-center justify-content-center bg-blue-500 font-bold text-white p-0 m-0 border-round">
              <p-button label="Entrar" iconPos="right" icon="pi pi-sign-in" [loading]="gettingRequest"
                (onClick)="login()">
              </p-button>
            </div>

          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>