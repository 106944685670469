import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ViaCepServiceService {
  constructor(public http: HttpClient) {}

  getCep(cep: string) {
    let url = `http://viacep.com.br/ws/${cep}/json/`;
    return this.http.get(url);
  }
}
