import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-list-vacation-sended',
  templateUrl: './list-vacation-sended.component.html',
  styleUrls: ['./list-vacation-sended.component.scss'],
})
export class ListVacationSendedComponent implements OnInit {
  public vacations: any[]= [];
  public selectedVocationConcluded: any [] = []
  public loading: boolean;
  public navbarVisible: boolean;
  public totalRecords:number = 0;
  constructor(
    public vacationService: VacationService,
    public utilsService: UtilsService,
    private navbarService: NavbarService,
    private messageService: MessageService
    ) {}

  ngOnInit(): void {
    
    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  listVacationsSended(event:LazyLoadEvent){
    this.vacations = [];
    this.loading = true;
    this.vacationService.getVacationsSended(event, this.totalRecords).subscribe({
      next: async (res:any) => {
        const promiseVacationSend = res.fer.map(async (item:any) => {
          this.vacations.push({
            ...item
          })
        })

        await Promise.all(promiseVacationSend);
        this.totalRecords = res.totalRecords
      },
      complete: () => {
        this.loading = false;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  async printVacationConclued(vacation) {
    
    console.log(vacation);
    
    if(vacation){
      this.selectedVocationConcluded = [vacation];
      this.loading = true;
      this.vacationService.getLayoutPdfReciboFerias(this.selectedVocationConcluded).subscribe({
        next: (res:any) => {
          const blob = new Blob([new Uint8Array(res.pdfBUffer.data)], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = res.document; 
            a.click();
        },
        complete: () => {
          this.loading = false;
          this.selectedVocationConcluded = [];
         
        
        },
        error: () => {}
      })
    }else{
      this.messageService.add({
        severity: 'info',
        summary: 'Férias',
        detail: 'Aguardar pagamento de férias',
      });
    }
  }
}
