import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Company } from 'src/app/models/company';
import { Faturamento } from 'src/app/models/faturamento';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-relatorio-financeiro-adesao-detalhado',
  templateUrl: './relatorio-financeiro-adesao-detalhado.component.html',
  styleUrls: ['./relatorio-financeiro-adesao-detalhado.component.scss']
})
export class RelatorioFinanceiroAdesaoDetalhadoComponent implements OnInit {
  financeiroDetalhado: Faturamento[] = [];
  financeiroDetalhadoSelected: Faturamento[] = [];
  loading: boolean = false;
  filterExecel: Faturamento[] = [];
  companies: Company[] = [{name:'Todas as Parceiras', id:0}];
  clients: Company[] = [{name:'Todas os Clientes', idClient:0}];
  selectedCompanies: Company | undefined;
  selectedClient: Company | undefined
  date: Date;
  dataFechementoSeleted: any
  newDateFechamento: any;
  items: MenuItem[];
  navbarVisible:boolean;

  constructor(
    private apiService: ApiService,
    private messageserce: MessageService,
    private navbarService: NavbarService
    ) { }

  ngOnInit(): void {
    let dataFechamento = new Date();
    dataFechamento.setDate(15);
    dataFechamento.setMonth(8);
    dataFechamento.setFullYear(2023);
    let dia = dataFechamento.getDate().toString().padStart(2, '0');
    let mes = (dataFechamento.getMonth() + 1).toString().padStart(2, '0');
    let ano = dataFechamento.getFullYear();
    this.newDateFechamento = `${ano}-${mes}-${dia}`;

    this.getRelatorioFinanceiroDetalhado();
    this.getAllCompanies();

    this.items = [
      {
          label: 'Relatório Financeiro detalhado',
          icon: 'pi pi-file-pdf',
          command: () => {
              this.exportFinanceiroAdesaoDetalhadoPdf();
          }
      },
      {
        label: 'Relatório financeiro resumido',
        icon: 'pi pi-file-pdf',
        command: () => {
            this.exportFinanceiroAdesaoResumidoPdf();
        }
    }
  ];

  this.navbarService.navbarVisible$.subscribe(show => {
    this.navbarVisible = show;
  });
  }


  onChangeDateSeleted(){
    let diaSelected = this.date?.getDate().toString().padStart(2, '0');
    let mesSeleted = (this.date?.getMonth() + 1).toString().padStart(2, '0');
    let anoSelected = this.date?.getFullYear();
    this.newDateFechamento = `${anoSelected}-${mesSeleted}-${diaSelected}`;
  }

  getRelatorioFinanceiroDetalhado(){
    this.loading = true
    let pesquisar = {
      id: this.selectedCompanies?.id || 0,
      idCliente: this.selectedClient?.idClient || 0,
      dateFechamento: this.newDateFechamento
    }
    this.apiService.relatorioFinananceiroConveniosDetalhado(pesquisar, this.selectedCompanies).subscribe((res: any) => {
      this.financeiroDetalhado = [];
      res.requests.map((item:any) => {
        this.financeiroDetalhado.push({
        id: item.id,
        cpf: item.cpf,
        datapedido: item.data_pedido,
        date: item.data_primeiro_desconto,
        nomefuncionairo: item.funcionario,
        descricaoProduto: item.produto,
        empresa: item.empresa,
        loja: item.razao_social,
        dataFaturamento: item.data_faturamento,
        precoFinal: item.total,
        qtdParcelas: item.qtd_parcelas,
        valorParcela: item.valor_parcela,
        parcelasPaga: item.parcelas_paga,
        statusFuncionario: item.status_funcionario,
        rescisao: item.rescisao,
        saldo: item.saldo,
        totalPago: item.total_pago,
        empresaClientfk: item.empresa_client_fk,
        comissao: item.comissao,
        code: item.code,
        taxaAdesao: item.taxaAdesao
        })
      })
    }).add(() => {
      this.loading = false;
    })
  }
  

  exportFinanceiroAdesaoDetalhadoPdf(){
    if(this.financeiroDetalhado.length > 0){
      const titulo = 'RELATÓRIO FINANCEIRO DE  CONVÊNIOS / DETALHADO';
      this.apiService.relatorioAdesaoPdfDetalhado(this.financeiroDetalhado, titulo).subscribe((res:any) => {
        const byteCharacters = atob(res.body);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = res.document; 
        a.click();
      }).add(() => {
        this.financeiroDetalhado = [];
      })
    }else{
      this.messageserce.add({ severity: 'warn', summary: 'Relatório', detail: 'Por favor realizar a pesquisa' })
    }
  }


async  exportFinanceiroAdesaoResumidoPdf(){
   const adesoes = await this.formatDataPdfResumo();
   if(adesoes.length > 0){
    const titulo = 'RELATÓRIO FINANCEIRO DE CONVÊNIOS / RESUMIDO';
     this.apiService.relatorioAdesaoPdfResumo(adesoes, this.selectedCompanies, titulo).subscribe((res:any) => {
       const byteCharacters = atob(res.body);
       const byteNumbers = new Array(byteCharacters.length);
       for (let i = 0; i < byteCharacters.length; i++) {
         byteNumbers[i] = byteCharacters.charCodeAt(i);
       }
       const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });
       const url = URL.createObjectURL(blob);
       const a = document.createElement('a');
       a.href = url;
       a.download = res.document; 
       a.click();
     }).add(() => {
       this.financeiroDetalhado = [];
     })
   }else{
    this.messageserce.add({ severity: 'warn', summary: 'Relatório', detail: 'Por favor realizar a pesquisa' })
   }
  }

  getAllCompanies(){
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{
    const filterCompanies = res.filter(item =>  item.tipo === 'Parceira')
    const filterClients = res.filter(item => item.tipo === 'Cliente')

    filterClients.map(item => {
      this.clients.push({
        name: item.nome_fantasia,
        idClient: item.id
      })
    })

    filterCompanies.map((item) => {
      this.companies.push({
        name: item.nome_fantasia,
        id: item.id
      })
    })

    })
  }

  async  formatDataPdfResumo(){
    const adesaoDetalhado = [];
    let   valorTotal:number=0;
    let   totalComissao:number=0;
    let   empresa:string;
  
        this.clients.map((client) => {          
          const countTotal = this.financeiroDetalhado.filter((adesao) => client.idClient === adesao.empresaClientfk);
          let totalAdeoes = countTotal.length
          countTotal.map((item) => {
              valorTotal = totalAdeoes * item.valorParcela,
              empresa= item.empresa,
              totalComissao = countTotal.length * item.comissao
          })
          
          
          if(totalAdeoes > 0){
            adesaoDetalhado.push({
              empresa: empresa,
              totalAdeoes: totalAdeoes,
              valorTotal:valorTotal,
              totalComissao: totalComissao
            })
          }
        })
      
       return adesaoDetalhado
    }

}
