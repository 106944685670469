<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>
    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>
        
        <div class="col-10" [class.col-12]="!navbarVisible">
            <form [formGroup]="postForm" novalidate>

                    <p-multiSelect class="mr-2" [options]="empresas" formControlName="companys" optionLabel="nome_fantasia"
                        optionValue="id" defaultLabel="Selecione as empresas" display="chip">
                    </p-multiSelect>
                
             
                    <p-multiSelect class="mr-2" [options]="productsCategories" formControlName="category" optionLabel="description"
                        optionValue="id" defaultLabel="Selecione as empresas" display="chip">
                    </p-multiSelect>

                    <p-dropdown class="mr-2" formControlName="product" [options]="allProducts" optionLabel="description"></p-dropdown>

                    <input type="text" pInputText formControlName="action" class="mr-2" placeholder="Action url"/>

                <div class="mt-2">
                    <p-fileUpload  
                    chooseLabel="Foto" 
                    [showUploadButton]=true 
                    uploadLabel="Publicar" 
                    cancelLabel="Cancelar"
                    [multiple]="false"
                    [customUpload]="true"
                     accept="image/*" 
                     (uploadHandler)="post($event)">
                    </p-fileUpload>

                </div>
            </form>
        </div>
    </div>
</div>