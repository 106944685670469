import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusConvenioParceiro'
})
export class StatusConvenioParceiroPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let ret = {
      "P": "PENDENTE",
      "A": "ANALISANDO",
      "F": "FATURADO",
      "C": "CANCELADO"
    };

    return ret[value]
  }

}
