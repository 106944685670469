
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { StorageService } from './../storage.service';
import { DataService } from './../data.service';

@Injectable({
  providedIn: 'root',
})
export class AppServices {
 
  urlApi = environment.apiUrl;
  
  constructor(
    public http: HttpClient,
    public storageService: StorageService,
    public dataService: DataService,
    public serviceLocalStorage: StorageService
  ) { }

 
  public saveAppVersion(app:any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/app/version/${app.id}`;
    return this.http.put(url, {app:app}, httpOptions);
  }


  public listVersion() {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/app/list/version`;
    return this.http.get(url, httpOptions);
  }
  }

 
 
    


