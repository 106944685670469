import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusFeriasEnvio',
})
export class StatusFeriasEnvioPipe implements PipeTransform {
  transform(value: boolean, ...args: unknown[]): unknown {
    return value ? 'Enviado' : 'Não enviado';
  }
}
