import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { NavbarService } from 'src/app/services/navbar-service';
@Component({
  selector: 'app-gerenciar-categorias-loja',
  templateUrl: './gerenciar-categorias-loja.component.html',
  styleUrls: ['./gerenciar-categorias-loja.component.scss'],
})
export class GerenciarCategoriasLojaComponent implements OnInit {
  public userLogado: any;
  public checked: boolean = true;
  navbarVisible:boolean;
  // editing: boolean = false;
  public clonedCategories: { [s: string]: any } = {};
  constructor(
    public api: ApiService,
    public storageService: StorageService,
    private messageService: MessageService,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
    try {
      this.userLogado = await this.storageService.getUserLogado();
      console.log(this.userLogado);
      this.carregarCategoriasDeLoja();
      this.loading = false;
    } catch (err: any) {
      console.log(err);
    }


    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }
  categoriasProdutos: any[] = [];

  representatives: any[];

  statuses: any[];

  loading: boolean = true;

  activityValues: number[] = [0, 100];
  clear(table: Table) {
    table.clear();
  }

  carregarCategoriasDeLoja() {
    this.categoriasProdutos = [];
    this.api.listCategories(this.userLogado.token)
      .subscribe((response: any) => {
        response.forEach((item: any) => {
          this.categoriasProdutos.push({
            id: item.id,
            descricao: item.description,
            status: item.status
          });
        });
      });
  }
  deletarCategoria(idCategoria: any) {
    this.loading = true;
    try {
      this.api
        .deletarCategoriaDaLoja(this.userLogado.token, idCategoria)
        .subscribe((response: any) => {
          if (response.deleted) {
            this.loading = false;
            this.carregarCategoriasDeLoja();
            return this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: response.message,
            });
          } else {
            this.carregarCategoriasDeLoja();
            this.loading = false;
            return this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: response.message,
            });
          }
        });
    } catch (error) {
      this.loading = false;
      this.carregarCategoriasDeLoja();
      return this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'Erro interno do servidor por favor tentar novamente mais tarde',
      });
    }
  }
  onRowEditInit(categoria: any) {
    console.log(categoria);
    this.clonedCategories[categoria.id] = { ...categoria };
  }
  onRowEditSave(categoria: any) {
    this.loading = true;

    console.log(categoria);
    this.api
      .editarCategoriaDeLoja(this.userLogado.token, categoria)
      .subscribe((response: any) => {
        if (response.edited) {
          this.loading = false;
          this.carregarCategoriasDeLoja();
          return this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: response.message,
          });
          delete this.clonedCategories[categoria];
        } else {
          this.carregarCategoriasDeLoja();
          this.loading = false;
          delete this.clonedCategories[categoria];
          return this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: response.message,
          });
        }
      });
  }

  onRowEditCancel(categoria: any, index: number) {
    this.categoriasProdutos[index] = this.clonedCategories[categoria.id];
    console.log(this.clonedCategories[index]);
    delete this.clonedCategories[index];
  }

  updateStatusCategory(category:any){
    this.loading = true;
    this.api.updateCategoryStatusLoja(this.userLogado.token, category).subscribe({
      next: (res:any) => {
        return this.messageService.add({
          severity: 'info',
          summary: 'Categoria',
          detail: res.message,
        });
      },
      complete: () => {
        this.carregarCategoriasDeLoja();
        this.loading = false;
      },
      error: () => {
        return this.messageService.add({
          severity: 'error',
          summary: 'Cateegoria',
          detail: 'Error desabilitar categoria',
        });
      }
    })
  }
}
