<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>

    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>
        
        <div class="col-10" [class.col-12]="!navbarVisible">
            <p-panel header="Criação de perfil">
                <div class="col-12 flex-column">
                    <span class="p-float-label">
                        <input type="text" id="nomeGrupo" pInputText [(ngModel)]="nomeGrupo">
                        <label htmlFor="nomeGrupo">Nome do perfil</label>

                    </span>
                </div>
                <div class="col-12 flex-column">

                    <form [formGroup]="groupForm" novalidate>
                        <p-selectButton [options]="stateOptions" formControlName="value" optionLabel="label"
                            optionValue="value" [multiple]="true"></p-selectButton>
                    </form>
                </div>
                <div class="col-12 flex-column">
                    <button id="btnCreate" pButton pRipple class="p-button-success" (click)="criarRole()">
                        <i class="pi pi-plus" style="font-size: 1rem"></i>
                        Salvar
                    </button>
                </div>
            </p-panel>
        </div>
        


    </div>
    <!-- Excluir Grupo -->
</div>