import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'celular'
})
export class CelularPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value?.length === 11)
      return `(${value.substring(0, 2)})${value.substring(2, 7)}-${value.substring(7, 11)}`;
    return ''
  }
}
