import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Faturamento } from 'src/app/models/faturamento';
import { ApiService } from 'src/app/services/api.service';
import { Column } from 'src/app/models/column';
import { ExportColumn } from 'src/app/models/exportcolumn';
import * as FileSaver from 'file-saver';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-pedido-faturado',
  templateUrl: './pedido-faturado.component.html',
  styleUrls: ['./pedido-faturado.component.scss']
})
export class PedidoFaturadoComponent implements OnInit {
  faturamentos: Faturamento[] = [];
  selectedFaturamento: Faturamento[] = [];
  loading: boolean = false;
  inputDesableRescisao = true;
  inputDesableButtonFaturar = true;
  cols: Column[] = [];
  selectedCols: Column[] = [];
  exportColumn: ExportColumn[] = [];
  filterExecel: Faturamento[] = [];
  permission:any;
  filtroCpf: string;
  totalRecordOrder: number;
  totalPrecoFinal: number = 0;
  valorTotalParcela: number = 0;
  totalPago: number = 0 ;
  comissao: number = 0;
  saldo: number = 0 ;
  taxaAdesao: number = 0;
  navbarVisible:boolean;


  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private storageService: StorageService,
    private utilsService: UtilsService,
    private navbarService: NavbarService
  ) { }

  ngOnInit(): void {
    this.getPedidosFAturado()
    this.permission = this.storageService.getStoragePeermission();

    this.cols = [
      {field: 'loja', header: 'Loja' },
      {field: 'empresa', header: 'Empresa' },
      {field: 'nomefuncionairo', header: 'Funcionário' },
      {field: 'cpf', header: 'Cpf' },
      {field: 'descricaoProduto', header: 'Produto'},
      {field: 'valorParcela', header: 'V.parcela' },
      {field: 'parcelasPaga', header: 'Q.P.Paga'},
      {field: 'qtdParcelas', header: 'Qtd.Parcelas' },
      {field: 'dataFaturamento', header: 'D.faturamento'},
      {field: 'datapedido', header:  'D.pedido'},
      {field: 'date', header: '1º Mês(desc)'},
      {field: 'precoFinal', header: 'Total'},
      {field: 'totalPago', header: 'T.Pago'},
      {field: 'statusFuncionario', header:'S.funcionário'},
      {field: 'saldo', header: 'Saldo'},
    ];

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  async getPedidosFAturado() {
    this.loading = true
    this.apiService.getPedidosFaturados().subscribe(
      (res: any) => {
        res.requests.map((item: any) => {
          this.totalPrecoFinal += item.total;
          this.valorTotalParcela += item.valor_parcela;
          this.totalPago += item.total_pago;
          this.comissao +=   item.comissao;
          this.saldo  += item.saldo;
          this.taxaAdesao += item.taxaAdesao;

          this.faturamentos.push({
            id: item.id,
            cpf: item.cpf,
            datapedido: item.data_pedido,
            date: item.data_primeiro_desconto,
            nomefuncionairo: item.funcionario,
            descricaoProduto: item.produto,
            empresa: item.empresa,
            loja: item.nome_fantasia,
            dataFaturamento: item.data_faturamento,
            precoFinal: item.total,
            qtdParcelas: item.qtd_parcelas,
            valorParcela: item.valor_parcela,

            parcelasPaga: item.parcelas_paga,
            statusFuncionario: item.status_funcionario,
            rescisao: item.rescisao, 
            saldo: item.saldo,
            totalPago: item.total_pago,
            comissao: item.comissao,
            taxaAdesao: item.taxaAdesao
          })
          this.totalRecordOrder = this.faturamentos.length
        })
        this.loading = false
      })
  }

  baixarParcelas() {
    if (this.selectedFaturamento.length > 0) {
      this.loading = true;
      this.apiService.updateBaixarParcelas(this.selectedFaturamento).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.faturamentos = []
            this.selectedFaturamento = []
            this.inputDesableButtonFaturar = true
            this.ngOnInit()
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.message
            })
          }
          //this.loading = false
        })
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Por favor selecione parcelas'
      })
    }

  }

  async calcularRescisao() {
    if (this.selectedFaturamento.length === 0) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Por favor selecione pedidos'
      })
    } else {
      this.loading = true
      this.apiService.updateRescisaoPrcelas(this.selectedFaturamento).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.loading = false;
            this.inputDesableRescisao = true
            this.faturamentos = []
            this.getPedidosFAturado()
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: res.message
            })
          }
        })
    }
  }


  enableInputRescisao() {
    this.inputDesableRescisao = false
  }

  enableinputDesableButtonFaturar() {
    if(this.inputDesableRescisao){
      this.inputDesableButtonFaturar = false
    }else{
      this.inputDesableButtonFaturar = true
    }
  }

  searchEmploee(event:any){
  const {cpf, parcelasPaga} = event.filters;
    if(cpf[0]?.value !== null){
      this.faturamentos = [];
      this.loading = true
      const cpfFilter = this.utilsService.limparCPF(cpf[0]?.value);
      this.apiService.getPedidosFaturadosFilterCpf(cpfFilter, parcelasPaga[0].value).subscribe({
        next: (res:any) => {
          res.requests.map((item: any) => {
            this.faturamentos.push({
              id: item.id,
              cpf: item.cpf,
              datapedido: item.data_pedido,
              date: item.data_primeiro_desconto,
              nomefuncionairo: item.funcionario,
              descricaoProduto: item.produto,
              empresa: item.empresa,
              loja: item.nome_fantasia,
              dataFaturamento: item.data_faturamento,
              precoFinal: item.total,
              qtdParcelas: item.qtd_parcelas,
              valorParcela: item.valor_parcela,
              parcelasPaga: item.parcelas_paga,
              statusFuncionario: item.status_funcionario,
              rescisao: item.rescisao,
              saldo: item.saldo,
              totalPago: item.total_pago,
              comissao: item.comissao,
              taxaAdesao: item.taxaAdesao
            })
          })
          
        },
        complete: () => {
          this.loading = false;

        },
        error(err) {
          
        },
      })

    }else{
      this.faturamentos = [];
      this.ngOnInit();
    }
  
  }
}
