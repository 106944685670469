import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { DataService } from '../../services/data.service';
import { FunctionsService } from '../../services/functions.service';
import { PrimeNGConfig, LazyLoadEvent } from 'primeng/api';
import { UtilsService } from '../../services/utils.service';
import { Employees, Employee } from '../../models/employee';
import { Dropdown } from '../../models/dropdown';


import {
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { VacationService } from 'src/app/services/gpe/vacation.service';
import { listProductsConvenios } from 'src/app/models/listProductsConvenios';
import { MarginMota } from 'src/app/models/marginMota';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-list-profiles',
  templateUrl: './list-profiles.component.html',
  styleUrls: ['./list-profiles.component.scss'],
})
export class ListProfilesComponent implements OnInit {
  public loadingTable: boolean;
  public requestDialogExport: boolean;
  public statusGenPdf: boolean;
  public isLoadingContracheque: boolean = false;
  public campos: any[];
  public selectedCampos = [];
  public loadingExport: boolean;
  public printingModes: any[];
  public selectedPrintingMode: 'l' | 'p' | 'portrait' | 'landscape' = 'p';
  public userLogado: any = {};
  public results: any[] = [];
  public copyEmployee: Employee;
  public copyEmployeeRoles: any;
  public resultsCopy: any;
  public typeFileExport: string;
  public statusRhValue: string;
  public totalRecords: number;
  public optionsStatusRh: Dropdown[];
  public lazyEvent: LazyLoadEvent;
  public showModalEditEmployee: boolean;
  public editingEmployeeForm: FormGroup;
  public optionsRoles: any[];
  public loadingUpdateEmployee: boolean;
  public loadingModalEmployee: number;
  public empresaId: number;
  public showModalAdesao: boolean= false;
  public listAdesoes: any[] = [];
  public navbarVisible: boolean;
  public listConvenios: listProductsConvenios[] = [];
  showModalConvenioAtivo: boolean = false;
  showVerbasProventos: boolean = false;
  typeProventos: boolean = false;
  typeDesconto: boolean = false;
  limparCpf: string;
  showmodalSearch: boolean = false;
  seachPassword: any[] = [];
  permission:any;
  openModalMota: boolean = false;
  dataMarginMota: MarginMota[] = [];
  showModalMota: boolean = false;
  
  constructor(
    public api: ApiService,
    public vocationService: VacationService,
    public formBuilder: FormBuilder,
    public storageService: StorageService,
    public messageService: MessageService,
    public dataService: DataService,
    public functionsService: FunctionsService,
    private config: PrimeNGConfig,
    private utilsService: UtilsService,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
    this.loadingModalEmployee = -1;
    this.loadingUpdateEmployee = false;
   
    this.editingEmployeeForm = this.formBuilder.group({
      roles: [''],
      email: ['', [Validators.maxLength(50)]],
      telefone: ['', [Validators.minLength(13), Validators.maxLength(13)]],
      celular: ['', [Validators.minLength(14), Validators.maxLength(14)]],
      nome: ['', [Validators.required]],
      status_rhapp: ['']
    });
    this.showModalEditEmployee = false;
    
    try {
      this.userLogado =  this.storageService.getUserLogado();
    } catch (error) {}
    this.permission = this.storageService.getStoragePeermission();

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }

  searchEmployee(event: LazyLoadEvent) {
    this.results = [];
    this.lazyEvent = event;
    this.loadingTable = true;
    try {
      this.api.getDadosFuncionarioV2(event).subscribe((res: Employees) => {
        
          this.loadingTable = false;
          this.totalRecords = res.totalRecords;
  
          let total  = 0
          res.employees.map((item:any) => {
            total = (parseFloat(item.concession_margin[0]?.margin_liquida)) - (parseFloat(item.totalAdesao) || 0);
            this.results.push({
              ...item,
              items:[{
                matricula: item.matricula,
                dt_admissao: item.dt_admissao,
                email: item.email,
                celular: item.celular,
                telefone: item.telefone,
                parcelas: item.parcelas
              }],
              saldo_magin: total === 0 ? item.concession_margin[0]?.margin_liquida : total
            })
          });
          
          this.resultsCopy = res;

        });
    } catch (error: any) {
      this.loadingTable = false;
      this.messageService.add({
        severity: 'error',
        summary:
          'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
      });
      console.error(error);
    }
  }

  showModalAdesoes(adesoes:any[]){
    this.listAdesoes=[]
    adesoes?.map((item) => {
     this.listAdesoes.push({
      produto: item.produto,
      valor: item.valor,
      dataPedido: item.dataPedido,
      dataPrimeiroDesconto: item.dataPrimeiroDesconto,
     })
      
    })
    this.showModalAdesao = true
  }

  showModalVerbasProventos(){
    this.typeProventos = true;
  }

  showModalVerbasDescontos(){
    this.typeDesconto = true;
  }

 async getListProductConvenios(employee:any){
    this.listConvenios = [];
    this.loadingTable = true;
    this.vocationService.listProductsConvenio(employee.usuario_fk, employee.empresa_fk).subscribe({
      next: (res:any) => {
        res.map(async (item:any) => {
          this.listConvenios.push({
            descontoConvenio: item.descontoConvenio,
            nome_funcionario: item.nome_funcionario,
            resultCalMargemBruta: item.resultCalMargemBruta,
            margemLiquida: item.listMargin[0].margin_liquida,
            list: item.list,
            verbasProventos: item.verbasProventos,
            verbasDescontos: item.verbasDescontos,
            cpf: item.cpf,
            calcDesconto: item.calcDesconto,
            periodo: await this.utilsService.parseStringdateFormatMonthYearDay(item.periodo)
          })
        })
      },
      complete: () => {
        this.showModalConvenioAtivo = true
        this.loadingTable = false;
      },
      error(err) {
        console.log(err);
        
      },
    })
  }


 

  extractSelectUser(user) {
    this.copyEmployee = user;
    Object.keys(user).forEach((key) => {
      try {
        // Evita que gere erro ao atribuir valores que existem no company e não no form
        this.editingEmployeeForm.controls[key].setValue(user[key]);
      } catch (error) {}
    });
  }

  editEmployee(employee: Employee, rowIndex: number) {
    this.loadingModalEmployee = rowIndex;
    // const roles
    // this.editingEmployeeForm.controls['roles'].setValue([1]);
    this.api.getRoles(employee).subscribe({
      next: (res: any) => {
        this.loadingModalEmployee = -1;
        const roles = res.roles.map((role) => {
          return role.role_fk;
        });
        this.editingEmployeeForm.controls['roles'].setValue(roles);
        this.showModalEditEmployee = true;
        this.copyEmployee = employee;
        this.copyEmployeeRoles = roles;

        this.editingEmployeeForm = this.functionsService.extractSelectRegister(
          this.editingEmployeeForm,
          employee,
          'employee'
        );
        console.log(res);
      },
      error: (error) => {
        this.loadingModalEmployee = -1;
        console.log(error);
      },
    });
  }

  extractFormValues(form) {
    let formValues = {};

    Object.keys(form.controls).forEach((key) => {
      formValues[key] = form.get(key).value;
    });

    return formValues;
  }
  async saveEmployeeDb() {
    let { id: userId } = this.copyEmployee;
    let differentRoles: boolean = false;
    let formChanged: boolean = false;
    let editingUserFormValues: any = this.extractFormValues(
      this.editingEmployeeForm
    );
    let employee: any = this.fieldsChanged(editingUserFormValues);
    let allRoles = editingUserFormValues.roles;
    let newRoles: [];
    let exclRoles: [];
    formChanged = Object.keys(employee).length === 0 ? false : true;
    if (this.copyEmployeeRoles !== allRoles) {
      differentRoles = true;
      this.loadingUpdateEmployee = true;
      newRoles = allRoles.filter((newRole) => {
        return this.copyEmployeeRoles.includes(newRole) ? false : newRole;
      });
      exclRoles = this.copyEmployeeRoles.filter((role) => {
        
        return allRoles.includes(role) ? false : role;
      });

     

      this.api.associateRoleToUser(this.copyEmployee, newRoles).subscribe({
        next: (res: any) => {
          this.loadingUpdateEmployee = false;
          if (res.edited)
            this.messageService.add({
              severity: 'info',
              summary: 'Sucesso',
              detail: res.message,
            });
          else
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: res.message,
            });
          console.log(res);
        },
        error: (error) => {
          this.loadingUpdateEmployee = false;
          console.log(error);
        },
      });
    }
    if (!formChanged && !differentRoles) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Ops',
        detail: 'O cadastro não foi alterado, não há necessidade de salvar.',
      });
    } else if (formChanged) {
      this.loadingUpdateEmployee = true;
      let data = {
        RHAPP: {
          grupo: this.copyEmployee.empresas.grupo,
          filial: this.copyEmployee.empresas.filial,
        },
        FUNC: {
          RA_MAT: this.copyEmployee.matricula,
          NOME:  
          this.copyEmployee.nome === undefined
          ? this.copyEmployee.nome
          : employee.nome,
          RA_PROCES: this.copyEmployee.processo,
          RA_DDDFONE:
            employee.ddd_telefone === undefined
              ? this.copyEmployee.ddd_telefone
              : employee.ddd_telefone,
          RA_TELEFON:
            employee.telefone === undefined
              ? this.copyEmployee.telefone
              : employee.telefone,
          RA_DDDCELU:
            employee.ddd_celular === undefined
              ? this.copyEmployee.ddd_celular
              : employee.ddd_celular,
          RA_NUMCELU:
            employee.celular === undefined
              ? this.copyEmployee.celular
              : employee.celular,
          RA_EMAIL:
            employee.email === undefined
              ? this.copyEmployee.email
              : employee.email,
          status_rhapp: employee.status_rhapp === undefined ? this.copyEmployee.status_rhapp : employee.status_rhapp
        },
      };
      const updateemployee = {
        token: this.userLogado.token,
        id: this.copyEmployee.usuario_fk,
        empresa: this.copyEmployee.empresas.id,
      };
     
      try {
        this.api.alteraDados(data, updateemployee).subscribe((res) => {
          this.loadingUpdateEmployee = false;
          this.messageService.add({
            severity: 'success',
            summary: 'A alteração foi efetuada com sucesso.',
          });
        });
      } catch (error) {
        this.loadingUpdateEmployee = false;
        console.error(error);
        this.messageService.add({
          severity: 'error',
          summary:
            'Ocorreu um erro ao processar a alteração, favor tentar novamente mais tarde',
        });
      }
    }
  }

  hasPendencyData({ telefone, email }: Employee) {
    if (telefone === '' || email === '')
      return '../../assets/img/status/pendente/pendente.png';
    return '../../assets/img/status/pendente/certo.png';
  }

  checkStatusEmploee(status: string){
    switch (status) {
      case ' ':
            return 'assets/img/status/ativo.png'; 
      case 'A':
            return 'assets/img/status/afastado.png'
      case 'D':
            return 'assets/img/status/demitido.png'
      case 'F':
            return 'assets/img/status/ferias.png'
      default:
            return 'assets/img/status/ativo.png'; 
    }
  }

  carregarContraCheque: boolean = false;
  //Criação do contra cheque
  public tpsContraCheque = [
    { nome: 'Folha Normal', valor: 'FOL' },
    { nome: 'Férias', valor: 'FER' },
    { nome: 'Decimo Terceiro 1° parcela', valor: '131' },
    { nome: 'Decimo Terceiro 2° parcela', valor: '132' },
  ];
  panelGetCheque: boolean = false;
  usuaroSelecionado: any = '';
  date: Date[] | any;
  tipo: string = '';
  cpf: string = '';
  

  showDialog(value: any) {
  
    
    if (!this.panelGetCheque) {
      this.tipo = '';
      this.date = '';
    }
    this.panelGetCheque = true;
    this.usuaroSelecionado = value; /* dadso aqui */
    this.cpf = this.usuaroSelecionado.cpf;
    this.empresaId = value.empresas.id
    
  }

  async generatePDF(): Promise<void> {
  this.carregarContraCheque = true;
   let dados = {
    periodo: this.dataService.trataPeriodo(this.date),
    roteiro: this.tipo,
  };
 
    if (dados.roteiro === 'FOL') {
      /*contracheque*/
      try {
        this.api.getContrachequePDF( 
        this.usuaroSelecionado,
        this.empresaId,
        this.userLogado.token,
        dados.periodo,
        dados.roteiro).subscribe({
          next: (res:any) => {
             if(res.recordset.length === 0){
             return this.messageService.add({
                key: 'toastContraCheque',
                severity: 'error',
                summary: 'Error',
                detail: 'Não foi encontrado nenhum registro',
              });
             }else{
               let remuneracoes = [];
               let descontos = [];
               res.recordsets[1].forEach((itemAtual:any) => {
                 if (itemAtual.tipoVerba === '1') {
                   remuneracoes.push({
                       style0: 'verbas',
                       verba: itemAtual.verba,
                       style1: 'verbas',
                       descVerba: itemAtual.descVerba,
                       style2: 'verbas',
                       referencia: itemAtual.referencia,
                       style3: 'verbas',
                       valor: itemAtual.valor,
                     });
                 } else if (itemAtual.tipoVerba === '2') {
                   descontos.push({
                       style0: 'verbas',
                       verba: itemAtual.verba,
                       style1: 'verbas',
                       descVerba: itemAtual.descVerba,
                       style2: 'verbas',
                       referencia: itemAtual.referencia,
                       style3: 'verbas',
                       valor: itemAtual.valor,
                     },
                   );
                 }
               });
               /*DATA*/
              const data = {
                 cabecalho: res.recordsets[0][0],
                 empresaFk: res?.recordsets[1][0]?.empresa,
                 comunication: res?.recordsets[4][0]?.message,
                 titulo: res?.recordsets[4][0]?.titulo,
                 remuneracoes: remuneracoes,
                 totais: {
                   total_bruto: parseFloat(
                     res.recordsets[2]
                       .find((element:any) => element.tipoVerba === '1')
                       .total.toFixed(2)
                   ),
                   total_desconto: parseFloat(
                     res.recordsets[2]
                       .find((element:any) => element.tipoVerba === '2')
                       .total.toFixed(2)
                   ),
                   liquido: parseFloat(
                     res.recordsets[2]
                       .find((element:any) => element.tipoVerba === '3')
                       .total.toFixed(2)
                   ),
                 },
                 descontos: descontos,
                 det_conta: {
                   banco: res.recordsets[3][0].banco,
                   conta: res.recordsets[3][0].conta,
                 },
               };
     
               this.vocationService.printPdfContraCheques(data).subscribe({
                 next: async (res:any) => {
                  await this.utilsService.printLayoutPdfReciboFerias(res)
                 },
                 error: (e) => {},
                 complete: () => {}
               })
             }
          },
          
          error: (e) => {
            this.carregarContraCheque = false;
            this.messageService.add({
              key: 'toastContraCheque',
              severity: 'error',
              summary: 'Error',
              detail: e.err,
            });
          },
          complete: () => {
             this.carregarContraCheque = false
            }
        })
      } catch (err) {
        this.carregarContraCheque = false;
        this.messageService.add({
          key: 'toastContraCheque',
          severity: 'error',
          summary: 'Error',
          detail: 'Não foi encontrado nenhum registro',
        });
        this.carregarContraCheque = false;
        this.statusGenPdf = false;
        console.log('Error');
        console.dir(err);
      }
     
    }else{
      /* recibo de ferias */
      this.carregarContraCheque = true;
      const dataVacation = {
        id: this.usuaroSelecionado.usuario_fk,
        periodo: dados.periodo
      }
      this.vocationService.listDadosVacationEmploeeUserid(dataVacation).subscribe({
        next: (res:any) => {
          if(res?.details.length === 0){
            this.messageService.add({
              key: 'toastContraCheque',
              severity: 'info',
              summary: 'Férias',
              detail: res.message,
              life: 7000
            });
          }else{
            this.vocationService.getLayoutPdfReciboFerias(res.details).subscribe({
              next: async (res) => {
               await this.utilsService.printLayoutPdfReciboFerias(res)
               this.panelGetCheque = false;
              },
              complete: () => {this.panelGetCheque = false;},
              error: () => {this.carregarContraCheque = false;}
            })
          }
        },
        complete: () => {
          this.carregarContraCheque = false;
        },
        error: () => {
          this.messageService.add({
            key: 'toastContraCheque',
            severity: 'error',
            summary: 'Férias',
            detail: 'Não possivel obter o pdf férias, verificar com suporte',
          });
          this.carregarContraCheque = false;
        }
      })
    }
}
 

  openModalFields(typeFile: string) {
    console.log(this.lazyEvent);
    this.requestDialogExport = true;
    this.typeFileExport = typeFile;
  }

  fieldsChanged(userChanged: any) {
    let fieldsChangeds = {};

    const treatmentCelTel = (key) => {
      const temp = this.copyEmployee[`ddd_${key}`] + this.copyEmployee[key];

      if (temp !== userChanged[key]) {
        let formatted = this.functionsService.formatCelTel(userChanged[key]);
        fieldsChangeds[`ddd_${key}`] = formatted.substring(0, 2); //ddd
        fieldsChangeds[key] = formatted.substring(2); //cel|telefone
      }
    };
    Object.keys(userChanged).forEach((key) => {
      if (
        userChanged[key] !== '' &&
        (key === 'telefone' || key === 'celular')
      ) {
        treatmentCelTel(key);
      } else if (
        this.copyEmployee[key] !== userChanged[key] &&
        key !== 'created_at' &&
        key !== 'roles'
      ) {
        if (key === 'dt_nasc') {
          let desconverteData = this.dataService.toProtheusDate(
            userChanged[key]
          );
          if (desconverteData !== this.copyEmployee[key])
            fieldsChangeds[key] = userChanged[key];
        } else if (key === 'cnpj')
          fieldsChangeds[key] = this.functionsService.formatCnpj(
            userChanged[key]
          );
        else fieldsChangeds[key] = userChanged[key];
      }
    });

    console.log(fieldsChangeds);
    return fieldsChangeds;
  }


  showmodalSearchPassword(employee:any){
    this.api.findPassWordEmploee(employee.cpf).subscribe({
      next: (res:any) => {
        this.seachPassword = res.data
      },
      complete: () => {
        this.showmodalSearch = true;
      },
      error: () => {}
    })
  }

  
  async searchMarginMota(data:any){ 
    this.dataMarginMota = [];
    const {cpf, matricula, empresas} = data;
    this.loadingTable = true;
    this.api.seachMarginMota(cpf, matricula, '1002', empresas.id).subscribe({
          next:(res:any) => {
            if(Object.keys(res).length > 0){
              this.messageService.add({
                severity: 'info', summary: 'Margin', detail: 'Margin mota consultada com sucesso!'
              })
              /* open modal */
              this.dataMarginMota = res.margin.dadosMargem;
              this.showModalMota = true;
            }else{
              this.messageService.add({
                severity: 'warn',
                summary: 'Cpf não encontrado base de dados mota',
              });
            }
          },
          complete:() => {
            this.loadingTable = false;
          },
          error:(err) => {
            this.loadingTable = false
          },
      });
    }
  

}
