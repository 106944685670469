
<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>
    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>
        <div class="col-10" [class.col-12]="!navbarVisible">
            <div class="card">
                <p-table #dt1 
                  [value]="campanigs" 
                  dataKey="id"
                  [rows]="50" 
                  [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10, 100, 200]" 
                  [loading]="loading" 
                  [paginator]="true"
                  [globalFilterFields]="['id']"
                  scrollDirection="horizontal" 
                  [scrollable]="true" 
                  scrollHeight="600px" 
                  editMode="row">

                  <ng-template pTemplate="caption">
                    <form [formGroup]="formCampaign">
                    <div class="grid">
                      <div class="col-12">
                          <p-editor formControlName="text" [style]="{ height: '80px' }" (keydown)="onKeyDown($event)"
                          [readonly]="countTextDisebled">
                          <ng-template pTemplate="header">
                              <span class="ql-formats">
                                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                                  <button type="button" class="ql-underline" aria-label="Underline"></button>
                              </span>
                          </ng-template>
                      </p-editor>
                    </div>

                    <div class="col-2">
                      <input type="text" class="flex w-full" pInputText placeholder="Titulo" formControlName="title"/> 
                    </div>
                    <div class="col-10">
                      <p-multiSelect class="mr-2" [options]="empresas" formControlName="companys" optionLabel="nome_fantasia"
                      optionValue="id" defaultLabel="Selecione as empresas" display="chip">
                    </p-multiSelect>
                    </div>
                    <div class="col-12">
                      <p-button 
                      icon="pi pi-plus" 
                      label="Salvar" 
                      (onClick)="salveCompaning()"
                      [disabled]="formCampaign.invalid"
                    ></p-button>

                    <p-button 
                    icon="pi pi-trash" 
                    label="Cancelar" 
                    styleClass="p-button-secondary ml-2"
                    (onClick)="resetForm()"
                    [disabled]="formCampaign.invalid"
                  ></p-button>
                    </div>
                    </div>

                </form>
                <div class="grid">
                  <div class="col-12">
                      158 / {{countText}}
                  </div>
              </div>
                </ng-template>
                 
                  <ng-template pTemplate="header">
                    <tr>
                      <th>
                        Titulo
                      </th>
                      <th>
                        Descrição
                       </th>
                       <th>
                         Data Criação
                       </th>
                      <th>
                       Action
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-campanig let-ri="rowIndex">
                    <tr>
                      <td> {{campanig.title}} </td>
                      <td> {{campanig.description}} </td>
                      <td>
                        {{campanig.created_at | localdate}}
                      </td>
                      <td class="actions">
                        <p-button 
                        icon="pi pi-eye" 
                        styleClass="p-button-rounded p-button-primary p-button-text"
                        (click)="viewAceeipt(campanig.accept_campaign)"
                        >
                        </p-button>

                        <p-button 
                          icon="pi pi-pencil" 
                          styleClass="p-button-rounded p-button-primary p-button-text"
                          (click)="updateCampaign(campanig)"
                        >
                        </p-button>

                        <p-button 
                          icon="pi pi-building" 
                          styleClass="p-button-rounded p-button-primary p-button-text"
                          (click)="viewCompanyCampagin(campanig)"
                        >
                        </p-button>

                        <form [formGroup]="formGroupChecked">
                          <p-toggleButton 
                          (onChange)="desableCampanig(campanig.id, campanig.status)"
                            formControlName="checked"
                            [onLabel]="campanig.status ? 'Yes' : 'Yes'"
                            [offLabel]="campanig.status ? 'Yes' : 'No'"
                            >
                          </p-toggleButton>
                        </form>
                      </td>
                    </tr>
            
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
        </div>
    </div>





    <!--modal--acceipt-->
    <p-dialog header="Confirmações de campanaha" [(visible)]="visible" [style]="{width: '80vw', height:'80vh'}">
      <p-table #dt1 
      [value]="acceipts" 
      dataKey="id"
      [rows]="50" 
      [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 100, 200]" 
      [loading]="loading" 
      [paginator]="true"
      [globalFilterFields]="['id']"
      scrollDirection="horizontal" 
      [scrollable]="true" 
      scrollHeight="600px" 
      editMode="row">
  
      <ng-template pTemplate="header">
        <tr>
          <th>
            Nome
          </th>
          <th>
            Empresa
           </th>
           <th>
             Cpf
           </th>
          <th>
           Email
          </th>
          <th>
            Telefone
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-acceipt let-ri="rowIndex">
        <tr>
          <td> {{acceipt.usuarios.dados_funcionarios.nome}}   </td>
          <td> {{acceipt.usuarios.empresas.nome_fantasia}}    </td>
          <td> {{acceipt.usuarios.dados_funcionarios.cpf}}    </td>
          <td> {{acceipt.usuarios.dados_funcionarios.email}}  </td>
          <td> {{acceipt.usuarios.dados_funcionarios.ddd_celular}}{{acceipt.usuarios.dados_funcionarios.celular}} </td>
        </tr>

      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">Não foi encontrada nenhuma categoria.</td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>



  <p-dialog header="Companyes" [(visible)]="visibleCampagin" [style]="{width: '80vw', height:'80vh'}">
    <p-table #dt1 
    [value]="Addcompany_campaigns" 
    dataKey="id"
    [rows]="50" 
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 100, 200]" 
    [loading]="loading" 
    [paginator]="true"
    [globalFilterFields]="['id']"
    scrollDirection="horizontal" 
    [scrollable]="true" 
    scrollHeight="600px" 
    editMode="row">

    <ng-template pTemplate="caption">
      <div class="grid">
      <div class="col-10">
       
      <p-multiSelect 
      [options]="empresas" 
      [(ngModel)]="selectedcompanys" 
      optionLabel="nome_fantasia"
      placeholder="Escolhas as empresas"
      ></p-multiSelect>
      </div>
      <div class="col-12">
        <p-button 
        icon="pi pi-plus" 
        label="Salvar" 
        (onClick)="salveCompanyCampaign(AddcompanyCampaignsId)"
      ></p-button>

      <p-button 
      icon="pi pi-trash" 
      label="Cancelar" 
      styleClass="p-button-secondary ml-2"
      (onClick)="resetCompanyCampaign()"
    ></p-button>
      </div>
      </div>

  </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th>
          Id
        </th>
        <th>
          Empresa
        </th>
       <th>Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-Addcompany_campaign let-ri="rowIndex">
      <tr>
        <td> {{Addcompany_campaign.id}} </td>
        <td> {{Addcompany_campaign.empresas.nome_fantasia}} </td>
        <td>
          <p-button 
            icon="pi pi-trash" 
            styleClass="p-button-rounded p-button-primary p-button-text"
            (click)="deleteCampaging(Addcompany_campaign.id)">
          </p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Não foi encontrada nenhuma categoria.</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
</div>