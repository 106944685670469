//api.service
import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';
import { DataService } from './data.service';
import { LazyLoadEvent } from 'primeng/api';
import { Employee } from '../models/employee';
import { Faturamento } from '../models/faturamento';
import { Company } from '../models/company';
import { Verbas } from '../models/verbas';
import { Products } from '../models/products';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // localhost
  urlApi = environment.apiUrl;
  // urlApi = "http://52.67.122.193:3200" //ec2
  //urlApi = environment.apiUrl;
  // urlApi = 'https://0f36-191-7-55-203.sa.ngrok.io'; //Ngrok;
  constructor(
    public http: HttpClient,
    public storageService: StorageService,
    public dataService: DataService,
    public serviceLocalStorage: StorageService
  ) { }

  public login(cpf: any, senha: any) {
    let url = this.urlApi + '/identity/login/v2';
    let body = {
      cpf: cpf,
      senha: senha,
    };
    return this.http.post(url, body);
  }



  public postViewCompanys() {
    const url = `${this.urlApi}/social/posts/view-companys`;
    const { token }: any = this.storageService.getUserLogado();
    let headers = new HttpHeaders({
      'x-access-token': token,
    });
    let options = {
      headers,
    };
    return this.http.get(url, options);
  }

  public  postPost(
    formData: FormData,
    {  companys, category }: any,
    { id, empresa, token }: any,
    product:Products,
    actionUrl:string
  ) {
    let url = `${this.urlApi}/social/posts/${empresa}/${id}`;
    let headers = new HttpHeaders({
      'x-access-token': token,
    });
    let params = new HttpParams();
    params = params
      .append('action', actionUrl)
      .append('category', category)
      .append('product_id', product.id)
      .append('companys', companys);

    let options = {
      headers,
      params,
    };
    
    return  this.http.post(url, formData, options);
  }

  public postImage(formData: FormData, { cod_identity, token }: any) {
    console.log('postImage()', formData);
    console.log(token);

    const url = `${this.urlApi}/aws/s3img/${cod_identity}?tp=pub`;
    let headers = new HttpHeaders({
      'x-access-token': token,
    });
    let options = {
      headers,
    };

    return this.http.post(url, formData, options);
  }

  public getBatidas(token: any) {
    let url = this.urlApi + '/ponto/ponto';

    let headers = new HttpHeaders({
      'x-access-token': token,
    });
    let options = {
      headers,
    };

    return this.http.get(url, options);
  }

  public postDadosFuncionario({ token }: any, body: any) {
    const url = `${this.urlApi}/gpe/dadosfuncionarios`;
    const headers = new HttpHeaders({
      'x-access-token': token,
    });
    const options = {
      headers,
    };

    return this.http.post(url, body, options);
  }
  /* lista de contracheck */
  public getDadosFuncionarioV2(event: LazyLoadEvent, exportToFile?: boolean) {
    const { token } = this.storageService.getUserLogado();
    const url = `${this.urlApi}/gpe/dadosfuncionarios/v2`;
    let params = new HttpParams();
    const headers = new HttpHeaders({
      'x-access-token': token,
    });
    let httpOptions: Object = {};

    if (!exportToFile)
      params = params.append('offset', event.first).append('rows', event.rows);
    if (event.sortField) {
      let order = event.sortOrder === 1 ? 'asc' : 'desc';
      params = params.append('sort', `${event.sortField}:${order}`);
    }
    let teste = [];
    Object.keys(event.filters).forEach((el) => {
      if (event.filters[el][0].value !== null) {
        let ret: any;
        if (typeof event.filters[el][0].value === 'object') {
          // No caso o filter retorna o tipo data como Object
          let fieldValue = this.dataService.toProtheusDate(
            event.filters[el][0].value
          );
          ret = {
            matchMode: event.filters[el][0].matchMode,
            value: fieldValue,
          };
        } else {
          ret = event.filters[el][0];
        }
        ret.field = el;
        teste.push(ret);
      }
    });

    teste.forEach((el) => {
      const removeCpf = el.value.replace(/[ .-]/g, '');
      params = params.append(`${el.field}:${el.matchMode}`, removeCpf);
    });
    
    httpOptions = {
      headers: headers,
      params: params,
    };
    return this.http.post(url, {}, httpOptions);
  }

  /**
   * Altera os dados no ERP Protheus, via execauto e na base de dados do aplicativo.
   * @param dados Objeto com as novas informações do usuário (celular, telefone, etc...)
   * @param { User } user
   * @param { id } user.id Id do usuario
   * @param { token } user.token Token de autorização do usuario
   * @param { empresa } user.empresa Empresa do usuário
   * @returns { Promise<HttpResponse> } Retorna o PUT da requisição
   */
  public alteraDados(dados: any, { token, empresa, id }: any) {
    const url = `${this.urlApi}/gpe/dadosfuncionario/altera/${empresa}/${id}`;
    const headers = {
      'x-access-token': token,
    };
    const options = {
      headers,
    };
    return this.http.put(url, dados, options);
  }
  /**
   *
   * @param {token} user.token
   * @param {id} user.id
   * @returns {Promise <HttpResponse>} Retorna o get da requisição
   */
  public getRoles({ usuario_fk: id }: Employee) {
    const { token } = this.storageService.getUserLogado();
    const url = `${this.urlApi}/identity/profiles/role/${id}`;
    const headers = {
      'x-access-token': token,
    };
    const options = {
      headers,
    };
    return this.http.get(url, options);
  }

  public associateRoleToUser({ usuario_fk: id }: Employee, roles: []) {
    const { token } = this.storageService.getUserLogado();
    const url = `${this.urlApi}/identity/profiles/role/${id}`;
    const headers = new HttpHeaders({
      'x-access-token': token,
    });
    const options = {
      headers,
    };

    return this.http.put(url, roles, options);
  }

  public createRole({ token }: any, description: any) {
    const url = `${this.urlApi}/identity/profiles/role`;

    const headers = {
      'x-access-token': token,
    };
    const options = {
      headers,
    };
    return this.http.post(url, { description: description }, options);
  }

  /**
   * Faz a validação do token JWT no backend, afim de validar a sessão ao iniciar o aplicativo.
   * @param { User } user
   * @param { id } user.id Id do usuario
   * @param { token } user.token Token de autorização do usuario
   * @returns { Promise<HttpResponse> } Retorna o GET da requisição
   */
  public getTokenValid({ token, id }: any) {
    const url = `${this.urlApi}/identity/isvalidtoken/${id}`;
    const headers = {
      'x-access-token': token,
    };

    const options = { headers };

    return this.http.get(url, options);
  }

  /*list verbas contrachques */
  public  getContrachequePDF(
    { usuario_fk }: any,
    empresaId: number,
    logedToken: any,
    periodo: string,
    roteiro: string
  ) {
    const url = `${this.urlApi}/gpe/contrachequepdf/${empresaId}/${usuario_fk}`;
    const params = {
      periodo,
      roteiro,
    };
    const header = {
      'x-access-token': logedToken,
    };
    return  this.http.get(url, { headers: header, params: params });
  }


  public obterCategoriasDeLoja(logedToken: any, sulfix: any) {
    const url = `${this.urlApi}/shop/store-categories/${sulfix}`;
    const headers = new HttpHeaders({
      'x-access-token': logedToken,
    });

    let res = this.http.get(url, { headers });
    return res;
  }


  public listCategories(logedToken:string) {
    const url = `${this.urlApi}/shop/list/category/products/portal`;
    const headers = new HttpHeaders({
      'x-access-token': logedToken,
    });

    let res = this.http.get(url, { headers });
    return res;
  }

  public criarCategoriadeLoja(
    logedToken: any,
    nomeCategoria: any,
    formData: FormData
  ) {
    let headers = new HttpHeaders({
      'x-access-token': logedToken,
    });
    let params = new HttpParams();
    params = params.append('categoryName', nomeCategoria);
    const url = `${this.urlApi}/shop/store-categories`;
    let options = {
      headers,
      params,
    };
    return this.http.post(url, formData, options);
  }

  public criarCategoriaDeProduto(
    logedToken: any,
    idCatLoja: any,
    nomeCategoria: any
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };

    const url = `${this.urlApi}/shop/product-categories`;

    const data = {
      productCategory: nomeCategoria,
      storeCategoryId: idCatLoja,
    };

    return this.http.post(url, data, httpOptions);
  }

  
  
  public deletarCategoriaDoProduto(logedToken: any, idParaSerDeletado: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };

    const url = `${this.urlApi}/shop/product-categories/${idParaSerDeletado}`;
    return this.http.delete(url, httpOptions);
  }
  public deletarCategoriaDaLoja(logedToken: any, idParaSerDeletado: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };

    const url = `${this.urlApi}/shop/store-categories/${idParaSerDeletado}`;
    return this.http.delete(url, httpOptions);
  }


  public editarCategoriaDeLoja(logedToken: any, objCategoria: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };
    const url = `${this.urlApi}/shop/store-categories/${objCategoria.id}`;
    const data = {
      categoryName: objCategoria.descricao,
    };

    return this.http.put(url, data, httpOptions);
  }

  updateCategoryStatusLoja(token:any, category:any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': token,
      }),
    }; 
    const url = `${this.urlApi}/shop/store-categories/status/${category.id}`;
    const data = {
      status: category.status,
    };

    return this.http.put(url, data, httpOptions);
  }


  criarFeature(logedToken: any, idCategoriaDeProduto: any, nomeFeature: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };
    const url = `${this.urlApi}/shop/products-categorys-features`;
    const data = {
      productCategoryId: idCategoriaDeProduto,
      featureName: nomeFeature,
      valueType: 'texto',
    };
    return this.http.post(url, data, httpOptions);
  }
  criarProduto(
    logedToken: any,
    title: any,
    idCategoriaDeLoja: any,
    preco: number,
    parcelas: number,
    metodoDeDesconto: any,
    comissao:number,
    verbas: Verbas[],
    taxaadm: number | 0,
    details: string,
    companyId:number,
    taxaAdesao:number
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };
    const data = {
      description: title,
      price: preco,
      number_plots: parcelas,
      discount_method: metodoDeDesconto,
      categoryId: idCategoriaDeLoja,
      comissao: comissao,
      verbas: verbas,
      taxaadm: taxaadm,
      details:details,
      companyId:companyId,
      taxaAdesao:taxaAdesao
    };
    const url = `${this.urlApi}/shop/products`;
    return this.http.post(url, {data:data}, httpOptions);
  }


  enviarImagem(logedToken: any, idProduto: string, formData: FormData) {
    let headers = new HttpHeaders({
      'x-access-token': logedToken,
    });

    let options = {
      headers,
    };

    const url = `${this.urlApi}/shop/products/${idProduto}/upload-img`;
    return this.http.post(url, formData, options);
  }
  obterPedidos(logedToken: any) {
    const { empresa_parceira } = this.serviceLocalStorage.getUserLogado();
    let headers = new HttpHeaders({
      'x-access-token': logedToken,
    });
    let options = {
      headers,
    };
    const url = `${this.urlApi}/shop/product-requests/view-requests/${empresa_parceira}`;
    return this.http.get(url, options);
  }
  obterProdutos(logedToken: any) {
    let headers = new HttpHeaders({
      'x-access-token': logedToken,
    });
    let options = {
      headers,
    };
    const url = `${this.urlApi}/shop/products`;
    return this.http.get(url, options);
  }
  //Criar empresa
  criarEmpresa(
    logedToken: any,
    cnpj: any,
    nomeFantasia: any,
    razaoSocial: any,
    grupo: any,
    filial: any,
    telefone: any,
    celular: any,
    type: any,
    email: string,
    cep: string,
    cidade: string,
    bairro: string,
    logradouro: string,
    numero: string,
    porcentagemMagem:string
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };
    const data = {
      cnpj: cnpj,
      nomeFantasia: nomeFantasia,
      grupo: grupo,
      filial: filial,
      tipo: type.tipo,
      razaoSocial: razaoSocial,
      telefone: telefone,
      celular: celular,
      email: email,
      cidade: cidade,
      bairro: bairro,
      logradouro: logradouro,
      numero: numero,
      cep: cep,
      porcentagemMagem: porcentagemMagem
    };

    const url = `${this.urlApi}/identity/company/create`;
    return this.http.post(url, data, httpOptions);
  }
  //Obter empresas aqui
  getEmpresas() {
    const { token } = this.serviceLocalStorage.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/company/list/all`;
    return this.http.get(url, httpOptions);
  }



  getRelatorio(logedToken: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': logedToken,
      }),
    };
    const url = `${this.urlApi}/shop/product-requests/view-report`;
    return this.http.get(url, httpOptions);
  }
  //Cria Usuarios
  criarUsuario(
    logedToken: any,
    {
      cpf,
      nomeUser,
      sexo,
      dataNascimento,
      email,
      celular,
      telefone,
      senha,
      empresa,
      role,
      funcao,
    }
  ) {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': logedToken,
      }),
    };
    const data = {
      name: nomeUser,
      cpf,
      email,
      password: senha,
      role: role,
      funcao,
      dtNascimento: dataNascimento,
      sexo,
      telefone,
      celular,
      company: empresa
    };
    
    const url = `${this.urlApi}/identity/create-user`;
    return this.http.post(url, data, httpOptions);
  }

  putProdRequestObservation({ requestId: id, observacao }: any) {
    const { token } = this.storageService.getUserLogado();
    let params = new HttpParams();
    params = params.append('id', id);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const data = {
      observation: observacao,
    };
    console.log(data);
    const url = `${this.urlApi}/shop/product-requests/add-observation/${id}`;
    return this.http.put(url, data, httpOptions);
  }
/* alteração */
  approveRequestProduct(pedido:any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/product-requests/approve/${pedido.requestId}`;
    return this.http.put(url, {pedido:pedido}, httpOptions);
  }

  aprovarPedidoComercail({ requestId: id }) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/product-requests/approve/comercial/${id}`;
    return this.http.put(url, {}, httpOptions);
  }

  rejectRequestProduct(requestId:number, observacao:string) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/product-requests/reject/${requestId}`;
    return this.http.put(url, {observacao:observacao}, httpOptions);
  }

  rejectRequestProductComercial( requestId:number,observacao:string) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/product-requests/reject/comercial/${requestId}`;
    return this.http.put(url, {observacao: observacao}, httpOptions);
  }

  editCompany(companyId: any, company: any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/company/edit/${companyId}`;
    return this.http.put(url, company, httpOptions);
  }

  deleteCompany(companyId) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/company/delete/${companyId}`;
    return this.http.delete(url, httpOptions);
  }

  getUsersDb() {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/list-users/all`;
    return this.http.get(url, httpOptions);
  }

  public editUser(userId, user) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/edit-user/${userId}`;
    return this.http.put(url, {user:user}, httpOptions);
  }

  public getAtestados(event: LazyLoadEvent, justSended?: boolean) {
    
    const { token } = this.storageService.getUserLogado();
    let params = new HttpParams();
    let headers = new HttpHeaders({
      'x-access-token': token,
    });
    let httpOptions: Object = {};
    params =  params.append('offset', event.first)
                    .append('rows', event.rows);
    if (event?.sortField) {
      let order = event?.sortOrder === 1 ? 'asc' : 'desc';
      params = params.append('sort', `${event.sortField}:${order}`);
    }
    let teste = [];
    Object.keys(event.filters).forEach((el) => {
      if (event.filters[el][0].value !== null) {
        let ret: any;
        if (typeof event.filters[el][0].value === 'object') {
          // No caso o filter retorna o tipo data como Object
          let fieldValue = this.dataService.toProtheusDate(
            event.filters[el][0].value
          );
          ret = {
            matchMode: event.filters[el][0].matchMode,
            value: fieldValue,
          };
        } else {
          ret = event.filters[el][0];
        }
        ret.field = el;
        teste.push(ret);
      }
    });

    teste.forEach((el) => {
      const removeCacterecpf = el.value.replace(/[.-]/g, '');
      params = params.append(`${el.field}:${el.matchMode}`, removeCacterecpf);
    });
    if (justSended)
      params = params.append('status:notEquals', 'P')
    else
      params = params.append('status:equals', 'P')
    httpOptions = {
      headers: headers,
      params: params,
    };

  
    const url = `${this.urlApi}/mdt/atestados/v2/all`;
    return this.http.get(url, httpOptions);
  }


  public getAtestadosFinalizados(event: LazyLoadEvent) {
    console.log(event);
    
    const { token } = this.storageService.getUserLogado();
    let params = new HttpParams();
    let headers = new HttpHeaders({
      'x-access-token': token,
    });
    let httpOptions: Object = {};
    params =  params.append('offset', event.first)
                    .append('rows', event.rows);
    if (event?.sortField) {
      let order = event?.sortOrder === 1 ? 'asc' : 'desc';
      params = params.append('sort', `${event.sortField}:${order}`);
    }
    let teste = [];
    Object.keys(event.filters).forEach((el) => {
      if (event.filters[el][0].value !== null) {
        let ret: any;
        if (typeof event.filters[el][0].value === 'object') {
          // No caso o filter retorna o tipo data como Object
          let fieldValue = this.dataService.toProtheusDate(
            event.filters[el][0].value
          );
          ret = {
            matchMode: event.filters[el][0].matchMode,
            value: fieldValue,
          };
        } else {
          ret = event.filters[el][0];
        }
        ret.field = el;
        teste.push(ret);
      }
    });

    teste.forEach((el) => {
      const removeCacterecpf = el.value.replace(/[.-]/g, '');
      params = params.append(`${el.field}:${el.matchMode}`, removeCacterecpf);
    });
    httpOptions = {
      headers: headers,
      params: params,
    };

  
    const url = `${this.urlApi}/mdt/atestados/v2/all`;
    return this.http.get(url, httpOptions);
  }


  public approveAtestado(atestados: [], sendToken:any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
   
    const url = `${this.urlApi}/mdt/atestado/approve`;
    return this.http.put(url, {atestados:atestados, sendToken:sendToken}, httpOptions);
  }

  public reproveAtestado({ id }: any, motivoReprovacao: string, notification:any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const body = {
      motivoReprovacao,
      notification
    }
    const url = `${this.urlApi}/mdt/atestado/reprove/${id}`;
    return this.http.put(url, body, httpOptions);
  }

  public getVacationsSync() {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/gpe/vacation/sync`;
    return this.http.get(url, httpOptions);
  }
  // Mardonis Alves
  public getDadosDashboard() {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/gpe/dashboard`;
    return this.http.get(url, httpOptions);
  }

  public getPermissionUSers(userId: number) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/profiles/role/${userId}`;
    return this.http.get(url, httpOptions);
  }

  public getAllcompany() {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/company/list/all}`;
    return this.http.get(url, httpOptions);
  }

  /* roles profiles */
  public getAllRolesProfiles() {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/roles/profiles`;
    return this.http.get(url, httpOptions);
  }


  public changePassword(id:number, senha:string){
    const {token} = this.storageService.getUserLogado();
    const update = {
      newPassword: senha,
      id: id
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/update/user/portal`;
    return this.http.put(url, {update:update}, httpOptions);
  }

  public getAccessCompanies(id:number){
    const {token} = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/access/companies/${id}`;
    return this.http.get(url, httpOptions);
  }

  public deleteAccessCompany(item:any){
    const {token} = this.storageService.getUserLogado();
    let params = new HttpParams()
    .set('usuario_fk', item.usuario_fk)
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params
    };
    const url = `${this.urlApi}/identity/delete/company/${item.id}`;
    return this.http.delete(url, httpOptions);
  }


  public addAccessCompany(access:any){
    const {token} = this.storageService.getUserLogado();
    let params = new HttpParams()
    .set('usuario_fk', access.usuario_fk)
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params: params
    };
    const url = `${this.urlApi}/identity/add/access`;
    return this.http.post(url, {access:access}, httpOptions);
  }



  public createPedidoFaturamento(faturamento: Faturamento) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/pedido/faturamento`;
    return this.http.post(url, faturamento, httpOptions);
  }

  public getPedidosFaturados() {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/baixar/parcelas/${empresa_parceira}`;
    return this.http.get(url, httpOptions);
  }

  public getPedidosFaturadosFilterCpf(cpf:string, parcelasPaga) {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    let params = new HttpParams()
    .set('cpf', cpf)
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
      params:params
    };
    const url = `${this.urlApi}/shop/list/baixar/parcelas/${empresa_parceira}`;
    return this.http.get(url, httpOptions);
  }

  public getParcelasAdesao(pesquisar: any) {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/parcelas/adesao`;
    return this.http.post(url, { pesquisar: { ...pesquisar, empresa_parceira: empresa_parceira } }, httpOptions);
  }

  public getRelatorioDetalhado(pesquisar: any) {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/shop/parcelas/adesao/detalhado`;
    return this.http.post(url, { pesquisar: { ...pesquisar, empresa_parceira: empresa_parceira } }, httpOptions);
  }

  public updateBaixarParcelas(faturamento: Faturamento[]) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/baixar/parcelas`;
    return this.http.put(url, { faturamento: faturamento }, httpOptions);
  }

  public updateRescisaoPrcelas(faturamento: Faturamento[]) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/rescisao/parcelas`;
    return this.http.put(url, { faturamento: faturamento }, httpOptions);
  }

/*list convenios concluidos */
  public pedidosConcluidos(event:LazyLoadEvent) {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };

    const data = {
      empresa_parceira:empresa_parceira,
      search: event
    }
    const url = `${this.urlApi}/shop/pedidos/concluidos`;
    return this.http.post(url, { data }, httpOptions);
  }

  public pedidosCancelados() {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/pedidos/cancelados/${empresa_parceira}`;
    return this.http.get(url, httpOptions);
  }

  public listCategoriesEmprestimos() {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/products/list/categorias/${empresa_parceira}`;
    return this.http.get(url, httpOptions);
  }


  public pesquisarFuncionario(cpf: string) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/pesquisar/funcionario/${cpf}`;
    return this.http.get(url, httpOptions);
  }

  public createEmprestimoBanco(emprestimo: Faturamento) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/create/emprestimo/banco`;
    return this.http.post(url, { emprestimo: emprestimo }, httpOptions);
  }

  /* relatorio pdf*/
  public relatorioAdesaoPdf(adesoes: any, tituloRelatorio:string) {
    const { token, name } = this.storageService.getUserLogado();

    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/relatorio/adesao/pdf`;
    return this.http.post(url, { adesoes: adesoes, name: name, titulo:tituloRelatorio }, httpOptions);
  }

  public relatorioAdesaoPdfDetalhado(adesoes: any, titulo) {
    const { token, name } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/relatorio/adesao/detalhado/pdf`;
    return this.http.post(url, { adesoes: adesoes, name: name, titulo:titulo }, httpOptions);
  }

  public relatorioAdesaoPdfResumo(adesoes: any, selectedCompanies: Company, titulo) {
    const { token, name } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/relatorio/adesao/resumo/pdf`;
    return this.http.post(url, { adesoes: adesoes, company: selectedCompanies, name: name, titulo: titulo }, httpOptions);
  }

  public relatorioFinananceiroConvenios(financeiro: any, selectedCompanies: Company) {
    const { token, name } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/relatorio/financeiro/convenios/pdf`;
    return this.http.post(url, { financeiro: financeiro, company: selectedCompanies, name: name }, httpOptions);
  }

  public relatorioFinananceiroConveniosDetalhado(financeiro: any, selectedCompanies: Company) {
    const { token, name } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/relatorio/financeiro/detalhado/resumido`;
    return this.http.post(url, { financeiro: financeiro, company: selectedCompanies, name: name }, httpOptions);
  }

  public cadastrarVerbas(verba:Verbas) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/cadastrar/verbas`;
    return this.http.post(url,  {verba}, httpOptions);
  }

  public listVerbas(search:any) {
    const { token, empresa_parceira } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/list/verbas/${empresa_parceira}`;
    return this.http.post(url,{search:search},  httpOptions);
  }

  public ativarInativarVerba(provento: Verbas) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/ativar/verba`;
    return this.http.put(url, {provento: provento}, httpOptions);
  }

  public deleteVerba(id: number) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/deletar/verba/${id}`;
    return this.http.delete(url, httpOptions);
  }

  public listPaypmentCodeByCompany(id: number) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/list/verbas/company/${id}`;
    return this.http.get(url, httpOptions);
  }

  public findPriceProduct(id: number) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/shop/find/price/product/${id}`;
    return this.http.get(url, httpOptions);
  }


  public findPassWordEmploee(cpf:string){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      }),
    };
    const url = `${this.urlApi}/identity/find/user/password/${cpf}`;
    return this.http.get(url, httpOptions);
  }


  /* search margin mota  */
  public seachMarginMota(cpf: string, matriculaFun:string, codBanco:string, codConvenio:any) {
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/mota/search/margin/mota?codBanco=${codBanco}&codConvenio=${codConvenio}&cpf=${cpf}&matricula=${matriculaFun}`;
    return this.http.get(url, httpOptions);
  }



  /* search contract wayconsig */
  public searchContractWayconsig(cpf: string, matricula:string, requestId:string){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': token,
      })
    };
    const url = `${this.urlApi}/mota/seacrh/contract?cpf=${cpf}&matricula=${matricula}&requestId=${requestId}`;
    return this.http.get(url, httpOptions);
  }


  /* search contract wayconsig */
  public liquidarContratoWayconsig(contrato:any, marginId:string, liqRhapp:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        })
    };
    const url = `${this.urlApi}/mota/liquidar/contrato/${marginId}`;
    return this.http.put(url, {contrato:contrato, liqRhapp:liqRhapp}, httpOptions);
    }

    /* update employee */
  public updateEmployee(employee:any){
    const { token } = this.storageService.getUserLogado();
    const httpOptions = {
        headers: new HttpHeaders({
          'x-access-token': token,
        })
    };
    const url = `${this.urlApi}/gpe/update/employee`;
    return this.http.put(url, {employee:employee}, httpOptions);
  }


}
