import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { MessageService } from 'primeng/api';
import { NavbarService } from 'src/app/services/navbar-service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  public results: any;
  public userLogado: any = {};
  navbarVisible:boolean;
  public loading: boolean = true;
  public clonedGroups: { [s: string]: any } = {};

  public results2: any;
  constructor(
    public api: ApiService,
    public storageService: StorageService,
    public messageService: MessageService,
    private navbarService: NavbarService
  ) {}

  async ngOnInit() {
    this.userLogado =  this.storageService.getUserLogado();
     this.obterDados();
    this.loading = false;

    this.navbarService.navbarVisible$.subscribe((show) => {
      this.navbarVisible = show
    })
  }

  obterDados() {
    try {
      this.api.getRoles(this.userLogado).subscribe((res: any) => {
        this.results = res.results;
      });
      // this.results = this.results.sort();

      // const parse = JSON.parse(res.subscribe)
    } catch (error: any) {
      console.log(error);
      this.messageService.add({
        severity: 'error',
        summary:
          'Ocorreu um erro ao carregar a tabela, por favor tente novamente mais tarde',
      });
      console.error(error);
    }
  }

  onRowEditInit(group: any) {
    console.log('onRowEditInit');
    this.clonedGroups[group.id] = { ...group };
    console.log(this.clonedGroups);
  }

  async onRowEditSave() {}

  onRowEditCancel(group: any, index: number) {
    console.log('onRowEditCancel');
    console.log(index);
    this.results2[index] = this.clonedGroups[group.id];
    delete this.results2[group.id];
  }
}
