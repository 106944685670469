<div class="surface-0">
    <app-menubar></app-menubar>
    <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
    </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
        <p-table 
            #dt2 
            [value]="results" 
            dataKey="id" 
            [rows]="10" 
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10,50,100]" 
            [loading]="loading" 
            [paginator]="true"
            currentPageReportTemplate="{first} de {last} de um total de {totalRecords} registros"
            [tableStyle]="{'min-width':'100%'}">

            <ng-template pTemplate="header">
                <tr>
                    <th style="width:22%">Codigo</th>
                    <th style="width:20%">Desrição</th>
                    <th style="width:20%">Editar</th>
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="text" field="id"></p-columnFilter>
                    </th>
                    <th>
                        <p-columnFilter type="text" field="description"></p-columnFilter>
                    </th>
                    <th>
                        <!-- Espaço vazio -->
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-group let-editing="editing" let-ri="rowIndex">
                <tr [pEditableRow]="group">

                    <td>
                        <span class="image-text">{{group.id}}</span>
                    </td>
                    <td>
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="group.description" required
                                    style="width: 100%;">
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{group.description}}
                            </ng-template>
                        </p-cellEditor>
                    </td>
                    <td>
                        <div class="flex align-items-center justify-content-center gap-2">
                            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"
                                (click)="onRowEditInit(group)" class="p-button-rounded p-button-text"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                                (click)="onRowEditSave()"
                                class="p-button-rounded p-button-text p-button-success mr-2"></button>
                            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                                (click)="onRowEditCancel(group, ri)"
                                class="p-button-rounded p-button-text p-button-danger"></button>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate=" emptymessage">
                <tr>
                    <td colspan="5">Não foram encontrados grupos.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</div>
