<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>
    <div class="grid">
        <div class="col-2" *ngIf="navbarVisible">
            <app-navbar></app-navbar>
        </div>
    
    <div class="col-10" [class.col-12]="!navbarVisible">
    <form [formGroup]="formNotification">
        <div class="grid">
            <div class="col-3">
                <input type="text" class="flex w-full" pInputText placeholder="Titulo" formControlName="titulo" />                
            </div>
            <div class="col-3">
                <p-dropdown formControlName="action" [options]="actions" optionLabel="name" placeholder="Selecione uma ação"></p-dropdown>
            </div>
            <div class="col-3">
                <p-dropdown formControlName="intent" [options]="intents" optionLabel="intent" placeholder="Selecione a intenção"></p-dropdown>
            </div>
        </div>
        <div class="grid">
            <div class="col-6">
                <div class="card">
                    <p-editor formControlName="text" [style]="{ height: '220px' }" (keydown)="onKeyDown($event)"
                        [readonly]="countTextDisebled">
                        <ng-template pTemplate="header">
                            <span class="ql-formats">
                                <button type="button" class="ql-bold" aria-label="Bold"></button>
                                <button type="button" class="ql-italic" aria-label="Italic"></button>
                                <button type="button" class="ql-underline" aria-label="Underline"></button>
                            </span>
                        </ng-template>
                    </p-editor>
                </div>
            </div>
            <div class="col-6">
                <p-fileUpload  
                chooseLabel="Foto" 
                [showUploadButton]=false 
                uploadLabel="Publicar" 
                cancelLabel="Cancelar"
                [multiple]="false"
                [customUpload]="true"
                 accept="image/*" 
                  #file>
                </p-fileUpload>

                <p-button label="Salvar" icon="pi pi-save" styleClass="p-button-primary mt-5"
                (onClick)="sendNotification(file)" type="reset">
                </p-button>
            </div>
        </div>
        <div class="grid">
            <div class="col-12">
                <p-button label="Cancelar" icon="pi pi-times-circle" styleClass="p-button-secondary m-1"
                    (onClick)="resetCountText()" type="reset">
                </p-button>
                    <p-multiSelect
                    [options]="clients"
                    [showToggleAll]="true"
                    [selectAll]="selectAll"
                    formControlName="client"
                    optionLabel="name"
                    [virtualScroll]="true"
                    [filter]="true"
                    [virtualScrollItemSize]="43"
                    class="multiselect-custom-virtual-scroll m-2"
                    placeholder="Selecione a empresa para enviar notificação"
                    (onSelectAllChange)="onSelectAllChange($event)"
                    (onChange)="onChange($event)"
                ></p-multiSelect>
                </div>
            </div>
    </form>
    <div class="grid">
        <div class="col-12">
            158 / {{countText}}
        </div>
    </div>
</div>
    </div>
</div>