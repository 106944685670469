
<div class="surface-0">
  <app-menubar></app-menubar>
  <p-toast></p-toast>

  <div class="grid mb-2">
      <div class="col-2"  *ngIf="navbarVisible">
        <app-navbar></app-navbar>
      </div>

      <div class="col-10"  [class.col-12]="!navbarVisible">
        <label for="nomeCategoria">Descrição da categoria</label>
        <input id="nomeCategoria" name="nomeCategoria" pInputText placeholder="Digite o nome da categoria" class="w-full"
          [(ngModel)]="nomeCategoria" />
          <p-fileUpload chooseLabel="Foto" [showUploadButton]=true uploadLabel="Publicar" cancelLabel="Cancelar"
            [customUpload]="true" accept="image/*" (uploadHandler)="criarCategoria($event)">
          </p-fileUpload>
      </div>
      </div>
</div>