import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from "../storage.service";


@Injectable({
    providedIn: 'root'
})

export class AwsService{
    public urlApi = environment.apiUrl;

    constructor(private readonly http:HttpClient, private storageService: StorageService){}


    uploadDocManualSignature(doc:any, event:any){
        const formData = new FormData();
        formData.append('arquivo', event.files[0]);
        formData.set('empresa_fk', doc[0].empresa_fk);
        formData.set('usuario_fk', doc[0].usuario_fk);
        formData.set('fer_cab_fk', doc[0].fer_id);
        

        const { token } = this.storageService.getUserLogado();
        const httpOptions = {
          headers: new HttpHeaders({
            'x-access-token': token,
          }),
        };
        console.log(doc);
        
        const url = `${this.urlApi}/aws/upload/manual/signature`;
        return this.http.post(url, formData, httpOptions);
    }
}