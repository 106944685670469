<div class="surface-0">
    <app-menubar></app-menubar>
    <p-toast></p-toast>

    <div class="grid">
    <div class="col-2" *ngIf="navbarVisible">
        <app-navbar></app-navbar>
    </div>
    <div class="col-10" [class.col-12]="!navbarVisible">
        <p-table 
        #dt1 
        [value]="adesoesDetalhado" 
        [(selection)]="selectedadesoesDetalhado" 
        dataKey="id" 
        [rows]="50"
        [showCurrentPageReport]="true" 
        [rowsPerPageOptions]="[100, 200, 300]" 
        [loading]="loading" 
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['id', 'cpf', 'date', 'nomefuncionairo', 'parcelasPaga', 'empresa', 'loja', 'dataFaturamento', 'datapedido', 'descricaoProduto', 'qtdParcelas']" 
        scrollDirection="horizontal" 
        [scrollable]="true"
        [filterDelay]="0"
        scrollHeight="600px" 
        editMode="row">

            <ng-template pTemplate="caption">
                <div class="print-docs">
                    <div>
                        <p-dropdown
                        class="listEmpresas" 
                        [options]="companies" 
                        [(ngModel)]="selectedCompanies" 
                        optionLabel="name"
                        placeholder="Escolher empresa">
                        </p-dropdown>
                        
                        <p-dropdown
                        class="listEmpresas" 
                        [options]="clients" 
                        [(ngModel)]="selectedClient" 
                        optionLabel="name"
                        placeholder="Escolher empresa cliente">
                        </p-dropdown>



                        <p-calendar 
                        [(ngModel)]="date"
                        placeholder="Data desconto"
                        (click)="onChangeDateSeleted()"
                        >
                        </p-calendar>

                        <p-button icon="pi pi-search" label="" class="m-2" (click)="getRelatoriosAdesaoDetalhado()"></p-button>
                        <p-splitButton 
                            label="" 
                            [model]="items" 
                            icon="pi pi-file-pdf"  
                            styleClass="p-button-raised p-button-danger">
                        </p-splitButton>
                    </div>
                    <div>
                        
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="funcionario">Funcionário</th>
                    <th>Cpf</th>
                    <th>Verba</th>
                    <th>Produto</th>
                    <th>Valor</th>
                    <th>Prazo</th>
                    <th class="dataFaturamento">Data Adesão</th>
                    <th>Comissão</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-faturamento>
                <tr>
                    <td>{{ faturamento.nomefuncionairo }}</td>
                    <td>{{ faturamento.cpf}}</td>
                    <td>{{ faturamento.code }} </td>
                    <td>{{ faturamento.descricaoProduto }}</td>
                    <td>{{ faturamento.valorParcela | currency:'BRL'}}</td>
                    <td>{{ faturamento.qtdParcelas }}</td>
                    <td>{{ faturamento.datapedido | datetime}}</td>
                    <td>{{ faturamento.comissao | currency:'BRL' }}</td>
                </tr>

            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não foi encontrada nenhuma categoria.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
</div>