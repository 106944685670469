<div class="surface-0">
  <p-toast></p-toast>
  <app-menubar></app-menubar>
  <div class="grid">
    <div class="col-2 bg-secondary" *ngIf="navbarVisible">
      <app-navbar></app-navbar>
    </div>

    <div class="col-10" [class.col-12]="!navbarVisible">
      <div class="card">
        <p-table #dt1 [value]="docConcluded" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 50, 100]"
          currentPageReportTemplate="Mostrando de {first} a {last} de um total de {totalRecords} registro(s)"
          [totalRecords]="totalRecords" [lazy]="true" (onLazyLoad)="listDocConcluded($event)" [paginator]="true"
          [loading]="loading" scrollDirection="horizontal" [scrollable]="true" scrollHeight="600px" editMode="row"
          [(selection)]="doConcludedSelected">

          <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
              <div>
                PESQUISAR POR:
                Locação
                <p-columnFilter [showAddButton]="false" matchMode="equals" [showOperator]="false"
                  [matchModeOptions]="[{label: 'igual', value: 'equals'}]" type="text" field="num_centro_custo"
                  display="menu">
                </p-columnFilter>
                Orgão
                <p-columnFilter [showAddButton]="false" matchMode="contains" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Contém', value: 'contains'}]" type="text" field="centro_custo"
                  display="menu">
                </p-columnFilter>
              </div>
              <div>
                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                  class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom">
                </button>
              </div>

            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th style="min-width: 20rem;">
                Empresa
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Contém', value: 'contains'}]" type="text" field="nome_fantasia"
                  display="menu"></p-columnFilter>
              </th>
              <th style="min-width: 25rem;">
                Nome Funcionário
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Contém', value: 'contains'}]" type="text" field="nome"
                  display="menu"></p-columnFilter>
              </th>
              <th style="min-width: 10rem;">
                CPF
                <p-columnFilter [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" [showAddButton]="false"
                  [showOperator]="false" type="text" field="cpf" display="menu">
                </p-columnFilter>
              </th>
              <th>
                Matricula
                <p-columnFilter [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" [showAddButton]="false"
                  [showOperator]="false" type="text" field="matricula" display="menu">
                </p-columnFilter>
              </th>
              <th>Locação</th>
              <th pSortableColumn="fer_ini" style="min-width: 10rem;">
                Início
                <p-sortIcon field="fer_ini"></p-sortIcon>
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" type="text" slotChar="dd/mm/yyyy"
                  field="fer_ini" display="menu" class="ml-auto"></p-columnFilter>
              </th>
              <th pSortableColumn="fer_fim" style="min-width: 10rem;">
                Fim
                <p-sortIcon field="fer_fim"></p-sortIcon>
                <p-columnFilter [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" [showAddButton]="false"
                  [showOperator]="false" type="text" slotChar="dd/mm/yyyy" field="fer_fim" display="menu"
                  class="ml-auto"></p-columnFilter>
              </th>
              <th pSortableColumn="warning_sended_at">
                Data Envio
                <p-sortIcon field="warning_sended_at"></p-sortIcon>
                <p-columnFilter [showAddButton]="false" [showOperator]="false" type="date" mask="999-99-9999"
                  placeholder="99-99-9999" slotChar="dd/mm/yyyy" field="warning_sended_at" display="menu"
                  class="ml-auto"></p-columnFilter>
              </th>
              <th pSortableColumn="warning_acceppted_at">
                Assinatura
                <p-sortIcon field="warning_acceppted_at"></p-sortIcon>
                <p-columnFilter [showAddButton]="false" [showOperator]="false" type="date" mask="999-99-9999"
                  placeholder="99-99-9999" slotChar="dd/mm/yyyy" field="warning_acceppted_at" display="menu"
                  class="ml-auto"></p-columnFilter>
              </th>
              <th>Wesing
              </th>
              <th>Status
                <p-columnFilter [showAddButton]="false" [showOperator]="false"
                  [matchModeOptions]="[{label: 'Igual', value: 'equals'}]" type="text" field="manual_status"
                  display="menu" class="ml-auto"></p-columnFilter>
              </th>
              <th style="min-width: 10rem">
                Action
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-doc let-ri="rowIndex">
            <tr>
              <td style="min-width: 20rem;">{{ doc.empresas.nome_fantasia }}</td>
              <td style="min-width: 25rem;">{{ doc.usuarios.dados_funcionarios.nome }}</td>
              <td style="min-width: 10rem;">{{ doc.usuarios.dados_funcionarios.cpf | cpf }}</td>
              <td>{{ doc.usuarios.dados_funcionarios.matricula }}</td>
              <td>{{doc.usuarios.dados_funcionarios.num_centro_custo}}</td>
              <td style="min-width: 4rem;">{{ doc.fer_ini }}</td>
              <td style="min-width: 7rem;">{{ doc.fer_fim }}</td>
              <td style="min-width: 8rem;"> {{ doc.created_at | localdate }} </td>
              <td> {{ doc.warning_acceppted_at | localdate }} </td>
              <td>{{doc.doc_signature[0]?.status || 'manual'}}</td>
              <td style="min-width: 8rem;">
                <ng-template [ngIf]="doc?.usuarios?.notifications_tokens?.length > 0" [ngIfElse]="notification">
                  <div class="flex justify-content-between">
                    <div>{{doc.manual_status}}</div>
                    <div class="userYesNotification"></div>
                  </div>
                </ng-template>

                <ng-template #notification>
                  <div class="flex justify-content-between">
                    <div> {{doc.manual_status}}</div>
                    <div class="userNotNotification"></div>
                  </div>
                </ng-template>
              </td>
              <td class="flex align-items-center justify-content-start" style="min-width: 10rem;">

                <div *ngIf="doc.doc_signature?.length > 0">
                  <button pButton pRipple pTooltip="Assinatura Digital" tooltipPosition="left" type="button"
                    icon="pi pi-file-pdf" class="p-button-rounded p-button-info p-button-text p-button-raised ml-1"
                    (click)="printDocwessignCloclued(doc)">
                  </button>
                </div>

                <div *ngIf="doc.doc_signature_manual.length  > 0 ">
                  <button pButton pTooltip="Assinatura Manual" tooltipPosition="left" pRipple type="button"
                    icon="pi pi-file-pdf" class="p-button-rounded p-button-info p-button-text p-button-raised ml-1"
                    (click)="downloadSignatureMnualAws(doc)">
                  </button>
                </div>
                <div>
                  <button pButton pRipple pTooltip="Editar dados" tooltipPosition="left" type="button"
                    icon="pi pi-pencil" class="p-button-rounded p-button-info p-button-text p-button-raised"
                    (click)="modalEditdataEmploee(doc)">
                  </button>
                </div>
                <div>
                  <button pButton pRipple pTooltip="Cancelamento de férias" tooltipPosition="left" type="button"
                    icon="pi pi-times" class="p-button-rounded p-button-warning p-button-text p-button-raised ml-1"
                    (click)="showmodal(doc)">
                  </button>
                </div>

              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Não foi encontrada nenhuma categoria.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="card flex justify-content-center gap-2">
        <p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
      </div>
    </div>
    <!--modal-dialog-->
    <div class="card flex justify-content-center">
      <p-dialog header="Informe o motivo do cancelamento" [(visible)]="visible" [style]="{ width: '50vw' }">
        <form [formGroup]="docForm">
          <textarea rows="5" cols="80" pInputTextarea formControlName="motivo_cancelamento"></textarea>
        </form>
        <p-button label="Enviar" [loading]="loading" (click)="cancelDocWessign()"></p-button>
      </p-dialog>




      <!--modal--edit--dados--invalidos-->
      <p-dialog header="{{doConcludedSelected[0]?.usuarios?.dados_funcionarios?.nome}}" [(visible)]="visibleEdit" [style]="{width: '30vw'}">
        <form [formGroup]="editForm">
          <div class="grid">
            <div class="p-inputgroup field flex">
              <div class="col-2">
                <input
                type="text"
                class="flex w-full"
                pInputText
                placeholder="ddd"
                maxlength="2"
                formControlName="ddd_celular"
              />
              </div>
              <div class="col-10">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  maxlength="9"
                  minlength="9"
                  placeholder="Celular"
                  formControlName="celular"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-12">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Email"
                  formControlName="email"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-12">
                <input
                  type="text"
                  class="flex w-full"
                  pInputText
                  placeholder="Nome"
                  formControlName="nome"
                />
                
              </div>
            </div>

            <div class="p-inputgroup field flex">
              <div class="col-6">
                <label for="buttondisplay" class="font-bold block mb-2"> Inicio das férias </label>
                <p-calendar 
                  formControlName="fer_ini" 
                  dateFormat="dd/mm/yy"
                  [showIcon]="true" 
                  inputId="buttondisplay" 
                  [showOnFocus]="false" >
              </p-calendar>
              </div>
              <div class="col-6">
                <label for="buttondisplay" class="font-bold block mb-2"> Fim das férias </label>
                <p-calendar 
                  formControlName="fer_fim" 
                  dateFormat="dd/mm/yy"
                  [showIcon]="true" 
                  inputId="buttondisplay" 
                  [showOnFocus]="false" >
              </p-calendar>
                
              </div>
            </div>
          </div>
        </form>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" 
            [disabled]="editForm.status === 'INVALID' ? true : false"
             (click)="editarCadastro()" label="EDITAR" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    </div>
  </div>
</div>