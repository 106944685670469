import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'situacaoPedidoConvenio'
})
export class SituacaoPedidoConvenioPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let ret = {
      "A": "ABERTO",
      "C": "CANCELADO",
      "F": "FINALIZADO"
    }

    return ret[value];
  }
}
