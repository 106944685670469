import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sexo'
})
export class SexoPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const fromTo = {
      'M': 'MASCULINO',
      'F': 'FEMININO'
    }
    return fromTo[value];
  }

}
