import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams
} from '@angular/common/http';
import { StorageService } from '../storage.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public urlApi = environment.apiUrl;
  constructor(public http: HttpClient, public storageService: StorageService) { }

  public notificationsProductShop(notifications: any, formData:FormData) {
    const { token } = this.storageService.getUserLogado();
    const bodyText = notifications.text.replace(/<\/?p>/g, '');
    console.log(notifications.produto.store_categories);
    
    const companys:any = [];
      for (const item of notifications.client) {
       companys.push(item.id)
      }


    let params = new HttpParams();
    params = params
      .append('titulo', notifications.titulo)
      .append('intent', notifications.intent.intent)
      .append('action', notifications.action.name)
      .append('text', bodyText)
      .append('companys', companys)
      .append('apiUrl', this.urlApi)
      /* category */
      .append('idCategory', notifications.produto.store_categories.id)
      .append('description', notifications.produto.store_categories.description)
      .append('img', notifications.produto.store_categories.img)
      /*product*/
      .append('idProduto', notifications.produto.id)
      .append('name', notifications.produto.name)
      .append('details_product', notifications.produto.details_product)
      .append('product_price', notifications.produto.product_price)
      .append('number_plots', notifications.produto.number_plots)
      .append('valor_parcela', notifications.produto.valor_parcela)
      .append('taxa_adesao', notifications.produto.taxa_adesao)
      .append('taxa_adm', notifications.produto.taxa_adm)

    let options = {
      headers: new HttpHeaders({
        'x-access-token': token
      }),
      params,
    };
    
    
    const url = `${this.urlApi}/notification/products/intent`;
    return this.http.post(url,  formData, options);
  }

  
  public notificationsDefault(notifications: any, formData:FormData) {
    const { token } = this.storageService.getUserLogado();
    const bodyText = notifications.text.replace(/<\/?p>/g, '');
   
    const companys:any = [];
      for (const item of notifications.client) {
       companys.push(item.id)
      }

    let params = new HttpParams();
    params = params
      .append('titulo', notifications.titulo)
      .append('intent', notifications.intent.intent)
      .append('action', notifications.action.name)
      .append('text', bodyText)
      .append('companys', companys)
      
    let options = {
      headers: new HttpHeaders({
        'x-access-token': token
      }),
      params,
    };
    
    const url = `${this.urlApi}/notification/default`;
    return this.http.post(url,  formData, options);
  }

}
