import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogarithmicScale } from 'chart.js';
import { MessageService } from 'primeng/api';

import { Company } from 'src/app/models/company';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar-service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProductService } from 'src/app/services/shop/product.service';
@Component({
  selector: 'app-criar-notificacao',
  templateUrl: './criar-notificacao.component.html',
  styleUrls: ['./criar-notificacao.component.scss'],
})
export class CriarNotificacaoComponent implements OnInit {
  countText: number  = 0;
  countTextDisebled: boolean = false;
  clients: Company[] = [];
  selectedItems!: any[];
  intents: any[];
  products: any[] = [];
  selectAll = false;
  formNotification: FormGroup;
  actions: any[];
  navbarVisible:boolean;

  constructor( 
    private formBuild: FormBuilder,
    private messageservice: MessageService,
    private apiService: ApiService,
    private notificationService: NotificationsService,
    private productService: ProductService,
    private navbarService: NavbarService
    ) {}

  ngOnInit(): void {
    this.intents = [{intent: 'PRODUCT_INTENT'}]
    this.formNotification = this.formBuild.group({
      text:['', [Validators.required, Validators.maxLength(200)]],
      client: ['', [Validators.required]],
      titulo: ['', Validators.required],
      action: ['', Validators.required],
      intent:['', Validators.required],
      produto: ['', Validators.required]
    })
    this.getAllCompanies();
    this.selectAllProducts();

    this.actions = [{name:'/view-product'}]

    this.navbarService.navbarVisible$.subscribe(show => {
      this.navbarVisible = show;
    });
  }



  onKeyDown(event:any){
    const {keyCode, key} = event
    if(keyCode !== 8){
    this.countText +=  1 
   }else if(key === 'Backspace' && this.countText >= 1){
    this.countText -=  1 
   }

    if(this.countText >= 158){
      this.countTextDisebled = true;
      this.countText = 0
        this.messageservice.add({ 
          severity: 'warn', 
          summary: 'Comunicação', 
          detail: `Quantidade maxima ${this.countText} caracteres` })
    }
    
  }

   resetCountText(){
    this.countText = 0
  }


  getAllCompanies(){
    this.apiService.getAllcompany().subscribe((res:Company[]) =>{
    const filterCompanies = res.filter(item =>  item.tipo === 'Cliente')
    filterCompanies.map((item) => {
      this.clients.push({
        name: item.nome_fantasia,
        id: item.id
      })
    })

    })
  }

  onSelectAllChange(event) {
    this.selectedItems = event.checked ? [...this.clients] : [];
    this.selectAll = event.checked;
}

onChange(event) {
    const { originalEvent, value } = event
    if(value) this.selectAll = value.length === this.clients.length;
}

sendNotification(event: any) {
    const formData = new FormData();
    for (let i = 0; i <= event.files.length; i++) {
      formData.append('arquivo', event.files[i]);
    }
    this.notificationService.notificationsProductShop(this.formNotification.value, formData).subscribe({
      next: (res:any) => {
        this.messageservice.add({ 
          severity: 'info', 
          summary: res.message
         })
      },
      complete: () => {
        this.formNotification.reset();
        window.location.href = '/criar-notificacao'
      },
      error: (erro) => {
        this.messageservice.add({ 
          severity: 'warn', 
          summary: 'notificação', 
          detail: 'Error ao enviar notificação' 
         })
      }
    })
}

selectAllProducts(){
  this.productService.listAllProdutos().subscribe({
    next: (res:any) => {  
     res.resultProducts.map((item:any) => {
      this.products.push({
        id: item.id,
        name: item.description,
        details_product: item.details_product,
        product_price: item.price,
        number_plots: item.number_plots,
        valor_parcela: item.valor_parcela,
        taxa_adesao: item.taxa_adesao[0].taxa_adesao,
        taxa_adm: item.taxa_adm,
        store_categories: item.store_categories
      })
    })
    },
    complete: () => {},
    error: () => {}
  })
}

}
